import React, { useState, useRef, forwardRef, type ForwardedRef } from 'react';
import classNames from 'classnames';
import noop from 'lodash/fp/noop';

import useAfterMount from '../../hooks/useAfterMount';

const DEFAULT_DIGIT_PRECISION = 3;
export const DEFAULT_VALUE = 0;
export const DEFAULT_STEP = 1;
export const DEFAULT_MIN = 0;
export const DEFAULT_MAX = Number.MAX_VALUE;

const validKeys = [
    '0',
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '.',
    ',',
    '-',
    'Backspace',
    'Tab',
    'ArrowLeft',
    'ArrowRight',
    'ArrowDown',
    'ArrowUp',
];

// Note: even if limits are set and input type is number, many browsers allow to enter invalid data
// like entering characters or values outside the boundaries, hence we have to check the input here
// See https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/number

const getValueFromProps = (
    val: number | undefined,
    min: number,
    max: number,
    placeholder: string | undefined,
    noDefault: boolean
) => {
    // Show placeholder or nothing if given instead of 0 if requested for
    if (val === undefined && (placeholder || noDefault)) {
        return '';
    }
    return clampNumber(Number(val), min, max) || DEFAULT_VALUE;
};

const clampNumber = (value: number, min: number, max: number) => {
    if (value < min) {
        return min;
    }
    if (value > max) {
        return max;
    }
    return value;
};

export const convertNonIntegerToDefault = (value: number | undefined, fallback: number) => {
    return typeof value === 'number' && Number.isFinite(value) ? value : fallback;
};

const isMultipleKeyInput = (
    key: string,
    event: React.KeyboardEvent<HTMLInputElement>,
    previousKeyRef: React.MutableRefObject<string>
) => {
    return event.key === key && previousKeyRef.current.includes(key);
};

const isInvalidAfter = (
    key: string,
    possiblePreviousKeys: string[],
    event: React.KeyboardEvent<HTMLInputElement>,
    previousKeyRef: React.MutableRefObject<string>
) => {
    return event.key === key && possiblePreviousKeys.includes(previousKeyRef.current);
};

export type NumberInputProps = Omit<React.HTMLProps<HTMLInputElement>, 'onChange' | 'controls'> & {
    /**
     * A native input id attribute.
     *
     * Passed through as HTML attribute to the input element.
     */
    id?: string;

    /**
     * The minimum value of the input.
     *
     * @default 0
     */
    min?: number;

    /**
     * The maximum value of the input.
     *
     * @default Number.MAX_VALUE
     */
    max?: number;

    /**
     * The initial value of the input.
     *
     * Used to control the component from the outside.
     *
     * @default 0
     */
    value?: number;

    /**
     * Lets you omit the default value of "0" when the vale is not defined.
     *
     * The input will be empty in this case.
     *
     * @default false
     */
    noDefault?: boolean;

    /**
     * The size of increment or decrement (only works with number type).
     *
     * @default 1
     */
    step?: number;

    /**
     * Enables or disabled the input.
     *
     * @default false
     */
    disabled?: boolean;

    /**
     * Callback function triggered when the input value changes.
     *
     * When the value is removed by the user, the input is kept empty, but it triggers the callback without any value
     * since the user has finished his input.
     *
     * @param value The new value of the input.
     */
    onChange?: (value?: number) => void;

    /**
     * @deprecated Please use `onChange` instead.
     */
    onValueChanged?: (value?: number) => void;

    /**
     * Callback function that gets triggered after filtering out invalid keystrokes.
     *
     * @param event The keyboard event.
     */
    onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;

    /**
     * Defines the size of the input to be rendered.
     *
     * @default 'md'
     */
    bsSize?: 'sm' | 'md' | 'lg';

    /**
     * A unit for this value.
     *
     * This will be shown in a dedicated input addon.
     */
    unit?: string | React.ReactNode;

    /**
     * A rioglyph icon tio be shown in front of the input.
     */
    inputAddon?: string;

    /**
     * Input error message.
     */
    errorMessage?: string | React.ReactNode;

    /**
     * Input warning message.
     */
    warningMessage?: string | React.ReactNode;

    /**
     * Feedback message width.
     *
     * @default 'normal'
     */
    messageWhiteSpace?: 'normal' | 'nowrap' | 'pre-line';

    /**
     * This prop is used by the NumberControl component to pass on the +/- spinner controls.
     *
     * @internal
     */
    controls?: React.ReactNode;

    /**
     * The input placeholder if no value is given.
     */
    placeholder?: string;

    /**
     * Number of digits after the comma the value should be fixed to.
     *
     * @default 3
     */
    digitPrecision?: number;

    /**
     * Additional classes to be set on the component.
     */
    className?: string;
};

const NumberInput = forwardRef((props: NumberInputProps, ref: ForwardedRef<HTMLInputElement>) => {
    const {
        id,
        min: propMin,
        max: propMax,
        value: propValue,
        step = DEFAULT_STEP,
        disabled = false,
        noDefault = false,
        onChange,
        onValueChanged = noop,
        onKeyDown = noop,
        bsSize = 'md',
        unit,
        inputAddon,
        errorMessage,
        warningMessage,
        messageWhiteSpace = 'normal',
        controls,
        placeholder,
        digitPrecision = DEFAULT_DIGIT_PRECISION,
        className = '',
        ...remainingProps
    } = props;

    const previousKeyRef = useRef<string>('');

    const callback = onChange || onValueChanged;

    const min = convertNonIntegerToDefault(propMin, DEFAULT_MIN);
    const max = convertNonIntegerToDefault(propMax, DEFAULT_MAX);

    const value = getValueFromProps(propValue, min, max, placeholder, noDefault);

    // Define local state and define initial values
    const [state, setState] = useState({
        value,
        enteredValue: value,
        isValid: true,
    });

    // Update internal value whenever the value prop from outside changes
    useAfterMount(() => {
        setState({
            value: getValueFromProps(propValue, min, max, placeholder, noDefault),
            enteredValue: value,
            isValid: true,
        });
    }, [propValue, min, max, placeholder, noDefault, value]);

    const applyValue = (newValue: string | number) => {
        if (newValue === '-' || newValue === '') {
            setState({
                value: newValue,
                enteredValue: newValue,
                isValid: true,
            });
            return;
        }

        const enteredValue = Number(newValue);
        const isValid = !isNaN(enteredValue) && enteredValue >= min && enteredValue <= max;
        const newValidValue = clampNumber(enteredValue, min, max);

        setState({
            value: newValidValue,
            enteredValue,
            isValid,
        });

        // Only call back the caller for valid values
        isValid && callback(newValidValue);
    };

    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        applyValue(event.target.value);
    };

    // Prevent entering exponent to avoid side effects
    // See https://bugzilla.mozilla.org/show_bug.cgi?id=1398528
    // Also preventing letter inputs or multiple consecutive characters
    // that is possible by Firefox and Safari
    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        // Allow for key combination like "ctrl + a"
        if (event.ctrlKey || event.altKey || event.metaKey) {
            return;
        }

        // Check for multiple dots, commas or dashes (consecutive)
        if (
            isMultipleKeyInput('.', event, previousKeyRef) ||
            isMultipleKeyInput(',', event, previousKeyRef) ||
            isMultipleKeyInput('-', event, previousKeyRef) ||
            isInvalidAfter(',', ['.', '-'], event, previousKeyRef) ||
            isInvalidAfter('.', [',', '-'], event, previousKeyRef) ||
            isInvalidAfter('-', ['.', ','], event, previousKeyRef) ||
            event.key === 'Process' ||
            event.key === 'Dead'
        ) {
            event.preventDefault();
        }

        // Filter out everything that is not a number
        if (!validKeys.includes(event.key)) {
            event.preventDefault();
        }

        previousKeyRef.current = event.key;

        onKeyDown(event);
    };

    const handleBlur = () => {
        // When the value is removed, keep the input empty but trigger the outside callback
        // since the user has finished his input
        const lastEnteredValue = state.enteredValue;
        if (lastEnteredValue === '') {
            callback();
            return;
        }

        // If there is no value defined and a placeholder is given or the default is not wanted,
        // keep the input empty
        if (!state.value && (noDefault || placeholder)) {
            return;
        }

        // Otherwise, validate the input, round it according to digitPrecision,
        // and clamp the value if the entered value exceeds the limitations
        const convertedEnteredValue = convertNonIntegerToDefault(Number(lastEnteredValue), DEFAULT_VALUE);
        const validNumber = clampNumber(Number(convertedEnteredValue.toFixed(digitPrecision)), min, max);
        applyValue(validNumber);
    };

    const handleWheel = (event: React.WheelEvent<HTMLInputElement>) => {
        const target = event.target;
        if (target instanceof HTMLInputElement) {
            target.blur();
        }
    };

    const inputGroupClassNames = classNames(
        'input-group',
        bsSize === 'sm' && 'input-group-sm',
        bsSize === 'lg' && 'input-group-lg'
    );

    const inputClassNames = classNames(
        'form-control',
        'no-controls',
        bsSize === 'sm' && 'input-sm',
        bsSize === 'lg' && 'input-lg',
        className
    );

    const hasFeedback = errorMessage || warningMessage;

    const input = (
        <input
            {...remainingProps}
            id={id}
            type='number'
            step={step}
            min={min}
            max={max}
            value={state.isValid ? state.value : state.enteredValue}
            className={inputClassNames}
            disabled={disabled}
            onBlur={handleBlur}
            // onChange={state.value ? handleOnChange : undefined}
            onChange={handleOnChange}
            onKeyDown={handleKeyDown}
            ref={ref}
            aria-label='number-input'
            placeholder={placeholder}
            onWheel={handleWheel}
        />
    );

    return (
        <div className={inputGroupClassNames}>
            {inputAddon && (
                <div className='input-group-addon'>
                    <span className={inputAddon} />
                </div>
            )}
            <div className='form-control-feedback-wrapper'>
                {input}
                {hasFeedback && (
                    <>
                        {errorMessage && <span className='form-control-feedback rioglyph rioglyph-error-sign' />}
                        {warningMessage && <span className='form-control-feedback rioglyph rioglyph-warning-sign' />}
                        <span className={`help-block white-space-${messageWhiteSpace}`}>
                            <span>{errorMessage || warningMessage}</span>
                        </span>
                    </>
                )}
            </div>
            {(unit || controls) && (
                <div className={`input-group-addon ${disabled ? 'disabled pointer-events-none' : ''}`}>
                    {unit && unit}
                    {controls && controls}
                </div>
            )}
        </div>
    );
});

export default NumberInput;
