// @ts-ignore-next-line importsNotUsedAsValues
import React, { type PropsWithChildren, type ReactElement } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import type { AnimationDefinition } from 'framer-motion/types/render/utils/animation';
import noop from 'lodash/fp/noop';

const DEFAULT_ANIMATION_TIME_IN_MS = 200;

type CollapseProps = {
    /**
     * Show the component and triggers the expand or collapse animation.
     * @default false
     */
    open: boolean | undefined;

    /** Deprecated, please use `show` instead. @deprecated */
    in?: boolean;

    /**
     * Unmount the component (remove it from the DOM) when it is collapsed.
     */
    unmountOnExit?: boolean;

    /**
     * Run the expand animation when the component mounts, if it is initially shown.
     * @default false
     */
    appear?: boolean;

    /**
     * Duration of the collapse animation in milliseconds.
     * @default 270
     */
    timeout?: number;

    /**
     * Callback fired after the component has expanded.
     * @returns
     */
    onEntered?: () => void;

    /**
     * Callback fired after the component has collapsed.
     * @returns
     */
    onExited?: () => void;

    /**
     * Callback fired when the animation starts for either expand or collapse.
     */
    onAnimationStart?: () => void;
};

const Collapse = (props: PropsWithChildren<CollapseProps>) => {
    const {
        open = false,
        unmountOnExit = false,
        appear = false,
        timeout = DEFAULT_ANIMATION_TIME_IN_MS,
        onEntered = noop,
        onExited = noop,
        onAnimationStart = noop,
        children,
    } = props;

    const shouldKeepContentInDom = !unmountOnExit;

    const handleAnimationStart = () => onAnimationStart();
    const handleAnimationComplete = (definition: AnimationDefinition) => {
        if (definition === 'open') {
            onEntered();
        }
        if (definition === 'collapsed') {
            onExited();
        }
    };

    const variants = {
        open: { opacity: 1, height: 'auto' },
        collapsed: { opacity: 0, height: 0 },
    };

    return (
        <>
            {/* @ts-ignore-next-line */}
            <AnimatePresence initial={appear} exitBeforeEnter>
                {(open || shouldKeepContentInDom) && (
                    <motion.div
                        key='content'
                        initial='collapsed'
                        animate={open ? 'open' : 'collapsed'}
                        exit='collapsed'
                        className='collapse display-block width-100pct'
                        variants={variants}
                        transition={{ duration: timeout / 1000, ease: 'easeIn' }}
                        onAnimationComplete={handleAnimationComplete}
                        onAnimationStart={handleAnimationStart}
                    >
                        {children}
                    </motion.div>
                )}
            </AnimatePresence>
        </>
    );
};
//

export default Collapse;
