import { Playground, PLAYGROUNG_BG_LIGHTEST } from '../../../components/playground/Playground';
import {
    PlaygroundClasses,
    PlaygroundResponsiveClasses,
    PlaygroundClassesSpacer,
} from '../../../components/playground/PlaygroundClasses';

import { Description } from '../../../components/Description';
import Button from '../../../../../src/Button';
import Divider from '../../../../../src/Divider';
import RadioButton from '../../../../../src/RadioButton';
import { dummyText } from '../../../utils/data';

export const Grid = () => {
    return (
        <div>
            <Description headline='Grid' anchor='grid' />
            <Playground example={gridColsExample} classes={gridColsClasses} />
            <Description headline='Grid colspan / rowspan' />
            <Playground example={gridColSpanExample} classes={gridColSpanClasses} />
            <Description headline='Grid column-start / column-end' />
            <Playground example={gridColumnPlacementExample} classes={gridColumnPlacementClasses} />
            <Description headline='Grid auto-fit' />
            <p className='max-width-1000'>
                In CSS Grid, the <code className='code-inline'>auto-fit</code> keyword is used with the repeat()
                function to automatically adjust the number of columns (or rows) based on the available space in the
                container. It helps create responsive layouts by filling the container with as many columns (or rows) of
                a specified size as possible.
            </p>
            <p className='max-width-1000'>
                Dynamic Column Creation: "auto-fit" automatically creates as many columns as will fit in the available
                space, potentially adding or removing columns as the container resizes.
            </p>
            <p className='max-width-1000'>
                Responsive Design: Ensures that the grid adapts to different screen sizes by adjusting the number of
                columns.
            </p>
            <Playground example={gridAutoFitExample} classes={gridAutoFitClasses} />
            <Description headline='Grid max-content / auto content' />
            <p className='max-width-1000'>
                The <code className='code-inline'>max-content</code> value sizes the grid column to be as wide as the
                largest piece of content inside it. Essentially, it takes up as much space as necessary to fit the
                longest word or item without breaking. This is useful when you want the column to expand to fit its
                content, ensuring no content is clipped or wrapped unnecessarily.
            </p>
            <p className='max-width-1000'>
                The <code className='code-inline'>auto</code> value sizes the grid column based on the content, but it
                can also take into account the available space and other factors. If there's more space available,
                "auto" can expand to fill it, but it won't exceed the available space like "max-content". This is useful
                when you want the column to take up space according to its content but also adapt to the overall layout
                and available space.
            </p>
            <p className='max-width-1000'>
                Choosing between <code className='code-inline'>max-content</code> and{' '}
                <code className='code-inline'>auto</code> depends on whether you want the column to strictly fit its
                content (potentially ignoring the available space) or to adapt to the content while also being flexible
                within the grid layout.
            </p>
            <Playground example={gridMaxContentExample} classes={gridMaxContentClasses} />
            <Description headline='Grid rows' />
            <p className='max-width-1000'>Helper class for 3 rows where the middle row expands and can scroll</p>
            <Playground example={gridRowExample} classes={gridRowClasses} background={PLAYGROUNG_BG_LIGHTEST} />
            <Description headline='Grid placement' />
            <Playground example={gridPlacementExample} classes={gridPlacementClasses} />
            <Description headline='Grid stacking'>
                This is an alternative to the commonly known position absolute using{' '}
                <code className='code-inline'>grid-template-areas</code> under the hood and giving all items the same
                grid area. To make the usage easier, use the following two classes{' '}
                <code className='code-inline'>grid-stack</code> and <code className='code-inline'>grid-stack-item</code>
            </Description>
            <Playground example={gridStackExample} classes={gridStackClasses} />
        </div>
    );
};

const gridColsExample = (
    <div className='display-grid gap-20'>
        <div className='display-grid gap-20 grid-cols-1'>
            {[1].map(() => (
                <div className='padding-10 bg-info rounded' key={crypto.randomUUID()}>
                    grid-cols-1
                </div>
            ))}
        </div>
        <div className='display-grid gap-20 grid-cols-2'>
            {[...Array(2)].map(() => (
                <div className='padding-10 bg-info rounded' key={crypto.randomUUID()}>
                    grid-cols-2
                </div>
            ))}
        </div>
        <div className='display-grid gap-20 grid-cols-3'>
            {[...Array(3)].map(() => (
                <div className='padding-10 bg-info rounded' key={crypto.randomUUID()}>
                    grid-cols-3
                </div>
            ))}
        </div>
        <div className='display-grid gap-20 grid-cols-4'>
            {[...Array(4)].map(() => (
                <div className='padding-10 bg-info rounded' key={crypto.randomUUID()}>
                    grid-cols-4
                </div>
            ))}
        </div>
        <div className='display-grid gap-20 grid-cols-5'>
            {[...Array(5)].map(() => (
                <div className='padding-10 bg-info rounded' key={crypto.randomUUID()}>
                    grid-cols-5
                </div>
            ))}
        </div>
        <div className='display-grid gap-20 grid-cols-6'>
            {[...Array(6)].map(() => (
                <div className='padding-10 bg-info rounded' key={crypto.randomUUID()}>
                    grid-cols-6
                </div>
            ))}
        </div>
        <div className='display-grid gap-20 grid-cols-7'>
            {[...Array(7)].map(() => (
                <div className='padding-10 bg-info rounded' key={crypto.randomUUID()}>
                    grid-cols-7
                </div>
            ))}
        </div>
        <div className='display-grid gap-20 grid-cols-8 hidden-xs'>
            {[...Array(8)].map(() => (
                <div className='padding-10 bg-info rounded' key={crypto.randomUUID()}>
                    grid-cols-8
                </div>
            ))}
        </div>
        <div className='display-grid gap-20 grid-cols-9 hidden-xs'>
            {[...Array(9)].map(() => (
                <div className='padding-10 bg-info rounded' key={crypto.randomUUID()}>
                    grid-cols-9
                </div>
            ))}
        </div>
        <div className='display-grid gap-20 grid-cols-10 hidden-xs'>
            {[...Array(10)].map(() => (
                <div className='padding-10 bg-info rounded' key={crypto.randomUUID()}>
                    grid-cols-10
                </div>
            ))}
        </div>
        <div className='display-grid gap-20 grid-cols-11 hidden-xs'>
            {[...Array(11)].map(() => (
                <div className='padding-10 bg-info rounded' key={crypto.randomUUID()}>
                    grid-cols-11
                </div>
            ))}
        </div>
        <div className='display-grid gap-20 grid-cols-12 hidden-xs'>
            {[...Array(12)].map(() => (
                <div className='padding-10 bg-info rounded' key={crypto.randomUUID()}>
                    grid-cols-12
                </div>
            ))}
        </div>
    </div>
);

const gridColsClasses = (
    <>
        <PlaygroundClasses label='Display' classes='display-grid' />
        <PlaygroundClasses label='Amount' prefix='grid-cols-' classes={[...Array(12)]} />
        <PlaygroundClasses label='Reset' classes='grid-cols-none' />
        <PlaygroundClassesSpacer label='Auto rows' />
        <p>
            "grid-auto-rows" is useful for automatically sizing additional rows when content exceeds the defined grid
            rows. If more content is added, implicit rows will be created to accommodate the overflow, sizing as small
            as possible while fitting their content without exceeding the maximum content size.
        </p>
        <PlaygroundClasses label='Rows' classes='grid-auto-rows' />
        <PlaygroundClassesSpacer label='Auto flow' />
        <p>
            The "grid-auto-flow" property in CSS Grid controls how auto-placed items are inserted into the grid. It
            determines the order and direction in which items are added to the grid when no specific placement is
            defined.
        </p>
        <p>
            <b>grid-auto-flow-row</b> (default): Fills rows first, then moves to the next row
            <br />
            <b>grid-auto-flow-column</b>: Fills columns first, then moves to the next column
        </p>
        <PlaygroundClasses label='auto-flow' classes={['grid-auto-flow-row', 'grid-auto-flow-column']} />
        <PlaygroundClassesSpacer label='Align' />
        <PlaygroundClasses
            label='Align Items'
            prefix='align-items-'
            classes={['start', 'end', 'center', 'baseline', 'stretch']}
        />
        <PlaygroundClasses
            label='Align Content'
            prefix='align-content-'
            classes={['start', 'end', 'center', 'between', 'around', 'stretch']}
        />
        <PlaygroundClasses
            label='Align Self'
            prefix='align-self-'
            classes={['auto', 'start', 'end', 'center', 'baseline', 'stretch']}
        />
        <PlaygroundResponsiveClasses />
    </>
);

const gridColSpanExample = (
    <div className='display-grid gap-20'>
        <div className='display-grid gap-20 grid-cols-6'>
            <div className='padding-10 bg-info rounded' />
            <div className='padding-10 bg-info rounded' />
            <div className='padding-10 bg-info rounded grid-colspan-3'>grid-colspan-3</div>
            <div className='padding-10 bg-info rounded' />
        </div>
    </div>
);

const gridColSpanClasses = (
    <>
        <PlaygroundClasses label='Reset' classes='grid-colspan-auto' />
        <PlaygroundClassesSpacer label='Cols' />
        <PlaygroundClasses
            label='Columns'
            prefix='grid-colspan-'
            classes={['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12']}
        />
        <PlaygroundClassesSpacer label='Rows' />
        <PlaygroundClasses label='Rows' prefix='grid-rowspan-' classes={['2', '3', '4']} />
        <p>Row span is useful for cases like cards with subgrid.</p>
        <PlaygroundResponsiveClasses />
    </>
);

const gridAutoFitExample = (
    <div className='display-grid gap-20 grid-auto-fit-400'>
        <div className='padding-25 bg-info rounded' />
        <div className='padding-25 bg-info rounded' />
        <div className='padding-25 bg-info rounded' />
        <div className='padding-25 bg-info rounded' />
    </div>
);

const gridAutoFitClasses = (
    <PlaygroundClasses
        label='Columns'
        prefix='grid-auto-fit-'
        classes={['100', '150', '200', '250', '300', '350', '400', '450', '500']}
    />
);

const gridColumnPlacementExample = (
    <div className='display-grid gap-15'>
        <div className='display-grid gap-15 grid-cols-6'>
            <div className='padding-10 bg-info rounded grid-column-start-1' />
        </div>
        <div className='display-grid gap-15 grid-cols-6'>
            <div className='padding-10 bg-info rounded grid-column-start-2' />
        </div>
        <div className='display-grid gap-15 grid-cols-6'>
            <div className='padding-10 bg-info rounded grid-column-start-3' />
        </div>
        <div className='display-grid gap-15 grid-cols-6'>
            <div className='padding-10 bg-info rounded grid-column-start-4' />
        </div>
        <div className='display-grid gap-15 grid-cols-6'>
            <div className='padding-10 bg-info rounded grid-column-start-5' />
        </div>
        <div className='display-grid gap-15 grid-cols-6'>
            <div className='padding-10 bg-info rounded grid-column-start-6' />
        </div>
        <Divider />
        <div className='display-grid gap-15 grid-cols-6'>
            <div className='padding-10 bg-info rounded grid-column-start-1 grid-column-end-6' />
        </div>
        <div className='display-grid gap-15 grid-cols-6'>
            <div className='padding-10 bg-info rounded grid-column-start-1 grid-column-end-5' />
        </div>
        <div className='display-grid gap-15 grid-cols-6'>
            <div className='padding-10 bg-info rounded grid-column-start-1 grid-column-end-4' />
        </div>
        <div className='display-grid gap-15 grid-cols-6'>
            <div className='padding-10 bg-info rounded grid-column-start-1 grid-column-end-3' />
        </div>
        <div className='display-grid gap-15 grid-cols-6'>
            <div className='padding-10 bg-info rounded grid-column-start-1 grid-column-end-2' />
        </div>
    </div>
);

const gridColumnPlacementClasses = (
    <>
        <PlaygroundClasses label='start' prefix='grid-column-start' classes={['1', '2', '3', '4', '5', '6']} />
        <PlaygroundClasses label='end' prefix='grid-column-end-' classes={['2', '3', '4', '5', '6', '7']} />
    </>
);

const gridMaxContentExample = (
    <div className='display-grid gap-20'>
        <div className='display-grid gap-20 grid-cols-2-max-content-1fr'>
            <div className='padding-10 bg-info rounded'>max-content</div>
            <div className='padding-10 bg-info rounded'>1fr</div>
        </div>
        <div className='display-grid gap-20 grid-cols-4-max-content-1fr'>
            <div className='padding-10 bg-info rounded'>max-content</div>
            <div className='padding-10 bg-info rounded'>1fr</div>
            <div className='padding-10 bg-info rounded'>max-content</div>
            <div className='padding-10 bg-info rounded'>1fr</div>
        </div>
        <div className='display-grid gap-20 grid-cols-2-1fr-max-content'>
            <div className='padding-10 bg-info rounded'>1fr</div>
            <div className='padding-10 bg-info rounded'>max-content</div>
        </div>
        <div className='display-grid gap-20 grid-cols-4-1fr-max-content'>
            <div className='padding-10 bg-info rounded'>1fr</div>
            <div className='padding-10 bg-info rounded'>max-content</div>
            <div className='padding-10 bg-info rounded'>1fr</div>
            <div className='padding-10 bg-info rounded'>max-content</div>
        </div>
        <Divider />
        <div className='display-grid gap-20 grid-cols-2-auto-1fr'>
            <div className='padding-10 bg-info rounded'>auto</div>
            <div className='padding-10 bg-info rounded'>1fr</div>
        </div>
        <div className='display-grid gap-20 grid-cols-4-auto-1fr'>
            <div className='padding-10 bg-info rounded'>auto</div>
            <div className='padding-10 bg-info rounded'>1fr</div>
            <div className='padding-10 bg-info rounded'>auto</div>
            <div className='padding-10 bg-info rounded'>1fr</div>
        </div>
        <div className='display-grid gap-20 grid-cols-2-1fr-auto'>
            <div className='padding-10 bg-info rounded'>1fr</div>
            <div className='padding-10 bg-info rounded'>auto</div>
        </div>
        <div className='display-grid gap-20 grid-cols-4-1fr-auto'>
            <div className='padding-10 bg-info rounded'>1fr</div>
            <div className='padding-10 bg-info rounded'>auto</div>
            <div className='padding-10 bg-info rounded'>1fr</div>
            <div className='padding-10 bg-info rounded'>auto</div>
        </div>
    </div>
);

const gridMaxContentClasses = (
    <>
        <PlaygroundClasses
            label='max-content'
            prefix='grid-cols-'
            classes={['2-max-content-1fr', '4-max-content-1fr', '2-1fr-max-content', '4-1fr-max-content']}
        />
        <PlaygroundClasses
            label='auto'
            prefix='grid-cols-'
            classes={['2-auto-1fr', '4-auto-1fr', '2-1fr-auto', '4-1fr-auto']}
        />
        <PlaygroundResponsiveClasses />
    </>
);

const gridRowExample = (
    <div className='display-grid grid-cols-2 gap-20'>
        <div className='display-grid gap-15 max-height-500 grid-rows-auto-1fr-auto'>
            <div className='form-group margin-0'>
                <div className='input-group'>
                    <span className='input-group-addon'>
                        <span className='rioglyph rioglyph-search' />
                    </span>
                    <input type='text' className='form-control' placeholder='Find your item' />
                </div>
            </div>
            <div className='padding-y-2 overflow-y-auto'>
                {[...Array(8)].map((_: any, index: number) => (
                    <RadioButton
                        key={crypto.randomUUID()}
                        name='stackedRadioBoxes'
                        custom
                        className={`padding-15 margin-top--1 border ${index === 0 ? 'rounded-top border-color-highlight bg-highlight-decent z-index-1' : 'bg-white hover-bg-decent'}`}
                        checked={index === 0}
                    >
                        <div className='radio-text-wrapper display-flex'>
                            <span className='radio-text' />
                            <div className='margin-left-5 margin-right-5'>
                                <div className='text-medium'>Lorem item {index}</div>
                                <div className='text-color-dark'>Lorem ipsum dolor sit amet</div>
                            </div>
                        </div>
                    </RadioButton>
                ))}
            </div>
            <Button>Button</Button>
        </div>
        <div className='panel panel-default panel-body display-flex flex-column max-height-500'>
            <div>{dummyText}</div>
        </div>
    </div>
);

const gridRowClasses = (
    <>
        <PlaygroundClasses label='Rows' prefix='grid-rows-' classes={['auto-1fr-auto']} />
        <PlaygroundResponsiveClasses />
    </>
);

const gridPlacementExample = (
    <div className='display-grid place-items-center bg-lightest height-100'>
        <div className='padding-10 bg-info rounded'>place-items-center</div>
    </div>
);

const gridPlacementClasses = (
    <>
        <PlaygroundClasses label='Wrapper' classes='display-grid place-items-center place-self-center' />
        <PlaygroundResponsiveClasses />
    </>
);

const gridStackExample = (
    <div className='display-grid grid-cols-1 grid-cols-2-sm align-content-center gap-20'>
        <div className='display-grid grid-stack place-items-center bg-lightest height-200 width-100pct'>
            <div
                className={
                    'grid-stack-item height-100 width-200 padding-x-15 ' +
                    'padding-top-5 padding-bottom-20 bg-white rounded border'
                }
            >
                stack item 1
            </div>
            <div className='grid-stack-item padding-x-15 padding-y-10 bg-info rounded'>stack item 2</div>
        </div>
        <div className='display-grid grid-stack align-items-center align-items-end bg-lightest height-200 width-100pct'>
            <div
                className={
                    'grid-stack-item height-100 width-200 padding-x-15 ' +
                    'padding-top-5 padding-bottom-20 bg-white rounded border'
                }
            >
                stack item 1
            </div>
            <div className='grid-stack-item padding-x-15 padding-y-10 bg-info rounded'>stack item 2</div>
        </div>
    </div>
);

const gridStackClasses = (
    <>
        <PlaygroundClasses label='Wrapper' classes='display-grid grid-stack' />
        <PlaygroundClasses label='Items' classes='grid-stack-item' />
    </>
);
