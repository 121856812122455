export const currentColorOptions = ['primary', 'secondary', 'info', 'success', 'warning', 'danger'] as const;

export type CurrentColor = (typeof currentColorOptions)[number];

const currentColorMapping: Record<string, string> = {
    primary: '--brand-primary',
    secondary: '--brand-secondary',
    info: '--brand-info',
    success: '--brand-success',
    warning: '--brand-warning',
    danger: '--brand-danger',
};

export const getCurrentBackgroundColor = (color: CurrentColor) => `var(${currentColorMapping[`${color}`]})`;
