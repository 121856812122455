import { useEffect } from 'react';
import throttle from 'lodash/fp/throttle';

export type UseWindowResize = (callback: () => void, timeout?: number) => void;

/**
 * A custom hook for handling window resize events with throttling.
 *
 * @param callback The callback function to execute on window resize.
 * @param timeout Optional. The throttling timeout duration in milliseconds. Default is 300ms.
 */
const useWindowResize: UseWindowResize = (onResizeCallback, timeout = 300) => {
    useEffect(() => {
        // Throttle the callback function to avoid excessive executions during window resize events.
        const throttledCallback = throttle(timeout, onResizeCallback);

        // Add event listener for window resize with the throttled callback.
        window.addEventListener('resize', throttledCallback, { passive: true });

        // Cleanup function to remove event listener when component unmounts.
        return () => {
            window.removeEventListener('resize', throttledCallback);
        };
    }, [onResizeCallback, timeout]);
};

export default useWindowResize;
