import React, { type ChangeEvent, type HTMLAttributes, useEffect, useState } from 'react';
import classNames from 'classnames';
import noop from 'lodash/noop';

import { getCurrentBackgroundColor, type CurrentColor } from '../../utils/currentColors';

export type SliderProps = {
    /**
     * The current value.
     *
     * @default 0
     */
    value?: number;

    /**
     * The lower limit.
     *
     * @default 0
     */
    minValue?: number;

    /**
     * The upper limit.
     *
     * @default Number.MAX_VALUE
     */
    maxValue?: number;

    /**
     * Whether to show the value labels.
     *
     * @default false
     */
    valueLabels?: boolean;

    /**
     * Additional unit used for the slider value label.
     */
    valueLabelUnit?: string | React.ReactNode;

    /**
     * Whether to hide the dark-colored value bar.
     *
     * @default false
     */
    hideValueBar?: boolean;

    /**
     * Whether to show larger value labels instead of the normal ones.
     *
     * @default false
     */
    largeValueLabels?: boolean;

    /**
     * Selector step value.
     *
     * @default 1
     */
    step?: number;

    /**
     * Defines the color of the slider.
     *
     * @default 'primary'
     */
    color?: CurrentColor;

    /**
     * Disables all pointer events.
     *
     * @default false
     */
    disabled?: boolean;

    /**
     * Callback to get the new value every time it changes.
     */
    onChange?: (newValue: number) => void;

    /**
     * Callback to get the value after the slider ended dragging.
     */
    onDragEnd?: (newValue: number) => void;

    /**
     * Additional classes to be set on the wrapper element.
     */
    className?: string;
} & Omit<HTMLAttributes<HTMLDivElement>, 'disabled' | 'onChange' | 'onDragEnd' | 'className'>;

const Slider = (props: SliderProps) => {
    const {
        value = 0,
        minValue = 0,
        maxValue = Number.MAX_VALUE,
        valueLabels = false,
        valueLabelUnit,
        largeValueLabels = false,
        hideValueBar = false,
        color = 'primary',
        step = 1,
        onChange = noop,
        onDragEnd = noop,
        className,
        disabled = false,
        ...remainingProps
    } = props;

    const [currentValue, setCurrentValue] = useState(value ? value : minValue);
    const [isChanging, setIsChanging] = useState(false);

    useEffect(() => {
        if (value >= minValue && value <= maxValue) {
            setCurrentValue(value);
        }
    }, [value, minValue, maxValue]);

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const newValue = Number.parseInt(event.target.value, 10);
        setCurrentValue(newValue);
        setIsChanging(true);
        onChange(newValue);
    };

    const onMouseUp = () => {
        setIsChanging(false);
        onDragEnd(currentValue);
    };

    const onMouseDown = () => {
        setIsChanging(true);
    };

    const wrapperClassNames = classNames(
        'range-slider',
        disabled && 'disabled',
        valueLabels && 'show-value-labels',
        hideValueBar && 'hide-value-bar',
        largeValueLabels && 'large-value-labels',
        className && className
    );

    const trackWidth = (100 * (currentValue - minValue)) / (maxValue - minValue);

    const sliderBackgroundColor = getCurrentBackgroundColor(color);

    return (
        <div {...remainingProps} className={wrapperClassNames} style={{ color: sliderBackgroundColor }}>
            <div className='range-whole-track' />
            <div className='range-track' style={{ width: `${trackWidth}%` }}>
                {valueLabels && (
                    <div className='range-value'>
                        <span>{valueLabelUnit ? `${currentValue} ${valueLabelUnit}` : currentValue}</span>
                    </div>
                )}
            </div>
            <input
                className={`${isChanging ? 'changing' : ''}`}
                value={currentValue}
                min={minValue}
                max={maxValue}
                step={step}
                type='range'
                onChange={handleChange}
                onMouseDown={onMouseDown}
                onMouseUp={onMouseUp}
            />
        </div>
    );
};

export default Slider;
