import React, { type PropsWithChildren, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import isArray from 'lodash/fp/isArray';
import head from 'lodash/fp/head';
import noop from 'lodash/noop';

import useAfterMount from '../../useAfterMount';

type AnimationValue = { x: number; y: number; opacity: number };

type AnimationProp = {
    initial: 'pageEnter' | AnimationValue | false;
    animate: 'pageCenter' | AnimationValue;
    exit: 'pageExit' | AnimationValue;
};

export type FadeAnimationStyle =
    | 'fade'
    | 'tabs'
    | 'page'
    | 'pageBack'
    | 'fromLeft'
    | 'fromRight'
    | 'fromTop'
    | 'fromBottom';

const animationPropsForStyle: Record<FadeAnimationStyle, AnimationProp> = {
    fromLeft: {
        initial: { x: -10, y: 0, opacity: 0 },
        animate: { x: 0, y: 0, opacity: 1 },
        exit: { x: -10, y: 0, opacity: 0 },
    },
    fromRight: {
        initial: { x: 10, y: 0, opacity: 0 },
        animate: { x: 0, y: 0, opacity: 1 },
        exit: { x: 10, y: 0, opacity: 0 },
    },
    fromTop: {
        initial: { y: -10, x: 0, opacity: 0 },
        animate: { y: 0, x: 0, opacity: 1 },
        exit: { y: -10, x: 0, opacity: 0 },
    },
    fromBottom: {
        initial: { y: 10, x: 0, opacity: 0 },
        animate: { y: 0, x: 0, opacity: 1 },
        exit: { y: 10, x: 0, opacity: 0 },
    },
    fade: {
        initial: { x: 0, y: 0, opacity: 0 },
        animate: { x: 0, y: 0, opacity: 1 },
        exit: { x: 0, y: 0, opacity: 0 },
    },
    tabs: {
        initial: { x: 2, y: 0, opacity: 0 },
        animate: { x: 0, y: 0, opacity: 1 },
        exit: { x: -2, y: 0, opacity: 0 },
    },
    page: {
        initial: 'pageEnter',
        animate: 'pageCenter',
        exit: 'pageExit',
    },
    pageBack: {
        initial: 'pageEnter',
        animate: 'pageCenter',
        exit: 'pageExit',
    },
} as const;

type PageDirection = -1 | 1;

const getPageDirection = (animationStyle: FadeAnimationStyle): PageDirection =>
    animationStyle === 'pageBack' ? -1 : 1;

const pageTransitionVariants = {
    pageEnter: (pageDirection: PageDirection) => ({ x: `${pageDirection * 60}%`, opacity: 0 }),
    pageCenter: { x: 0, opacity: 1 },
    pageExit: (pageDirection: PageDirection) => ({ zIndex: 0, x: `${-pageDirection * 60}%`, opacity: 0 }),
};

export type FadeProps = {
    /**
     * Defines whether to show the content.
     *
     * @default true
     */
    show?: boolean;

    /**
     * Duration of the fade animation in seconds.
     *
     * @default 0.2
     */
    duration?: number;

    /**
     * Defines whether the animation is triggered initially when showing the content.
     *
     * @default false
     */
    initial?: boolean;

    /**
     * Defines the desired animation style.
     *
     * Possible values are:
     * - `fade`
     * - `tabs`
     * - `page`
     * - `pageBack`
     * - `fromLeft`
     * - `fromRight`
     * - `fromTop`
     * - `fromBottom`
     *
     * @default 'fade'
     */
    animationStyle?: FadeAnimationStyle;

    /**
     * If set to true, only one component will be rendered at a time.
     *
     * The exiting component will finish its exit animation before the entering component is rendered.
     *
     * @default false
     */
    exitBeforeEnter?: boolean;

    /**
     * Fires when all exiting nodes have completed animating out.
     *
     * @default noop
     */
    onExitComplete?: VoidFunction;

    /**
     * Additional custom props for the underlying Framer motion AnimatePresence component.
     *
     * Use this for additional customizations.
     */
    animatePresenceProps?: object;

    /**
     * Additional custom props for the underlying Framer motion &lt;motion.div&gt; component.
     *
     * Use this for additional customizations.
     */
    motionProps?: object;
};

const Fade = (props: PropsWithChildren<FadeProps>) => {
    const {
        show = true,
        initial = false,
        duration = 0.2,
        exitBeforeEnter = false,
        animationStyle = 'fade',
        animatePresenceProps,
        motionProps,
        onExitComplete = noop,
        children,
    } = props;

    const [allowInitialAnimation, setAllowInitialAnimation] = useState(initial);

    useAfterMount(() => {
        if (!allowInitialAnimation) {
            setAllowInitialAnimation(true);
        }
    });

    let motionDivKey = 'fade';

    // If there are multiple children that are conditionally rendered like
    // in case of a tabbed content - get the current visible child key
    // to update the motion.div key to animate different children
    if (isArray(children)) {
        const currentChild = head(children.filter(child => Boolean(child)));
        motionDivKey = `fade-${currentChild.key}`;
    }

    // In case the animation is a page transition, use custom variants and add a custom pageDirection variable
    // to animate in the right direction
    const isPageTransition = animationStyle === 'page' || animationStyle === 'pageBack';
    const pageDirection = getPageDirection(animationStyle);
    const custom = isPageTransition ? pageDirection : null;
    const pageTransitionProps = isPageTransition ? { variants: pageTransitionVariants } : null;

    const selectedAnimationProps = animationPropsForStyle[animationStyle];

    // Disable initial animation on mount. After mount the initial animation step is needed to enable
    // the "fade-in" animation, otherwise it just appears
    const selectedInitial = allowInitialAnimation ? selectedAnimationProps.initial : false;
    const animationProps = { ...selectedAnimationProps, initial: selectedInitial };

    return (
        // @ts-ignore-next-line
        <AnimatePresence
            initial={allowInitialAnimation}
            exitBeforeEnter={exitBeforeEnter}
            onExitComplete={onExitComplete}
            custom={custom}
            {...animatePresenceProps}
        >
            {show && (
                <motion.div
                    key={motionDivKey}
                    transition={{ duration }}
                    custom={custom}
                    {...animationProps}
                    {...pageTransitionProps}
                    {...motionProps}
                >
                    {children}
                </motion.div>
            )}
        </AnimatePresence>
    );
};

Fade.FADE = 'fade' as const;
Fade.FROM_LEFT = 'fromLeft' as const;
Fade.FROM_RIGHT = 'fromRight' as const;
Fade.FROM_TOP = 'fromTop' as const;
Fade.FROM_BOTTOM = 'fromBottom' as const;
Fade.TABS = 'tabs' as const;
Fade.PAGE = 'page' as const;
Fade.PAGE_BACK = 'pageBack' as const;

export default Fade;
