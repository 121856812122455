// @ts-ignore-next-line importsNotUsedAsValues
import React, { type ReactNode } from 'react';

type ListMenuHeaderProps = {
    group?: string | ReactNode;
    groupNavItem?: string | ReactNode;
    className?: string;
};

const ListMenuHeader = ({ group, groupNavItem, className = '' }: ListMenuHeaderProps) => {
    if (!group && !groupNavItem) {
        return null;
    }

    return (
        <li className='ListMenuHeader'>{groupNavItem ? groupNavItem : <span className={className}>{group}</span>}</li>
    );
};

export default ListMenuHeader;
