// @ts-ignore-next-line importsNotUsedAsValues
import React, { type PropsWithChildren } from 'react';
import classNames from 'classnames';

export type TableToolbarProps = {
    /**
     * Optional class names for the wrapper element.
     */
    className?: string;
};

const TableToolbar = ({ className, children }: PropsWithChildren<TableToolbarProps>) => (
    <div className={classNames('table-toolbar', className)}>{children}</div>
);

export default TableToolbar;
