import { Playground } from '../../../components/playground/Playground';
import { Description } from '../../../components/Description';

export const Helper = () => (
    <div>
        <Description headline='Helper' anchor='helper' />
        <Playground example={helperExample} />
    </div>
);

const helperExample = (
    <div className='display-flex flex-wrap gap-5 margin-bottom-20'>
        <code className='code'>clearance</code>
        <code className='code'>clearfix</code>
        <code className='code'>children-first-border-top-none</code>
        <code className='code'>first-child-border-top-none</code>
        <code className='code'>children-last-border-bottom-none</code>
        <code className='code'>last-child-border-bottom-none</code>
        <code className='code'>children-first-margin-top-0</code>
        <code className='code'>first-child-margin-top-0</code>
        <code className='code'>children-last-margin-bottom-0</code>
        <code className='code'>last-child-margin-bottom-0</code>
    </div>
);
