import React, { PropsWithChildren, useState } from 'react';
import isEqual from 'lodash/fp/isEqual';

import classNames from 'classnames';

import Collapse from '../collapse/Collapse';
import { hasBorderClass, hasRoundedClass } from '../../utils/hasUtilityClass';

export type ExpanderListItem = {
    /**
     * The "id" property is used to know which item is expanded. It will also be used to control
     * the component from the outside. It can be a number or a string. If there is no "id" provided,
     * a unique id is internally generated  which will be used internally for the uncontrolled case.'
     */
    id?: string | number;

    /**
     * The header content.
     */
    header: string | React.ReactNode;

    /**
     * The body content. If there is no "body" provided, the list item is not expandable.
     */
    body?: string | React.ReactNode;

    /**
     * Defines if the item will be expanded or closed by default.
     */
    open?: boolean;

    /**
     * Callback fired when item toggles to open after a click.
     */
    onOpen?: VoidFunction;

    /**
     * Callback fired when item toggles to close after a click.
     */
    onClose?: VoidFunction;

    /**
     * Additional classes to be set on "expander-list-header" node.
     */
    headerClassName?: string;

    /**
     * Additional classes to be set on "expander-list-body" node.
     */
    bodyClassName?: string;

    /**
     * Additional classes to be set on list item node.
     */
    className?: string;
};

export type ExpanderListProps = {
    /**
     * List of items to be rendered. The expanded state can be defined via the items `open` prop.
     */
    items: ExpanderListItem[];

    /**
     * Defines whether the "expander-list-body" is rounded or not.
     * @default true
     */
    rounded?: boolean;

    /**
     * Defines whether the "expander-list-body" has a border or not.
     * @default true
     */
    bordered?: boolean;

    /**
     * It unmounts the body component (remove it from the DOM) when it is collapsed.
     * Set it to false to avoid the unmount.
     * @default true
     */
    unmountOnExit?: boolean;

    /**
     * Additional classes to be set on the unordered list itself.
     */
    className?: string;
};

const getRandomString = () => (Math.random() + 1).toString(36).toUpperCase().substring(2);

// Generate a unique id (if not present) instead of using the index for the key as it will create
// side effects when removing items from the list and re-render the ExpanderList.
const parseItems = (items: ExpanderListItem[]) => {
    return items.map(item => {
        if (!item.id) {
            item.id = getRandomString();
        }
        return item;
    });
};

const ExpanderList = (props: ExpanderListProps) => {
    const { items = [], unmountOnExit = true, rounded = true, bordered = true, className = '' } = props;

    const [listItems, setListItems] = useState(parseItems(items));

    // Update internal state from external prop change
    const [previousItems, setPreviousItems] = useState(items);
    if (!isEqual(previousItems, items)) {
        setListItems(parseItems(items));
        setPreviousItems(items);
    }

    const handleToggleItem = (itemToExpand: ExpanderListItem) => {
        if (!itemToExpand.body) {
            return;
        }

        // Toggle the open state for the selected item based on the provided or generated id
        const updatedListItems = [...listItems].map(item => {
            if (item.id === itemToExpand.id) {
                item.onOpen && !item.open && item.onOpen();
                item.onClose && item.open && item.onClose();
                item.open = !item.open;
            }
            return item;
        });

        setListItems(updatedListItems);
    };

    const listClassNames = classNames(
        'expander-list list-group',
        rounded && !hasRoundedClass(className) && 'rounded',
        bordered && !hasBorderClass(className) && 'border',
        className
    );

    return (
        <ul className={listClassNames}>
            {listItems.map(item => {
                const isOpen = item.open;

                const itemClassNames = classNames(
                    'list-group-item',
                    item.className && item.className,
                    item.body && 'expandable',
                    isOpen && 'open'
                );

                return (
                    <li className={itemClassNames} key={item.id}>
                        <ExpanderListItemHeader item={item} onToggle={handleToggleItem} />
                        {item.body && (
                            <Collapse open={isOpen} unmountOnExit={unmountOnExit}>
                                <div className='expander-list-body-wrapper'>
                                    <ExpanderListItemBody className={item.bodyClassName}>
                                        {item.body}
                                    </ExpanderListItemBody>
                                </div>
                            </Collapse>
                        )}
                    </li>
                );
            })}
        </ul>
    );
};

type ExpanderListItemHeaderProps = {
    item: ExpanderListItem;
    onToggle: (item: ExpanderListItem) => void;
};

const ExpanderListItemHeader = ({ item, onToggle }: ExpanderListItemHeaderProps) => {
    const headerClassNames = classNames('expander-list-header', item.headerClassName);
    const iconClassNames = classNames('expander-icon', 'rioglyph', 'rioglyph-chevron-down');

    return (
        <div className={headerClassNames} onClick={() => onToggle(item)} aria-label='expander item header'>
            <span className='expander-list-header-content'>{item.header}</span>
            {item.body && <span className={iconClassNames} />}
        </div>
    );
};

type ExpanderListItemBodyProps = {
    className?: string;
};

const ExpanderListItemBody = ({ className, children }: PropsWithChildren<ExpanderListItemBodyProps>) => {
    const bodyClassNames = classNames('expander-list-body', className);
    return (
        <div className={bodyClassNames} aria-label='expander item body'>
            {children}
        </div>
    );
};

export default ExpanderList;
