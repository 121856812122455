import { Title } from '../../../components/Title';

import Pager from '../../../../../src/Pager';

export default () => (
    <div>
        <Title>Pager with label</Title>
        <div className='display-flex gap-15 max-width-800 margin-bottom-50'>
            <Pager label='Previous' title='The previous chapter' alignRight />
            <Pager label='Next' title='The next chapter' />
        </div>

        <Title>Compact Pager without label</Title>
        <div className='display-flex gap-15 max-width-800 justify-content-between'>
            <Pager title='Previous' variant={Pager.VARIANT_COMPACT} disabled alignRight />
            <Pager title='Next' label='foo' variant={Pager.VARIANT_COMPACT} />
        </div>
    </div>
);
