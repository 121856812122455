import { useEffect, useRef } from 'react';

/**
 * Calls the given callback once after the component has finished mounting and starts executing effects.
 *
 * @param callback The effect callback function to be used after the component has been mounted.
 * @param deps The deps of the callback (the same as if you were using useCallback or useEffect).
 */
const useAfterMount = (callback: VoidFunction, deps?: unknown[]) => {
    const hasMountedRef = useRef(false);

    useEffect(() => {
        if (hasMountedRef.current) {
            // Run the callback only after the first render and
            // whenever the dependencies change afterwards
            callback();
        } else {
            hasMountedRef.current = true;
        }
    }, deps);
};

export default useAfterMount;
