// @ts-ignore-next-line importsNotUsedAsValues
import React, { type HTMLAttributes, type PropsWithChildren } from 'react';
import classNames from 'classnames';

export type ActionBarItemListItemProps = Omit<HTMLAttributes<HTMLLIElement>, 'onClick'> & {
    /**
     * Set it to true when you want to use the item as link item.
     *
     * In this case, please use the `Link` component from `react-router-dom` instead of an anchor tag to prevent side
     * effects when automatically closing the popover.
     */
    hasLink?: boolean;

    /**
     * The name of the icon to be used for the item.
     */
    icon?: string;

    /**
     * Callback function to be triggered when the internal button of a list item is clicked.
     *
     * Note: When no callback function is provided, the children are rendered as is without wrapping them in a button.
     */
    onClick?: VoidFunction;

    /**
     * Additional class names that are added to the wrapping list item component.
     */
    className?: string;
};

const ActionBarItemListItem = (props: PropsWithChildren<ActionBarItemListItemProps>) => {
    const { hasLink, icon, onClick, className, children, ...remainingProps } = props;

    const classes = classNames('ActionBarItemListItem', hasLink && 'has-link', className);
    const iconClasses = classNames('ActionBarItemListItemIcon', 'rioglyph', icon);
    const btnClasses = classNames('ActionBarItemListItemButton');

    return (
        <li {...remainingProps} className={classes} onClick={onClick}>
            {icon && <span className={iconClasses} />}
            {hasLink && !onClick && <span>{children}</span>}
            {onClick && <div className={btnClasses}>{children}</div>}
            {!hasLink && !onClick && children}
        </li>
    );
};

export default ActionBarItemListItem;
