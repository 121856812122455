import { Playground, PLAYGROUNG_BG_LIGHTER } from '../../../components/playground/Playground';
import { PlaygroundClasses } from '../../../components/playground/PlaygroundClasses';
import { Description } from '../../../components/Description';
import { Note } from '../../../components/Note';

import Divider from '../../../../../src/components/divider/Divider';

const eachShadowVariants = ['subtle', 'muted', 'accent', 'default', 'smooth', 'hard'];

const eachDownShadows = ['muted-down', 'default-down'];

const eachShadowDirections = ['smooth-up', 'smooth-down', 'hard-up', 'hard-down'];

export const Shadows = () => (
    <div>
        <Description headline='Shadows' subline='Shadow class for panels and blocks' anchor='shadows'>
            <p>
                The default shadow class is intended to be used on panels or blocks which should stand out from the
                light gray background and should be avoided to put everywhere. Use it to emphasize certain blocks or
                panels e.g. on a dashboard or items of a drag and drop list.
            </p>
            <p>
                <b>Do not use hard shadows on tables, in dialogs, or on elements placed on white background.</b>
            </p>
            <p>
                The exception for not using shadows on a white background is for floating elements placed above the
                content for instance via position absolute.
            </p>
        </Description>
        <Playground example={shadowDefaultExample} classes={shadowClasses} background={PLAYGROUNG_BG_LIGHTER} />
        <Description headline='Shadow variants' headlineType='h3' />
        <Playground example={shadowExample} classes={shadowClasses} background={PLAYGROUNG_BG_LIGHTER} />
    </div>
);

const shadowExample = (
    <div>
        <Description headline='Surrounding shadows' headlineType='h4' headlineNoTopSpace />
        <div className='display-grid grid-cols-4 gap-20 text-color-darker'>
            {eachShadowVariants.map(value => (
                <div
                    className={`height-100 rounded bg-white shadow-${value} display-grid place-items-center`}
                    key={crypto.randomUUID()}
                >
                    <span className={`hidden-xs ${value === 'default' ? 'text-medium' : ''}`}>shadow-{value}</span>
                </div>
            ))}
        </div>

        <Description headline='Direction shadows' headlineType='h4' className='margin-top-50' />
        <div className='display-grid grid-cols-4 gap-20 text-color-darker'>
            {eachDownShadows.map(value => (
                <div
                    className={`
                        height-100 rounded bg-white shadow-${value} display-grid place-items-center
                    `}
                    key={crypto.randomUUID()}
                >
                    <span className='hidden-xs'>shadow-{value}</span>
                </div>
            ))}
        </div>

        <div className='display-grid grid-cols-4 gap-20 text-color-darker margin-top-50'>
            {eachShadowDirections.map(value => (
                <div
                    className={`
                        height-100 rounded bg-white shadow-${value} display-grid place-items-center
                    `}
                    key={crypto.randomUUID()}
                >
                    <span className='hidden-xs'>shadow-{value}</span>
                </div>
            ))}
        </div>

        <Divider className='margin-top-20' />
        <Description headline='Accent shadow for list items' headlineType='h5'>
            <Note>
                When utilizing the <code className='code-inline'>shadow-accent</code> avoid combining it with a border
                style, as the shadow is optimized for small block items like those in a list and{' '}
                <b>works best without a border</b>.
            </Note>
        </Description>
        <div className='display-grid grid-cols-2 column-gap-25 row-gap-5 max-width-600'>
            {Array.from({ length: 6 }, value => (
                <div
                    className={`
                        height-80 rounded bg-white shadow-accent display-grid place-items-center
                    `}
                    key={crypto.randomUUID()}
                >
                    <span>Item</span>
                </div>
            ))}
        </div>
    </div>
);

const shadowDefaultExample = (
    <div className='display-grid grid-cols-2 gap-20 text-color-darker'>
        <div
            className='
            height-100 panel panel-default panel-body margin-bottom-0
            shadow-default display-grid place-items-center
        '
        >
            shadow-default
        </div>
        <div className='height-100 panel panel-default margin-bottom-0 shadow-default'>
            <div className='panel-heading'>Panel with shadow</div>
            <div className='panel-body display-grid place-items-center'>shadow-default</div>
        </div>
    </div>
);

const shadowClasses = (
    <>
        <PlaygroundClasses label='Variants' prefix='shadow-' classes={eachShadowVariants} />
        <PlaygroundClasses
            label='Direction shadows'
            prefix='shadow-'
            classes={[...eachDownShadows, ...eachShadowDirections]}
        />
    </>
);
