// @ts-ignore-next-line importsNotUsedAsValues
import React, { forwardRef, useRef } from 'react';
import classNames from 'classnames';
import noop from 'lodash/fp/noop';

import useHover from '../../hooks/useHover';
import mergeRefs from '../../utils/mergeRefs';

export type TypeCounterProps = {
    /**
     * The item type for that counter. This will be used as a return value for the onClick callback.
     */
    type?: string;

    /**
     * The icon name. Note, when using the built-in generic type counter, the icon name will the
     * same as the item type. So make sure there is a corresponding icon for the defined type.
     */
    icon?: string;

    /**
     * Sets the active styling if active
     */
    isActive?: boolean;

    /**
     * Should be set when there is a type filter set in the tree to style the counter accordingly.
     *
     * @default false
     */
    hasFilter?: boolean;

    /**
     * Allows for interactivity. Use this prop when you allow clicking on the counter.
     *
     * @default false
     */
    enableActivity?: boolean;

    /**
     * Defines if the counter is rendered at all when the value is not defined ot 0.
     *
     * @default false
     */
    hideOnZero?: boolean;

    /**
     * Callback function when the counter is clicked. It returns the type value.
     * @param type
     * @returns
     */
    onClick?: (type: string) => void;

    /**
     * A custom value to be shown in the counter.
     */
    value?: string | React.ReactNode | number;
};

const TypeCounter = forwardRef<HTMLDivElement, TypeCounterProps>((props, ref) => {
    const {
        icon,
        value,
        isActive,
        hasFilter = false,
        enableActivity = false,
        onClick = noop,
        type,
        hideOnZero = false,
        ...remainingProps
    } = props;

    const internalRef = useRef<HTMLDivElement>(null);
    const mergedRef = mergeRefs([ref, internalRef]);

    const isHover = useHover(internalRef);

    const wrapperClassName = classNames(
        'TypeCounter',
        'display-flex align-items-center',
        'user-select-none',
        'margin-right-2',
        enableActivity ? 'cursor-pointer' : 'pointer-events-none',
        enableActivity && hasFilter && !isActive && !isHover && 'opacity-50'
    );

    const counterClassNames = classNames(
        'TreeLabelCount',
        'label label-condensed',
        isHover && !isActive && 'bg-white text-color-darker',
        isHover && !isActive && 'border-color-gray',
        isHover && isActive && 'border-color-primary',
        isActive ? 'label-primary' : 'label-muted label-filled'
    );

    const handleClick = () => type && onClick(type);

    if (hideOnZero && !value) {
        return null;
    }

    return (
        <div ref={mergedRef} {...remainingProps} className={wrapperClassName} onClick={handleClick}>
            <span className={`rioglyph rioglyph-${icon} text-size-16 margin-right-2`} />
            <span className={counterClassNames}>{value || 0}</span>
        </div>
    );
});

export default TypeCounter;
