// @ts-ignore-next-line importsNotUsedAsValues
import React, { forwardRef, type PropsWithChildren } from 'react';
import classNames from 'classnames';

export type TagProps = {
    /**
     * Defines if the tag is active.
     *
     * @default false
     */
    active?: boolean;

    /**
     * Defines if the tag is clickable.
     *
     * @default false
     */
    clickable?: boolean;

    /**
     * Defines if the tag is deletable.
     *
     * @default false
     */
    deletable?: boolean;

    /**
     * Defines if the tag is revertable.
     *
     * @default false
     */
    revertable?: boolean;

    /**
     * Defines if the tag is disabled.
     *
     * @default false
     */
    disabled?: boolean;

    /**
     * Define any rioglyph icon like "rioglyph-truck".
     */
    icon?: string;

    /**
     * Sets a more subtle style for border and background.
     *
     * @default false
     */
    muted?: boolean;

    /**
     * Defines if the tag is round.
     *
     * @default true
     */
    round?: boolean;

    /**
     * Defines if the tag is selectable.
     *
     * @default false
     */
    selectable?: boolean;

    /**
     * Defines if you want to render the tag in a different size.
     *
     * Possible values are: 'small' and 'medium'
     */
    size?: 'small' | 'medium';

    multiline?: boolean;
    [remainingProp: string]: unknown;

    /**
     * Additional classes to be set on the wrapper element.
     */
    className?: string;
};

const Tag = forwardRef((props: PropsWithChildren<TagProps>, ref: React.Ref<HTMLDivElement>) => {
    const {
        children,
        icon,
        active = false,
        disabled = false,
        clickable = false,
        selectable = false,
        deletable = false,
        revertable = false,
        multiline = false,
        muted = false,
        round = true,
        size,
        className,
        ...remainingProps
    } = props;

    const tagClasses = classNames(
        'tag',
        size === 'small' && 'tag-small',
        icon && `rioglyph ${icon}`,
        active && 'active clickable rioglyph rioglyph-ok',
        disabled && 'disabled',
        clickable && 'clickable',
        selectable && 'selectable clickable rioglyph rioglyph-checkbox',
        deletable && 'deletable clickable rioglyph rioglyph-remove',
        revertable && 'revertable clickable rioglyph rioglyph-revert',
        multiline && 'multiline',
        muted && 'tag-muted',
        !round && 'rounded',
        className
    );

    return (
        <div ref={ref} {...remainingProps} className={tagClasses}>
            <div className='tag-inner'>{children}</div>
        </div>
    );
});

export default Tag;
