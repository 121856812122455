// @ts-ignore-next-line importsNotUsedAsValues
import React, { forwardRef, type ChangeEvent, type PropsWithChildren } from 'react';
import classNames from 'classnames';
import noop from 'lodash/fp/noop';

import { getCurrentBackgroundColor, type CurrentColor } from '../../utils/currentColors';

export type SwitchProps = {
    /**
     * Optional key that is used as key and as the id on the internal input.
     */
    keyName?: string;

    /**
     * Callback function triggered when clicking the button.
     */
    onChange?: (toggleState: boolean) => void;

    /**
     * Set active / inactive state.
     *
     * @default false
     */
    checked?: boolean;

    /**
     * Defines the color of the switch.
     *
     * @default 'primary'
     */
    color?: CurrentColor;

    /**
     * The min-width of the switch.
     */
    minWidth?: number;

    /**
     * Disables the component.
     *
     * @default false
     */
    disabled?: boolean;

    /**
     * The text used for the ON state.
     */
    enabledText?: string;

    /**
     * The text used for the OFF state.
     */
    disabledText?: string;

    /**
     * Defines the position of the switch label.
     *
     * Possible values are `left` and `right`.
     */
    labelPosition?: 'left' | 'right';
};

const Switch = forwardRef((props: PropsWithChildren<SwitchProps>, ref: React.Ref<HTMLDivElement>) => {
    const {
        keyName,
        checked = false,
        enabledText,
        minWidth = 40,
        disabled = false,
        color = 'primary',
        disabledText,
        children,
        labelPosition = children ? 'right' : undefined,
        onChange = noop,
        ...remainingProps
    } = props;

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        onChange(event.target.checked);
    };

    const hasMultipleText = enabledText && disabledText;
    const hasSingleText = enabledText && !disabledText;

    const switchLabelClasses = classNames(
        'switch-label',
        disabled && 'cursor-not-allowed',
        labelPosition && `label-position-${labelPosition}`
    );

    const switchContentClasses = classNames(
        'switch-content'
        // !hasMultipleText && !hasSingleText && 'width-40'
    );

    const switchBackgroundColor = getCurrentBackgroundColor(color);

    return (
        <div ref={ref} {...remainingProps} className='uikit-switch'>
            <label className={switchLabelClasses}>
                <input
                    onChange={handleChange}
                    checked={checked}
                    type='checkbox'
                    id={keyName}
                    key={keyName}
                    className='switch-input'
                    disabled={disabled}
                />
                <div className={switchContentClasses} style={{ color: switchBackgroundColor, minWidth }}>
                    {hasMultipleText ? (
                        <div className='switch-text' data-on={enabledText} data-off={disabledText} />
                    ) : (
                        hasSingleText && <div className='switch-text' data-on={enabledText} data-off={enabledText} />
                    )}
                    <div className='switch-handle' />
                </div>

                {children && children}
            </label>
        </div>
    );
}) as React.ForwardRefExoticComponent<PropsWithChildren<SwitchProps> & React.RefAttributes<HTMLDivElement>> & {
    LABEL_POSITION_LEFT: 'left';
    LABEL_POSITION_RIGHT: 'right';
};

Switch.LABEL_POSITION_LEFT = 'left' as const;
Switch.LABEL_POSITION_RIGHT = 'right' as const;

export default Switch;
