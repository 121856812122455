import React, { PropsWithChildren } from 'react';
import PropTypes from 'prop-types';

type TitleProps = {
    type?: 'lead';
    className?: string;
    caniuseLink?: string;
};

export const Title = (props: PropsWithChildren<TitleProps>) => {
    const { type = 'lead', caniuseLink, children, className = '' } = props;

    return (
        <div className={`${type} ${className}`}>
            <div className='display-flex justify-content-between'>
                <div>{children && children}</div>
                {caniuseLink && (
                    <div>
                        <a href={caniuseLink} target='_blank' rel='noreferrer' className='text-size-12'>
                            caniuse <span className='rioglyph rioglyph-new-window' />
                        </a>
                    </div>
                )}
            </div>
            <hr className='margin-top-10 border-color-light' />
        </div>
    );
};
