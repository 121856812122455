import { useRef, useLayoutEffect, useCallback, type MutableRefObject } from 'react';
import noop from 'lodash/fp/noop';

export type Options = {
    attributes?: boolean;
    childList?: boolean;
    subtree?: boolean;
};

// Options for the observer (which mutations to observe)
const DEFAULT_OPTIONS: Options = {
    attributes: true,
    childList: false,
    subtree: false,
};

// biome-ignore lint/suspicious/noExplicitAny: <explanation>
const isRef = (obj: any): obj is MutableRefObject<HTMLElement | null> =>
    obj !== null && typeof obj === 'object' && Object.prototype.hasOwnProperty.call(obj, 'current');

const unRef = (target: HTMLElement | MutableRefObject<HTMLElement | null> | null): HTMLElement | null =>
    isRef(target) ? target.current : target;

/**
 * Custom hook to observe mutations on a target element.
 *
 * @param {HTMLElement | MutableRefObject<HTMLElement | null> | null} target - The element or ref to observe.
 * @param {MutationCallback | undefined} callback - The callback function to execute when mutations occur.
 * @param {object} options - Options to configure the MutationObserver.
 * @param {boolean} options.attributes - Set to true if attribute mutations should be observed.
 * @param {boolean} options.childList - Set to true if mutations to target's children should be observed.
 * @param {boolean} options.subtree - Set to true if mutations to target's descendants should be observed.
 */
const useMutationObserver = (
    target: HTMLElement | MutableRefObject<HTMLElement | null> | null,
    callback: MutationCallback | undefined = noop,
    options: {
        attributes?: boolean;
        childList?: boolean;
        subtree?: boolean;
    } = DEFAULT_OPTIONS
) => {
    const observer = useRef<MutationObserver | null>(null);

    const disconnect = useCallback(() => observer.current?.disconnect(), []);

    const observe = useCallback(() => {
        const targetElement = unRef(target);

        if (targetElement) {
            observer.current = new MutationObserver(callback);
            observer.current.observe(targetElement, options);
        }
    }, [callback, options, target]);

    useLayoutEffect(() => {
        observe();
        return () => disconnect();
    }, [disconnect, observe]);
};

export default useMutationObserver;
