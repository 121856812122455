// @ts-ignore-next-line importsNotUsedAsValues
import React from 'react';

export type NoItemMessageProps = {
    noItemMessage: string | React.ReactNode;
    className?: string;
};

const NoItemMessage = (props: NoItemMessageProps) => {
    const { noItemMessage, className } = props;

    if (!noItemMessage) {
        return null;
    }

    return (
        <ul className={className} role='menu'>
            <li className='no-item-message disabled' role='presentation'>
                {/* biome-ignore lint/a11y/useValidAnchor: common structure to use anchor tags without links */}
                <a role='menuitem'>
                    <i>{noItemMessage}</i>
                </a>
            </li>
        </ul>
    );
};

export default NoItemMessage;
