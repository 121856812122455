import { Playground, PLAYGROUNG_BG_LIGHTER } from '../../../components/playground/Playground';
import { PlaygroundClasses } from '../../../components/playground/PlaygroundClasses';
import { PlaygroundColors } from '../../../components/playground/PlaygroundColors';
import { Description } from '../../../components/Description';

import { colors, getColorNames } from '../../../utils/colors';

export const Callouts = () => (
    <div>
        <Description headline='Callouts' anchor='callouts' />
        <Playground
            example={callouts}
            classes={calloutsClasses}
            background={PLAYGROUNG_BG_LIGHTER}
            availableColors={<PlaygroundColors bgDefault bgPrimary bgSecondary bgInfo bgSuccess bgWarning bgDanger />}
        />
    </div>
);
const eachColor = ['default', ...getColorNames(colors.brand, colors.status)];

const callouts = (
    <div className='display-grid grid-cols-1 grid-cols-2-ls grid-cols-3-md gap-20'>
        {eachColor.map((color, index) => (
            <div className={`callout callout-${color}`} key={index}>
                <div
                    className={`${
                        color !== 'default' ? `text-color-${color}` : ''
                    } text-medium text-size-20 line-height-125rel margin-bottom-10`}
                >
                    Callout <span>{color}</span>
                </div>
                <p>At vero eos et accusam et justo duo dolores et ea rebum clita kasd gubergren, no sea takimata.</p>
            </div>
        ))}
    </div>
);

const calloutsClasses = <PlaygroundClasses label='Colors' prefix='callout-' classes={eachColor} />;
