// @ts-ignore-next-line importsNotUsedAsValues
import React, { forwardRef, type ForwardedRef } from 'react';
import isEmpty from 'lodash/fp/isEmpty';
import classNames from 'classnames';

import ApplicationActionBar from '../../../../components/applicationHeader/ApplicationActionBar';
import type { ApplicationHeaderProps } from '../../../../ApplicationHeader';

export type ModuleProps = {
    key: string;
    route: React.ReactNode;
};

export type VolkswagenApplicationHeaderProps = ApplicationHeaderProps & {
    homeRoute?: React.ReactNode;
    appMenuItems?: ModuleProps[];
    appNavigator?: React.ReactNode;
    appNavigatorClassName?: string;
    navItems?: ModuleProps[];
    actionBarItems?: React.ReactNode[];
    onMount?: VoidFunction;
    onUnmount?: VoidFunction;
    className?: string;
};

export const VolkswagenApplicationHeader = forwardRef(
    (props: VolkswagenApplicationHeaderProps, ref: ForwardedRef<HTMLElement>) => {
        const {
            homeRoute = '',
            appNavigator,
            appNavigatorClassName,
            appMenuItems,
            navItems = [],
            actionBarItems = [],
            onMount = () => {},
            onUnmount = () => {},
            className = '',
            ...remainingProps
        } = props;

        const wrapperClassNames = classNames(
            'ApplicationHeader VolkswagenApplicationHeader',
            'user-select-none',
            className && className
        );

        return (
            <nav {...remainingProps} className={wrapperClassNames} ref={ref}>
                <div className='width-100pct'>
                    <div className='brand'>
                        <div className='logo'>{homeRoute}</div>
                    </div>
                    {isEmpty(navItems) && <ul className='SubmoduleNavigation nav' />}
                    {!isEmpty(navItems) && (
                        <ul className='SubmoduleNavigation nav'>
                            {navItems.map(navItem => (
                                <li key={navItem.key} className='submodule' data-nav-item-key={navItem.key}>
                                    {navItem.route}
                                </li>
                            ))}
                        </ul>
                    )}
                    <ApplicationActionBar items={actionBarItems} />
                </div>
            </nav>
        );
    }
);

export default VolkswagenApplicationHeader;
