import React from 'react';
import classNames from 'classnames';
// @ts-ignore-next-line 7016
import { NotificationContainer } from 'react-notifications';

export type NotificationsContainerProps = {
    /**
     * Defines whether the notification are stacked.
     */
    stacked?: boolean;
};

const NotificationsContainer = ({ stacked }: NotificationsContainerProps) => {
    const wrapperClasses = classNames('notification-container-wrapper', stacked && 'stacked');
    return (
        <div className={wrapperClasses}>
            <NotificationContainer />
        </div>
    );
};

export default NotificationsContainer;
