// @ts-ignore-next-line importsNotUsedAsValues
import React, {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useRef,
    type ForwardedRef,
    type PropsWithChildren,
} from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import classNames from 'classnames';
import noop from 'lodash/fp/noop';
import debounce from 'lodash/fp/debounce';

export type SmoothScrollbarsProps = {
    slideIn?: boolean;
    largeTrack?: boolean;
    trackOffset?: boolean;
    className?: string;
    tagName?: string;
    autoHeight?: boolean;
    autoHeightMin?: number;
    autoHeightMax?: number;
    onScroll?: (event: React.UIEvent<any> | undefined) => void;
};

type ScrollbarsWithUpdate = Scrollbars & {
    update: () => void;
};

const RESIZE_THROTTLING = 100;

const SmoothScrollbars = forwardRef(
    (componentProps: PropsWithChildren<SmoothScrollbarsProps>, ref: ForwardedRef<any>) => {
        const {
            slideIn = false,
            largeTrack = false,
            trackOffset = false,
            className = '',
            onScroll = noop,
            tagName = 'div',
            autoHeight = false,
            autoHeightMin = 0,
            autoHeightMax = 200,
            children,
        } = componentProps;

        const scrollbarsRef = useRef<Scrollbars>(null);
        const contentRef = useRef<HTMLDivElement>(null);

        useImperativeHandle(ref, () => scrollbarsRef.current as Scrollbars);

        useEffect(() => {
            if (!contentRef.current || !scrollbarsRef.current) {
                return;
            }

            const updateScrollbars = debounce(RESIZE_THROTTLING)(() => {
                if (scrollbarsRef.current) {
                    // Trigger a update for the scrollbars.
                    // This is needed since the lib does not automatically update on content height changes
                    // See: https://github.com/RobPethick/react-custom-scrollbars-2/issues/12

                    // Use type assertion to tell TypeScript that 'update' exists
                    (scrollbarsRef.current as ScrollbarsWithUpdate).update();
                }
            });

            const resizeObserver = new ResizeObserver(updateScrollbars);
            resizeObserver.observe(contentRef.current);

            return () => {
                resizeObserver.disconnect();
                updateScrollbars.cancel();
            };
        }, []);

        const wrapperClassNames = classNames(className, slideIn && 'scrollbar-fly-in', 'smooth-scrollbar-wrapper');

        const renderTrackVerticalNames = classNames(
            'scrollbar-track-vertical',
            largeTrack && 'scrollbar-track-size-large',
            trackOffset && 'scrollbar-track-offset'
        );

        const renderThumbVerticalNames = classNames(
            'scrollbar-thumb-vertical',
            largeTrack && 'scrollbar-thumb-size-large'
        );

        const renderTrackHorizontalNames = classNames(
            'scrollbar-track-horizontal',
            largeTrack && 'scrollbar-track-size-large',
            trackOffset && 'scrollbar-track-offset'
        );

        const renderThumbHorizontalNames = classNames(
            'scrollbar-thumb-horizontal',
            largeTrack && 'scrollbar-thumb-size-large'
        );

        return (
            <Scrollbars
                ref={scrollbarsRef}
                tagName={tagName}
                onScroll={onScroll}
                className={wrapperClassNames}
                hideTracksWhenNotNeeded
                autoHeight={autoHeight}
                autoHeightMin={autoHeightMin}
                autoHeightMax={autoHeightMax}
                renderView={props => {
                    return <div {...props} className='scrollbar-view' />;
                }}
                renderTrackVertical={props => {
                    return <div {...props} className={renderTrackVerticalNames} />;
                }}
                renderThumbVertical={props => {
                    return <div {...props} className={renderThumbVerticalNames} />;
                }}
                renderTrackHorizontal={props => {
                    return <div {...props} className={renderTrackHorizontalNames} />;
                }}
                renderThumbHorizontal={props => {
                    return <div {...props} className={renderThumbHorizontalNames} />;
                }}
            >
                <div className='scrollbar-content-wrapper' ref={contentRef}>
                    {children}
                </div>
            </Scrollbars>
        );
    }
);

export default SmoothScrollbars;
