import { Playground } from '../../../components/playground/Playground';
import { PlaygroundClasses } from '../../../components/playground/PlaygroundClasses';
import { Description } from '../../../components/Description';
import { Title } from '../../../components/Title';
import { Note } from '../../../components/Note';

const eachScale = ['50', '80', '90', '100', '105', '130', '140', '150', '160', '170', '180', '190', '200'];

export const Scale = () => (
    <div>
        <Description headline='Scale' anchor='scale' />
        <Playground example={scaleExample} classes={scaleClasses} />
        <Note>
            The transform classes can not be combined. For example rotate or scale. It is because of the CSS syntax. For
            more information see{' '}
            <a href='https://developer.mozilla.org/en-US/docs/Web/CSS/transform#Syntax'>Transform syntax</a>.
        </Note>
    </div>
);

const scaleExample = (
    <div>
        <Title>Scale</Title>
        <div className='display-flex flex-wrap gap-20 margin-bottom-50'>
            {eachScale.map((value, index) => (
                <div className={`width-20 height-20 bg-info rounded scale-${value}`} key={index} />
            ))}
        </div>
        <Title>Hover scale</Title>
        <div className='display-flex flex-wrap gap-20 margin-bottom-20'>
            {eachScale.map((value, index) => (
                <div className={`width-20 height-20 bg-info rounded hover-scale-${value}`} key={index} />
            ))}
        </div>
    </div>
);

const scaleClasses = (
    <>
        <PlaygroundClasses label='Scale' prefix='scale-' classes={eachScale} />
        <PlaygroundClasses label='Hover scale' prefix='hover-scale-' classes={eachScale} />
    </>
);
