import { useMemo, useState } from 'react';
import { useLocation } from 'react-router';
import upperFirst from 'lodash/fp/upperFirst';
import last from 'lodash/fp/last';

import useDocumentTitle from '../../../src/useDocumentTitle';
import { ListMenu } from '../index';
import { isMobile, isMac } from '../../../src/DeviceUtils';
import { useUikitContext } from '../UikitContext';
import { routes } from '../routes';

const TITLE_PREFIX = 'RIO UIKIT - ';

// change document title for active page
const useTitle = (pathname: string) => {
    const title = last(pathname.split('/')) ?? '';
    useDocumentTitle(`${TITLE_PREFIX}${upperFirst(title)}`);
};

export const UikitMenu = () => {
    const [filterValue, setFilterValue] = useState('');

    const { pathname } = useLocation();

    const { startMenu, designMenu, componentsMenu, mapMenu, chartsMenu } = useUikitContext();

    useTitle(pathname);

    const isDesktop = !isMobile();

    const isMacOs = isMac();

    const items = useMemo(() => {
        // No filter: select menu based on pathname
        if (!filterValue) {
            if (pathname.startsWith(routes.START)) {
                return startMenu;
            }
            if (pathname.startsWith(routes.COMPONENTS)) {
                return componentsMenu;
            }
            if (pathname.startsWith(routes.DESIGN)) {
                return designMenu;
            }
            if (pathname.startsWith(routes.MAP)) {
                return mapMenu;
            }
            if (pathname.startsWith(routes.CHARTS)) {
                return chartsMenu;
            }
            return [];
        }

        // With filter: merge all menu items
        return [...startMenu, ...designMenu, ...componentsMenu, ...mapMenu, ...chartsMenu];
    }, [filterValue, pathname, startMenu, designMenu, componentsMenu, mapMenu, chartsMenu]);

    return (
        <ListMenu
            menuItems={items}
            enableFilter
            focusFilter
            filterKey='meta'
            filterPlaceholder='Filter by name'
            onFilterChange={setFilterValue}
            notFoundMessage='Nothing found'
            responsive={false}
            trailingInputAddon={
                isDesktop && (
                    <div className='input-group-addon padding-right-5'>
                        <kbd className='text-normal'>{isMacOs ? '⌘' : 'ctrl'}</kbd>
                        <kbd className='text-normal'>k</kbd>
                    </div>
                )
            }
        />
    );
};
