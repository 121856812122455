import React from 'react';
import classNames from 'classnames';

import ClearableInput, { type ClearableInputProps } from '../clearableInput/ClearableInput';

export type TableSearchProps = Omit<ClearableInputProps, 'className'> & {
    /**
     * Optional class names for the wrapper.
     */
    className?: string;
};

const TableSearch = (props: TableSearchProps) => {
    const { className, ...remainingProps } = props;

    return (
        <div className={classNames('table-toolbar-search', 'input-group', className)}>
            <span className='input-group-addon'>
                <span className='rioglyph rioglyph-search' />
            </span>
            <ClearableInput {...remainingProps} />
        </div>
    );
};

export default TableSearch;
