import { cssua } from './cssuseragent';

// Navigator.standalone - Returns a boolean indicating whether the browser is running in standalone mode.
// Caution, it's available on Apple's iOS Safari only.
type NavigatorWithStandalone = Navigator & {
    standalone?: boolean;
};

// As "cssuseragent" is accessing the html dom object directly without checking whether it exists or not,
// we need to initialize only when html really exists. This avoids issues when running the UIKIT in a web-worker
if (document?.documentElement) {
    cssua(document.documentElement, navigator.userAgent, (navigator as NavigatorWithStandalone).standalone);
}

// biome-ignore lint/suspicious/noExplicitAny: <explanation>
const isDefined = (val: any): boolean => val !== undefined && val !== null;

// see https://patrickhlauke.github.io/touch/touchscreen-detection/ for reference
export const hasTouch = () => {
    if (window.PointerEvent && isDefined(navigator.maxTouchPoints)) {
        // if Pointer Events are supported, just check maxTouchPoints
        if (navigator.maxTouchPoints > 0) {
            return true;
        }
    } else if (window.matchMedia?.('(any-pointer:coarse)').matches) {
        // no Pointer Events...
        // check for any-pointer:coarse which mostly means touchscreen
        return true;
    } else if (window.TouchEvent || isDefined(window.ontouchstart)) {
        // last resort - check for exposed touch events API / event handler
        return true;
    }

    return false;
};

// Screen sizes
export const MOBILE_SCREEN = 480;

export const SCREEN_SM = 768;
export const SCREEN_MD = 992;
export const SCREEN_LG = 1200;
export const SCREEN_XL = 1700;

export const isMobileScreen = () => window.matchMedia(`(max-width: ${MOBILE_SCREEN}px)`).matches;

export const isMobile = () => document?.documentElement.classList.contains('ua-mobile');
export const isDesktop = () => document?.documentElement.classList.contains('ua-desktop');

export const isMac = () => document?.documentElement.classList.contains('ua-desktop-macintosh');

export const isRetinaDevice = () => window.devicePixelRatio > 1;

export const inIframe = () => {
    try {
        return window.self !== window.top;
    } catch (_) {
        return true;
    }
};

// Disables the Zoom for the mobile devices.
// This is not good for accessibility but makes the webpage feel more like a native app.
// <meta name="viewport" content="user-scalable=0 ..." />
const USER_SCALABLE = 'user-scalable=0 maximum-scale=1';
export const toggleZoomOnMobile = () => {
    if (!document) {
        return;
    }

    const viewportMeta = document.head.querySelector('[name="viewport"]') as HTMLMetaElement | null;
    if (!viewportMeta) {
        return;
    }

    const isScalable = viewportMeta.content.includes(USER_SCALABLE);

    // Restrict the zoom for mobile if not yet set
    if (isMobile() && !isScalable) {
        viewportMeta.content += ` ${USER_SCALABLE}`;
        return;
    }

    // Remove the setting, for non mobile cases
    if (!isMobile() && isScalable) {
        viewportMeta.content = viewportMeta.content.replace(USER_SCALABLE, '').trim();
        return;
    }
};
