import { Playground } from '../../../components/playground/Playground';
import {
    PlaygroundClasses,
    PlaygroundResponsiveClasses,
    PlaygroundPrintClasses,
} from '../../../components/playground/PlaygroundClasses';

import { Description } from '../../../components/Description';
import { Title } from '../../../components/Title';

import { dummyTextShort, dummyTextUltraShort, dummyTextExtraShort } from '../../../utils/data';

export const Flexbox = () => {
    return (
        <div>
            <Description headline='Flexbox' anchor='flexbox' />
            <Playground example={flexboxExample} classes={flexboxClasses} />
        </div>
    );
};

const flexboxExample = (
    <div>
        <Title>Center</Title>
        <div className='display-flex justify-content-center align-items-center bg-lightest margin-bottom-20 height-100'>
            <div className='bg-info rounded padding-10'>Lorem ipsum dolor</div>
        </div>

        <div className='display-flex justify-content-between gap-20'>
            <div className='flex-1-1'>
                <Title>Top start</Title>
                <div
                    className={
                        'display-flex justify-content-start align-items-start ' +
                        'bg-lightest margin-bottom-20 height-100'
                    }
                >
                    <div className='bg-info rounded padding-10'>Lorem ipsum dolor</div>
                </div>

                <Title>Bottom start</Title>
                <div
                    className={
                        'display-flex justify-content-start align-items-end ' +
                        'bg-lightest margin-bottom-20 height-100'
                    }
                >
                    <div className='bg-info rounded padding-10'>Lorem ipsum dolor</div>
                </div>
            </div>

            <div className='flex-1-1'>
                <Title>Top end</Title>
                <div
                    className={
                        'display-flex justify-content-end align-items-start ' +
                        'bg-lightest margin-bottom-20 height-100'
                    }
                >
                    <div className='bg-info rounded padding-10'>Lorem ipsum dolor</div>
                </div>

                <Title>Bottom end</Title>
                <div
                    className={
                        'display-flex justify-content-end align-items-end ' + 'bg-lightest margin-bottom-20 height-100'
                    }
                >
                    <div className='bg-info rounded padding-10'>Lorem ipsum dolor</div>
                </div>
            </div>
        </div>

        <Title>Space between</Title>
        <div className='display-flex justify-content-between align-items-center bg-lightest margin-bottom-20'>
            <div className='bg-info padding-10 rounded'>Lorem ipsum dolor</div>
            <div className='bg-info padding-10 rounded'>Lorem ipsum dolor</div>
            <div className='bg-info padding-10 rounded'>Lorem ipsum dolor</div>
        </div>

        <Title>Even split columns</Title>
        <div className='display-flex gap-20 margin-bottom-20'>
            <div className='flex-basis-100pct bg-info padding-10 rounded'>{dummyTextShort}</div>
            <div className='flex-basis-100pct bg-info padding-10 rounded'>{dummyTextUltraShort}</div>
        </div>

        <Title>Multiple even columns</Title>
        <div className='display-flex gap-20 margin-bottom-20'>
            <div className='flex-basis-100pct bg-info padding-10 rounded'>{dummyTextShort}</div>
            <div className='flex-basis-100pct bg-info padding-10 rounded'>{dummyTextUltraShort}</div>
            <div className='flex-basis-100pct bg-info padding-10 rounded'>{dummyTextExtraShort}</div>
        </div>

        <Title>70/30 columns</Title>
        <div className='display-flex gap-20'>
            <div className='flex-basis-70pct bg-info padding-10 rounded'>{dummyTextShort}</div>
            <div className='flex-basis-30pct bg-info padding-10 rounded'>{dummyTextExtraShort}</div>
        </div>
    </div>
);

const flexboxClasses = (
    <>
        <PlaygroundClasses label='Wrapper' classes='display-flex' />
        <PlaygroundClasses label='Shorthands' prefix='flex-' classes={['0', '0-1', '1-0', '1-1', '1-1-0']} />
        <PlaygroundClasses
            label='Row/Col'
            prefix='flex-'
            classes={['row', 'row-reverse', 'column', 'column-reverse']}
        />
        <PlaygroundClasses label='Order' prefix='flex-order-' classes={['1', '2', '3', '4', '5']} />
        <PlaygroundClasses label='Wrap' prefix='flex-' classes={['wrap', 'nowrap', 'wrap-reverse']} />
        <PlaygroundClasses
            label='Justify content'
            prefix='justify-content-'
            classes={['start', 'end', 'center', 'between', 'around']}
        />
        <PlaygroundClasses label='Justify Self' prefix='justify-self-' classes={['start', 'end', 'center']} />
        <PlaygroundClasses
            label='Align items'
            prefix='align-items-'
            classes={['start', 'end', 'center', 'baseline', 'stretch']}
        />
        <PlaygroundClasses
            label='Align content'
            prefix='align-content-'
            classes={['start', 'end', 'center', 'between', 'around', 'stretch']}
        />
        <PlaygroundClasses
            label='Align self'
            prefix='align-self-'
            classes={['auto', 'start', 'end', 'center', 'baseline', 'stretch']}
        />
        <PlaygroundClasses
            label='Basis'
            prefix='flex-basis-'
            suffix='pct'
            classes={['10', '20', '25', '30', '33', '40', '50', '60', '66', '70', '75', '80', '90', '100']}
        />
        <PlaygroundResponsiveClasses />
        <PlaygroundPrintClasses />
    </>
);
