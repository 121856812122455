import React, { useRef, forwardRef, useCallback, useImperativeHandle, type ForwardedRef } from 'react';
import isEmpty from 'lodash/fp/isEmpty';
import classNames from 'classnames';

import AppMenu from './AppMenu';
import ApplicationActionBar from './ApplicationActionBar';
import MobileSubmoduleNavigation from './MobileSubmoduleNavigation';
import MobileAppMenu from './MobileAppMenu';
import useResizeObserver from '../../hooks/useResizeObserver';
import NavItems from './NavItems';

const SCREEN_SM = 768; // @screen-sm: 768px;

export type ModulePropType = {
    /**
     * Unique key for the sub-module navigation component of an app.
     */
    key: string;

    /**
     * The navigation link component used for rendering the name and for routing.
     *
     * @example
     *
     * ```tsx
     * <NavLink to='/app/ipsum'>{'Lorem ipsum'}</NavLink>
     * ```
     */
    route: React.ReactNode;
};

export type ApplicationHeaderProps = {
    /**
     * The service name shown as the navigator dropdown label.
     */
    label?: string | React.ReactNode;

    /**
     * The home URL that shall be used for the home button. Pass a `react-router-dom` `NavLink`
     * as homeRoute into the header. For example:
     *
     * @example
     *
     * ```tsx
     * <NavLink to='https://home.rio.cloud' />
     * ```
     */
    homeRoute?: React.ReactNode;

    /**
     * Defines to either show the home icon or the RIO logo as brand logo.
     */
    showHomeIcon?: boolean;

    /**
     * The component for the navigation between apps which will be shown inside the main dropdown.
     *
     * Platform apps may use the default AppNavigator, but there are also cases like support apps where it's just a
     * simple list In this case, use the prop `appMenuItems`.
     *
     * If no appNavigator is defined, the dropdown menu will not be rendered.
     */
    appNavigator?: React.ReactNode;

    /**
     * Additional class names that are added to the appNavigator.
     */
    appNavigatorClassName?: string;

    /**
     * List of application navigation link components.
     */
    appMenuItems?: ModulePropType[];

    /**
     * List of submodule navigation component of an app.
     *
     * Items collapse into a dropdown if remaining space in the header is not sufficient.
     */
    navItems?: ModulePropType[];

    /**
     * List of external smart components.
     *
     * For example, the rio-accountmenu or application-owned components such as a ServiceInfo icon. These components
     * are based on the `ActionBarItem`.
     */
    actionBarItems?: React.ReactNode[];

    /**
     * Callback function triggered when the application menu is open or closed.
     *
     * @param isOpen The new "open" state.
     */
    onToggleAppMenu?: (isOpen: boolean) => void;

    /**
     * Additional class names that are added to the wrapper component.
     */
    className?: string;
};

export const ApplicationHeader = forwardRef((props: ApplicationHeaderProps, ref: ForwardedRef<HTMLDivElement>) => {
    const {
        homeRoute = '',
        showHomeIcon = true,
        label,
        className,
        appNavigator,
        appNavigatorClassName,
        appMenuItems,
        navItems,
        actionBarItems = [],
        onToggleAppMenu = () => {},
        ...remainingProps
    } = props;

    const actionBarRef = useRef(null);

    const [navRef, entry] = useResizeObserver();

    const contentRect = entry?.contentRect;

    useImperativeHandle<HTMLDivElement, any>(ref, () => navRef, [navRef]);

    const getContentRect = useCallback(
        (key: keyof DOMRectReadOnly) => {
            if (contentRect) {
                const value = contentRect[key];
                return typeof value === 'number' && Math.round(value);
            }
        },
        [contentRect]
    );
    const containerWidth = getContentRect('width') || 0;

    const isMobileWidth = containerWidth <= SCREEN_SM;

    const hasActionBarItems = !isEmpty(actionBarItems);

    const wrapperClassNames = classNames(
        'ApplicationHeader',
        'user-select-none',
        isMobileWidth && 'mobile',
        className && className
    );

    return (
        <nav {...remainingProps} className={wrapperClassNames} ref={navRef}>
            {isMobileWidth && (
                <React.Fragment>
                    <MobileAppMenu
                        showHomeIcon={showHomeIcon}
                        homeRoute={homeRoute}
                        appMenuItems={appMenuItems}
                        appNavigator={appNavigator}
                        onToggleAppMenu={onToggleAppMenu}
                    />
                    <div className='flex-1-1-0 display-flex gap-10'>
                        <MobileSubmoduleNavigation className='flex-1-1' label={label} navItems={navItems} />
                        {hasActionBarItems && <Divider />}
                        <ApplicationActionBar className='mobile' nodeRef={actionBarRef} items={actionBarItems} />
                    </div>
                </React.Fragment>
            )}
            {!isMobileWidth && (
                <React.Fragment>
                    <div className='navbar-header'>
                        <span className={`navbar-brand ${showHomeIcon ? 'home-icon' : ''}`}>{homeRoute}</span>
                    </div>
                    {label && (
                        <AppMenu
                            label={label}
                            key='AppMenu'
                            appMenuItems={appMenuItems}
                            appNavigator={appNavigator}
                            appNavigatorClassName={appNavigatorClassName}
                            onToggleAppMenu={onToggleAppMenu}
                        />
                    )}
                    {isEmpty(navItems) && <ul className='SubmoduleNavigation nav' />}
                    {!isEmpty(navItems) && (
                        <NavItems
                            key='NavItems'
                            navItems={navItems}
                            containerWidth={containerWidth}
                            actionBarItems={actionBarItems}
                        />
                    )}
                    <ApplicationActionBar nodeRef={actionBarRef} items={actionBarItems} />
                </React.Fragment>
            )}
        </nav>
    );
});

const Divider = () => (
    <div className='divider display-flex align-items-center'>
        <div className='width-3 height-20 bg-lighter' />
    </div>
);

export default ApplicationHeader;
