import React, { useCallback, useState } from 'react';
import {
    closestCenter,
    DndContext,
    type DragEndEvent,
    type DragStartEvent,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
    useDraggable,
} from '@dnd-kit/core';
import {
    SortableContext,
    rectSortingStrategy,
    sortableKeyboardCoordinates,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import type { UniqueIdentifier } from '@dnd-kit/core/dist/types';

import TableSettingsListItem from './TableSettingsListItem';
import type { TableSettingsItemProps } from './TableSettingsDialog.types';

export type TableSettingsListContainerProps = {
    items: string[];
    itemProps: TableSettingsItemProps;
    onSortEnd: (event: DragEndEvent, previousOrder: string[]) => void;
};

export const TableSettingsListContainer = (props: TableSettingsListContainerProps) => {
    const { items, itemProps, onSortEnd } = props;

    const [activeDraggedColumn, setActiveDraggedColumn] = useState<UniqueIdentifier | null>(null);

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, { coordinateGetter: sortableKeyboardCoordinates })
    );

    const handleDragStart = useCallback((event: DragStartEvent) => {
        setActiveDraggedColumn(event.active.id);
    }, []);

    const handleDragEnd = (event: DragEndEvent) => {
        setActiveDraggedColumn(null);
        onSortEnd(event, items);
    };

    const activeClass = 'table-settings-item shadow-smooth-to-bottom z-index-max';

    return (
        <div className='table-settings-items-container'>
            <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                modifiers={[restrictToVerticalAxis]}
                onDragStart={handleDragStart}
                onDragEnd={handleDragEnd}
            >
                <SortableContext items={items} strategy={verticalListSortingStrategy}>
                    {items.map((column, index) => (
                        <TableSettingsListItem
                            key={column}
                            column={column}
                            orderIndex={index}
                            isActive={activeDraggedColumn === column}
                            className={activeDraggedColumn === column ? activeClass : ''}
                            {...itemProps}
                        />
                    ))}
                </SortableContext>
            </DndContext>
        </div>
    );
};
