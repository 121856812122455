import React, { useRef, useEffect, type ReactNode, type PropsWithChildren } from 'react';
import classNames from 'classnames';
import noop from 'lodash/fp/noop';

import { CheckboxIcon } from './CheckboxIcon';

const DEFAULT_ICON_SIZE = 16;

const ICON_LABEL_VERTICAL = 'vertical';
const ICON_LABEL_HORIZONTAL = 'horizontal';

export type CheckboxProps = {
    /**
     * Passed through as HTML attribute to the underlying input.
     * Set an `id` or a `name` otherwise.
     */
    id?: string;

    /**
     * Passed through as HTML attribute to the underlying input
     */
    name?: string;

    /**
     * Define some text or component as a label.
     */
    label?: string | ReactNode;

    /**
     * Define a custom icon for the checkbox by naming a rioglyph icon like `rioglyph-truck`.
     */
    icon?: string;

    /**
     * The icon size in pixel.
     */
    iconSize?: number;

    /**
     * The label position for a custom icon checkbox. Using this on a regular checkbox has no effect.
     *
     * Possible values are: `horizontal` or `vertical`.
     */
    iconLabelPosition?: typeof ICON_LABEL_VERTICAL | typeof ICON_LABEL_HORIZONTAL;

    /**
     * Callback function that is invoked when the checkbox is checked or unchecked.
     * @param event
     * @returns
     */
    onClick?: (event: React.MouseEvent<HTMLInputElement>) => void;

    /**
     * Callback function that is invoked when the checkbox value changes.
     * This will also be invoked by a keyboard event.
     * @param event
     * @returns
     */
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;

    /**
     * Defines whether the checkbox is checked or not (state cannot be changed).
     */
    checked?: boolean;

    /**
     * Defines whether the checkbox is initially checked or not (state can be changed on click).
     */
    defaultChecked?: boolean;

    /**
     * Defines whether the checkbox is disabled or not.
     * @default false
     */
    disabled?: boolean;

    /**
     * Allows for rendering a completely different layout with or without a checkbox tick.
     * Note, when using the `custom` option, make sure to wrap the children with the class name
     * `checkbox-text-wrapper`. For using the checkbox tick, use an element with class name
     * `checkbox-text`.
     *
     * @example
     *
     * ```tsx
     * <div className='checkbox-text-wrapper display-flex justify-content-between'>
     *     <div className='margin-right-15'>
     *         <div className='text-medium text-size-16 text-color-darker'>Option 1</div>
     *         <div className='text-color-dark'>This option is a first option</div>
     *     </div>
     *     <div className='checkbox-text' />
     * </div>
     * ```
     */
    custom?: boolean;

    /**
     * Defines whether the checkbox is required or not.
     *
     * @default false
     */
    required?: boolean;

    /**
     * Defines if the checkbox is in an indeterminate state in regard to other checkboxes that may be in different
     * selection state.
     *
     * @default false
     */
    indeterminate?: boolean;

    /**
     * Defines whether the checkbox is applying an inline style. Use this in combination
     * with other checkboxes.
     *
     * @default false
     */
    inline?: boolean;

    /**
     * Defines whether the checkbox is on the right side.
     *
     * @default false
     */
    right?: boolean;

    /**
     * Use "error" to change color of the checkbox.
     *
     * @default false
     */
    error?: boolean;

    /**
     * Defines the size of the checkbox. Omitting this prop renders the Checkbox in normal size.
     *
     * Possible values are:
     * `large`
     */
    size?: 'large';

    /**
     * A React ref assigned to the input itself.
     */
    inputRef?: React.RefObject<HTMLInputElement>;

    /**
     * Number of the index used for keyboard support.
     *
     * @default 0
     */
    tabIndex?: number;

    /**
     * Additional classes to be set on the input field.
     */
    className?: string;
};

type CheckboxEvent =
    | React.MouseEvent<HTMLInputElement>
    | React.ChangeEvent<HTMLInputElement>
    | React.KeyboardEvent<HTMLLabelElement>;

const Checkbox = (props: PropsWithChildren<CheckboxProps>) => {
    const {
        checked,
        children,
        className,
        custom = false,
        defaultChecked,
        disabled = false,
        error = false,
        icon = '',
        iconLabelPosition = ICON_LABEL_VERTICAL,
        iconSize = DEFAULT_ICON_SIZE,
        id = props.name,
        indeterminate = false,
        inline = false,
        inputRef,
        label,
        name,
        onChange = noop,
        onClick = noop,
        required = false,
        right,
        size,
        tabIndex = 0,
        ...remainingProps
    } = props;

    const labelRef = useRef<HTMLLabelElement>(null);

    useEffect(() => {
        const input = labelRef.current?.firstChild as HTMLInputElement;
        if (input) {
            input.indeterminate = indeterminate;
        }
    }, [indeterminate, labelRef.current]);

    const handleToggleKeyDown = (event: React.KeyboardEvent<HTMLLabelElement>) => {
        switch (event.key) {
            case ' ':
                // toggle on space
                toggle(event);
                break;
            case 'Enter':
                // open on enter
                toggle(event);
                break;
            default:
                break;
        }
    };

    const toggle = (event: CheckboxEvent) => {
        event.preventDefault();

        if (disabled) {
            return;
        }

        const input = labelRef.current?.firstChild as HTMLInputElement;

        if (input.indeterminate) {
            input.indeterminate = false;
        }

        input.checked = !input.checked;

        onClick(event as React.MouseEvent<HTMLInputElement>);
        onChange(event as React.ChangeEvent<HTMLInputElement>);
    };

    const text = label || children;

    const labelClassnames = classNames(
        'checkbox',
        inline && 'checkbox-inline',
        size === 'large' && 'checkbox-large',
        right && 'checkbox-right',
        className
    );

    const inputClassnames = classNames(
        error && 'error',
        size === 'large' && 'large',
        indeterminate && 'indeterminate',
        className
    );

    const renderCustomIcon = !!icon;
    const renderCustomContent = custom && children;
    const renderDefault = !icon && !custom;

    return (
        <label
            {...remainingProps}
            className={labelClassnames}
            tabIndex={tabIndex}
            htmlFor={id}
            onKeyDown={handleToggleKeyDown}
            ref={labelRef}
        >
            <input
                id={id}
                name={name}
                type='checkbox'
                checked={checked}
                required={required}
                defaultChecked={defaultChecked}
                disabled={disabled}
                className={inputClassnames}
                onClick={onClick}
                onChange={onChange}
                ref={inputRef}
            />
            {renderCustomIcon && (
                <CheckboxIcon icon={icon} iconSize={iconSize} iconLabelPosition={iconLabelPosition} text={text} />
            )}
            {renderDefault && <span className='checkbox-text'>{text && <span>{text}</span>}</span>}
            {renderCustomContent && children}
        </label>
    );
};

Checkbox.ICON_LABEL_VERTICAL = ICON_LABEL_VERTICAL as typeof ICON_LABEL_VERTICAL;
Checkbox.ICON_LABEL_HORIZONTAL = ICON_LABEL_HORIZONTAL as typeof ICON_LABEL_HORIZONTAL;

export default Checkbox;
