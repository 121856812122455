// @ts-ignore-next-line importsNotUsedAsValues
import React from 'react';
import classNames from 'classnames';
import isObject from 'lodash/fp/isObject';

import Checkbox from '../checkbox/Checkbox';
import RadioButton from '../radiobutton/RadioButton';
import type { TreeItem } from './Tree';

export type TreeLeafProps = {
    item: TreeItem;
    hasMultiselect: boolean;
    showRadioButtons: boolean;
    isSelected: boolean;
    onSelectItem: VoidFunction;
    onActiveItem: VoidFunction;
};

const TreeLeaf = React.memo((props: TreeLeafProps) => {
    const { item, hasMultiselect, showRadioButtons, isSelected, onSelectItem, onActiveItem } = props;

    const treeNodeClassNames = classNames(
        'TreeLeaf',
        'form-group margin-bottom-0',
        isSelected && 'active',
        item.className && item.className
    );

    return (
        <div className={treeNodeClassNames} data-key={item.id}>
            {hasMultiselect && <Checkbox className='TreeCheckbox' checked={isSelected} onClick={onSelectItem} />}
            {!hasMultiselect && showRadioButtons && (
                <RadioButton className='TreeRadioButton' checked={isSelected} onChange={onSelectItem} />
            )}
            <span className='TreeLabel TreeLabelName' onClick={onActiveItem}>
                {!item.subType && <span className={`rioglyph rioglyph-${item.type}`} />}
                {item.subType && (
                    <span className='rioglyph-icon-pair'>
                        <span className={`rioglyph rioglyph-${item.type}`} />
                        <span className={`rioglyph rioglyph-${item.subType}`} />
                    </span>
                )}
                <span className='TreeLabelNameText'>
                    <span className='TreeLabelNameTextHeadline'>
                        {isObject(item.name) ? (
                            <>
                                <span className='text-light margin-right-3'>{item.name.firstName}</span>
                                <span>{item.name.lastName}</span>
                            </>
                        ) : (
                            item.name
                        )}
                    </span>
                    {item.info && <span className='TreeLabelNameTextSubline'>{item.info}</span>}
                </span>
            </span>
        </div>
    );
});

export default TreeLeaf;
