import { Playground } from '../../../components/playground/Playground';
import { PlaygroundClasses } from '../../../components/playground/PlaygroundClasses';
import { Description } from '../../../components/Description';

const eachCursor = [
    'cursor-default',
    'cursor-pointer',
    'cursor-text',
    'cursor-move',
    'cursor-grabbing',
    'cursor-grab',
    'cursor-not-allowed',
    'cursor-row-resize',
    'cursor-col-resize',
    'cursor-col-resize',
    'cursor-ew-resize',
    'cursor-help',
    'cursor-cell',
    'cursor-copy',
    'cursor-crosshair',
];

export const Cursor = () => (
    <div>
        <Description headline='Cursor' anchor='cursor' />
        <Playground example={cursorExample} classes={cursorClasses} />
        <p className='margin-top-0 margin-bottom-5'>
            You can disable all pointer events by adding
            <code className='code code-inline'>pointer-events-none</code>
        </p>
    </div>
);

const cursorExample = (
    <div className='display-flex flex-wrap gap-20'>
        {eachCursor.map((cursor, index) => (
            <div className={`bg-info padding-10 rounded ${cursor}`} key={index}>
                {cursor}
            </div>
        ))}
    </div>
);

const cursorClasses = <PlaygroundClasses classes={eachCursor} />;
