import { Playground } from '../../../components/playground/Playground';
import { PlaygroundClasses } from '../../../components/playground/PlaygroundClasses';
import { Description } from '../../../components/Description';

export const Inset = () => (
    <div>
        <Description headline='Inset' anchor='inset' />
        <Playground example={insetExample} classes={insetClasses} />
    </div>
);

const insetExample = (
    <div>
        <div className='display-flex flex-wrap gap-20 margin-bottom-20'>
            <div>
                <label>inset-0</label>
                <div className='width-100 aspect-ratio-1 position-relative bg-lightest rounded shorthand-style'>
                    <div className='bg-info inset-0' />
                </div>
            </div>

            <div>
                <label>inset-x-0</label>
                <div
                    className={
                        'width-100 aspect-ratio-1 position-relative bg-lightest rounded shorthand-style ' +
                        'display-grid place-items-center'
                    }
                >
                    <div className='bg-info inset-x-0 padding-10' />
                </div>
            </div>

            <div>
                <label>inset-y-0</label>
                <div
                    className={
                        'width-100 aspect-ratio-1 position-relative bg-lightest rounded shorthand-style ' +
                        'display-grid place-items-center'
                    }
                >
                    <div className='bg-info inset-y-0 padding-10' />
                </div>
            </div>
        </div>
        <hr />
        <div className='display-flex gap-20'>
            <div className='display-flex align-items-center'>
                <span
                    className={
                        'display-inline-block width-20 height-20 bg-lightest rounded ' +
                        'shorthand-style margin-right-5'
                    }
                />
                Base element
            </div>
            <div className='display-flex align-items-center'>
                <span className='display-inline-block width-20 height-20 bg-info rounded margin-right-5' />
                Shorthand element
            </div>
        </div>
    </div>
);

const insetClasses = (
    <>
        <PlaygroundClasses
            label='Surrounding'
            classes={
                <>
                    <span>
                        For a positioning of <b>top/bottom/right/left</b> of <b>0px</b> use
                    </span>
                    <code className='code-inline'>inset-0</code>
                </>
            }
        />
        <PlaygroundClasses
            label='Top & bottom'
            classes={
                <>
                    <span>
                        For a vertical positioning of <b>top/bottom</b> of <b>0px</b> use
                    </span>
                    <code className='code-inline'>inset-y-0</code>
                </>
            }
        />
        <PlaygroundClasses
            label='Left & right'
            classes={
                <>
                    <span>
                        For a horizontal positioning of <b>left/right</b> of <b>0px</b> use
                    </span>
                    <code className='code-inline'>inset-x-0</code>
                </>
            }
        />
    </>
);
