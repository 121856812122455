import React, { createContext, useContext } from 'react';
import noop from 'lodash/fp/noop';

export type DialogContext = {
    onClose: (event: React.MouseEvent<HTMLButtonElement>) => void;
    isSmallestDialog: boolean;
    showXsDialogBorders: boolean;
    showCloseButton: boolean;
};

const initialContext = {
    onClose: noop,
    isSmallestDialog: false,
    showXsDialogBorders: false,
    showCloseButton: true,
};

export const dialogContext = createContext<DialogContext>(initialContext);

export const DialogContextProvider = dialogContext.Provider;

export const useDialogContext = () => {
    const context = useContext(dialogContext);
    if (!context) {
        throw new Error('Dialog compound components cannot be rendered outside the Dialog component');
    }
    return context;
};
