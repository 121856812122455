import { colors, getColorNames } from '../../../utils/colors';

const eachColor: string[] = ['default', ...getColorNames(colors.brand, colors.status)];

export default () => (
    <>
        <div className='display-grid grid-cols-1 gap-20 margin-bottom-25'>
            {eachColor.map((color, index) => (
                <div className='btn-toolbar' key={index}>
                    <button type='button' className={`btn btn-${color}`}>
                        <span>Button</span>
                    </button>
                    <button type='button' className={`btn btn-${color} hover`}>
                        <span>Hover button</span>
                    </button>
                    <button type='button' className={`btn btn-${color} active`}>
                        <span>Active button</span>
                    </button>
                    <button type='button' className={`btn btn-${color} focus`}>
                        <span>Focus button</span>
                    </button>
                    <button type='button' className={`btn btn-${color}`} disabled>
                        <span>Disabled button</span>
                    </button>
                </div>
            ))}
        </div>
    </>
);
