import { getNames } from './mapUtils';

export const getIconNames = getNames;

export const icons = {
    vehicles: [
        {
            name: 'rioglyph-car',
        },
        {
            name: 'rioglyph-bus',
        },
        {
            name: 'rioglyph-van',
        },
        {
            name: 'rioglyph-truck',
        },
        {
            name: 'rioglyph-trailer',
        },
    ],
    humans: [
        {
            name: 'rioglyph-user',
        },
        {
            name: 'rioglyph-driver',
        },
        {
            name: 'rioglyph-businessman',
        },
    ],
    signs: [
        {
            name: 'rioglyph-error-sign',
        },
        {
            name: 'rioglyph-exclamation-sign',
        },
        {
            name: 'rioglyph-question-sign',
        },
        {
            name: 'rioglyph-info-sign',
        },
        {
            name: 'rioglyph-ok-sign',
        },
        {
            name: 'rioglyph-minus-sign',
        },
        {
            name: 'rioglyph-plus-sign',
        },
        {
            name: 'rioglyph-play-circle',
        },
    ],
    arrows: [
        {
            name: 'rioglyph-arrow-up',
        },
        {
            name: 'rioglyph-arrow-down',
        },
        {
            name: 'rioglyph-arrow-left',
        },
        {
            name: 'rioglyph-arrow-right',
        },
    ],
    chevron: [
        {
            name: 'rioglyph-chevron-down',
        },
        {
            name: 'rioglyph-chevron-up',
        },
        {
            name: 'rioglyph-chevron-left',
        },
        {
            name: 'rioglyph-chevron-right',
        },
    ],
};

const iconLibrary = [...getIconNames(icons.vehicles, icons.humans, icons.signs)];
export const getRandomIcon = () => iconLibrary[Math.floor(Math.random() * iconLibrary.length)];
