import isEmpty from 'lodash/fp/isEmpty';
import isEqual from 'lodash/fp/isEqual';
import orderBy from 'lodash/fp/orderBy';
import isObject from 'lodash/fp/isObject';
import debounce from 'lodash/fp/debounce';
import countBy from 'lodash/fp/countBy';
import flow from 'lodash/fp/flow';
import filter from 'lodash/fp/filter';
import negate from 'lodash/fp/negate';
import mapValues from 'lodash/fp/mapValues';

import { searchNormalized } from '../../utils/searchNormalized';
import type { TreeItem, TreeGroup, TreeItemName, GroupedItems, GroupedItem } from './Tree';

const SEARCH_DEBOUNCE = 100;

export const notEqual = negate(isEqual);
export const notEmpty = negate(isEmpty);

export const filterOutByItemId = (list: string[]) => (item: TreeItem | TreeGroup) => !list.includes(item.id);

export const containsItemById = (list: string[]) => (item: TreeGroup) => list.includes(item.id);

export const getListIds = (list: TreeItem[] | TreeGroup[]) => list.map(listItem => listItem.id);

export const getTypeCounts = (items: TreeItem[]) => countBy(item => item.type, items);
export const getSubTypeCounts = (items: TreeItem[]) => countBy(item => item.subType, items);

// biome-ignore lint/suspicious/noExplicitAny: <explanation>
export const debounceFn = <T extends (...args: any[]) => any>(fn: T) => debounce(SEARCH_DEBOUNCE, fn);

const isNameObject = (item: TreeItem) => isObject(item.name);

export const getFullName = (item: TreeItem) =>
    `${(item.name as TreeItemName).firstName} ${(item.name as TreeItemName).lastName}`;

const getName = (item: TreeItem) => (isNameObject(item) ? getFullName(item) : (item.name as string));

const orderByName = orderBy(
    [
        (item: TreeItem) =>
            isNameObject(item)
                ? (item.name as TreeItemName).lastName.toLowerCase()
                : ((item.name as string).toLowerCase() ?? ''),
    ],
    ['asc']
) as (items: TreeItem[] | GroupedItems) => TreeItem[];

// TODO: extend to use provided external search function instead?
const filterByName =
    (searchValue: string) =>
    (item: TreeItem): boolean => {
        if (searchValue) {
            return searchNormalized(getName(item), searchValue);
        }
        return true;
    };

export const getFlatItems = (items: TreeItem[], searchValue: string): TreeItem[] =>
    flow(filter(filterByName(searchValue)), orderByName)(items);

export const filterEmptyGroups = filter((group: GroupedItems) => notEmpty(group.items));
export const filterAssetByType = (types: string[] = []) => filter((asset: TreeItem) => types.includes(asset.type));

export const excludeFromList = <T>(list: T[], itemId: T): T[] => list.filter(item => item !== itemId);

export const sortGroupsByName = (groups: GroupedItems) => {
    const fixedGroups: GroupedItems = {};
    const sortableGroups: GroupedItems = {};

    mapValues((group: GroupedItem) => {
        if (group.position === 'last') {
            fixedGroups[group.id] = { ...group };
        } else {
            sortableGroups[group.id] = { ...group };
        }
    })(groups);

    const sortedGroups = orderByName(sortableGroups);

    return isEmpty(fixedGroups) ? sortedGroups : { ...sortedGroups, ...fixedGroups };
};

export const sortGroupItemsByName = (groups: GroupedItems): GroupedItems => {
    return mapValues((group: GroupedItem) => ({
        ...group,
        items: orderByName(group.items),
    }))(groups);
};

export const getMappedItemsToGroups = (groups: TreeGroup[], items: TreeItem[]): GroupedItems => {
    const mappedGroups: GroupedItems = {};

    // build an object for listing the groups by id
    groups.forEach(group => {
        mappedGroups[group.id] = {
            ...group,
            items: [],
        };
    });

    items.forEach(item => {
        // add items to the respective group
        const groupIds = item.groupIds || [];
        groupIds.forEach(groupId => {
            const mappedGroup = mappedGroups[groupId];
            if (mappedGroup) {
                mappedGroup.items.push(item);
            }
        });
    });

    return mappedGroups;
};

export const addOrRemoveFromList = <T>(list: T[], item: T): T[] => {
    const listSet = new Set(list);
    if (listSet.has(item)) {
        listSet.delete(item);
    } else {
        listSet.add(item);
    }
    return [...listSet];
};
