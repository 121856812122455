const COOKIE_NAME = 'uikit-color-scheme';
const PATH = '/';
const DOMAIN = '.rio.cloud';
const EXPIRATION_DATE = 'Thu, 18 Dec 2030 12:00:00 UTC';

export const THEME_DATA_ATTRIBUTE = 'data-theme';
export const THEME_CHANGE_EVENT = 'themechange';

export const THEME_DEFAULT = 'default' as const;
export const THEME_SYSTEM = 'system' as const;
export const THEME_LIGHT = 'light' as const;
export const THEME_DARK = 'dark' as const;

export type ColorScheme = typeof THEME_DEFAULT | typeof THEME_SYSTEM | typeof THEME_LIGHT | typeof THEME_DARK;

const updateHtmlScheme = (scheme?: ColorScheme | undefined) => {
    if (scheme) {
        document.documentElement.setAttribute(THEME_DATA_ATTRIBUTE, scheme);
    } else {
        document.documentElement.removeAttribute(THEME_DATA_ATTRIBUTE);
    }
};

const getSchemeFromCookie = () => {
    const decodedCookie = decodeURIComponent(document.cookie);
    const find = decodedCookie.split(';').find(entry => entry.includes(COOKIE_NAME));
    return find?.split('=')[1] as ColorScheme | undefined;
};

/**
 * Returns the desired color scheme based on the user's cookie.
 *
 * This might be `undefined`, too, when no cookie value is set!
 */
export const getColorScheme = () => {
    const colorScheme = getSchemeFromCookie();

    switch (colorScheme) {
        case THEME_DEFAULT:
            updateHtmlScheme();
            break;

        case THEME_SYSTEM:
            if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
                updateHtmlScheme(THEME_DARK);
            } else {
                updateHtmlScheme(THEME_LIGHT);
            }
            break;

        case THEME_LIGHT:
        case THEME_DARK:
            updateHtmlScheme(colorScheme);
            break;
    }

    return colorScheme;
};

/**
 * Stores the given preferred color scheme in the browser's cookies.
 *
 * This should be done by a RIO service like User Profile SPA using a theme switcher component from the UIKIT where this
 * function is referenced.
 *
 * @param scheme The color scheme to save.
 */
export const setColorScheme = (scheme: ColorScheme) => {
    const isDevEnv = window.location.host.startsWith('localhost') || window.location.host.startsWith('127.0.0.1');

    const newCookieValues = encodeURIComponent(scheme);
    const domain = isDevEnv ? 'localhost' : DOMAIN;
    document.cookie = `${COOKIE_NAME}=${newCookieValues};domain=${domain};expires=${EXPIRATION_DATE};path=${PATH}`;
};

/**
 * Initiates listening for theme change events.
 */
export const listenForSchemeChange = () => {
    window.addEventListener(
        'message',
        ({ data }) => {
            if (data === THEME_CHANGE_EVENT) {
                getColorScheme();

                // Re-send the event to all iframes that might not be notified yet
                for (const iframe of document.getElementsByTagName('iframe')) {
                    iframe.contentWindow?.postMessage(THEME_CHANGE_EVENT, '*');
                }
            }
        },
        false
    );
};
