import { Playground } from '../../../components/playground/Playground';
import {
    PlaygroundClasses,
    PlaygroundPrintClasses,
    PlaygroundClassesSpacer,
} from '../../../components/playground/PlaygroundClasses';
import { Description } from '../../../components/Description';
import { Title } from '../../../components/Title';
import { Note } from '../../../components/Note';

import useDarkMode from '../../../../../src/hooks/useDarkMode';

import { colors, getColorNames } from '../../../utils/colors';

const eachText = getColorNames(colors.text);
const eachBrand = getColorNames(colors.brand);
const eachStatus = getColorNames(colors.status);
const eachDrivingStatus = getColorNames(colors.drivingStatus);
const eachMapMarker = getColorNames(colors.mapMarker);
const eachRating = getColorNames(colors.rating);
const eachGray = getColorNames(colors.gray);
const eachHighlight = getColorNames(colors.highlight);

const eachDemoColor = [...getColorNames(colors.brand, colors.status), 'dark', 'black'];
const eachBorderVariant = ['solid', 'dotted', 'dashed'];
const eachRemoveBorder = ['top-none', 'bottom-none', 'left-none', 'right-none'];
const eachOnlyBorder = ['top-only', 'bottom-only', 'left-only', 'right-only'];
const eachBorder = [...eachRemoveBorder, ...eachOnlyBorder];

export const Border = () => (
    <div>
        <Description headline='Border' anchor='border'>
            You need to combine multiple classes to get the result you want.
            <p className='margin-top-0'>
                You can use the the shorthand
                <code className='code-inline'>border</code>
                for the automatic combination of
                <code className='code-inline'>border-style-solid</code>,
                <code className='code-inline'>border-width-1</code>
                and
                <code className='code-inline'>border-color-light</code>
            </p>
        </Description>
        {useDarkMode() && (
            <div className='alert alert-info margin-bottom-20'>
                <Note defaultContent='darkmode' />
            </div>
        )}
        <Playground example={borderExample} classes={borderClasses} />
        <Playground example={borderHoverExample} classes={borderClasses} />
        <Playground example={borderHelperExample} classes={borderClasses} />
    </div>
);

const borderExample = (
    <>
        <div className='display-flex flex-wrap gap-20 margin-bottom-20'>
            <div className='border width-70 height-50 text-center rounded' />
        </div>
        <div className='display-flex flex-wrap gap-20 margin-bottom-20'>
            {eachDemoColor.map(color => (
                <div
                    className={`border border-color-${color} width-70 height-50 text-center rounded`}
                    key={crypto.randomUUID()}
                />
            ))}
        </div>
        <div className='display-flex flex-wrap gap-20 margin-bottom-20'>
            {eachDemoColor.map(color => (
                <div
                    key={crypto.randomUUID()}
                    className={`border border-color-${color} border-width-2 width-70 height-50 text-center rounded`}
                />
            ))}
        </div>
        <div className='display-flex flex-wrap gap-20 margin-bottom-20'>
            {eachDemoColor.map(color => (
                <div
                    key={crypto.randomUUID()}
                    className={`
                        border border-color-${color} border-width-1 border-style-dotted
                        width-70 height-50 text-center
                    `}
                />
            ))}
        </div>
        <div className='display-flex flex-wrap gap-20 margin-bottom-20'>
            {eachDemoColor.map(color => (
                <div
                    key={crypto.randomUUID()}
                    className={`
                        border border-color-${color} border-width-1 border-style-dashed
                        width-70 height-50 text-center
                    `}
                />
            ))}
        </div>
        <div className='display-flex flex-wrap gap-20 margin-bottom-20'>
            {eachDemoColor.map((color, index) => (
                <div
                    key={crypto.randomUUID()}
                    className={`
                        border border-color-${color} border-${eachBorder[index]} border-width-3
                        width-70 height-50 text-center
                    `}
                />
            ))}
        </div>
    </>
);

const borderHoverExample = (
    <>
        <label>Hover Border Color</label>
        <div className='display-flex flex-wrap gap-20 margin-bottom-20'>
            {eachDemoColor.map(color => (
                <div
                    key={crypto.randomUUID()}
                    className={`border hover-border-color-${color} width-70 height-50 text-center rounded`}
                />
            ))}
        </div>
        <div className='display-flex flex-wrap gap-20 margin-bottom-20'>
            {[...eachGray, ...eachHighlight].map(color => (
                <div
                    key={crypto.randomUUID()}
                    className={`border hover-border-color-${color} border-width-2 width-70 height-50 text-center rounded`}
                />
            ))}
        </div>
        <label>Hover Border Color with Transition</label>
        <div className='display-flex flex-wrap gap-20 margin-bottom-20'>
            {eachDemoColor.map(color => (
                <div
                    key={crypto.randomUUID()}
                    className={`border hover-border-color-${color} transition-all transition-ease-in-out transition-duration-02 border-width-2 width-70 height-50 text-center rounded`}
                />
            ))}
        </div>
        <label>Hover Border Style</label>
        <div className='display-flex flex-wrap gap-20 margin-bottom-20'>
            <div className='border border-style-dashed hover-border-style-solid border-width-2 width-70 height-50 text-center rounded' />
            <div className='border hover-border-style-dashed border-width-2 width-70 height-50 text-center rounded' />
            <div className='border hover-border-style-dotted border-width-2 width-70 height-50 text-center rounded' />
        </div>
    </>
);

const borderClasses = (
    <>
        <PlaygroundClasses label='Base' classes='border' />
        <PlaygroundClasses label='Width' prefix='border-width-' classes={['1', '2', '3']} />
        <PlaygroundClasses label='Style' prefix='border-style-' classes={eachBorderVariant} />
        <PlaygroundClasses label='Remove All' classes='border-none' />
        <PlaygroundClasses label='Remove Single' prefix='border-' classes={eachRemoveBorder} />
        <PlaygroundClasses label='Show Single' prefix='border-' classes={eachOnlyBorder} />
        <PlaygroundClassesSpacer label='Colors' />
        <PlaygroundClasses label='Transparent' classes='border-color-transparent' />
        <PlaygroundClasses label='Basic' prefix='border-color-' classes={eachText} />
        <PlaygroundClasses label='Brand' prefix='border-color-' classes={eachBrand} />
        <PlaygroundClasses label='Status' prefix='border-color-' classes={eachStatus} />
        <PlaygroundClasses label='Driving Status' prefix='border-color-' classes={eachDrivingStatus} />
        <PlaygroundClasses label='Map Marker' prefix='border-color-' classes={eachMapMarker} />
        <PlaygroundClasses label='Rating' prefix='border-color-' classes={eachRating} />
        <PlaygroundClasses label='Gray' prefix='border-color-' classes={eachGray} />
        <PlaygroundClasses label='Highlight' prefix='border-color-' classes={eachHighlight} />
        <PlaygroundClassesSpacer label='Hover Colors' />
        <PlaygroundClasses label='Transparent' classes='hover-border-color-transparent' />
        <PlaygroundClasses label='Basic' prefix='hover-border-color-' classes={eachText} />
        <PlaygroundClasses label='Brand' prefix='hover-border-color-' classes={eachBrand} />
        <PlaygroundClasses label='Status' prefix='hover-border-color-' classes={eachStatus} />
        <PlaygroundClasses label='Driving Status' prefix='hover-border-color-' classes={eachDrivingStatus} />
        <PlaygroundClasses label='Map Marker' prefix='hover-border-color-' classes={eachMapMarker} />
        <PlaygroundClasses label='Rating' prefix='hover-border-color-' classes={eachRating} />
        <PlaygroundClasses label='Gray' prefix='hover-border-color-' classes={eachGray} />
        <PlaygroundClasses label='Highlight' prefix='hover-border-color-' classes={eachHighlight} />
        <PlaygroundClassesSpacer label='Hover Style' />
        <PlaygroundClasses label='Style' prefix='hover-border-style-' classes={eachBorderVariant} />
        <PlaygroundPrintClasses />
    </>
);

const borderHelperExample = (
    <div className='display-grid grid-cols-1 grid-cols-4-sm gap-20'>
        <div>
            <Title>Last Child (Self Class)</Title>
            <div>
                {[...Array(5)].map(index => (
                    <div
                        className={`border border-bottom-only last-child-border-bottom-none padding-2 text-center ${
                            index === 5 ? 'text-color-success' : 'text-color-info'
                        }`}
                        key={crypto.randomUUID()}
                    >
                        {index === 5 ? 'Last child' : 'Child'}
                    </div>
                ))}
            </div>
        </div>
        <div>
            <Title>Last Child (Parent Class)</Title>
            <div className='children-last-border-bottom-none'>
                {[...Array(5)].map(index => (
                    <div
                        className={`border border-bottom-only padding-2 text-center ${
                            index === 5 ? 'text-color-success' : 'text-color-info'
                        }`}
                        key={crypto.randomUUID()}
                    >
                        {index === 5 ? 'Last child' : 'Child'}
                    </div>
                ))}
            </div>
        </div>
        <div>
            <Title>First Child (Self Class)</Title>
            <div>
                {[...Array(5)].map(index => (
                    <div
                        className={`border border-top-only padding-2 text-center first-child-border-top-none ${
                            index === 1 ? 'text-color-success' : 'text-color-info'
                        }`}
                        key={crypto.randomUUID()}
                    >
                        {index === 1 ? 'First child' : 'Child'}
                    </div>
                ))}
            </div>
        </div>
        <div>
            <Title>First Child (Parent Class)</Title>
            <div className='children-first-border-top-none'>
                {[...Array(5)].map(index => (
                    <div
                        className={`border border-top-only padding-2 text-center ${
                            index === 1 ? 'text-color-success' : 'text-color-info'
                        }`}
                        key={crypto.randomUUID()}
                    >
                        {index === 1 ? 'First child' : 'Child'}
                    </div>
                ))}
            </div>
        </div>
    </div>
);

const borderHelperClasses = (
    <>
        <PlaygroundClasses label='Parent' classes={['children-first-margin-top-0', 'children-last-margin-bottom-0']} />
        <PlaygroundClasses label='Self' classes={['first-child-margin-top-0', 'last-child-margin-bottom-0']} />
    </>
);
