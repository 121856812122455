import React from 'react';
import noop from 'lodash/fp/noop';

import MenuItemComponent, { type MenuItemProps as MenuItem } from './MenuItem';

export type MenuItemsProps = {
    items: MenuItem[];
    closeMenu?: () => void;
    onMouseEnter?: () => void;
};

const MenuItems = ({ items, closeMenu = noop, onMouseEnter = noop }: MenuItemsProps) => {
    return (
        <>
            {items.map((item, index) => (
                <MenuItemComponent
                    key={item.index ?? index}
                    {...item}
                    index={item.index ?? index}
                    closeMenu={closeMenu}
                    onMouseEnter={onMouseEnter}
                />
            ))}
        </>
    );
};

export default MenuItems;
