import { Playground } from '../../../components/playground/Playground';
import { Description } from '../../../components/Description';
import { Title } from '../../../components/Title';
import { Note } from '../../../components/Note';
import packageJson from '../../../../../package.json';

import { icons, getRandomIcon } from '../../../utils/icons';
import { getNames } from '../../../utils/mapUtils';
import { colors, getColorNames } from '../../../utils/colors';
import { sizes, getSizeClasses } from '../../../utils/sizes';

const humanIcons = [...getNames(icons.humans)] as string[];
const vehicleIcons = [...getNames(icons.vehicles)] as string[];

const eachColor = getColorNames(colors.brand, colors.status) as string[];
const eachTextSize = getSizeClasses(sizes.text) as string[];

const CDN_URL = 'https://uikit.developers.rio.cloud';

export const Rioglyph = () => (
    <div className='uikit-icons'>
        <Description headline='RioGlyph' anchor='rioglyph' />
        <Playground example={rioglyphIconsExample} />
        <Note label='Download' type='success'>
            {'Download all RioGlyph icons as SVG images '}
            <a href={`${CDN_URL}/${packageJson.version}/rioglyph.zip`} target='_blank' rel='noreferrer'>
                here.
            </a>
        </Note>
        <Note>
            Do not use a fixed unicode string as <code className='code-inline'>content: ""</code> because the strings
            may change in the future.
        </Note>
        <Description headline='Vehicle icons' headlineType='h3' />
        <Playground example={rioglyphVehicleIconsExample} />
        <Description headline='Pair icons' headlineType='h3' />
        <Playground example={rioglyphPairIconsExample} />
        <Description headline='Animated icons' headlineType='h3' />
        <Playground example={rioglyphAnimatedIconsExample} />
    </div>
);

const rioglyphIconsExample = (
    <div className='display-flex flex-wrap gap-50'>
        <div>
            <Title>Example icons</Title>
            <div className='display-flex flex-wrap gap-10'>
                {humanIcons.map(icon => (
                    <span className={`rioglyph ${icon} text-size-h1`} key={icon} />
                ))}
            </div>
        </div>
        <div>
            <Title>Sized icons</Title>
            <div className='display-flex flex-wrap gap-10'>
                {eachTextSize.map(size => (
                    <span className={`rioglyph rioglyph-user ${size}`} key={size} />
                ))}
            </div>
        </div>
        <div>
            <Title>Colored icons</Title>
            <div className='display-flex flex-wrap gap-10'>
                {eachColor.map(color => (
                    <span className={`rioglyph ${getRandomIcon()} text-color-${color} text-size-h1`} key={color} />
                ))}
            </div>
        </div>
    </div>
);

const rioglyphPairIconsExample = (
    <div className='display-flex flex-wrap gap-50'>
        <div>
            <Title>Default</Title>
            <div className='display-flex flex-wrap gap-10'>
                <div>
                    <span className='rioglyph-icon-pair text-size-h1'>
                        <span className='rioglyph rioglyph-truck' />
                        <span className='rioglyph rioglyph-climate-control spinning' />
                    </span>
                </div>
                <div>
                    <span className='rioglyph-icon-pair text-size-h1'>
                        <span className='rioglyph rioglyph-stack' />
                        <span className='rioglyph rioglyph-plus' />
                    </span>
                </div>
                <div>
                    <span className='rioglyph-icon-pair text-size-h1'>
                        <span className='rioglyph rioglyph-trailer' />
                        <span className='rioglyph rioglyph-ok text-color-success' />
                    </span>
                </div>
            </div>
        </div>
        <div>
            <Title>With background</Title>
            <div className='display-flex flex-wrap gap-10'>
                <div>
                    <span className='rioglyph-icon-pair text-size-h1'>
                        <span className='rioglyph rioglyph-truck' />
                        <span className='rioglyph rioglyph-climate-control spinning bg-darkest text-color-white' />
                    </span>
                </div>
                <div>
                    <span className='rioglyph-icon-pair text-size-h1'>
                        <span className='rioglyph rioglyph-bus' />
                        <span className='rioglyph rioglyph-heart bg-danger text-color-white' />
                    </span>
                </div>
                <div>
                    <span className='rioglyph-icon-pair text-size-h1'>
                        <span className='rioglyph rioglyph-trailer' />
                        <span className='rioglyph rioglyph-ok bg-success text-color-white' />
                    </span>
                </div>
            </div>
        </div>
        <div>
            <Title>Display engine types</Title>
            <div className='display-flex flex-wrap gap-10'>
                <div>
                    <span className='rioglyph-icon-pair text-size-h1'>
                        <span className='rioglyph rioglyph-truck' />
                        <span className='rioglyph rioglyph-fuel-diesel' />
                    </span>
                </div>
                <div>
                    <span className='rioglyph-icon-pair text-size-h1'>
                        <span className='rioglyph rioglyph-bus' />
                        <span className='rioglyph rioglyph-fuel-electric' />
                    </span>
                </div>
                <div>
                    <span className='rioglyph-icon-pair text-size-h1'>
                        <span className='rioglyph rioglyph-van' />
                        <span className='rioglyph rioglyph-fuel-hydrogen' />
                    </span>
                </div>
                <div>
                    <span className='rioglyph-icon-pair text-size-h1'>
                        <span className='rioglyph rioglyph-trailer' />
                        <span className='rioglyph rioglyph-fuel-gas' />
                    </span>
                </div>
            </div>
            <div className='display-flex flex-wrap gap-10 margin-top-20'>
                <div>
                    <span className='rioglyph-icon-pair text-size-h1'>
                        <span className='rioglyph rioglyph-truck' />
                        <span className='rioglyph rioglyph-fuel-diesel bg-darkest text-color-white' />
                    </span>
                </div>
                <div>
                    <span className='rioglyph-icon-pair text-size-h1'>
                        <span className='rioglyph rioglyph-bus' />
                        <span className='rioglyph rioglyph-fuel-electric bg-darkest text-color-white' />
                    </span>
                </div>
                <div>
                    <span className='rioglyph-icon-pair text-size-h1'>
                        <span className='rioglyph rioglyph-van' />
                        <span className='rioglyph rioglyph-fuel-hydrogen bg-darkest text-color-white' />
                    </span>
                </div>
                <div>
                    <span className='rioglyph-icon-pair text-size-h1'>
                        <span className='rioglyph rioglyph-trailer' />
                        <span className='rioglyph rioglyph-fuel-gas bg-darkest text-color-white' />
                    </span>
                </div>
            </div>
        </div>
        <div>
            <Title>Sized</Title>
            <div className='display-flex flex-wrap gap-10'>
                <div className='display-flex align-items-start'>
                    <span className='rioglyph-icon-pair text-size-h1'>
                        <span className='rioglyph rioglyph-truck' />
                        <span className='rioglyph rioglyph-fuel-electric' />
                    </span>
                </div>
                <div className='display-flex align-items-start'>
                    <span className='rioglyph-icon-pair text-size-h2'>
                        <span className='rioglyph rioglyph-truck' />
                        <span className='rioglyph rioglyph-fuel-electric' />
                    </span>
                </div>
                <div className='display-flex align-items-start'>
                    <span className='rioglyph-icon-pair text-size-h3'>
                        <span className='rioglyph rioglyph-truck' />
                        <span className='rioglyph rioglyph-fuel-electric' />
                    </span>
                </div>
                <div className='display-flex align-items-start'>
                    <span className='rioglyph-icon-pair text-size-20'>
                        <span className='rioglyph rioglyph-truck' />
                        <span className='rioglyph rioglyph-fuel-electric' />
                    </span>
                </div>
                <div className='display-flex align-items-start'>
                    <span className='rioglyph-icon-pair text-size-18'>
                        <span className='rioglyph rioglyph-truck' />
                        <span className='rioglyph rioglyph-fuel-electric' />
                    </span>
                </div>
                <div className='display-flex align-items-start'>
                    <span className='rioglyph-icon-pair text-size-16'>
                        <span className='rioglyph rioglyph-truck' />
                        <span className='rioglyph rioglyph-fuel-electric' />
                    </span>
                </div>
                <div className='display-flex align-items-start'>
                    <span className='rioglyph-icon-pair text-size-14'>
                        <span className='rioglyph rioglyph-truck' />
                        <span className='rioglyph rioglyph-fuel-electric' />
                    </span>
                </div>
            </div>
            <div className='display-flex flex-wrap gap-10 margin-top-20'>
                <div className='display-flex align-items-start'>
                    <span className='rioglyph-icon-pair text-size-h1'>
                        <span className='rioglyph rioglyph-truck' />
                        <span className='rioglyph rioglyph-fuel-electric bg-darkest text-color-white' />
                    </span>
                </div>
                <div className='display-flex align-items-start'>
                    <span className='rioglyph-icon-pair text-size-h2'>
                        <span className='rioglyph rioglyph-truck' />
                        <span className='rioglyph rioglyph-fuel-electric bg-darkest text-color-white' />
                    </span>
                </div>
                <div className='display-flex align-items-start'>
                    <span className='rioglyph-icon-pair text-size-h3'>
                        <span className='rioglyph rioglyph-truck' />
                        <span className='rioglyph rioglyph-fuel-electric bg-darkest text-color-white' />
                    </span>
                </div>
                <div className='display-flex align-items-start'>
                    <span className='rioglyph-icon-pair text-size-20'>
                        <span className='rioglyph rioglyph-truck' />
                        <span className='rioglyph rioglyph-fuel-electric bg-darkest text-color-white' />
                    </span>
                </div>
                <div className='display-flex align-items-start'>
                    <span className='rioglyph-icon-pair text-size-18'>
                        <span className='rioglyph rioglyph-truck' />
                        <span className='rioglyph rioglyph-fuel-electric bg-darkest text-color-white' />
                    </span>
                </div>
                <div className='display-flex align-items-start'>
                    <span className='rioglyph-icon-pair text-size-16'>
                        <span className='rioglyph rioglyph-truck' />
                        <span className='rioglyph rioglyph-fuel-electric bg-darkest text-color-white' />
                    </span>
                </div>
                <div className='display-flex align-items-start'>
                    <span className='rioglyph-icon-pair text-size-14'>
                        <span className='rioglyph rioglyph-truck' />
                        <span className='rioglyph rioglyph-fuel-electric bg-darkest text-color-white' />
                    </span>
                </div>
            </div>
        </div>
    </div>
);

const rioglyphVehicleIconsExample = (
    <div className='display-flex flex-wrap gap-50'>
        <div>
            <Title>Vehicle icons</Title>
            <div className='display-flex flex-wrap gap-10'>
                {vehicleIcons.map(icon => (
                    <span className={`rioglyph ${icon} text-size-h1`} key={icon} />
                ))}
            </div>
        </div>
        <div>
            <Title>Baseline vehicle icons</Title>
            <div className='display-flex flex-wrap gap-10'>
                {vehicleIcons.map(icon => (
                    <span className={`rioglyph ${icon}-baseline text-size-h1`} key={icon} />
                ))}
            </div>
        </div>
    </div>
);

const rioglyphAnimatedIconsExample = (
    <div className='display-flex flex-wrap gap-50'>
        <div>
            <Title>Spinning Icons</Title>
            <div className='display-flex flex-wrap gap-10'>
                <div className='text-size-h2'>
                    <span className='rioglyph rioglyph-refresh spinning text-size-150pct' />
                    <span className='rioglyph rioglyph-climate-control spinning text-size-150pct' />
                    <span className='rioglyph rioglyph-cog spinning text-size-150pct' />
                    <span className='rioglyph rioglyph-spinner spinning text-size-150pct' />
                    <span className='rioglyph rioglyph-tire spinning text-size-150pct' />
                </div>
            </div>
        </div>
        <div>
            <Title>Pulsing Icons</Title>
            <div className='display-flex flex-wrap gap-10'>
                <div className='text-size-h2'>
                    {humanIcons.map(icon => (
                        <span className={`rioglyph ${icon} pulsing text-size-150pct`} key={icon} />
                    ))}
                </div>
            </div>
        </div>
    </div>
);
