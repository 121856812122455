import React, {
    Children,
    cloneElement,
    forwardRef,
    type PropsWithChildren,
    type ReactElement,
    type ReactHTML,
    useEffect,
    useRef,
    useState,
} from 'react';
import classNames from 'classnames';
import findIndex from 'lodash/fp/findIndex';
import chunk from 'lodash/fp/chunk';
import { AnimatePresence, motion } from 'framer-motion';
import noop from 'lodash/noop';
import type { Variants } from 'framer-motion/types/types';

import useAfterMount from '../../hooks/useAfterMount';
import useElementSize from '../../hooks/useElementSize';
import usePrevious from '../../hooks/usePrevious';

const MIN_WITH_THRESHOLD = 10;

const DEFAULT_TRANSITION = { duration: 0.2 };

const ANIMATION_NEXT = 'page';
const ANIMATION_BACK = 'pageBack';

const COLUMN_ITEM_CLASSNAME = 'ColumnItem';

const variants: Variants = {
    pageEnter: pageDirection => ({
        x: pageDirection === ANIMATION_NEXT ? '60%' : '-60%',
        opacity: 0,
    }),
    pageCenter: () => ({ x: 0, opacity: 1 }),
};

const getFirstColumnItem = (node: Element): HTMLElement => {
    if ([...node.classList].includes(COLUMN_ITEM_CLASSNAME)) {
        return node as HTMLElement;
    }
    return getFirstColumnItem(node.children[0] as HTMLElement);
};

export type ResponsiveColumnStripeProps = {
    /**
     * The minimum width in pixel of a single column.
     *
     * This value determines how many columns are shown per page depending on the parent width.
     *
     * @default 300
     */
    minColumnWith?: number;

    /**
     * The minimum amount of columns that should be shown per page.
     *
     * @default 1
     */
    minColumns?: number;

    /**
     * The maximum amount of columns that should be shown per page.
     *
     * @default 5
     */
    maxColumns?: number;

    /**
     * Defines whether the items on the last page are stretched out to fill the space.
     *
     * @default false
     */
    stretchLastItems?: boolean;

    /**
     * The page that shall be shown.
     *
     * This can be used to control the pages from outside.
     *
     * @default 0
     */
    activePage?: number;

    /**
     * The DOM element type of the wrapping column element.
     *
     * If you need a list, this might be set to "ul".
     *
     * @default 'div'
     */
    asType?: keyof ReactHTML;

    /**
     * Set to true to skip animating pages.
     *
     * @default false
     */
    disableAnimation?: boolean;

    /**
     * Callback function for when the previous page is clicked.
     *
     * @param pageNumber
     * @param columnsPerPage
     */
    onPreviousClick?: (pageNumber: number, columnsPerPage: number) => void;

    /**
     * Callback function for when the next page is clicked.
     *
     * @param pageNumber
     * @param columnsPerPage
     */
    onNextClick?: (pageNumber: number, columnsPerPage: number) => void;

    /**
     * Additional classes set to the navigation buttons.
     */
    buttonClassName?: string;

    /**
     * Additional classes set to the component wrapper element.
     */
    columnsWrapperClassName?: string;

    /**
     * Additional classes set to the column wrapper element.
     */
    className?: string;
};

const ResponsiveColumnStripe = forwardRef<HTMLDivElement, PropsWithChildren<ResponsiveColumnStripeProps>>(
    (props, ref) => {
        const {
            minColumnWith = 300,
            minColumns = 1,
            maxColumns = 5,
            stretchLastItems = true,
            activePage = 0,
            asType: ComponentType = 'div',
            onPreviousClick = noop,
            onNextClick = noop,
            buttonClassName = '',
            columnsWrapperClassName = '',
            className = '',
            disableAnimation = false,
            children,
            ...remainingProps
        } = props;

        const [currentPage, setCurrentPage] = useState(activePage);
        const [columnsPerPage, setColumnsPerPage] = useState(maxColumns);
        const [enableInitialAnimation, setEnableInitialAnimation] = useState(false);

        const [isResizePage, setIsResizePage] = useState(false);

        // The base for reacting on changing width of the wrapping element.
        // It uses a ResizeObserver under the hood.
        const columnWrapperRef = useRef<HTMLDivElement>(null);
        const [columnWrapperWidth] = useElementSize(columnWrapperRef);

        const previousPage = usePrevious(currentPage);
        const animationDirection = currentPage > previousPage ? ANIMATION_NEXT : ANIMATION_BACK;

        useAfterMount(() => {
            setEnableInitialAnimation(true);
        });

        // Update active page from outside to be used as controlled component
        const [previousActivePage, setPreviousActivePage] = useState(activePage);
        if (Number.isFinite(activePage) && activePage !== previousActivePage) {
            setCurrentPage(activePage);
            setPreviousActivePage(activePage);
        }

        const updatePageOnColumnsSizeChange = (firstItem: HTMLElement, perPage: number) => {
            // Split children in page chunks
            const pages = chunk(perPage)(children as ReactElement[]);

            // find the currently rendered first item inside the chunks.
            // The found chunk index is the new page to render.
            // example of chunks => [['a', 'b', 'c'], ['d']]
            const targetPage = findIndex((page: ReactElement[]) =>
                page.some(pageItem => pageItem.key === firstItem.dataset['key'])
            )(pages);

            if (targetPage !== currentPage) {
                setCurrentPage(targetPage);
            }
        };

        // When the container size changes, adapt the amount of columns to be rendered according
        // to the given min width of a single item. The new amount of columns also has to respect
        // the lower and upper limit. When updating the amount, the current page hse to be updated as well.
        useEffect(() => {
            if (!columnWrapperRef.current) {
                return;
            }

            const firstItem = getFirstColumnItem(columnWrapperRef.current.children[0]);
            if (!firstItem) {
                return;
            }

            // Get the width of the first column to calculate how many columns fit in one page
            // according to the given minWidth per column
            const columnWidth = firstItem.getBoundingClientRect().width;

            // Limit columns per page for given min and max values
            const allowForLessColumns = columnsPerPage - 1 >= minColumns;
            const allowForMoreColumns = columnsPerPage + 1 <= maxColumns;

            // The threshold is required to avoid jumping between bigger and smaller after the columns per page
            // has changed, and it continues to resize
            const goSmaller = allowForLessColumns && columnWidth + MIN_WITH_THRESHOLD <= minColumnWith;
            const goBigger = allowForMoreColumns && (columnsPerPage + 1) * minColumnWith <= columnWrapperWidth!;

            // During resizing, we don't want any animation
            setIsResizePage(true);

            if (goBigger) {
                const newValue = columnsPerPage + 1;
                setColumnsPerPage(newValue);
                updatePageOnColumnsSizeChange(firstItem, newValue);
                return;
            }

            if (goSmaller) {
                const newValue = columnsPerPage - 1;
                setColumnsPerPage(newValue);
                updatePageOnColumnsSizeChange(firstItem, newValue);
                return;
            }
        }, [columnWrapperWidth, columnWrapperRef.current, children, columnsPerPage]);

        const handlePrevClick = () => {
            setIsResizePage(false);

            const pageNumber = currentPage - 1;
            setCurrentPage(pageNumber);
            onPreviousClick(pageNumber, columnsPerPage);
        };

        const handleNextClick = () => {
            setIsResizePage(false);

            const pageNumber = currentPage + 1;
            setCurrentPage(pageNumber);
            onNextClick(pageNumber, columnsPerPage);
        };

        if (!children) {
            return null;
        }

        // Split the children/columns in chunks according to the current columns per page size
        // and get the current page chunk for rendering
        const columnsToDisplay = chunk(columnsPerPage)(children as ReactElement[])[currentPage];

        const showPageButtons = minColumns < (children as ReactElement[]).length;

        const disablePreviousPage = currentPage === 0;
        const disableNextPage = currentPage === Math.ceil((children as ReactElement[]).length / columnsPerPage) - 1;

        const wrapperClassName = classNames(
            'ResponsiveColumnStripe',
            'display-flex align-items-center',
            'overflow-hidden',
            className
        );

        const baseButtonClassName = classNames(
            'align-items-center',
            'align-self-stretch',
            'display-flex',
            'hover-scale-105',
            'hover-text-color-darkest',
            'padding-10',
            'cursor-pointer',
            'text-color-darker',
            'text-size-12'
        );

        const disabledButtonClassName = 'pointer-events-none opacity-30';

        const previousButtonClassName = classNames(
            'PreviousButton',
            baseButtonClassName,
            disablePreviousPage && disabledButtonClassName
        );

        const nextButtonClassName = classNames(
            'NextButton',
            baseButtonClassName,
            disableNextPage && disabledButtonClassName
        );

        const mergedColumnsWrapperClassName = classNames(
            'ColumnWrapper',
            'flex-1-1',
            'display-flex',
            columnsWrapperClassName
        );

        const columnClassName = disableNextPage && !stretchLastItems ? 'flex-0-1' : 'flex-1-1';

        // Use the given custom component type "div" or any other. Clone the children to inject certain
        // properties that are required to stretch the items and deal with their size.
        const columns = (
            <ComponentType className={mergedColumnsWrapperClassName}>
                {Children.map(columnsToDisplay, column =>
                    cloneElement(column, {
                        className: `${COLUMN_ITEM_CLASSNAME} ${columnClassName} ${column.props?.className || ''}`,
                        // style: { ...column.props?.style, minWidth: `${minColumnWith}px` },
                        'data-key': column.key,
                    })
                )}
            </ComponentType>
        );

        return (
            <div {...remainingProps} ref={ref} className={wrapperClassName}>
                {showPageButtons && (
                    <div className={previousButtonClassName} onClick={handlePrevClick}>
                        <span className='rioglyph rioglyph-chevron-left' />
                    </div>
                )}
                <div className='ColumnsArea flex-1-1 overflow-hidden' ref={columnWrapperRef}>
                    {disableAnimation || isResizePage ? columns : null}
                    {!disableAnimation && !isResizePage && (
                        // @ts-ignore-next-line
                        <AnimatePresence exitBeforeEnter custom={animationDirection}>
                            <motion.div
                                key={currentPage}
                                variants={variants}
                                initial={enableInitialAnimation ? 'pageEnter' : false}
                                animate='pageCenter'
                                custom={animationDirection}
                                transition={DEFAULT_TRANSITION}
                            >
                                {columns}
                            </motion.div>
                        </AnimatePresence>
                    )}
                </div>
                {showPageButtons && (
                    <div className={nextButtonClassName} onClick={handleNextClick}>
                        <span className='rioglyph rioglyph-chevron-right' />
                    </div>
                )}
            </div>
        );
    }
);

export default ResponsiveColumnStripe;
