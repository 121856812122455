import { Link } from 'react-router-dom';

import { Playground, PLAYGROUNG_BG_LIGHTER } from '../../../components/playground/Playground';
import { PlaygroundClasses, PlaygroundClassesSpacer } from '../../../components/playground/PlaygroundClasses';
import { Description } from '../../../components/Description';
import { Callout } from '../../../components/Callout';

import TableToolbar from '../../../../../src/TableToolbar';
import DatePicker from '../../../../../src/DatePicker';
import Button from '../../../../../src/Button';
import TableViewToggles, { type TableViewTogglesViewType } from '../../../../../src/TableViewToggles';

export const Table = () => (
    <div>
        <Description headline='Table' anchor='table' />
        <Callout>
            See also our <Link to='/components/tables'>Table</Link> components like <b>TableToolbar</b>,{' '}
            <b>TableSearch</b>,<b>TableViewToggles</b>, <b>TableCardsSorting</b>, and <b>TableSettingsDialog</b>. For a
            more advanced table demo checkout our <Link to='/demoService/table'>demo service</Link>
        </Callout>

        <Description headline='Common table style' headlineType='h3' headlineNoTopSpace />
        <Playground
            example={tableExample}
            classes={tableClasses}
            background={PLAYGROUNG_BG_LIGHTER}
            className='overflow-hidden'
        />

        <Description headline='Table head' headlineType='h3' />
        <Playground
            example={tableHeadExample}
            classes={tableHeadClasses}
            background={PLAYGROUNG_BG_LIGHTER}
            className='overflow-hidden'
        />
    </div>
);

const tableExample = (
    <div>
        <TableToolbar>
            <div className='table-toolbar-container'>
                <div className='table-toolbar-group-left'>
                    <div className='table-toolbar-column'>
                        <label className='table-toolbar-label'>Label</label>
                        <div className='btn-toolbar table-btn-toolbar'>
                            <Button bsStyle={Button.PRIMARY} iconName='rioglyph-plus'>
                                New
                            </Button>
                            <Button>Action</Button>
                        </div>
                    </div>
                    <div className='table-toolbar-column'>
                        <div className='min-width-200'>
                            <DatePicker inputProps={{ placeholder: 'Select date' }} />
                        </div>
                    </div>
                </div>
                <div className='table-toolbar-group-right'>
                    <div className='table-toolbar-column'>
                        <div className='table-toolbar-search input-group'>
                            <span className='input-group-addon'>
                                <span className='rioglyph rioglyph-search' />
                            </span>
                            <div className='ClearableInput input-group'>
                                <input type='text' placeholder='Search...' className='form-control' />
                                <span className='clearButton hide'>
                                    <span className='clearButtonIcon rioglyph rioglyph-remove-sign' />
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className='table-toolbar-column table-toolbar-column-spacer'>
                        <label className='table-toolbar-label'>Label</label>
                        <div className='btn-toolbar'>
                            <TableViewToggles
                                initialViewType={TableViewToggles.VIEW_TYPE_TABLE}
                                tableViewTooltipContent='Table view'
                                singleCardViewTooltipContent='List view'
                                multiCardsViewTooltipContent='Cards view'
                                onViewTypeChange={(viewType: TableViewTogglesViewType) => console.log(viewType)}
                            />
                        </div>
                    </div>
                    <div className='table-toolbar-column'>
                        <Button iconOnly iconName='rioglyph-settings' />
                    </div>
                </div>
            </div>
        </TableToolbar>
        <div className='table-responsive'>
            <table className='table table-hover table-head-filled table-bordered'>
                <thead>
                    <tr>
                        {[...Array(4)].map(() => (
                            <th key={crypto.randomUUID()}>Head column</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    <tr className='active'>
                        {[...Array(4)].map(() => (
                            <td key={crypto.randomUUID()}>Column</td>
                        ))}
                    </tr>
                    <tr>
                        {[...Array(4)].map(() => (
                            <td key={crypto.randomUUID()}>Column</td>
                        ))}
                    </tr>
                    <tr>
                        {[...Array(4)].map(() => (
                            <td key={crypto.randomUUID()}>Column</td>
                        ))}
                    </tr>
                    <tr>
                        {[...Array(4)].map(() => (
                            <td key={crypto.randomUUID()}>Column</td>
                        ))}
                    </tr>
                    <tr className='info'>
                        {[...Array(4)].map(() => (
                            <td key={crypto.randomUUID()}>Column</td>
                        ))}
                    </tr>
                    <tr className='success'>
                        {[...Array(4)].map(() => (
                            <td key={crypto.randomUUID()}>Column</td>
                        ))}
                    </tr>
                    <tr className='danger'>
                        {[...Array(4)].map(() => (
                            <td key={crypto.randomUUID()}>Column</td>
                        ))}
                    </tr>
                    <tr className='warning'>
                        {[...Array(4)].map(() => (
                            <td key={crypto.randomUUID()}>Column</td>
                        ))}
                    </tr>
                    <tr>
                        {[...Array(4)].map(() => (
                            <td key={crypto.randomUUID()}>Column</td>
                        ))}
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
);

const tableClasses = (
    <>
        <PlaygroundClasses label='<table>' classes='table' />
        <PlaygroundClasses
            label='Variants'
            prefix='table-'
            classes={[
                'head-filled',
                'condensed',
                'layout-fixed',
                'sticky',
                'sticky-in-container',
                'column-overflow-hidden',
                'striped',
                'hover',
            ]}
        />
        <PlaygroundClasses
            label='Bordered'
            prefix='table-'
            classes={['bordered', 'row-first-border-none', 'row-last-border-none']}
        />
        <PlaygroundClassesSpacer label='Row' />
        <PlaygroundClasses label='<tr>' classes={['compactRow', 'extendedRow']} />
        <PlaygroundClassesSpacer label='Render table cols as cards' />
        <PlaygroundClasses label='<table>' classes='table-cards' />
        <PlaygroundClasses
            label='Variants'
            classes={[
                'table-cards table-single-card',
                'table-cards table-multi-cards',
                'table-cards table-cards-with-border',
            ]}
        />
        <PlaygroundClassesSpacer label='Horizontal scrollable on mobile' />
        <PlaygroundClasses label='Wrapper' classes='table-responsive' />
    </>
);

const tableHeadExample = (
    <>
        <Description headline='Default' headlineType='h4' headlineNoTopSpace />
        <div className='table-responsive'>
            <table className='table margin-bottom-50'>
                <thead>
                    <tr>
                        {[...Array(4)].map(() => (
                            <th key={crypto.randomUUID()}>Head column</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        {[...Array(4)].map(() => (
                            <td key={crypto.randomUUID()}>Column</td>
                        ))}
                    </tr>
                </tbody>
            </table>
        </div>
        <Description headline='Table Head Filled' headlineType='h4' headlineNoTopSpace />
        <div className='table-responsive'>
            <table className='table table-head-filled'>
                <thead>
                    <tr>
                        {[...Array(4)].map(() => (
                            <th key={crypto.randomUUID()}>Head column</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        {[...Array(4)].map(() => (
                            <td key={crypto.randomUUID()}>Column</td>
                        ))}
                    </tr>
                </tbody>
            </table>
        </div>
    </>
);

const tableHeadClasses = (
    <>
        <PlaygroundClasses label='<table>' classes='table' />
        <PlaygroundClasses label='Variants' prefix='table-' classes={['head-filled']} />
    </>
);
