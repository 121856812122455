import { Playground } from '../../../components/playground/Playground';
import { Description } from '../../../components/Description';

import { replaceUikitImport } from '../../../utils/replaceUikitImport';

import AnimationsExample from './AnimationsExample';
import AnimationsExampleRaw from './AnimationsExample.tsx?raw';

export const Animations = () => (
    <div>
        <Description headline='Animations' anchor='animations' />
        <Playground example={<AnimationsExample />} codeReact={replaceUikitImport(AnimationsExampleRaw)} codeHtml='' />
    </div>
);
