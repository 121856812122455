const localStorage = window.localStorage;

const STORAGE_PREFIX = 'UIKIT.demoService';

const save = (key, value) => {
    try {
        localStorage.setItem(`${STORAGE_PREFIX}.${key}`, JSON.stringify(value));
    } catch (_) {
        // Intentionally left blank
    }
};

const load = key => {
    try {
        return JSON.parse(localStorage.getItem(`${STORAGE_PREFIX}.${key}`));
    } catch (_) {
        // Intentionally left blank
    }
};

export const storage = { save, load };
