/* eslint-disable max-len */
import { NavLink } from 'react-router-dom';

import { New } from '../../components/NavLinkItem';

import { UikitMenuSidebar } from '../../components/UikitMenuSidebar';
import { UikitMenu } from '../../components/UikitMenu';

import { Alerts } from './alerts/Alerts';
import { Animations } from './animations/Animations';
import { AspectRatio } from './aspectRatio/AspectRatio';
import { Background } from './background/Background';
import { BackgroundColors } from './backgroundColors/BackgroundColors';
import { Badges } from './badges/Badges';
import { Blockquote } from './blockquote/Blockquote';
import { Blur } from './blur/Blur';
import { Border } from './border/Border';
import { Breadcrumb } from './breadcrumb/Breadcrumb';
import { Buttons } from './buttons/Buttons';
import { Callouts } from './callouts/Callouts';
import { Code } from './code/Code';
import { Cols } from './cols/Cols';
import { Container } from './container/Container';
import { Cursor } from './cursor/Cursor';
import { Divider } from './divider/Divider';
import { Display } from './display/Display';
import { Ellipsis } from './ellipsis/Ellipsis';
import { Flexbox } from './flexbox/Flexbox';
import { Forms } from './forms/Forms';
import { Gap } from './gap/Gap';
import { Counters } from './counter/Counter';
import { Grid } from './grid/Grid';
import { Subgrid } from './grid/Subgrid';
import { Height } from './height/Height';
import { Helper } from './helper/Helper';
import { Inset } from './inset/Inset';
import { Key } from './key/Key';
import { Labels } from './labels/Labels';
import { LineHeight } from './lineHeight/LineHeight';
import { ListGroups } from './listGroups/ListGroups';
import { Lists } from './lists/Lists';
import { Margin } from './margin/Margin';
import { Opacity } from './opacity/Opacity';
import { Overflow } from './overflow/Overflow';
import { Padding } from './padding/Padding';
import { Pager } from './pager/Pager';
import { Pagination } from './pagination/Pagination';
import { Panels } from './panels/Panels';
import { Pills } from './pills/Pills';
import { Position } from './position/Position';
import { ProgressBars } from './progressBars/ProgressBars';
import { Rioglyph } from './rioglyph/Rioglyph';
import { RioglyphCheatsheet } from './rioglyph/RioglyphCheatsheet';
import { Rotate } from './rotate/Rotate';
import { Rounded } from './rounded/Rounded';
import { Scale } from './scale/Scale';
import { Shadows } from './shadows/Shadows';
import { Space } from './space/Space';
import { SvgColors } from './svgColors/SvgColors';
import { Table } from './tables/Table';
import { TableHover } from './tables/TableHover';
import { TableBordered } from './tables/TableBordered';
import { TableStriped } from './tables/TableStriped';
import { TableGrouped } from './tables/TableGrouped';
import { TableSticky } from './tables/TableSticky';
import { TableCondensed } from './tables/TableCondensed';
import { TableFixed } from './tables/TableFixed';
import { TableResponsive } from './tables/TableResponsive';
import { TableStates } from './tables/TableStates';
import { TablePanel } from './tables/TablePanel';
import { TableRowAnimation } from './tables/TableRowAnimation';
import { Tabs } from './tabs/Tabs';
import { TextAlignment } from './textAlignment/TextAlignment';
import { TextColors } from './textColors/TextColors';
import { TextStyles } from './textStyles/TextStyles';
import { TextWrapping } from './textWrapping/TextWrapping';
import { Transition } from './transition/Transition';
import { Translate } from './translate/Translate';
import { UserSelection } from './userSelection/UserSelection';
import { Visibility } from './visibility/Visibility';
import { Wells } from './wells/Wells';
import { Width } from './width/Width';
import { Zindex } from './zIndex/Zindex';

import { routes } from '../../routes';

export const BASE_URL = routes.DESIGN;

export const getDesignMenuItems = () => [
    {
        group: 'Interaction',
        navItems: [
            {
                key: 'buttons',
                item: <NavLink to={`${BASE_URL}/buttons`}>Buttons</NavLink>,
                page: <Buttons />,
                meta: 'buttons',
            },
        ],
    },

    {
        group: 'Colors',
        navItems: [
            {
                key: 'backgroundColors',
                item: <NavLink to={`${BASE_URL}/backgroundColors`}>Background</NavLink>,
                page: <BackgroundColors />,
                meta: 'backgroundColors bg',
            },
            {
                key: 'textColors',
                item: <NavLink to={`${BASE_URL}/textColors`}>Text</NavLink>,
                page: <TextColors />,
                meta: 'textColors font fontcolor',
            },
            {
                key: 'svgColors',
                item: <NavLink to={`${BASE_URL}/svgColors`}>SVG</NavLink>,
                page: <SvgColors />,
                meta: 'svgColors fill',
            },
        ],
    },

    {
        group: 'Styling',
        navItems: [
            {
                key: 'background',
                item: <NavLink to={`${BASE_URL}/background`}>Background</NavLink>,
                page: <Background />,
                meta: 'background bg',
            },
            {
                key: 'border',
                item: <NavLink to={`${BASE_URL}/border`}>Border</NavLink>,
                page: <Border />,
                meta: 'border',
            },
            {
                key: 'divider',
                item: <NavLink to={`${BASE_URL}/divider`}>Divider</NavLink>,
                page: <Divider />,
                meta: 'divider separator',
            },
            {
                key: 'opacity',
                item: <NavLink to={`${BASE_URL}/opacity`}>Opacity</NavLink>,
                page: <Opacity />,
                meta: 'opacity transparency',
            },
            {
                key: 'rounded',
                item: <NavLink to={`${BASE_URL}/rounded`}>Rounded</NavLink>,
                page: <Rounded />,
                meta: 'rounded border radius',
            },
            {
                key: 'shadows',
                item: <NavLink to={`${BASE_URL}/shadows`}>Shadows</NavLink>,
                page: <Shadows />,
                meta: 'shadows box-shadow',
            },
        ],
    },

    {
        group: 'Icons',
        navItems: [
            {
                key: 'rioglyph',
                item: <NavLink to={`${BASE_URL}/rioglyph`}>RioGlyph</NavLink>,
                page: <Rioglyph />,
                meta: 'rioglyph icons',
            },
            {
                key: 'rioglyphCheatsheet',
                item: <NavLink to={`${BASE_URL}/rioglyphCheatsheet`}>Icon cheatsheet</NavLink>,
                page: <RioglyphCheatsheet />,
                meta: 'cheatsheet icons',
            },
        ],
    },

    {
        group: 'Text',
        navItems: [
            {
                key: 'textStyles',
                item: <NavLink to={`${BASE_URL}/textStyles`}>Text styles</NavLink>,
                page: <TextStyles />,
                meta: 'textStyles font headline h1 h2 h3 h4 h5',
            },
            {
                key: 'alignment',
                item: <NavLink to={`${BASE_URL}/alignment`}>Alignment</NavLink>,
                page: <TextAlignment />,
                meta: 'alignment',
            },
            {
                key: 'blockquote',
                item: <NavLink to={`${BASE_URL}/blockquote`}>Blockquote</NavLink>,
                page: <Blockquote />,
                meta: 'blockquote',
            },
            {
                key: 'code',
                item: <NavLink to={`${BASE_URL}/code`}>Code</NavLink>,
                page: <Code />,
                meta: 'code pre',
            },
            {
                key: 'cursors',
                item: <NavLink to={`${BASE_URL}/cursors`}>Cursor</NavLink>,
                page: <Cursor />,
                meta: 'cursors',
            },
            {
                key: 'ellipsis',
                item: <NavLink to={`${BASE_URL}/ellipsis`}>Ellipsis</NavLink>,
                page: <Ellipsis />,
                meta: 'ellipsis truncating',
            },
            {
                key: 'lineHeight',
                item: <NavLink to={`${BASE_URL}/lineHeight`}>Line height</NavLink>,
                page: <LineHeight />,
                meta: 'lineHeight',
            },
            {
                key: 'userSelect',
                item: <NavLink to={`${BASE_URL}/userSelect`}>User select</NavLink>,
                page: <UserSelection />,
                meta: 'userSelect',
            },
            {
                key: 'textWrapping',
                item: <NavLink to={`${BASE_URL}/textWrapping`}>Wrapping</NavLink>,
                page: <TextWrapping />,
                meta: 'textWrapping',
            },
        ],
    },

    {
        group: 'Navigation',
        navItems: [
            {
                key: 'breadcrumbs',
                item: <NavLink to={`${BASE_URL}/breadcrumbs`}>Breadcrumbs</NavLink>,
                page: <Breadcrumb />,
                meta: 'breadcrumbs navigation',
            },
            {
                key: 'pager',
                item: <NavLink to={`${BASE_URL}/pager`}>Pager</NavLink>,
                page: <Pager />,
                meta: 'pager navigation',
            },
            {
                key: 'pagination',
                item: <NavLink to={`${BASE_URL}/pagination`}>Pagination</NavLink>,
                page: <Pagination />,
                meta: 'pagination navigation',
            },
            {
                key: 'pills',
                item: <NavLink to={`${BASE_URL}/pills`}>Pills</NavLink>,
                page: <Pills />,
                meta: 'pills navigation nav-pills stacked',
            },
            {
                key: 'tabs',
                item: <NavLink to={`${BASE_URL}/tabs`}>Tabs</NavLink>,
                page: <Tabs />,
                meta: 'tabs navigation',
            },
        ],
    },

    {
        group: 'Allocation',
        navItems: [
            {
                key: 'cols',
                item: <NavLink to={`${BASE_URL}/cols`}>Cols</NavLink>,
                page: <Cols />,
                meta: 'cols columns',
            },
            {
                key: 'flexbox',
                item: <NavLink to={`${BASE_URL}/flexbox`}>Flexbox</NavLink>,
                page: <Flexbox />,
                meta: 'display flexbox',
            },
            {
                key: 'grid',
                item: <NavLink to={`${BASE_URL}/grid`}>Grid</NavLink>,
                page: <Grid />,
                meta: 'display grid',
            },
            {
                key: 'subgrid',
                item: <NavLink to={`${BASE_URL}/subgrid`}>Subgrid</NavLink>,
                page: <Subgrid />,
                meta: 'display subgrid grid',
            },
            {
                key: 'position',
                item: <NavLink to={`${BASE_URL}/position`}>Position</NavLink>,
                page: <Position />,
                meta: 'position absolute top left right bottom',
            },
        ],
    },

    {
        group: 'Content',
        navItems: [
            {
                key: 'container',
                item: <NavLink to={`${BASE_URL}/container`}>Container</NavLink>,
                page: <Container />,
                meta: 'container',
            },
            {
                key: 'counter',
                item: (
                    <NavLink activeClassName='active' to={`${BASE_URL}/counter`}>
                        Counter
                    </NavLink>
                ),
                page: <Counters />,
                meta: 'counter',
            },
            {
                key: 'listGroups',
                item: <NavLink to={`${BASE_URL}/listGroups`}>List groups</NavLink>,
                page: <ListGroups />,
                meta: 'listGroups',
            },
            {
                key: 'wells',
                item: <NavLink to={`${BASE_URL}/wells`}>Wells</NavLink>,
                page: <Wells />,
                meta: 'wells',
            },
        ],
    },

    {
        group: 'Tables',
        navItems: [
            {
                key: 'table',
                item: <NavLink to={`${BASE_URL}/table`}>Table</NavLink>,
                page: <Table />,
                meta: 'table',
            },
            {
                key: 'tableHover',
                item: <NavLink to={`${BASE_URL}/tableHover`}>Hover table</NavLink>,
                page: <TableHover />,
                meta: 'table hover',
            },
            {
                key: 'tableBordered',
                item: <NavLink to={`${BASE_URL}/tableBordered`}>Bordered table</NavLink>,
                page: <TableBordered />,
                meta: 'table bordered',
            },
            {
                key: 'tableStriped',
                item: <NavLink to={`${BASE_URL}/tableStriped`}>Striped table</NavLink>,
                page: <TableStriped />,
                meta: 'table striped',
            },
            {
                key: 'tableGrouped',
                item: <NavLink to={`${BASE_URL}/tableGrouped`}>Grouped table</NavLink>,
                page: <TableGrouped />,
                meta: 'table grouped',
            },
            {
                key: 'tableSticky',
                item: <NavLink to={`${BASE_URL}/tableSticky`}>Sticky table</NavLink>,
                page: <TableSticky />,
                meta: 'table sticky',
            },
            {
                key: 'tableCondensed',
                item: <NavLink to={`${BASE_URL}/tableCondensed`}>Condensed table</NavLink>,
                page: <TableCondensed />,
                meta: 'table condensed',
            },
            {
                key: 'tableFixed',
                item: <NavLink to={`${BASE_URL}/tableFixed`}>Fixed table</NavLink>,
                page: <TableFixed />,
                meta: 'table fixed',
            },
            {
                key: 'tableResponsive',
                item: <NavLink to={`${BASE_URL}/tableResponsive`}>Responsive table</NavLink>,
                page: <TableResponsive />,
                meta: 'table responsive',
            },
            {
                key: 'tableStates',
                item: <NavLink to={`${BASE_URL}/tableStates`}>States in tables</NavLink>,
                page: <TableStates />,
                meta: 'table states',
            },
            {
                key: 'tablePanel',
                item: (
                    <NavLink to={`${BASE_URL}/tablePanel`}>
                        <New>Combined tables in panel</New>
                    </NavLink>
                ),
                page: <TablePanel />,
                meta: 'table expand panel combined',
            },
            {
                key: 'tableRowAnimation',
                item: <NavLink to={`${BASE_URL}/tableRowAnimation`}>Row animation</NavLink>,
                page: <TableRowAnimation />,
                meta: 'table row animation',
            },
        ],
    },

    {
        group: 'Elements',
        navItems: [
            {
                key: 'alerts',
                item: <NavLink to={`${BASE_URL}/alerts`}>Alerts</NavLink>,
                page: <Alerts />,
                meta: 'alerts',
            },
            {
                key: 'badges',
                item: <NavLink to={`${BASE_URL}/badges`}>Badges</NavLink>,
                page: <Badges />,
                meta: 'badges',
            },
            {
                key: 'callouts',
                item: <NavLink to={`${BASE_URL}/callouts`}>Callouts</NavLink>,
                page: <Callouts />,
                meta: 'callouts',
            },
            {
                key: 'key',
                item: <NavLink to={`${BASE_URL}/key`}>Key</NavLink>,
                page: <Key />,
                meta: 'key',
            },
            {
                key: 'labels',
                item: <NavLink to={`${BASE_URL}/labels`}>Labels</NavLink>,
                page: <Labels />,
                meta: 'labels',
            },
            {
                key: 'lists',
                item: <NavLink to={`${BASE_URL}/lists`}>Lists</NavLink>,
                page: <Lists />,
                meta: 'lists iconlist treelist',
            },
            {
                key: 'panels',
                item: <NavLink to={`${BASE_URL}/panels`}>Panels</NavLink>,
                page: <Panels />,
                meta: 'panels',
            },
            {
                key: 'progress',
                item: <NavLink to={`${BASE_URL}/progress`}>Progress bars</NavLink>,
                page: <ProgressBars />,
                meta: 'progress',
            },
        ],
    },

    {
        group: 'Forms',
        navItems: [
            {
                key: 'forms',
                item: <NavLink to={`${BASE_URL}/forms`}>Forms</NavLink>,
                page: <Forms />,
                meta: 'forms input select password fieldset legend',
            },
        ],
    },

    {
        group: 'Spacing',
        navItems: [
            {
                key: 'gap',
                item: <NavLink to={`${BASE_URL}/gap`}>Gap</NavLink>,
                page: <Gap />,
                meta: 'gap',
            },
            {
                key: 'space',
                item: <NavLink to={`${BASE_URL}/space`}>Space</NavLink>,
                page: <Space />,
                meta: 'space',
            },
            {
                key: 'margin',
                item: <NavLink to={`${BASE_URL}/margin`}>Margin</NavLink>,
                page: <Margin />,
                meta: 'margin',
            },
            {
                key: 'padding',
                item: <NavLink to={`${BASE_URL}/padding`}>Padding</NavLink>,
                page: <Padding />,
                meta: 'padding',
            },
        ],
    },

    {
        group: 'Sizing',
        navItems: [
            {
                key: 'height',
                item: <NavLink to={`${BASE_URL}/height`}>Height</NavLink>,
                page: <Height />,
                meta: 'height size',
            },
            {
                key: 'width',
                item: <NavLink to={`${BASE_URL}/width`}>Width</NavLink>,
                page: <Width />,
                meta: 'width size',
            },
        ],
    },

    {
        group: 'Animate',
        navItems: [
            {
                key: 'animations',
                item: <NavLink to={`${BASE_URL}/animations`}>Animations</NavLink>,
                page: <Animations />,
                meta: 'animations',
            },
            {
                key: 'transition',
                item: <NavLink to={`${BASE_URL}/transition`}>Transition</NavLink>,
                page: <Transition />,
                meta: 'transition',
            },
        ],
    },

    {
        group: 'CSS Filter',
        navItems: [
            {
                key: 'blur',
                item: <NavLink to={`${BASE_URL}/blur`}>Blur</NavLink>,
                page: <Blur />,
                meta: 'blur',
            },
        ],
    },

    {
        group: 'Utilities',
        navItems: [
            {
                key: 'aspectRatio',
                item: <NavLink to={`${BASE_URL}/aspectRatio`}>Aspect ratio</NavLink>,
                page: <AspectRatio />,
                meta: 'aspectRatio',
            },
            {
                key: 'inset',
                item: <NavLink to={`${BASE_URL}/inset`}>Inset</NavLink>,
                page: <Inset />,
                meta: 'inset',
            },
            {
                key: 'helper',
                item: <NavLink to={`${BASE_URL}/helper`}>Helper</NavLink>,
                page: <Helper />,
                meta: 'helper',
            },
        ],
    },

    {
        group: 'Transform',
        navItems: [
            {
                key: 'rotate',
                item: <NavLink to={`${BASE_URL}/rotate`}>Rotate</NavLink>,
                page: <Rotate />,
                meta: 'rotate',
            },
            {
                key: 'scale',
                item: <NavLink to={`${BASE_URL}/scale`}>Scale</NavLink>,
                page: <Scale />,
                meta: 'scale',
            },
            {
                key: 'translate',
                item: <NavLink to={`${BASE_URL}/translate`}>Translate</NavLink>,
                page: <Translate />,
                meta: 'translate',
            },
        ],
    },

    {
        group: 'Visibility',
        navItems: [
            {
                key: 'display',
                item: <NavLink to={`${BASE_URL}/display`}>Display</NavLink>,
                page: <Display />,
                meta: 'display',
            },
            {
                key: 'overflow',
                item: <NavLink to={`${BASE_URL}/overflow`}>Overflow</NavLink>,
                page: <Overflow />,
                meta: 'overflow',
            },
            {
                key: 'visibility',
                item: <NavLink to={`${BASE_URL}/visibility`}>Visibility</NavLink>,
                page: <Visibility />,
                meta: 'visibility',
            },
            {
                key: 'zIndex',
                item: <NavLink to={`${BASE_URL}/zIndex`}>Z-Index</NavLink>,
                page: <Zindex />,
                meta: 'zIndex',
            },
        ],
    },
];

export const DesignMenu = () => (
    <UikitMenuSidebar title='Design'>
        <UikitMenu />
    </UikitMenuSidebar>
);
