import { Playground } from '../../../components/playground/Playground';
import { PlaygroundClasses } from '../../../components/playground/PlaygroundClasses';
import { Description } from '../../../components/Description';

export const Zindex = () => (
    <div>
        <Description headline='Z-index' anchor='z-index' />
        <Playground example={zIndexExample} classes={zIndexClasses} />
    </div>
);

const zIndexExample = (
    <div className='display-flex'>
        <div className='bg-info width-50 height-50 rounded rotate-45 z-index-2 position-relative' />
        <div
            className={
                'shorthand-style width-50 height-50 rounded bg-success ' +
                'rotate-45 z-index-3 hover-z-index-1 position-relative'
            }
        />
        <div className='bg-info width-50 height-50 rounded rotate-45 z-index-2 position-relative' />
    </div>
);

const zIndexClasses = (
    <>
        <PlaygroundClasses
            label='Basic'
            prefix='z-index-'
            classes={['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', 'max']}
        />
        <PlaygroundClasses label='Hover' prefix='hover-z-index-' classes={['1', '2', '3', '4', '5']} />
    </>
);
