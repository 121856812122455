import { Playground } from '../../../components/playground/Playground';
import {
    PlaygroundClasses,
    PlaygroundClassesSpacer,
    PlaygroundNavClasses,
} from '../../../components/playground/PlaygroundClasses';
import { Description } from '../../../components/Description';
import { Title } from '../../../components/Title';

export const Pills = () => (
    <div>
        <Description headline='Pills' anchor='pills' />
        <Playground example={pillsExample} classes={pillsClasses} />
        <Playground example={pillsFilledExample} classes={pillsClasses} />
        <Playground example={pillsStackedExample} classes={pillsClasses} />
    </div>
);

const pillsExample = (
    <div className='display-grid grid-cols-1 grid-cols-2-sm column-gap-25 row-gap-50'>
        <div>
            <Title>Standard</Title>
            <ul className='nav nav-pills'>
                <li className='active'>
                    <span>Pill 1</span>
                </li>
                <li>
                    <span>Pill 2</span>
                </li>
                <li>
                    <span>Pill 3</span>
                </li>
                <li className='disabled'>
                    <span>Disabled Pill</span>
                </li>
            </ul>
        </div>

        <div>
            <Title>Justified</Title>
            <ul className='nav nav-pills nav-justified'>
                <li className='active'>
                    <span>First tab</span>
                </li>
                <li>
                    <span>Second tab with longer content</span>
                </li>
                <li>
                    <span>Third tab</span>
                </li>
                <li>
                    <span>Fourth tab</span>
                </li>
            </ul>
        </div>

        <div>
            <Title>Justified with word-wrap</Title>
            <ul className='nav nav-pills nav-justified nav-justified-word-wrap'>
                <li className='active'>
                    <span>First tab</span>
                </li>
                <li>
                    <span>Second tab with longer content</span>
                </li>
                <li>
                    <span>Third tab</span>
                </li>
                <li>
                    <span>Fourth tab</span>
                </li>
            </ul>
        </div>
    </div>
);

const pillsFilledExample = (
    <div className='display-grid grid-cols-1 grid-cols-2-sm column-gap-25 row-gap-50'>
        <div>
            <Title>Filled</Title>
            <ul className='nav nav-pills nav-pills-filled nav-justified'>
                <li className='active'>
                    <span>First tab</span>
                </li>
                <li>
                    <span>Second tab with longer content</span>
                </li>
                <li>
                    <span>Third tab</span>
                </li>
                <li className='disabled'>
                    <span>Fourth tab</span>
                </li>
            </ul>
        </div>

        <div>
            <Title>Filled with icons only</Title>
            <ul className='rounded-none nav nav-pills nav-pills-filled nav-justified text-size-18'>
                <li className='active'>
                    <span className='rioglyph rioglyph-pushpin' />
                </li>
                <li className=''>
                    <span className='rioglyph rioglyph-geofence' />
                </li>
                <li className=''>
                    <span className='rioglyph rioglyph-workshop' />
                </li>
                <li className=''>
                    <span className='rioglyph rioglyph-filling-e-station' />
                </li>
                <li className=''>
                    <span className='rioglyph rioglyph-parking' />
                </li>
            </ul>
        </div>
    </div>
);

const pillsStackedExample = (
    <div className='display-grid grid-cols-1 grid-cols-2-sm column-gap-25 row-gap-50'>
        <div>
            <Title>Stacked</Title>
            <ul className='nav nav-pills nav-stacked'>
                <li className='active'>
                    <span>Stacked pill 1</span>
                </li>
                <li>
                    <span>Stacked pill 2</span>
                </li>
                <li>
                    <span>Stacked pill 3</span>
                </li>
                <li className='disabled'>
                    <span>Disabled stacked pill</span>
                </li>
            </ul>
        </div>

        <div>
            <Title>Stacked decent</Title>
            <ul className='nav nav-pills nav-pills-stacked-decent nav-stacked nav-stacked-decent'>
                <li className='active'>
                    <span>Stacked pill 1</span>
                </li>
                <li>
                    <span>Stacked pill 2</span>
                </li>
                <li>
                    <span>Stacked pill 3</span>
                </li>
                <li className='disabled'>
                    <span>Disabled stacked pill</span>
                </li>
            </ul>
        </div>

        <div>
            <Title>Stacked decent menu</Title>
            <ul className='nav nav-pills nav-pills-stacked-decent nav-stacked nav-stacked-decent'>
                <li className='menu-item active'>
                    <a href='/#'>
                        <div className='flex-1-1 display-flex align-items-center gap-10 padding-y-2'>
                            <div className='position-relative'>
                                <span className='text-size-h4 text-color-primary rioglyph rioglyph-user' />
                            </div>
                            <div className='line-height-125rel'>
                                <div className='text-size-16'>Lorem ipsum</div>
                                <div className='text-size-12 text-color-dark'>Dolor sit amet</div>
                            </div>
                        </div>
                    </a>
                </li>
                <li className='menu-item'>
                    <a href='/#'>
                        <div className='flex-1-1 display-flex align-items-center gap-10 padding-y-2'>
                            <div className='position-relative'>
                                <span className='text-size-h4 text-color-primary rioglyph rioglyph-addressbook' />
                            </div>
                            <div className='line-height-125rel'>
                                <div className='text-size-16'>Lorem ipsum</div>
                                <div className='text-size-12 text-color-dark'>Dolor sit amet</div>
                            </div>
                        </div>
                    </a>
                </li>
                <li className='menu-item'>
                    <a href='/#'>
                        <div className='flex-1-1 display-flex align-items-center gap-10 padding-y-2'>
                            <div className='position-relative'>
                                <span className='text-size-h4 text-color-primary rioglyph rioglyph-book' />
                            </div>
                            <div className='line-height-125rel'>
                                <div className='text-size-16'>Lorem ipsum</div>
                                <div className='text-size-12 text-color-dark'>Dolor sit amet</div>
                            </div>
                        </div>
                    </a>
                </li>
                <li className='menu-item disabled'>
                    <a href='/#'>
                        <div className='flex-1-1 display-flex align-items-center gap-10 padding-y-2'>
                            <div className='position-relative'>
                                <span className='text-size-h4 text-color-light rioglyph rioglyph-api' />
                            </div>
                            <div className='line-height-125rel'>
                                <div className='text-size-16'>Lorem ipsum</div>
                                <div className='text-size-12 text-color-light'>Dolor sit amet</div>
                            </div>
                        </div>
                    </a>
                </li>
            </ul>
        </div>
    </div>
);

const pillsClasses = (
    <>
        <PlaygroundNavClasses showNavVariants />
        <PlaygroundClassesSpacer label='Nav pills' />
        <PlaygroundClasses label='Wrapper' classes='nav nav-pills' />
        <PlaygroundClasses label='Variants' classes={['nav-pills-filled', 'nav-pills-stacked-decent']} />
    </>
);
