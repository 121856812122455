import React, { type ForwardedRef, type PropsWithChildren, forwardRef } from 'react';
import classNames from 'classnames';

export type ApplicationLayoutSidebarProps = {
    /**
     * Additional class names that are added to the respective component
     */
    className?: string;
};

type Ref = ForwardedRef<HTMLDivElement>;
type Props = PropsWithChildren<ApplicationLayoutSidebarProps>;

const ApplicationLayoutSidebar = forwardRef((props: Props, ref: Ref) => {
    const { className, children, ...remainingProps } = props;
    const classes = classNames('ApplicationLayoutSidebar', className);

    return (
        <div ref={ref} {...remainingProps} className={classes}>
            {children}
        </div>
    );
});

export default ApplicationLayoutSidebar;
