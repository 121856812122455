import { getNames } from './mapUtils';

export const getBreakpointNames = getNames;

export type Breakpoint = {
    name: string;
    minWidth: string;
    maxWidth: string;
    prevBreakpointName: string;
    prevBreakpointSize: string;
    nextBreakpointName: string;
    nextBreakpointSize: string;
};

// These values must always be kept in sync with those of the less folder
// src/themes/RIO/css/mapping/breakpoint-map.less

export const breakpoints: Breakpoint[] = [
    {
        name: 'xs',
        minWidth: '0',
        maxWidth: '479.99px',
        prevBreakpointName: '-',
        prevBreakpointSize: '-',
        nextBreakpointName: 'ls',
        nextBreakpointSize: '480px',
    },
    {
        name: 'ls',
        minWidth: '480px',
        maxWidth: '767.99px',
        prevBreakpointName: 'xs',
        prevBreakpointSize: '0',
        nextBreakpointName: 'sm',
        nextBreakpointSize: '768px',
    },
    {
        name: 'sm',
        minWidth: '768px',
        maxWidth: '991.99px',
        prevBreakpointName: 'ls',
        prevBreakpointSize: '480px',
        nextBreakpointName: 'md',
        nextBreakpointSize: '992px',
    },
    {
        name: 'md',
        minWidth: '992px',
        maxWidth: '1199.99px',
        prevBreakpointName: 'sm',
        prevBreakpointSize: '768px',
        nextBreakpointName: 'lg',
        nextBreakpointSize: '1200px',
    },
    {
        name: 'lg',
        minWidth: '1200px',
        maxWidth: '1699.99px',
        prevBreakpointName: 'md',
        prevBreakpointSize: '992px',
        nextBreakpointName: 'xl',
        nextBreakpointSize: '1700px',
    },
    {
        name: 'xl',
        minWidth: '1700px',
        maxWidth: '-',
        prevBreakpointName: 'lg',
        prevBreakpointSize: '1200px',
        nextBreakpointName: '-',
        nextBreakpointSize: '-',
    },
];
