import { Playground } from '../../../components/playground/Playground';
import { PlaygroundClasses } from '../../../components/playground/PlaygroundClasses';
import { Description } from '../../../components/Description';

import CounterExample from './CounterExample';

const eachCounterColor = ['gray', 'primary', 'secondary'];
const eachCounterSize = ['small', 'large'];

export const Counters = () => (
    <div className='uikit-counter'>
        <Description headline='Counter' anchor='counter'>
            The counter component is a pure CSS component that can be applied to any element in order to count the child
            elements in a nice way. This works for list items as well as for div's or other HTML tags.
        </Description>

        <Playground example={<CounterExample />} classes={CounterClasses} />
    </div>
);

const CounterClasses = (
    <>
        <PlaygroundClasses label='Color' prefix='color-' classes={eachCounterColor} />
        <PlaygroundClasses label='Sizes' prefix='size-' classes={eachCounterSize} />
        <PlaygroundClasses
            label='Custom'
            classes={
                <>
                    <span>You can add a</span>
                    <code className='code-inline'>data-count</code> attribute top set a custom count. <b>Example</b>
                    <code className='code-inline'>data-count="6"</code>
                </>
            }
        />
    </>
);
