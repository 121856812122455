import React, { forwardRef, type HTMLProps, type PropsWithChildren } from 'react';
import classNames from 'classnames';

import Button, { type BUTTON_VARIANT, type BUTTON_SIZE, type BUTTON_STYLE } from '../button/Button';
import useMergeRefs from '../../hooks/useMergeRefs';

export type DropdownToggleButtonProps = HTMLProps<HTMLButtonElement> & {
    id?: string;
    disabled?: boolean;
    splitButton?: boolean;
    iconOnly?: boolean;
    onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
    bsSize: BUTTON_SIZE;
    bsStyle: BUTTON_STYLE;
    variant?: BUTTON_VARIANT;
    outerRef?: React.Ref<HTMLButtonElement>;
    className?: string;
};

const DropdownToggleButton = forwardRef<HTMLButtonElement, PropsWithChildren<DropdownToggleButtonProps>>(
    (props, ref) => {
        const {
            id,
            disabled,
            bsSize,
            bsStyle,
            variant,
            splitButton = false,
            onClick,
            className = '',
            children,
            outerRef,
            ...remainingProps
        } = props;

        const toggleRef = useMergeRefs(outerRef, ref);

        const labelButtonClasses = classNames(!splitButton && 'dropdown-toggle', !splitButton && className);

        return (
            <Button
                {...remainingProps}
                id={splitButton ? `button-${id}` : id}
                type='button'
                ref={toggleRef}
                disabled={disabled}
                bsStyle={bsStyle}
                bsSize={bsSize}
                variant={variant}
                onClick={onClick}
                className={labelButtonClasses}
            >
                {children}
            </Button>
        );
    }
);

export default DropdownToggleButton;
