import { colors, getColorNames } from '../../../utils/colors';
import { getRandomIcon } from '../../../utils/icons';
import { Title } from '../../../components/Title';

const eachColor: string[] = ['default', ...getColorNames(colors.brand, colors.status)];

export default () => (
    <>
        <div className='display-grid grid-cols-1 grid-cols-3-ls gap-20 margin-bottom-25'>
            <div className='grid-colspan-2-ls'>
                <Title>XS buttons</Title>
                <div className='btn-toolbar'>
                    {eachColor.map((color, index) => (
                        <button type='button' className={`btn btn-${color} btn-xs`} key={index}>
                            <span className={`rioglyph ${getRandomIcon()}`} aria-hidden='true' />
                            <span className='text-capitalize'>{color}</span>
                        </button>
                    ))}
                </div>
            </div>
            <div>
                <Title>XS icon buttons</Title>
                <div className='btn-toolbar'>
                    {eachColor.map((color, index) => (
                        <button type='button' className={`btn btn-${color} btn-xs btn-icon-only`} key={index}>
                            <span className={`rioglyph ${getRandomIcon()}`} aria-hidden='true' />
                        </button>
                    ))}
                </div>
            </div>
        </div>

        <div className='display-grid grid-cols-1 grid-cols-3-ls gap-20 margin-bottom-25'>
            <div className='grid-colspan-2-ls'>
                <Title>SM buttons</Title>
                <div className='btn-toolbar'>
                    {eachColor.map((color, index) => (
                        <button type='button' className={`btn btn-${color} btn-sm`} key={index}>
                            <span className={`rioglyph ${getRandomIcon()}`} aria-hidden='true' />
                            <span className='text-capitalize'>{color}</span>
                        </button>
                    ))}
                </div>
            </div>
            <div>
                <Title>SM icon buttons</Title>
                <div className='btn-toolbar'>
                    {eachColor.map((color, index) => (
                        <button type='button' className={`btn btn-${color} btn-sm btn-icon-only`} key={index}>
                            <span className={`rioglyph ${getRandomIcon()}`} aria-hidden='true' />
                        </button>
                    ))}
                </div>
            </div>
        </div>

        <div className='display-grid grid-cols-1 grid-cols-3-ls gap-20 margin-bottom-25'>
            <div className='grid-colspan-2-ls'>
                <Title>MD buttons (default)</Title>
                <div className='btn-toolbar'>
                    {eachColor.map((color, index) => (
                        <button type='button' className={`btn btn-${color}`} key={index}>
                            <span className={`rioglyph ${getRandomIcon()}`} aria-hidden='true' />
                            <span className='text-capitalize'>{color}</span>
                        </button>
                    ))}
                </div>
            </div>
            <div>
                <Title>MD icon buttons (default)</Title>
                <div className='btn-toolbar'>
                    {eachColor.map((color, index) => (
                        <button type='button' className={`btn btn-${color} btn-icon-only`} key={index}>
                            <span className={`rioglyph ${getRandomIcon()}`} aria-hidden='true' />
                        </button>
                    ))}
                </div>
            </div>
        </div>

        <div className='display-grid grid-cols-1 grid-cols-3-ls gap-20 margin-bottom-25'>
            <div className='grid-colspan-2-ls'>
                <Title>LG buttons</Title>
                <div className='btn-toolbar'>
                    {eachColor.map((color, index) => (
                        <button type='button' className={`btn btn-${color} btn-lg`} key={index}>
                            <span className={`rioglyph ${getRandomIcon()}`} aria-hidden='true' />
                            <span className='text-capitalize'>{color}</span>
                        </button>
                    ))}
                </div>
            </div>
            <div>
                <Title>LG icon buttons</Title>
                <div className='btn-toolbar'>
                    {eachColor.map((color, index) => (
                        <button type='button' className={`btn btn-${color} btn-lg btn-icon-only`} key={index}>
                            <span className={`rioglyph ${getRandomIcon()}`} aria-hidden='true' />
                        </button>
                    ))}
                </div>
            </div>
        </div>
    </>
);
