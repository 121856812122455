import { Playground } from '../../../components/playground/Playground';
import { Description } from '../../../components/Description';

export const Pagination = () => (
    <div>
        <Description headline='Pagination' anchor='pagination' />
        <Playground example={paginationExample} />
    </div>
);

const paginationExample = (
    <div className='display-flex gap-50 flex-wrap'>
        <ul className='pagination'>
            <li className='disabled'>
                <span className='rioglyph rioglyph-chevron-left' />
            </li>
            <li>
                <span>1</span>
            </li>
            <li className='active'>
                <span>2</span>
            </li>
            <li>
                <span>3</span>
            </li>
            <li>
                <span>4</span>
            </li>
            <li>
                <span>5</span>
            </li>
            <li>
                <span className='rioglyph rioglyph-chevron-right' />
            </li>
        </ul>

        <ul className='pagination'>
            <li className='disabled'>
                <span className='rioglyph rioglyph-chevron-left' />
            </li>
            <li />
            <li />
            <li className='active' />
            <li />
            <li />
            <li>
                <span className='rioglyph rioglyph-chevron-right' />
            </li>
        </ul>
    </div>
);
