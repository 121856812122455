import { getColorScheme, listenForSchemeChange } from '../colorScheme';
import { version } from '../../version.json';
import { isProd, isTest } from './initConfig';
import { printPoweredByRIO } from './printPoweredByRIO';
import { checkCSSVersion, initCSS } from './initCSS';

const versionsListUrl = 'https://uikit.developers.rio.cloud/versionsList.json';

const checkForReleaseVersion = async () => {
    if (!isProd) {
        return;
    }

    try {
        const response = await fetch(versionsListUrl);
        const { latest_release } = await response.json();

        if (latest_release > version) {
            console.log(
                `🆕 You are using version ${version} of the UIKIT while the latest released version is` +
                    ` ${latest_release}. Please consider updating.`
            );
        }
    } catch (error) {
        console.debug('Failed to fetch UIKIT version list.');
    }
};

export const initDocumentBootstrapping = async () => {
    if (!document || !document.documentElement) {
        return;
    }

    initCSS();

    if (!isTest) {
        console.group('UIKIT');
        console.log(`Init UIKIT for ${document.location.href}.`);

        getColorScheme();
        listenForSchemeChange();
        printPoweredByRIO();

        await checkForReleaseVersion();

        checkCSSVersion();

        console.groupEnd();
    }
};
