// @ts-ignore-next-line importsNotUsedAsValues
import React from 'react';

export type ApplicationActionBarProps = {
    items?: React.ReactNode[];
    nodeRef?: React.MutableRefObject<HTMLUListElement | null> | undefined;
    className?: string;
};

const ApplicationActionBar = (props: ApplicationActionBarProps) => {
    const { items, nodeRef, className = '', ...remainingProps } = props;

    return (
        <ul
            {...remainingProps}
            className={`ApplicationActionBar nav navbar-nav navbar-right ${className}`}
            ref={nodeRef}
        >
            {items?.map((item, index) => {
                return (
                    <li key={index} role='presentation' className='navigationItem'>
                        {item}
                    </li>
                );
            })}
        </ul>
    );
};

export default ApplicationActionBar;
