// @ts-ignore-next-line importsNotUsedAsValues
import React, { type PropsWithChildren } from 'react';
import classNames from 'classnames';

export type StatsWidgetHeaderProps = {
    /**
     * Additional class names to be added to the element.
     */
    className?: string;
};

const StatsWidgetHeader = (props: PropsWithChildren<StatsWidgetHeaderProps>) => {
    const { children, className, ...remainingProps } = props;

    const wrapeprClasses = classNames('StatsWidget-header', className);

    return (
        <div {...remainingProps} className={wrapeprClasses}>
            {children}
        </div>
    );
};

export default StatsWidgetHeader;
