import type { PropsWithChildren } from 'react';

export type TreeCategoryProps = {
    /**
     * A unique identifier for that category.
     */
    id: string;

    /**
     * The rioglyph icon name for that category.
     */
    icon: string;

    /**
     * The optional label for the category which will be shown in a tooltip.
     */
    label?: string | React.ReactNode;

    /**
     * Defines whether or not a selection indicator should be shown.
     */
    hasSelection?: boolean;
};

export type TreeCategoryNode = React.ReactElement<TreeCategoryProps>;

const TreeCategory = (props: PropsWithChildren<TreeCategoryProps>) => props.children || null;

export default TreeCategory;
