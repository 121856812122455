// @ts-ignore-next-line importsNotUsedAsValues
import React from 'react';
import classNames from 'classnames';
import noop from 'lodash/fp/noop';

import ClearableInput from '../clearableInput/ClearableInput';

export type TreeSearchProps = {
    /**
     * The search value to be shown and used for the search.
     */
    value?: string;

    /**
     * Callback for when the search value changes.
     * @param newValue
     * @param event
     * @returns
     */
    onChange?: (newValue: string, event: React.ChangeEvent | React.MouseEvent) => void;

    /**
     * The placeholder text used for the input field.
     */
    placeholder?: string;

    /**
     * Additional classes added to the wrapping element.
     */
    className?: string;
};

const TreeSearch = React.memo((props: TreeSearchProps) => {
    const { value, className, placeholder, onChange = noop } = props;

    const classes = classNames('TreeSearch', className);

    return (
        <div className={classes}>
            <div className='input-group flex-1-0'>
                <span className='input-group-addon'>
                    <span className='rioglyph rioglyph-search' aria-hidden='true' />
                </span>
                <ClearableInput value={value} onChange={onChange} placeholder={placeholder} />
            </div>
        </div>
    );
});

export default TreeSearch;
