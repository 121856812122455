import { debug, isInIframe } from './initConfig';
import { version } from '../../version.json';

const documentHas = (selector: string) => !!document.head.querySelector(selector);
const documentHasAnyOf = (selectors: string[]) => selectors.some(documentHas);

type Brand = (typeof BRANDS)[number];

const BRANDS = ['rio', 'man', 'vw', 'scania', 'traton'] as const;

const isValidBrand = (input: unknown): input is Brand => BRANDS.includes(input as any);

const getBrand = (input: unknown): Brand => (isValidBrand(input) ? input : 'rio');

export const initCSS = () => {
    if (!window.CSS || !window.CSS.supports || !window.CSS.supports('gap: 1px')) {
        document.documentElement.classList.add('css-no-gap');
    }

    if (isInIframe) {
        document.documentElement.classList.add('window-iframe');
    }

    if (document.title?.includes('UIKIT Demo')) {
        return;
    }

    debug('Checking for UIKIT CSS in document head.');

    const hasUikitCssLink = documentHasAnyOf(['link[href*="rio-uikit.css"]', 'link[href*="rio-uikit-core.css"]']);
    const isUikitDemoPage = documentHas('style[data-vite-dev-id*="uikit.less"]');
    const hasUikitBrandCssLink = documentHasAnyOf([
        'link[href*="man-uikit.css"]',
        'link[href*="vw-uikit.css"]',
        'link[href*="scania-uikit.css"]',
        'link[href*="traton-uikit.css"]',
        'link[href*="rio-website.css"]',
    ]);

    if (hasUikitCssLink || isUikitDemoPage || hasUikitBrandCssLink) {
        debug('UIKIT CSS found in document head.');
        return;
    }

    // If nothing is set, add a link tag to fetch the CSS from the UIKIT CDN.
    // Note: there is no way to differentiate between core + responsive or full CSS
    debug('No UIKIT CSS found => append link tag to document head.');

    const brand = getBrand(document.documentElement.dataset.brand);

    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.type = 'text/css';
    link.href = `https://uikit.developers.rio.cloud/${version}/${brand}-uikit.css`;

    document.head.appendChild(link);
};

export const checkCSSVersion = () => {
    const cssLinks = [...document.querySelectorAll('link[rel="stylesheet"]')] as HTMLLinkElement[];
    cssLinks.forEach(cssLink => {
        const { href } = cssLink;

        if (href.includes('uikit.developers.rio.cloud')) {
            const [, uikitVersionCSS] = new URL(href).pathname.split('/');

            if (!href.includes(version)) {
                console.warn(
                    // biome-ignore lint/style/useTemplate: <explanation>
                    'You are using different UIKIT JS and CSS versions.\n' +
                        `Your current JS version is ${version} and ` +
                        `your current CSS version is ${uikitVersionCSS}.\n` +
                        'If this is not on purpose, please fix it. Otherwise, this may lead to UI bugs.'
                );
            }
        } else if (href.includes('cdn.rio.cloud/libs/rio-uikit')) {
            console.error(
                'You are still using an old "cdn.rio.cloud" css import. This will most likely cause major UI bugs! ' +
                    'Please update to the CSS URLs as explained on https://uikit.developers.rio.cloud/#start/howto.'
            );
        }
    });
};

// remote URL is not supported by most browsers yet
// export const addTabsClippingSVG = () => {
//     const clippingSVG = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
//     clippingSVG.setAttribute('width', '0');
//     clippingSVG.setAttribute('height', '0');
//     clippingSVG.setAttribute('id', 'tabs-rounded-clipping-mask');
//     clippingSVG.innerHTML = `
//         <clipPath id='tabs-rounded-clip-mask'>
//             <path fill-rule='evenodd' d='M0 4a4 4 0 0 0 4-4v4z' />
//         </clipPath>`;
//     document.body.appendChild(clippingSVG);
// };
