import { Link } from 'react-router-dom';

import { Playground } from '../../../components/playground/Playground';
import { Description } from '../../../components/Description';
import { Callout } from '../../../components/Callout';

import PagerExample from '../../components/pager/PagerExample';

export const Pager = () => (
    <div className='uikit-activity'>
        <Description headline='Pager' anchor='Pager' />
        <Playground example={<PagerExample />} />
        <Callout>
            See <Link to='/components/pager'>Pager</Link> for the actual React component.
        </Callout>
    </div>
);
