// @ts-ignore-next-line importsNotUsedAsValues
import React from 'react';

import type { ModulePropType } from './ApplicationHeader';

export type AppMenuContentProps = {
    appMenuItems: ModulePropType[];
    appNavigator?: React.ReactNode;
};

const AppMenuContent = (props: AppMenuContentProps) => {
    const { appMenuItems, appNavigator } = props;

    // In case there is a navigation component injected into the header,
    // render this component
    if (appNavigator) {
        return (
            <li role='presentation' key='app-navigator'>
                {appNavigator}
            </li>
        );
    }

    // If there is a list of appMenuItems given, render them as a list
    return (
        <>
            {appMenuItems.map(module => {
                return (
                    <li role='presentation' key={`link-icon-${module.key}`}>
                        {module.route}
                    </li>
                );
            })}
        </>
    );
};

export default AppMenuContent;
