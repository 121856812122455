import React, { useEffect, useRef, type MouseEvent, type TouchEvent, type PropsWithChildren } from 'react';
import classNames from 'classnames';
import noop from 'lodash/fp/noop';

const HORIZONTAL = 'x';
const VERTICAL = 'y';

const LEFT = 'left';
const RIGHT = 'right';
const TOP = 'top';
const BOTTOM = 'bottom';

export type ResizerProps = {
    /**
     * Defines where the resize handle is positioned relative to the element to resize.
     *
     * Possible values are:
     * `Resizer.LEFT`, `Resizer.RIGHT`, `Resizer.TOP`, `Resizer.BOTTOM` or
     * `left`, `right`, `top`, `bottom`.
     */
    position?: typeof LEFT | typeof RIGHT | typeof TOP | typeof BOTTOM;

    /**
     * Defines on which axis to resize.
     *
     * Possible values are:
     * `Resizer.HORIZONTAL`, `Resizer.VERTICAL` or `x`, `y`.
     */
    direction?: typeof HORIZONTAL | typeof VERTICAL;

    /**
     * Callback when the resize starts, means when the handle is pressed. It returns the respective event.
     * @param event
     * @returns
     */
    onResizeStart?: (event: MouseEvent | TouchEvent) => void;

    /**
     * Callback when the resize handle is moved. The function returns the distant between the last
     * position and the mouse movement. Means you can either subtract or add this diff to the elements
     * width you want to change.
     * @param diff
     * @returns
     */
    onResize?: (diff: number) => void;

    /**
     * Callback when the resize ends, means when the handle is released. It returns the respective event.
     * @param event
     * @returns
     */
    onResizeEnd?: (event: Event) => void;

    /**
     * Additional classes to be set on the wrapper element.
     */
    className?: string;
};

type Position = {
    x: number;
    y: number;
};

const createListeners = (
    targetNode: HTMLDivElement | null,
    onEnd: (event: Event) => void,
    onMove: (event: Event) => void
) => {
    if (!targetNode) {
        return;
    }

    // The read-only ownerDocument property of the Node interface returns
    // the top-level document object of the node.
    const ownerDocument = targetNode.ownerDocument;

    ownerDocument.addEventListener('mouseup', onEnd);
    ownerDocument.addEventListener('mousemove', onMove);
    ownerDocument.addEventListener('touchend', onEnd);
    ownerDocument.addEventListener('touchmove', onMove);
    return {
        remove: () => {
            ownerDocument.removeEventListener('mouseup', onEnd);
            ownerDocument.removeEventListener('mousemove', onMove);
            ownerDocument.removeEventListener('touchend', onEnd);
            ownerDocument.removeEventListener('touchmove', onMove);
        },
    };
};

const Resizer = (props: PropsWithChildren<ResizerProps>) => {
    const {
        className = '',
        direction = HORIZONTAL,
        position = RIGHT,
        onResizeStart = noop,
        onResize = noop,
        onResizeEnd = noop,
        children,
        ...remainingProps
    } = props;

    const eventsRef = useRef<{ remove: () => void } | undefined | null>(null);

    // Use refs here instead of local state as the move event listener fired before the state is set
    // and then it would work with outdated state at that time.
    const elementRef = useRef<HTMLDivElement>(null);
    const isDragRef = useRef<boolean>(false);
    const startPositionRef = useRef<Position>({ x: 0, y: 0 });

    useEffect(() => {
        return () => {
            removeListeners();
        };
    }, []);

    const getClientX = (event: Event) => {
        // Note: some browsers don't provide the global "TouchEvent" constructor!
        if (!!window.TouchEvent && event instanceof window.TouchEvent) {
            return event.touches[0].clientX;
        }

        if (event instanceof MouseEvent) {
            return event.clientX;
        }

        throw new Error('Unsupported event type');
    };

    const getClientY = (event: Event) => {
        // Note: some browsers don't provide the global "TouchEvent" constructor!
        if (!!window.TouchEvent && event instanceof window.TouchEvent) {
            return event.touches[0].clientY;
        }

        if (event instanceof MouseEvent) {
            return event.clientY;
        }

        throw new Error('Unsupported event type');
    };

    const handleMouseStart = (event: React.MouseEvent) => {
        isDragRef.current = true;

        // Use the native event to check for the event type. Otherwise the type check
        // fails and the start position would be { x: 0, y: 0 }.
        const mouseEvent = event.nativeEvent;
        const { clientX, clientY } = mouseEvent;
        startPositionRef.current = {
            x: clientX,
            y: clientY,
        };

        onResizeStart(event);

        eventsRef.current = createListeners(elementRef.current, handleEnd, handleMove);
    };

    const handleTouchStart = (event: React.TouchEvent) => {
        isDragRef.current = true;

        // Use the native event to check for the event type. Otherwise the type check
        // fails and the start position would be { x: 0, y: 0 }.
        const touchEvent = event.nativeEvent;
        const firstTouch = touchEvent.touches[0];
        startPositionRef.current = {
            x: firstTouch.clientX,
            y: firstTouch.clientY,
        };

        onResizeStart(event);

        eventsRef.current = createListeners(elementRef.current, handleEnd, handleMove);
    };

    const handleMove = (event: Event) => {
        if (!isDragRef.current) {
            return;
        }

        if (direction === Resizer.HORIZONTAL) {
            const diff = startPositionRef.current.x - getClientX(event);

            if (diff !== 0) {
                onResize(diff);
            }
        }

        if (direction === Resizer.VERTICAL) {
            const diff = startPositionRef.current.y - getClientY(event);
            if (diff !== 0) {
                onResize(diff);
            }
        }

        startPositionRef.current = {
            x: getClientX(event),
            y: getClientY(event),
        };
    };

    const handleEnd = (event: Event) => {
        isDragRef.current = false;
        onResizeEnd(event);
        removeListeners();
    };

    const removeListeners = () => {
        if (eventsRef.current) {
            eventsRef.current.remove();
        }
    };

    const wrapperClasses = classNames(
        'Resizer',
        direction === 'x' && 'resize-horizontal',
        direction === 'y' && 'resize-vertical',
        position === 'left' && 'resize-left',
        position === 'right' && 'resize-right',
        position === 'top' && 'resize-top',
        position === 'bottom' && 'resize-bottom',
        className && className
    );

    return (
        <div
            ref={elementRef}
            className={wrapperClasses}
            onMouseDown={handleMouseStart}
            onTouchStart={handleTouchStart}
            {...remainingProps}
        >
            {children}
        </div>
    );
};

Resizer.HORIZONTAL = HORIZONTAL as typeof HORIZONTAL;
Resizer.VERTICAL = VERTICAL as typeof VERTICAL;

Resizer.LEFT = LEFT as typeof LEFT;
Resizer.RIGHT = RIGHT as typeof RIGHT;
Resizer.TOP = TOP as typeof TOP;
Resizer.BOTTOM = BOTTOM as typeof BOTTOM;

export default Resizer;
