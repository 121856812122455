// @ts-ignore-next-line importsNotUsedAsValues
import React, { type PropsWithChildren } from 'react';
import classNames from 'classnames';

export type DividerProps = {
    /**
     * The amount of space to either side
     *
     * @default 15
     */
    spacing?: number;

    /**
     * Defines the width of the divider line
     *
     * @default 1
     */
    dividerWidth?: 1 | 2 | 3;

    /**
     * Defines the color of the divider line
     *
     * @default 'light'
     */
    dividerColor?: 'darkest' | 'darker' | 'dark' | 'gray' | 'light' | 'lighter' | 'lightest';

    /**
     * Use the component as a spacer element without the divider line
     *
     * @default false
     */
    asSpacer?: boolean;

    /**
     * Set the orientation for the divider line to vertical.
     * This may be useful for button toolbars or elements on a single row.
     *
     * @default false
     */
    vertical?: boolean;

    /**
     * Defines where the children are aligned on the divider line
     *
     * @default 'center'
     */
    titleAlignment?: 'left' | 'center' | 'right';

    /**
     * Additional classes added to the wrapping element.
     */
    className?: string;
};

const Divider = (props: PropsWithChildren<DividerProps>) => {
    const {
        spacing = 15,
        dividerWidth = 1,
        dividerColor = 'light',
        asSpacer = false,
        vertical = false,
        titleAlignment = 'center',
        className = '',
        children,
    } = props;

    const classes = classNames('Divider', 'position-relative', !vertical && 'width-100pct', className);

    const spacingValue = `${spacing}px`;
    const wrapperStyle: React.CSSProperties = {};

    if (vertical) {
        wrapperStyle.paddingLeft = spacingValue;
        wrapperStyle.paddingRight = spacingValue;
    }

    if (!vertical) {
        wrapperStyle.paddingTop = spacingValue;
        wrapperStyle.paddingBottom = spacingValue;
    }

    const dividerLineClasses = classNames('divider-line', `bg-${dividerColor}`);

    const isStringTitle = typeof children === 'string';

    const line = (
        <div
            className={dividerLineClasses}
            style={{
                width: vertical ? `${dividerWidth}px` : '100%',
                height: vertical ? '100%' : `${dividerWidth}px`,
            }}
        />
    );

    const titleLine = <div className='flex-1-0'>{line}</div>;
    const title = <div className={isStringTitle ? 'padding-x-10' : ''}>{children}</div>;

    const dividerTitleClasses = classNames('divider-title', 'display-flex', 'align-items-center');

    return (
        <div className={classes} style={wrapperStyle}>
            {!asSpacer && !children && line}
            {children && (
                <div className={dividerTitleClasses}>
                    {titleAlignment === 'left' && (
                        <>
                            {title}
                            {titleLine}
                        </>
                    )}
                    {titleAlignment === 'center' && (
                        <>
                            {titleLine}
                            {title}
                            {titleLine}
                        </>
                    )}
                    {titleAlignment === 'right' && (
                        <>
                            {titleLine}
                            {title}
                        </>
                    )}
                </div>
            )}
        </div>
    );
};

export default Divider;
