import classNames from 'classnames';

import { Tooltip, OverlayTrigger } from '../../index';

const isVisible = (prop: { visible: boolean }) => prop.visible;

type TextStyleProps = {
    tooltip: string;
    color: string;
    background: string;
    border: boolean;
};

const TextStyle = (props: TextStyleProps) => {
    const { tooltip, color, background, border } = props;

    const classes = classNames(
        color,
        background,
        border && 'border border-color-lighter',
        'width-15 height-15 text-size-10 line-height-10',
        'rounded-circle cursor-default',
        'display-flex justify-content-center align-items-center'
    );

    return (
        <OverlayTrigger placement='top' overlay={<Tooltip width='auto'>{tooltip}</Tooltip>}>
            <span className={classes}>T</span>
        </OverlayTrigger>
    );
};

type ElementStyleProps = {
    tooltip: string;
    background: string;
    border: boolean;
};

const ElementStyle = (props: ElementStyleProps) => {
    const { tooltip, background, border } = props;

    const classes = classNames(
        background,
        border && 'border border-color-lighter',
        'rounded-circle width-15 height-15'
    );

    return (
        <OverlayTrigger placement='top' overlay={<Tooltip width='auto'>{tooltip}</Tooltip>}>
            <span className={classes} />
        </OverlayTrigger>
    );
};

type PlaygroundColorsProps = {
    textWhite?: boolean;
    textBlack?: boolean;
    textLightest?: boolean;
    textLighter?: boolean;
    textLight?: boolean;
    text?: boolean;
    textGray?: boolean;
    textDark?: boolean;
    textDarker?: boolean;
    textDarkest?: boolean;
    bgDefault?: boolean;
    bgPrimary?: boolean;
    bgSecondary?: boolean;
    bgInfo?: boolean;
    bgSuccess?: boolean;
    bgWarning?: boolean;
    bgDanger?: boolean;
    bgMuted?: boolean;
};

export const PlaygroundColors = (props: PlaygroundColorsProps) => {
    const {
        textLightest = false,
        textLighter = false,
        textLight = false,
        textGray = false,
        textDark = false,
        textDarker = false,
        textDarkest = false,
        textWhite = false,
        textBlack = false,
        bgDefault = false,
        bgPrimary = false,
        bgSecondary = false,
        bgInfo = false,
        bgSuccess = false,
        bgWarning = false,
        bgDanger = false,
        bgMuted = false,
    } = props;

    const textStyles = [
        {
            visible: textLightest,
            tooltip: 'Lightest',
            color: 'text-color-lightest',
            background: 'bg-white',
        },
        {
            visible: textLighter,
            tooltip: 'Lighter',
            color: 'text-color-lighter',
            background: 'bg-white',
        },
        {
            visible: textLight,
            tooltip: 'Light',
            color: 'text-color-light',
            background: 'bg-white',
        },
        {
            visible: textGray,
            tooltip: 'Gray',
            color: 'text-color-gray',
            background: 'bg-white',
        },
        {
            visible: textDark,
            tooltip: 'Dark',
            color: 'text-color-dark',
            background: 'bg-white',
        },
        {
            visible: textDarker,
            tooltip: 'Darker',
            color: 'text-color-darker',
            background: 'bg-white',
        },
        {
            visible: textDarkest,
            tooltip: 'Darkest',
            color: 'text-color-darkest',
            background: 'bg-white',
        },
        {
            visible: textBlack,
            tooltip: 'Black',
            color: 'text-color-black',
            background: 'bg-white',
        },
        {
            visible: textWhite,
            tooltip: 'White',
            color: 'text-color-white',
            background: 'bg-black',
        },
    ];

    const elementStyles = [
        {
            visible: bgDefault,
            tooltip: 'Default',
            background: 'bg-gray',
        },
        {
            visible: bgPrimary,
            tooltip: 'Primary',
            background: 'bg-primary',
        },
        {
            visible: bgSecondary,
            tooltip: 'Secondary',
            background: 'bg-secondary',
        },
        {
            visible: bgInfo,
            tooltip: 'Info',
            background: 'bg-info',
        },
        {
            visible: bgSuccess,
            tooltip: 'Success',
            background: 'bg-success',
        },
        {
            visible: bgWarning,
            tooltip: 'Warning',
            background: 'bg-warning',
        },
        {
            visible: bgDanger,
            tooltip: 'Danger',
            background: 'bg-danger',
        },
        {
            visible: bgMuted,
            tooltip: 'Muted',
            background: 'bg-lightest',
        },
    ];

    return (
        <div className='display-flex column-gap-5'>
            {textStyles.filter(isVisible).map(({ tooltip, color, background }, index) => (
                <TextStyle
                    tooltip={tooltip}
                    color={color}
                    background={background}
                    border={background !== 'bg-black'}
                    key={index}
                />
            ))}

            {elementStyles.filter(isVisible).map(({ tooltip, background }, index) => (
                <ElementStyle
                    tooltip={tooltip}
                    background={background}
                    border={background === 'bg-lightest'}
                    key={index}
                />
            ))}
        </div>
    );
};
