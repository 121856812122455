// @ts-ignore-next-line: importsNotUsedAsValues
import React, { type HTMLAttributes } from 'react';
import classNames from 'classnames';

export type ActionBarItemListSeparatorProps = HTMLAttributes<HTMLHRElement> & {
    /**
     * Additional class names that are added to the wrapping list item component.
     */
    className?: string;
};

const ActionBarItemListSeparator = ({ className, ...remainingProps }: ActionBarItemListSeparatorProps) => {
    const classes = classNames('ActionBarItemListSeparator', className);
    return <hr {...remainingProps} className={classes} />;
};

export default ActionBarItemListSeparator;
