// @ts-ignore-next-line importsNotUsedAsValues
import React, { type PropsWithChildren } from 'react';

import CustomState from './CustomState';
import type { BaseStateProps } from './BaseStateProps';

export type NotFoundStateProps = BaseStateProps;

const NotFoundState = (props: PropsWithChildren<NotFoundStateProps>) => {
    const { icon = 'rioglyph-looking-glass-man', ...remainingProps } = props;
    return <CustomState icon={icon} {...remainingProps} />;
};

export default NotFoundState;
