import React, { forwardRef, type ForwardedRef } from 'react';
import isEmpty from 'lodash/fp/isEmpty';

import type { ModulePropType } from './ApplicationHeader';

type CollapsedNavItemProps = {
    show: boolean;
    navItems: ModulePropType[];
    actionBarItems: React.ReactNode[];
    onDropdownClick: () => void;
    onItemClick: () => void;
};

type Ref = ForwardedRef<HTMLLIElement>;

const CollapsedNavItem = forwardRef((props: CollapsedNavItemProps, ref: Ref) => {
    const { show = false, navItems, actionBarItems, onDropdownClick, onItemClick } = props;

    if (isEmpty(navItems) && isEmpty(actionBarItems)) {
        return null;
    }

    const collapsedDropdownClass = show ? 'open' : '';

    return (
        <li className={`CollapsedDropdown dropdown ${collapsedDropdownClass}`} key='collapsed-dropdown' ref={ref}>
            <a
                id='basic-nav-dropdown'
                role='button'
                className='dropdown-toggle text-color-gray'
                aria-haspopup='true'
                aria-expanded='true'
                onClick={onDropdownClick}
            >
                <span className='rioglyph rioglyph-option-horizontal' aria-hidden='true' />
            </a>

            <ul className='dropdown-menu' role='menu' aria-labelledby='basic-nav-dropdown'>
                {navItems.map(navItem => {
                    return (
                        <li
                            key={navItem.key}
                            className='submodule'
                            onClick={onItemClick}
                            data-nav-item-key={navItem.key}
                        >
                            {navItem.route}
                        </li>
                    );
                })}
            </ul>
        </li>
    );
});

export default CollapsedNavItem;
