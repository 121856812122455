import React from 'react';
import classNames from 'classnames';
import noop from 'lodash/fp/noop';

import Button from '../button/Button';
import SimpleTooltip from '../tooltip/SimpleTooltip';

export type SidebarFullscreenToggleProps = {
    isFullscreen?: boolean;
    tooltip?: string;
    onClick?: VoidFunction;
};

const SidebarFullscreenToggle = ({ isFullscreen, tooltip, onClick = noop }: SidebarFullscreenToggleProps) => {
    const fullscreenIconClasses = classNames(
        'rioglyph',
        isFullscreen ? 'rioglyph-resize-small' : 'rioglyph-resize-full'
    );

    const button = (
        <Button bsStyle={Button.MUTED} iconOnly onClick={onClick} data-testid='sidebarFullscreenToggle'>
            <span className={fullscreenIconClasses} />
        </Button>
    );

    if (tooltip) {
        return (
            <SimpleTooltip content={tooltip} placement='bottom'>
                {button}
            </SimpleTooltip>
        );
    }

    return button;
};

export default SidebarFullscreenToggle;
