import React, {
    forwardRef,
    type ForwardRefExoticComponent,
    type RefAttributes,
    type PropsWithChildren,
    type ReactNode,
    type ComponentProps,
    isValidElement,
    useState,
    useEffect,
} from 'react';
import { AnimatePresence, motion } from 'framer-motion';

import { PLACEMENT } from '../../values/Placement';

export const PLACEMENT_MAP = {
    AUTO: PLACEMENT.AUTO,
    TOP: PLACEMENT.TOP,
    RIGHT: PLACEMENT.RIGHT,
    BOTTOM: PLACEMENT.BOTTOM,
    LEFT: PLACEMENT.LEFT,
} as const;

export type PopoverProps = Omit<ComponentProps<'div'>, 'title'> & {
    /**
     * HTML ID attribute, necessary for accessibility.
     */
    id: string;

    /**
     * Sets the direction the Popover is positioned towards.
     *
     * This is generally provided by the OverlayTrigger component positioning the Popover.
     *
     * Possible values are:
     * - `auto`
     * - `top`
     * - `right`
     * - `bottom`
     * - `left`
     */
    placement?: 'auto' | 'top' | 'right' | 'bottom' | 'left';

    /**
     * Additional props assigned to the arrow element. Internally used.
     */
    arrowProps?: Record<string, unknown>;

    /**
     * Any element to be rendered as the title of the Popover.
     *
     * It creates a `Popover.Title` inside the `Popover` passing the title directly into it.
     */
    title?: string | ReactNode;

    /**
     * Additional classes to be set on the `Popover.Title` element.
     */
    titleClassName?: string;

    /**
     * Additional classes to be set on the `Popover.Content` element.
     */
    contentClassName?: string;

    /**
     * Additional class names for the wrapper element.
     */
    className?: string;
};

const PopoverTitle = ({ className = '', children }: PropsWithChildren<{ className?: string }>) => (
    <div className={`popover-header popover-title ${className}`}>{children}</div>
);

const PopoverContent = ({ className = '', children }: PropsWithChildren<{ className?: string }>) => (
    <div className={`popover-content popover-body ${className}`}>{children}</div>
);

const isPopoverTitleOrContent = (element: ReactNode): boolean => {
    return isValidElement(element) && (element.type === PopoverTitle || element.type === PopoverContent);
};

type PopoverType = ForwardRefExoticComponent<PropsWithChildren<PopoverProps> & RefAttributes<HTMLDivElement>> & {
    Title: typeof PopoverTitle;
    Content: typeof PopoverContent;
};

const Popover = forwardRef<HTMLDivElement, PropsWithChildren<PopoverProps>>((props, ref) => {
    const {
        placement = 'bottom',
        arrowProps,
        title,
        titleClassName = '',
        contentClassName = '',
        children,
        className = '',
        ...remainingProps
    } = props;

    const hasTitle = !!title;
    const hasCustomContent = React.Children.toArray(children).some(isPopoverTitleOrContent);

    // Mount the component but don't show it yet via CSS.
    // After it is mounted, we set the visibility class to use CSS animation
    const [isMounted, setIsMounted] = useState(false);
    useEffect(() => setIsMounted(true), []);

    return (
        <div
            {...remainingProps}
            role='tooltip'
            // x-placement is used by the css to define how to position the arrow.
            // eslint-disable-next-line react/no-unknown-property
            x-placement={placement}
            ref={ref}
            className={`popover fade ${isMounted ? 'show' : ''} ${className}`}
        >
            <div className='arrow' {...arrowProps} />
            {hasTitle && <PopoverTitle className={titleClassName}>{title}</PopoverTitle>}
            {hasCustomContent ? children : <PopoverContent className={contentClassName}>{children}</PopoverContent>}
        </div>
    );
}) as PopoverType;

Popover.Title = PopoverTitle;
Popover.Content = PopoverContent;

Object.assign(Popover, PLACEMENT_MAP);

export default Popover;
