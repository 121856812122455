// @ts-ignore-next-line importsNotUsedAsValues
import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import isEqual from 'lodash/fp/isEqual';
import isEmpty from 'lodash/fp/isEmpty';
import mapValues from 'lodash/fp/mapValues';
import some from 'lodash/fp/some';
import noop from 'lodash/fp/noop';
import { arrayMove } from '@dnd-kit/sortable';

import type { DragEndEvent } from '@dnd-kit/core';

import Dialog from '../dialog/Dialog';
import ClearableInput from '../clearableInput/ClearableInput';
import { TableSettingsDialogFooter } from './TableSettingsDialogFooter';
import { TableSettingsListContainer } from './TableSettingsListContainer';
import { filterColumns } from './TableSettingsListItem';
import type { TableColumnDetailsMap, ColumnLabelStrings, TableColumnDetails } from './TableSettingsDialog.types';

const DEFAULT_COLUMN_WIDTH = 0;
const MAX_COLUMN_WIDTH = 1000;

type TableSettingsDialogProps = {
    /**
     * Defined whether to show the dialog or not.
     *
     * @default false
     */
    show: boolean;

    /**
     * The title for the dialog header.
     */
    title: React.ReactNode;

    /**
     * The subtitle for the dialog header.
     */
    subtitle?: React.ReactNode;

    /**
     * List of column names in default order. This will be used for resetting changes.
     *
     * @default []
     */
    defaultColumnOrder: string[];

    /**
     * List of hidden columns that are hidden by default. This will be used for resetting changes.
     *
     * @default []
     */
    defaultHiddenColumns?: string[];

    /**
     * List of column names in current order. The "columnOrder" will be returned when the order changes.
     *
     * @default []
     */
    columnOrder: string[];

    /**
     * List of column names which are currently hidden. The "hiddenColumns
     * will be returned when the order changes.
     *
     * @default []
     */
    hiddenColumns?: string[];

    /**
     * List of column labels which will be shown in the table header. These labels are usually
     * translated labels that can be set as FormattedMessage components for instance.
     *
     * The object key is the column name and the object value is the translated message.
     *
     * @default {}
     * @example
     * { name: <FormattedMessage id='name' /> }
     */
    columnLabels: any;

    /**
     * Hide a sorted column will result in an error, so disable at least one important fallback column
     * or the sorted column (fallback column recommended)
     */
    disabledColumns: string[];

    /**
     * Optional object of detail properties to be shown in the details section of the respective
     * column item. If the prop is not given, the width sections won't be rendered.
     * The keys of this object are the column names / keys you use to identify a column.
     */
    columnsDetails?: TableColumnDetailsMap;

    autoLabel?: string | React.ReactNode;

    /**
     * Text for the "apply" button. This button will not be shown when
     * `immediateChange` is enabled.
     */
    applyButtonText?: string | React.ReactNode;

    /**
     * Text for the "cancel" button. This button will not be shown when
     * `immediateChange` is enabled.
     */
    cancelButtonText?: string | React.ReactNode;

    /**
     * Text for the "close" button. This button will only be shown when
     * `immediateChange` is enabled.
     */
    closeButtonText?: string | React.ReactNode;

    /**
     * Text for the "reset to default" button.
     */
    resetButtonText: string | React.ReactNode;

    /**
     * Callback function for when the column order or visibility changes.
     *
     * @param columnOrder
     * @param hiddenColumns
     * @param columnsDetails
     * @returns
     */
    onColumnChange?: (columnOrder: string[], hiddenColumns: string[], columnsDetails?: TableColumnDetailsMap) => void;

    /**
     * Callback function for when a single column details like width changes.
     *
     * @param column
     * @param columnsDetails
     * @returns
     */
    onColumnDetailsChange?: (column: string, columnsDetails: TableColumnDetails) => void;

    /**
     * Callback function for when the changes are discarded and the dialog should close.
     * Will not be triggered when `immediateChange` is enabled.
     *
     * @returns
     */
    onDiscard?: () => void;

    /**
     * Callback function for when the final changes should be applied and the dialog should close.
     * Will not be triggered when `immediateChange `is enabled.
     *
     * @param columnOrder
     * @param hiddenColumns
     * @param columnsDetails
     * @returns
     */
    onApply?: (columnOrder: string[], hiddenColumns: string[], columnsDetails: TableColumnDetailsMap) => void;

    /**
     * Callback function for when dialog should close.
     *
     * @returns
     */
    onHide: () => void;

    /**
     * Search value which should be set for the search field when the dialog opens.
     */
    columnSearchValue?: string;

    /**
     * Callback function for when the search value changes.
     *
     * @param value
     * @returns
     */
    onSearchChange?: (value: string) => void;

    /**
     * Placeholder text for the search input.
     */
    searchPlaceholder: React.ReactNode;

    /**
     * Message that should be shown when column search result is empty.
     */
    notFoundMessage?: string;

    /**
     * Defines whether or not all changes apply immediately. If so, no cancel and apply buttons are shown.
     * Enable this if you want to update the table after each change. Be aware of having side effects when
     * toggling columns where data need to be fetched from the backend.
     *
     * @default false
     */
    immediateChange?: boolean;

    /**
     * Optional class names for the wrapper element.
     */
    className?: string;
};

const TableSettingsDialog = (props: TableSettingsDialogProps) => {
    const {
        show = false,
        title,
        subtitle,
        className,
        defaultColumnOrder = [],
        defaultHiddenColumns = [],
        columnOrder: extColumnOrder = [],
        hiddenColumns: extHiddenColumns = [],
        columnLabels = {},
        disabledColumns = [],
        columnsDetails: extColumnsDetails = {},
        autoLabel = '',
        applyButtonText,
        cancelButtonText,
        closeButtonText,
        resetButtonText,
        onColumnChange = noop,
        onColumnDetailsChange = noop,
        onDiscard = noop,
        // onCancel = noop,
        onApply = noop,
        onHide,
        columnSearchValue: extColumnSearchValue = '',
        onSearchChange = noop,
        searchPlaceholder,
        notFoundMessage = '',
        immediateChange = false,
    } = props;

    const [columnSearchValue, setColumnSearchValue] = useState(extColumnSearchValue);
    const [columnOrder, setColumnOrder] = useState(extColumnOrder);
    const [hiddenColumns, setHiddenColumns] = useState(extHiddenColumns || defaultHiddenColumns);

    const [columnsDetails, setColumnsDetails] = useState(extColumnsDetails);
    const [openColumnsDetails, setOpenColumnsDetails] = useState<Record<string, string>>({});

    const [columnLabelStrings, setColumnLabelStrings] = useState<ColumnLabelStrings>({});
    const [updateColumnLabelStrings, setUpdateColumnLabelStrings] = useState(true);

    // Dirty flag for offering to reset changes or to discard them
    const [hasChanged, setHasChanged] = useState(false);
    const [isResetAll, setIsResetAll] = useState(false);

    const [movedColumn, setMovedColumn] = useState(false);

    const contentRef = useRef<HTMLDivElement>(null);

    // Update items from outside
    useEffect(() => {
        setColumnSearchValue(extColumnSearchValue);
        setColumnOrder(extColumnOrder);
        setHiddenColumns(extHiddenColumns);

        if (show) {
            getColumnLabelStringsFromDOM();
        }
    }, [extColumnSearchValue, extColumnOrder, extHiddenColumns, show]);

    const hasColumnsDetailsChanged = (columnsDetailsToCheck: TableColumnDetailsMap) => {
        if (isEmpty(columnsDetailsToCheck)) {
            return false;
        }

        const hasObjectChanged = some((details: TableColumnDetails) => {
            const defaultWidth = Number.isFinite(details.defaultWidth) ? details.defaultWidth : DEFAULT_COLUMN_WIDTH;
            return details.width !== defaultWidth;
        })(columnsDetailsToCheck);

        return hasObjectChanged;
    };

    // Update column details from outside if provided. Note, that in "sort columns only" mode
    // the columns details are undefined and no column widths can be set
    const [previousColumnDetails, setPreviousColumnDetails] = useState(extColumnsDetails);
    if (!isEqual(columnsDetails, previousColumnDetails)) {
        const columnsDetailsChanged = hasColumnsDetailsChanged(extColumnsDetails);
        setColumnsDetails(columnsDetailsChanged ? extColumnsDetails : columnsDetails);
        setPreviousColumnDetails(extColumnsDetails);
    }

    const getColumnLabelStringsFromDOM = () => {
        if (!contentRef.current) {
            return;
        }

        // For searching by name we need to get the label from the DOM as it may contain a FormattedMessage
        const labels = contentRef.current.getElementsByClassName('table-settings-item-label');

        const columnStrings: { [key: string]: string } = {};
        [...labels].map(label => {
            const dataKey = label.getAttribute('data-key');
            if (dataKey) {
                const updatedLabel = label.textContent?.replace(/\r?\n|\r/g, '').toLowerCase();
                if (updatedLabel) {
                    columnStrings[dataKey] = updatedLabel;
                }
            }
        });

        setColumnLabelStrings(columnStrings);
        setUpdateColumnLabelStrings(false);
    };

    const deleteMovedColumn = () => {
        setMovedColumn(false);
    };

    const moveColumnToIndex = (columnName: string, newIndex: number, changeMovedColumn: boolean) => {
        const newColumnOrder = columnOrder.filter(name => name !== columnName);
        newColumnOrder.splice(newIndex, 0, columnName);

        setColumnOrder(newColumnOrder);
        setMovedColumn(changeMovedColumn ? !!columnName : false);
        setHasChanged(true);

        if (immediateChange) {
            onColumnChange(newColumnOrder, hiddenColumns);
        }

        window.setTimeout(deleteMovedColumn, 500);
    };

    const handleResetColumnChanges = () => setIsResetAll(true);
    const handleCancelResetColumnChanges = () => setIsResetAll(false);

    const resetColumnsDetails = (details: TableColumnDetailsMap) => {
        return mapValues((singleColumnDetails: TableColumnDetails) => {
            return {
                ...singleColumnDetails,
                width: singleColumnDetails.defaultWidth || DEFAULT_COLUMN_WIDTH,
            };
        })(details);
    };

    const resetAllColumnChanges = () => {
        const defaultColumnsDetails = resetColumnsDetails(columnsDetails);

        setColumnOrder(defaultColumnOrder);
        setHiddenColumns(defaultHiddenColumns);
        setColumnSearchValue('');
        setHasChanged(false);
        setIsResetAll(false);

        if (!isEmpty(columnsDetails)) {
            setColumnsDetails(defaultColumnsDetails);
        }

        if (immediateChange) {
            onSearchChange('');
            onColumnChange(defaultColumnOrder, defaultHiddenColumns, defaultColumnsDetails);
        }
    };

    const discardColumnChanges = () => {
        onSearchChange('');
        // onCancel();
        onDiscard();
        onHide();
    };

    const handleManuallyApplyChanges = () => {
        setColumnSearchValue('');

        onSearchChange('');
        onColumnChange(columnOrder, hiddenColumns, columnsDetails);
        onApply(columnOrder, hiddenColumns, columnsDetails);
        onHide();
    };

    const toggleHideColumn = (column: string) => {
        const isHidden = hiddenColumns.includes(column);
        const newHiddenColumns = isHidden ? hiddenColumns.filter(name => name !== column) : [...hiddenColumns, column];

        setHiddenColumns(newHiddenColumns);
        setHasChanged(true);

        if (immediateChange) {
            onColumnChange(columnOrder, newHiddenColumns);
        }
    };

    const handleSearchChange = (searchValue: string) => {
        const newSearch = searchValue.toLowerCase();

        setColumnSearchValue(() => {
            onSearchChange(newSearch);
            return newSearch;
        });
    };

    const handleColumnWidthChange = (column: keyof TableColumnDetailsMap, value: number) => {
        if (columnsDetails[column]) {
            columnsDetails[column].width = value;
        } else {
            columnsDetails[column] = {
                width: value,
                defaultWidth: 0,
                maxWidth: MAX_COLUMN_WIDTH,
            };
        }

        setColumnsDetails(columnsDetails);
        setHasChanged(true);

        if (immediateChange) {
            onColumnDetailsChange(column, columnsDetails[column]);
        }
    };

    const handleResetColumnWidth = (column: keyof TableColumnDetailsMap) => {
        const updatedColumnDetails = columnsDetails[column];
        updatedColumnDetails.width = updatedColumnDetails.defaultWidth;

        setColumnsDetails(columnsDetails);

        if (immediateChange) {
            onColumnDetailsChange(column, columnsDetails[column]);
        }
    };

    const handleOpenColumnsDetails = (columnName: keyof TableColumnDetailsMap) => {
        const updatedOpenColumnDetails = { ...openColumnsDetails };

        if (updatedOpenColumnDetails[columnName]) {
            delete updatedOpenColumnDetails[columnName];
        } else {
            updatedOpenColumnDetails[columnName] = columnName;
        }

        setOpenColumnsDetails(updatedOpenColumnDetails);
    };

    const handleSortEnd = (event: DragEndEvent, previousOrder: string[]) => {
        const { active, over } = event;

        const activeId = active.id;
        const overId = over?.id;

        if (activeId === overId) {
            return;
        }

        const oldIndex = previousOrder.indexOf(String(activeId));
        const newIndex = previousOrder.indexOf(String(overId));

        const newColumnOrder = arrayMove(previousOrder, oldIndex, newIndex);

        setColumnOrder(newColumnOrder);
        setMovedColumn(true);
        setHasChanged(true);

        if (immediateChange) {
            onColumnChange(newColumnOrder, hiddenColumns);
        }
    };

    const renderTableSettingsDialogContent = () => {
        const itemProps = {
            columnLabels,
            autoLabel,
            disabledColumns,
            columnOrder,
            hiddenColumns,
            columnSearchValue,
            columnsDetails,
            columnLabelStrings,
            openColumnsDetails,
            updateColumnLabelStrings,
            onMoveColumn: moveColumnToIndex,
            onOpenDetails: handleOpenColumnsDetails,
            onColumnWidthChange: handleColumnWidthChange,
            onResetColumnWidth: handleResetColumnWidth,
            onToggleHideColumn: toggleHideColumn,
        };

        const filteredColumns = columnOrder.filter(column =>
            filterColumns(columnSearchValue, column, columnLabelStrings)
        );

        const hasItems = !isEqual(filteredColumns, columnOrder);

        return (
            <div ref={contentRef}>
                <div className='table-settings-search'>
                    <div className='input-group width-100pct'>
                        <span className='input-group-addon'>
                            <span className='rioglyph rioglyph-search' />
                        </span>
                        <ClearableInput
                            value={columnSearchValue}
                            onChange={handleSearchChange}
                            placeholder={searchPlaceholder}
                        />
                    </div>
                </div>
                <div className='table-settings-body'>
                    {hasItems ? (
                        <TableSettingsListContainer
                            items={columnOrder}
                            onSortEnd={handleSortEnd}
                            itemProps={{ ...itemProps }}
                        />
                    ) : (
                        <div className='text-center text-color-gray'>{notFoundMessage}</div>
                    )}
                </div>
            </div>
        );
    };

    const renderTableSettingsDialogFooter = () => {
        return (
            <TableSettingsDialogFooter
                hasChanged={hasChanged}
                isResetAll={isResetAll}
                immediateChange={immediateChange}
                resetButtonText={resetButtonText}
                closeButtonText={closeButtonText}
                cancelButtonText={cancelButtonText}
                applyButtonText={applyButtonText}
                onHide={onHide}
                onResetColumnChanges={handleResetColumnChanges}
                onDiscardChanges={discardColumnChanges}
                onApplyChanges={handleManuallyApplyChanges}
                onConfirmResetColumnChanges={resetAllColumnChanges}
                onCancelResetColumnChanges={handleCancelResetColumnChanges}
            />
        );
    };

    if (!show) {
        return null;
    }

    const dialogClassNames = classNames('TableSettingsDialog', className);

    return (
        <Dialog
            show={show}
            title={title}
            subtitle={subtitle}
            onClose={onHide}
            body={renderTableSettingsDialogContent()}
            footer={renderTableSettingsDialogFooter()}
            className={dialogClassNames}
        />
    );
};

export default TableSettingsDialog;
