import React, { useState } from 'react';
import classNames from 'classnames';
import noop from 'lodash/noop';

import Select from '../selects/Select';
import ToggleButton from '../button/ToggleButton';

type TableCardsSortingOption = {
    /**
     * ID to use for the select option.
     */
    id: string;

    /**
     * Translated column label to be shown in the select.
     */
    label: string | React.ReactNode;

    /**
     * Defines the column which is currently sorted by.
     */
    selected?: boolean;

    /**
     * Defines if the column can be sorted.
     *
     * Hence, it will be disabled in the select.
     */
    disabled?: boolean;
};

export type TableCardsSortingProps = {
    /**
     * "Name", "Key", or "Id" of the current sorted column.
     */
    sortName: string;

    /**
     * Defines the sort direction.
     *
     * @default 'asc'
     */
    sortOrder: 'asc' | 'desc';

    /**
     * List of objects for the column name select component.
     */
    selectOptions: TableCardsSortingOption[];

    /**
     * Function that is called when the sort key or direction changes.
     *
     * @default noop
     */
    onSortChange?: (name: string, order: 'asc' | 'desc') => void;

    /**
     * Optional class names for the wrapper element.
     */
    className?: string;
};

const TableCardsSorting = (props: TableCardsSortingProps) => {
    const {
        sortName: controlledSortName,
        sortOrder: controlledSortOrder = 'asc',
        selectOptions,
        onSortChange = noop,
        className,
    } = props;

    const [sortName, setSortName] = useState(controlledSortName);
    const [sortOrder, setSortOrder] = useState(controlledSortOrder);

    const updateSortOrder = (newSortOrder: 'asc' | 'desc') => {
        setSortOrder(newSortOrder);
        onSortChange(sortName, newSortOrder);
    };

    const updateSortName = (newSortName: string) => {
        setSortName(newSortName);
        onSortChange(newSortName, sortOrder);
    };

    const wrapperClassNames = classNames('table-toolbar-cards-sorting', 'btn-toolbar', className && className);

    return (
        <div className={wrapperClassNames}>
            <div className='btn-group margin-left-0'>
                <ToggleButton
                    className='btn-icon-only'
                    active={sortOrder === 'asc'}
                    onClick={() => updateSortOrder('asc')}
                >
                    <span className='rioglyph rioglyph-sort-by-attributes-alt' />
                </ToggleButton>

                <ToggleButton
                    className='btn-icon-only'
                    active={sortOrder === 'desc'}
                    onClick={() => updateSortOrder('desc')}
                >
                    <span className='rioglyph rioglyph-sort-by-attributes' />
                </ToggleButton>
            </div>

            {selectOptions.length && (
                <div className='btn-group'>
                    <Select options={selectOptions} onChange={item => item && updateSortName(item.id)} />
                </div>
            )}
        </div>
    );
};

export default TableCardsSorting;
