import { Playground } from '../../../components/playground/Playground';
import { Description } from '../../../components/Description';
import { Title } from '../../../components/Title';
import { dummyTextUltraShort } from '../../../utils/data';

export const Lists = () => (
    <div>
        <Description headline='Lists' anchor='lists' />
        <Playground example={listsExample} />
    </div>
);

const listsExample = (
    <div className='display-grid grid-cols-1 grid-cols-3-sm gap-50'>
        <div>
            <Title>Ordered list</Title>
            <ul>
                {[...Array(3)].map(() => (
                    <li key={crypto.randomUUID()}>{dummyTextUltraShort}</li>
                ))}
                <ul>
                    {[...Array(3)].map(() => (
                        <li key={crypto.randomUUID()}>{dummyTextUltraShort}</li>
                    ))}
                </ul>
            </ul>
        </div>

        <div>
            <Title>Unordered list</Title>
            <ol>
                {[...Array(3)].map(() => (
                    <li key={crypto.randomUUID()}>{dummyTextUltraShort}</li>
                ))}
                <ol>
                    {[...Array(3)].map(() => (
                        <li key={crypto.randomUUID()}>{dummyTextUltraShort}</li>
                    ))}
                </ol>
            </ol>
        </div>

        <div>
            <Title>Square list</Title>
            <ul className='square-list'>
                {[...Array(3)].map(() => (
                    <li key={crypto.randomUUID()}>{dummyTextUltraShort}</li>
                ))}
            </ul>
        </div>
    </div>
);
