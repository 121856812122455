import React, { forwardRef, type PropsWithChildren, type HTMLProps } from 'react';
import classNames from 'classnames';

type MenuItemListProps = {
    pullRight?: boolean;
    className?: string;
} & HTMLProps<HTMLUListElement>;

const MenuItemList = forwardRef<HTMLUListElement, PropsWithChildren<MenuItemListProps>>((props, ref) => {
    const { pullRight, className, children, ...remainingProps } = props;

    const dropdownMenuClasses = classNames('dropdown-menu', pullRight && 'pull-right', className);

    return (
        <ul {...remainingProps} className={dropdownMenuClasses} ref={ref} role='menu'>
            {children}
        </ul>
    );
});

export default MenuItemList;
