import React from 'react';
import classNames from 'classnames';

import Checkbox from '../checkbox/Checkbox';
import type { GroupedItem } from './Tree';

export type TreeNodeProps = {
    node: GroupedItem;
    hasMultiselect?: boolean;
    isSelected?: boolean;
    isIndeterminate?: boolean;
    onToggleNode: (nodeId: string) => void;
    onSelect: (node: GroupedItem, isIndeterminate: boolean) => void;
    className?: string;
};

const TreeNode = React.memo((props: TreeNodeProps) => {
    const { node, hasMultiselect = false, isSelected = false, isIndeterminate = false, onToggleNode, onSelect } = props;

    const treeNodeClassNames = classNames(
        'TreeNode',
        'from-group',
        isSelected && 'checked',
        node.className && node.className
    );

    const hasChildren = !!node.items.length;

    return (
        <div className={treeNodeClassNames} data-key={node.id} onClick={() => hasChildren && onToggleNode(node.id)}>
            {hasMultiselect && (
                <Checkbox
                    className='TreeCheckbox'
                    checked={isSelected}
                    disabled={node.disabled}
                    indeterminate={isIndeterminate}
                    onClick={() => onSelect(node, isIndeterminate)}
                />
            )}
            <span className='TreeLabel TreeLabelName'>
                {node.icon && <span className={`rioglyph ${node.icon}`} />}
                <span className='TreeLabelNameText'>
                    <span className='TreeLabelNameTextHeadline'>{node.name}</span>
                </span>
                <span className='TreeLabelCount label label-muted label-filled label-condensed'>
                    {node.items.length}
                </span>
                <span
                    className={`TreeLabelExpander rioglyph rioglyph-chevron-down ${
                        hasChildren ? '' : 'text-color-light'
                    }`}
                />
            </span>
        </div>
    );
});

export default TreeNode;
