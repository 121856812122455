// Re-export all components of the actual library to avoid deep links
export * from '../../src/index';

import { createRoot } from 'react-dom/client';
import { Route } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import { IntlProvider } from 'react-intl';

import { history, store } from './setup/store';
import { UIKIT } from './UIKIT';

const container = document.getElementById('root') as HTMLDivElement;
const root = createRoot(container);

root.render(
    <Provider store={store}>
        <IntlProvider locale='en-GB'>
            <ConnectedRouter history={history}>
                <Route path='/' component={UIKIT} />
            </ConnectedRouter>
        </IntlProvider>
    </Provider>
);
