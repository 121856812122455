import { Playground, PLAYGROUNG_BG_LIGHTER } from '../../../components/playground/Playground';
import { Description } from '../../../components/Description';

export const TableStates = () => (
    <div>
        <Description headline='States in tables' anchor='table-states' />
        <Playground example={statesExample} background={PLAYGROUNG_BG_LIGHTER} className='overflow-hidden' />
    </div>
);

import NotFoundState from '../../../../../src/NotFoundState';

const statesExample = (
    <table className='table table-head-filled table-layout-fixed'>
        <thead>
            <tr>
                {[...Array(5)].map(() => (
                    <th key={crypto.randomUUID()} className='ellipsis-1'>
                        Head column
                    </th>
                ))}
            </tr>
        </thead>
        <tbody>
            <tr>
                <td colSpan={5}>
                    <NotFoundState
                        headline='No entries found'
                        message='We could not find any data entries for your search query. Please refine your search'
                        outerClassName='border-none'
                    />
                </td>
            </tr>
        </tbody>
    </table>
);
