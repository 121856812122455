import { Playground, PLAYGROUNG_BG_LIGHTER } from '../../../components/playground/Playground';
import { Description } from '../../../components/Description';

import FormsExample from './FormsExample';
import FormsFieldsetExample from './FormsFieldsetExample';
import FormsSizedExample from './FormsSizedExample';
import FormsToggleExample from './FormsToggleExample';

export const Forms = () => (
    <div>
        <Description headline='Forms' anchor='forms' />
        <Playground example={<FormsExample />} background={PLAYGROUNG_BG_LIGHTER} />
        <Description headline='Form sized examples' headlineType='h3' />
        <Playground example={<FormsSizedExample />} background={PLAYGROUNG_BG_LIGHTER} />
        <Description headline='Form with fieldset and legend' headlineType='h3' />
        <p>
            An HTML <code>{'<fieldset>'}</code> element is used to group related elements within a form. It is
            particularly useful for organizing complex forms by creating logical sections, improving both the form's
            structure and user experience. The <code>{'<fieldset>'}</code> can be paired with a{' '}
            <code>{'<legend>'}</code> element to provide a caption for the grouped content, making the form easier to
            understand and navigate.
        </p>
        <p>
            Allows for selective processing of grouped data, making it useful for handling specific sections of a form
            independently when used with the{' '}
            <a
                href='https://developer.mozilla.org/en-US/docs/Web/API/FormData/FormData'
                target='_blank'
                rel='noreferrer'
            >
                FormData
            </a>{' '}
            API.
        </p>
        <Playground example={<FormsFieldsetExample />} background={PLAYGROUNG_BG_LIGHTER} />
        <Description headline='Form toggle examples' headlineType='h3' />
        <Playground example={<FormsToggleExample />} background={PLAYGROUNG_BG_LIGHTER} />
        <Description headline='Input resize' headlineType='h3' />
        <Playground
            example={
                <div>
                    <code className='code-inline-first'>resize-none</code>
                    <code className='code-inline'>resize-vertical</code>
                </div>
            }
        />
    </div>
);
