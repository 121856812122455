import { handleActions } from 'redux-actions';
import { LOCATION_CHANGE } from 'connected-react-router';
import { SortDirection } from '../../index';
import flow from 'lodash/fp/flow';
import omit from 'lodash/fp/omit';
import omitBy from 'lodash/fp/omitBy';
import isNil from 'lodash/fp/isNil';
import isEmpty from 'lodash/fp/isEmpty';
import cloneDeep from 'lodash/fp/cloneDeep';
import { data, tableConfig } from './content';

import { parseRoute } from './routeIntents';
import { actions } from './actions';
import { storage } from '../../setup/storage';

const defaultAppState = {
    userList: data,
    activeUser: null,
    selectedUserIds: [],
    sortBy: tableConfig.defaultSortKey,
    sortDir: SortDirection.ASCENDING,
    chunks: 1,
    columns: {
        columnOrder: tableConfig.defaultColumnOrder,
        hiddenColumns: tableConfig.defaultHiddenColumns,
        columnsDetails: tableConfig.defaultColumnsDetails,
    },
    activityFilter: [],
    stateFilter: [],
    showTableSettings: false,
    isPrintSidebar: false,
    isPrintTable: false,
};

const appReducer = handleActions(
    {
        [actions.deleteUsers]: (state, action) => {
            const userIds = action.payload || [];
            const newUserList = cloneDeep(state.userList).filter(user => !userIds.includes(user.itemId));
            return {
                ...state,
                activeUser: null,
                userList: newUserList,
            };
        },
        [actions.selectedUserIds]: (state, action) => ({ ...state, selectedUserIds: action.payload }),
        [actions.showConfirmDelete]: (state, action) => ({ ...state, showConfirmDelete: action.payload }),
        [actions.showCreateAppointment]: (state, action) => ({ ...state, showCreateAppointment: action.payload }),
        [actions.showCreateSingleAppointment]: (state, action) => ({
            ...state,
            showCreateSingleAppointment: action.payload,
        }),
        [actions.activeUser]: (state, action) => ({ ...state, activeUser: action.payload }),
        [actions.printSidebar]: (state, action) => ({ ...state, isPrintSidebar: action.payload }),
        [actions.printTable]: (state, action) => ({ ...state, isPrintTable: action.payload }),

        // There's a LOCATION_CHANGE action we can use to derive state from the URL.
        // This means, when you want to change state you only trigger route updates and no dedicated actions
        [LOCATION_CHANGE]: (state, action) => {
            if (isEmpty(action.payload)) {
                return state;
            }

            const parsedUrlState = parseRoute(action.payload);
            const cleanedUrlState = flow(omitBy(isNil), omit(['']))(parsedUrlState);

            // Special handling for empty values wich are intentionally set in order to remove those values again
            const urlState = { ...cleanedUrlState, search: parsedUrlState.search, assetIds: parsedUrlState.assetIds };

            // Store certain values in the storage in order to retrieve it from there in case the value is not given
            // by the URL i.e. when restoring state on initial load
            if (urlState.columns) {
                storage.save('columns', urlState.columns);
            } else {
                urlState.columns = storage.load('columns') || defaultAppState.columns;
            }

            return {
                ...state,
                ...urlState,
            };
        },
    },
    defaultAppState
);

export default appReducer;
