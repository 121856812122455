import React, { type JSX, useEffect, useRef, useState } from 'react';
import noop from 'lodash/noop';

const loadImage = (onLoad: VoidFunction, onError: VoidFunction) => (src: string, isAnonymous: boolean) => {
    const image = new Image();

    if (isAnonymous) {
        image.crossOrigin = 'anonymous';
    }

    // attach listeners
    image.onload = onLoad;
    image.onerror = onError;

    // preload the actual image
    image.src = src;

    return image;
};

type ImagePreloaderStatus = 'PENDING' | 'FAILED' | 'LOADED';

export type ImagePreloaderChild = (props: {
    status: ImagePreloaderStatus;
    image: HTMLImageElement;
}) => JSX.Element | null;

export type ImagePreloaderProps = {
    /**
     * The URL of the image to load.
     */
    src: string;

    /**
     * Invoked when the image is loaded.
     *
     * @param image The native Image object.
     */
    onLoaded?: (image: HTMLImageElement) => void;

    /**
     * Invoked when the image failed to load.
     *
     * @param image The native Image object.
     */
    onFailed?: (image: HTMLImageElement) => void;

    /**
     * Whether to set the "crossOrigin" to "anonymous".
     *
     * @default false
     */
    isAnonymous?: boolean;

    /**
     * A render function can be used to access both the current state and the image element.
     *
     * Using this, you can decide how to render the different states and/or the loaded image.
     *
     * The function will receive props containing the `status` and `image` properties.
     *
     * `status` will be one of 'PENDING', 'FAILED' or 'LOADED'. `image` is the Image element.
     */
    children?: ImagePreloaderChild;
};

const ImagePreloader = (props: ImagePreloaderProps) => {
    const { src, isAnonymous = false, onLoaded = noop, onFailed = noop, children } = props;

    const [status, setStatus] = useState<ImagePreloaderStatus>('PENDING');
    const [image, setImage] = useState<HTMLImageElement>();

    const willUnmount = useRef(false);

    const handleImageLoaded = () => {
        if (willUnmount.current) {
            return;
        }
        setStatus('LOADED');
        onLoaded(image);
    };

    const handleImageFailed = () => {
        if (willUnmount.current) {
            return;
        }
        setStatus('FAILED');
        onFailed(image);
    };

    const loadImageWithCallbacks = loadImage(handleImageLoaded, handleImageFailed);

    useEffect(() => {
        setImage(loadImageWithCallbacks(src, isAnonymous));

        return () => {
            willUnmount.current = true;
        };
    }, []);

    useEffect(() => {
        setStatus('PENDING');
        setImage(loadImageWithCallbacks(src, isAnonymous));
    }, [src]);

    return children ? children({ status, image: image! }) : null;
};

ImagePreloader.STATUS_PENDING = 'PENDING';
ImagePreloader.STATUS_LOADED = 'LOADED';
ImagePreloader.STATUS_FAILED = 'FAILED';

export default ImagePreloader;
