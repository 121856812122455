// @ts-ignore-next-line importsNotUsedAsValues
import React, { type PropsWithChildren } from 'react';
import classNames from 'classnames';

export type StatsWidgetBodyProps = {
    /**
     * Whether to use flex space-between for StatsWidgetNumbers.
     * @default false
     */
    fullWidth?: boolean;

    /**
     * Additional class names to be added to the element.
     */
    className?: string;
};

const StatsWidgetBody = (props: PropsWithChildren<StatsWidgetBodyProps>) => {
    const { children, fullWidth = false, className, ...remainingProps } = props;

    const wrapperClasses = classNames('StatsWidget-body', fullWidth && 'full-width', className);

    return (
        <div {...remainingProps} className={wrapperClasses}>
            {fullWidth && <div className='StatsWidget-body-column-spacer invisible' />}
            {children}
            {fullWidth && <div className='StatsWidget-body-column-spacer invisible' />}
        </div>
    );
};

export default StatsWidgetBody;
