import React, { forwardRef, type HTMLProps, type PropsWithChildren } from 'react';
import classNames from 'classnames';

import Caret from './Caret';
import Button, { BUTTON_STYLE, type BUTTON_SIZE } from '../button/Button';

export type SplitCaretButtonProps = HTMLProps<HTMLButtonElement> & {
    disabled?: boolean;
    iconOnly?: boolean;
    onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
    bsSize: BUTTON_SIZE;
    bsStyle: BUTTON_STYLE;
    className?: string;
};

const SplitCaretButton = forwardRef<HTMLButtonElement, PropsWithChildren<SplitCaretButtonProps>>((props, ref) => {
    const { disabled = false, bsStyle, bsSize, onClick, className, ...remainingProps } = props;

    const splitCaretButtonClasses = classNames('dropdown-toggle', className);

    return (
        <Button
            {...remainingProps}
            type='button'
            ref={ref}
            disabled={disabled}
            bsStyle={bsStyle}
            bsSize={bsSize}
            onClick={onClick}
            className={splitCaretButtonClasses}
        >
            <Caret />
        </Button>
    );
});

export default SplitCaretButton;
