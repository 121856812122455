import React, { useState } from 'react';
import isNil from 'lodash/fp/isNil';

import MobileHeaderModal from './MobileHeaderModal';
import type { ModulePropType } from './ApplicationHeader';

export type MobileAppMenuProps = {
    homeRoute: React.ReactNode;
    showHomeIcon: boolean;
    appMenuItems?: ModulePropType[];
    appNavigator?: React.ReactNode;
    onToggleAppMenu: (isShown: boolean) => void;
};

const MobileAppMenu = (props: MobileAppMenuProps) => {
    const { appMenuItems, appNavigator, showHomeIcon, homeRoute, onToggleAppMenu } = props;

    const [isShown, setIsShown] = useState(false);

    const handleToggleNavigation = () => {
        const newState = !isShown;
        setIsShown(newState);
        onToggleAppMenu(newState);
    };

    const hasAppContent = !(isNil(appMenuItems) && isNil(appNavigator));

    return (
        <React.Fragment>
            <div
                className={
                    'navbar-header min-width-50 padding-5 ' +
                    'overflow-hidden position-relative display-flex align-items-center'
                }
                onClick={handleToggleNavigation}
            >
                <span className={`navbar-brand height-40 width-40 ${showHomeIcon ? 'home-icon' : ''}`}>
                    {!hasAppContent && homeRoute}
                </span>
                {hasAppContent && (
                    <div className='position-absolute right-2 bottom-0 text-color-white rotate-45'>
                        <span className='rioglyph rioglyph-triangle-right' />
                    </div>
                )}
            </div>
            {appNavigator && (
                <MobileHeaderModal show={isShown} modalClassName='padding-top-25' onClose={handleToggleNavigation}>
                    <div className='width-100pct height-100pct'>{appNavigator}</div>
                </MobileHeaderModal>
            )}
            {appMenuItems && (
                <MobileHeaderModal show={isShown} modalClassName='padding-15' onClose={handleToggleNavigation}>
                    {appMenuItems.map(module => (
                        <li role='presentation' key={`link-icon-${module.key}`}>
                            {module.route}
                        </li>
                    ))}
                </MobileHeaderModal>
            )}
        </React.Fragment>
    );
};

export default MobileAppMenu;
