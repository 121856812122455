import { Playground } from '../../../components/playground/Playground';
import { PlaygroundClasses, PlaygroundResponsiveClasses } from '../../../components/playground/PlaygroundClasses';
import { Description } from '../../../components/Description';
import { Title } from '../../../components/Title';
import { Note } from '../../../components/Note';
import { dummyTextUltraShort } from '../../../utils/data';

import { sizes, getSizeClasses } from '../../../utils/sizes';
import { findByClass } from '../../../utils/mapUtils';

const eachTextLineHeight = getSizeClasses(sizes.lineHeightText) as string[];
const eachHeadlineLineHeight = getSizeClasses(sizes.lineHeightHeadlines) as string[];
const eachRelativeLineHeight = getSizeClasses(sizes.lineHeightRelative) as string[];

export const LineHeight = () => (
    <div>
        <Description headline='Line height' anchor='line-height' />
        <p>
            When working with text, it's important to consider the relationship between the text size and the line
            height. For larger headlines, a line height of 1.2 to 1.25 is recommended to maintain readability and visual
            balance. For plain body text or text inside promotion blocks, a slightly larger line height of 1.4 to 1.5 is
            suggested to improve readability and the overall reading experience.
        </p>
        <p>Rule of thumb: the larger the text size, the smaller the line height should be.</p>
        <Playground example={lineHeightTextExample} classes={lineHeightTextClasses} />
        <Playground example={lineHeightHeadlineExample} classes={lineHeightHeadlineClasses} />
        <Note>
            The listed classes are <b>not</b> necessary for the use of our
            <code className='code-inline'>text-size</code> classes. Use them <b>only</b> if you want to have the{' '}
            <b>exact the same</b> line-height as your text-size is.
        </Note>
    </div>
);

const lineHeightTextExample = (
    <div className='display-grid gap-20'>
        <div className='display-grid grid-cols-1 grid-cols-2-ls grid-cols-4-sm grid-cols-7-md gap-20'>
            {eachTextLineHeight.map((itemClass, index) => (
                <div key={index}>
                    <Title>{itemClass}</Title>
                    <p
                        className={`${itemClass} padding-5 text-size-${
                            findByClass(itemClass, sizes.lineHeightText).value
                        }`}
                    >
                        {dummyTextUltraShort}
                    </p>
                </div>
            ))}
        </div>
        <div className='display-grid grid-cols-1 grid-cols-2-ls grid-cols-4-sm grid-cols-7-md gap-20'>
            {eachRelativeLineHeight.map((itemClass, index) => (
                <div key={index}>
                    <Title>{itemClass}</Title>
                    <p className={`${itemClass} padding-5 text-size-16`}>{dummyTextUltraShort}</p>
                </div>
            ))}
        </div>
    </div>
);

const lineHeightHeadlineExample = (
    <div className='display-grid grid-cols-1 grid-cols-2-ls grid-cols-3-sm grid-cols-6-md gap-20'>
        {eachHeadlineLineHeight.map((itemClass, index) => (
            <div key={index}>
                <Title>{itemClass}</Title>
                <p
                    className={`${itemClass} padding-5 text-size-${
                        findByClass(itemClass, sizes.lineHeightHeadlines).value
                    }`}
                >
                    {dummyTextUltraShort}
                </p>
            </div>
        ))}
    </div>
);

const lineHeightTextClasses = (
    <>
        <PlaygroundClasses label='Text' classes={eachTextLineHeight} />
        <PlaygroundClasses label='Relative' classes={eachRelativeLineHeight} />
        <PlaygroundResponsiveClasses />
    </>
);

const lineHeightHeadlineClasses = (
    <>
        <PlaygroundClasses label='Headlines' classes={eachHeadlineLineHeight} />
        <PlaygroundResponsiveClasses />
    </>
);
