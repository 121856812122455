import { Playground } from '../../../components/playground/Playground';
import {
    PlaygroundClasses,
    PlaygroundClassesSpacer,
    PlaygroundNavClasses,
} from '../../../components/playground/PlaygroundClasses';
import { Description } from '../../../components/Description';
import { Title } from '../../../components/Title';

export const Tabs = () => (
    <div>
        <Description headline='Tabs' anchor='tabs' />
        <Playground example={tabsExample} classes={tabsClasses} />
    </div>
);

const tabsExample = (
    <div className='display-grid grid-cols-1 grid-cols-2-sm column-gap-25 row-gap-50'>
        <div>
            <Title>Standard</Title>
            <ul className='nav nav-tabs'>
                <li>
                    <a href='#tab1' data-toggle='tab'>
                        Tab 1
                    </a>
                </li>
                <li>
                    <a href='#tab2' data-toggle='tab'>
                        Tab 2
                    </a>
                </li>
                <li className='active'>
                    <a href='#tab3' data-toggle='tab'>
                        Tab 3
                    </a>
                </li>
                <li className='disabled'>
                    <a>Disabled tab</a>
                </li>
            </ul>
            <div id='myTabContent' className='tab-content'>
                <div className='tab-pane fade active in' id='tab1'>
                    <p>
                        Raw denim you probably have not heard of them jean shorts Austin. Nesciunt tofu stumptown
                        aliqua, retro synth master cleanse. Mustache cliche tempor, williamsburg carles vegan helvetica.
                        Reprehenderit butcher retro keffiyeh dreamcatcher synth. Cosby sweater eu banh mi, qui irure
                        terry richardson ex squid. Aliquip placeat salvia cillum iphone. Seitan aliquip quis cardigan
                        american apparel, butcher voluptate nisi qui.
                    </p>
                </div>
            </div>
        </div>

        <div>
            <Title>Bordered content</Title>
            <ul className='nav nav-tabs'>
                <li className='active'>
                    <a href='#tab4' data-toggle='tab'>
                        Tab 4
                    </a>
                </li>
                <li>
                    <a href='#tab5' data-toggle='tab'>
                        Tab 5
                    </a>
                </li>
            </ul>
            <div id='tab4' className='tab-content-bordered'>
                <div className='tab-pane fade active in'>
                    <p>
                        Raw denim you probably have not heard of them jean shorts Austin. Nesciunt tofu stumptown
                        aliqua, retro synth master cleanse. Mustache cliche tempor, williamsburg carles vegan helvetica.
                        Reprehenderit butcher retro keffiyeh dreamcatcher synth. Cosby sweater eu banh mi, qui irure
                        terry richardson ex squid. Aliquip placeat salvia cillum iphone. Seitan aliquip quis cardigan
                        american apparel, butcher voluptate nisi qui.
                    </p>
                </div>
            </div>
        </div>

        <div>
            <Title>Justified</Title>
            <ul className='nav nav-tabs nav-justified'>
                <li className='active'>
                    <a href='#tab1' data-toggle='tab'>
                        Tab 1
                    </a>
                </li>
                <li>
                    <a href='#tab2' data-toggle='tab'>
                        Tab 2
                    </a>
                </li>
                <li>
                    <a href='#tab3' data-toggle='tab'>
                        Tab 3
                    </a>
                </li>
                <li>
                    <a href='#tab4' data-toggle='tab'>
                        Long tab name with line-break
                    </a>
                </li>
            </ul>
            <div id='myTabContent' className='tab-content'>
                <div className='tab-pane fade active in' id='tab1'>
                    <p>
                        Raw denim you probably have not heard of them jean shorts Austin. Nesciunt tofu stumptown
                        aliqua, retro synth master cleanse. Mustache cliche tempor, williamsburg carles vegan helvetica.
                        Reprehenderit butcher retro keffiyeh dreamcatcher synth. Cosby sweater eu banh mi, qui irure
                        terry richardson ex squid. Aliquip placeat salvia cillum iphone. Seitan aliquip quis cardigan
                        american apparel, butcher voluptate nisi qui.
                    </p>
                </div>
            </div>
        </div>

        <div>
            <Title>Justified with word-wrap</Title>
            <ul className='nav nav-tabs nav-justified nav-justified-word-wrap'>
                <li>
                    <a href='#tab1' data-toggle='tab'>
                        Tab 1
                    </a>
                </li>
                <li className='active'>
                    <a href='#tab2' data-toggle='tab'>
                        Tab 2
                    </a>
                </li>
                <li>
                    <a href='#tab3' data-toggle='tab'>
                        Tab 3
                    </a>
                </li>
                <li>
                    <a>Long tab name with line-break</a>
                </li>
            </ul>
            <div id='myTabContent' className='tab-content'>
                <div className='tab-pane fade active in' id='tab1'>
                    <p>
                        Raw denim you probably have not heard of them jean shorts Austin. Nesciunt tofu stumptown
                        aliqua, retro synth master cleanse. Mustache cliche tempor, williamsburg carles vegan helvetica.
                        Reprehenderit butcher retro keffiyeh dreamcatcher synth. Cosby sweater eu banh mi, qui irure
                        terry richardson ex squid. Aliquip placeat salvia cillum iphone. Seitan aliquip quis cardigan
                        american apparel, butcher voluptate nisi qui.
                    </p>
                </div>
            </div>
        </div>
    </div>
);

const tabsClasses = (
    <>
        <PlaygroundNavClasses showNavVariants />
        <PlaygroundClassesSpacer label='Nav Tabs' />
        <PlaygroundClasses label='Wrapper' classes={['nav-tabs']} />
        <PlaygroundClasses label='Content' classes={['tab-content', 'tab-content-bordered']} />
    </>
);
