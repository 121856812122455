import { Playground, PLAYGROUNG_BG_LIGHTER } from '../../../components/playground/Playground';
import { PlaygroundClasses } from '../../../components/playground/PlaygroundClasses';
import { Description } from '../../../components/Description';

export const TableBordered = () => (
    <div>
        <Description headline='Bordered table' anchor='table-bordered' />
        <Playground
            example={tableBorderedExample}
            classes={tableBorderedClasses}
            background={PLAYGROUNG_BG_LIGHTER}
            className='overflow-hidden'
        />
    </div>
);

const tableBorderedExample = (
    <div className='table-responsive'>
        <table className='table table-head-filled table-bordered'>
            <thead>
                <tr>
                    {[...Array(4)].map(() => (
                        <th key={crypto.randomUUID()}>Head column</th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {[...Array(4)].map(() => (
                    <tr key={crypto.randomUUID()}>
                        {[...Array(4)].map(() => (
                            <td key={crypto.randomUUID()}>Column</td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </table>
    </div>
);

const tableBorderedClasses = (
    <>
        <PlaygroundClasses label='<table>' classes='table' />
        <PlaygroundClasses label='Variants' classes='table-bordered' />
    </>
);
