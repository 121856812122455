/* eslint-disable quotes */
export const replaceUikitImport = text => {
    const defaultImportMatch = /from.+index'/gm;
    const esImportMatch = /from.+\/..\/src/gm;

    if (text.match(defaultImportMatch)) {
        return text.replace(defaultImportMatch, "from 'rio-uikit'");
    }
    if (text.match(esImportMatch)) {
        return text.replace(esImportMatch, "from '@rio-cloud/rio-uikit");
    }
    return text;
};
