import { version } from '../../version.json';
import { isInIframe, isProd } from './initConfig';

const logStyled = (message: string) => console.log('%c%s', 'color: #697a8b;', message);

export const printPoweredByRIO = () => {
    logStyled('- - - - - - - - - - - - - - - - - -');

    const message = [`RIO UIKIT Version ${version}\nhttps://uikit.developers.rio.cloud`];
    if (isProd && !isInIframe) {
        message.push('\n\nYou are a Developer? RIO is hiring!\n👉 https://rio.cloud/en/career 👈');
    }

    logStyled(message.join(''));
    logStyled('- - - - - - - - - - - - - - - - - -');
};
