import memoize from 'lodash/fp/memoize';
import faker from 'faker';

export const serviceInfo = {
    name: 'Demo service',
    version: '0.0.1',
};

export const BASE_PATH = '/demoService';

export const activities = ['available', 'resting', 'working', 'driving'];
export const userStates = ['success', 'warning', 'danger'];
export const userImages = [
    'https://randomuser.me/api/portraits/men/0.jpg',
    'https://randomuser.me/api/portraits/men/1.jpg',
    'https://randomuser.me/api/portraits/men/2.jpg',
    'https://randomuser.me/api/portraits/men/3.jpg',
    'https://randomuser.me/api/portraits/men/4.jpg',
    'https://randomuser.me/api/portraits/men/5.jpg',
    'https://randomuser.me/api/portraits/men/6.jpg',
    'https://randomuser.me/api/portraits/men/7.jpg',
    'https://randomuser.me/api/portraits/men/8.jpg',
    'https://randomuser.me/api/portraits/men/9.jpg',
    'https://randomuser.me/api/portraits/men/10.jpg',
    'https://randomuser.me/api/portraits/men/11.jpg',
    'https://randomuser.me/api/portraits/men/12.jpg',
    'https://randomuser.me/api/portraits/men/13.jpg',
    'https://randomuser.me/api/portraits/men/14.jpg',
    'https://randomuser.me/api/portraits/men/15.jpg',
    'https://randomuser.me/api/portraits/men/16.jpg',
    'https://randomuser.me/api/portraits/men/17.jpg',
    'https://randomuser.me/api/portraits/men/18.jpg',
    'https://randomuser.me/api/portraits/men/19.jpg',
    'https://randomuser.me/api/portraits/men/20.jpg',
    'https://randomuser.me/api/portraits/men/21.jpg',
    'https://randomuser.me/api/portraits/men/22.jpg',
    'https://randomuser.me/api/portraits/men/23.jpg',
    'https://randomuser.me/api/portraits/men/24.jpg',
    'https://randomuser.me/api/portraits/men/25.jpg',
    'https://randomuser.me/api/portraits/men/26.jpg',
    'https://randomuser.me/api/portraits/men/27.jpg',
    'https://randomuser.me/api/portraits/men/28.jpg',
    'https://randomuser.me/api/portraits/men/29.jpg',
    'https://randomuser.me/api/portraits/men/30.jpg',
    'https://randomuser.me/api/portraits/men/31.jpg',
    'https://randomuser.me/api/portraits/men/32.jpg',
    'https://randomuser.me/api/portraits/men/33.jpg',
    'https://randomuser.me/api/portraits/men/34.jpg',
    'https://randomuser.me/api/portraits/men/35.jpg',
    'https://randomuser.me/api/portraits/men/36.jpg',
    'https://randomuser.me/api/portraits/men/37.jpg',
    'https://randomuser.me/api/portraits/men/38.jpg',
    'https://randomuser.me/api/portraits/men/39.jpg',
    'https://randomuser.me/api/portraits/men/40.jpg',
    'https://randomuser.me/api/portraits/men/41.jpg',
    'https://randomuser.me/api/portraits/men/42.jpg',
    'https://randomuser.me/api/portraits/men/43.jpg',
    'https://randomuser.me/api/portraits/men/44.jpg',
    'https://randomuser.me/api/portraits/men/45.jpg',
    'https://randomuser.me/api/portraits/men/46.jpg',
    'https://randomuser.me/api/portraits/men/47.jpg',
    'https://randomuser.me/api/portraits/men/48.jpg',
    'https://randomuser.me/api/portraits/men/49.jpg',
    'https://randomuser.me/api/portraits/men/50.jpg',
    'https://randomuser.me/api/portraits/men/51.jpg',
    'https://randomuser.me/api/portraits/men/52.jpg',
    'https://randomuser.me/api/portraits/men/53.jpg',
    'https://randomuser.me/api/portraits/men/54.jpg',
    'https://randomuser.me/api/portraits/men/55.jpg',
    'https://randomuser.me/api/portraits/men/56.jpg',
    'https://randomuser.me/api/portraits/men/57.jpg',
    'https://randomuser.me/api/portraits/men/58.jpg',
    'https://randomuser.me/api/portraits/men/59.jpg',
    'https://randomuser.me/api/portraits/men/60.jpg',
    'https://randomuser.me/api/portraits/men/61.jpg',
    'https://randomuser.me/api/portraits/men/62.jpg',
    'https://randomuser.me/api/portraits/men/63.jpg',
    'https://randomuser.me/api/portraits/men/64.jpg',
    'https://randomuser.me/api/portraits/men/65.jpg',
    'https://randomuser.me/api/portraits/men/66.jpg',
    'https://randomuser.me/api/portraits/men/67.jpg',
    'https://randomuser.me/api/portraits/men/68.jpg',
    'https://randomuser.me/api/portraits/men/69.jpg',
    'https://randomuser.me/api/portraits/men/70.jpg',
    'https://randomuser.me/api/portraits/men/71.jpg',
    'https://randomuser.me/api/portraits/men/72.jpg',
    'https://randomuser.me/api/portraits/men/73.jpg',
    'https://randomuser.me/api/portraits/men/74.jpg',
    'https://randomuser.me/api/portraits/men/75.jpg',
    'https://randomuser.me/api/portraits/men/76.jpg',
    'https://randomuser.me/api/portraits/men/77.jpg',
    'https://randomuser.me/api/portraits/men/78.jpg',
    'https://randomuser.me/api/portraits/men/79.jpg',
    'https://randomuser.me/api/portraits/men/80.jpg',
    'https://randomuser.me/api/portraits/men/81.jpg',
    'https://randomuser.me/api/portraits/men/82.jpg',
    'https://randomuser.me/api/portraits/men/83.jpg',
    'https://randomuser.me/api/portraits/men/84.jpg',
    'https://randomuser.me/api/portraits/men/85.jpg',
    'https://randomuser.me/api/portraits/men/86.jpg',
    'https://randomuser.me/api/portraits/men/87.jpg',
    'https://randomuser.me/api/portraits/men/88.jpg',
    'https://randomuser.me/api/portraits/men/89.jpg',
    'https://randomuser.me/api/portraits/men/90.jpg',
    'https://randomuser.me/api/portraits/men/91.jpg',
    'https://randomuser.me/api/portraits/men/92.jpg',
    'https://randomuser.me/api/portraits/men/93.jpg',
    'https://randomuser.me/api/portraits/men/94.jpg',
    'https://randomuser.me/api/portraits/men/95.jpg',
    'https://randomuser.me/api/portraits/men/96.jpg',
    'https://randomuser.me/api/portraits/men/97.jpg',
    'https://randomuser.me/api/portraits/men/99.jpg',
    'https://randomuser.me/api/portraits/men/98.jpg',
    'https://randomuser.me/api/portraits/men/99.jpg',
    'https://randomuser.me/api/portraits/women/0.jpg',
    'https://randomuser.me/api/portraits/women/1.jpg',
    'https://randomuser.me/api/portraits/women/2.jpg',
    'https://randomuser.me/api/portraits/women/3.jpg',
    'https://randomuser.me/api/portraits/women/4.jpg',
    'https://randomuser.me/api/portraits/women/5.jpg',
    'https://randomuser.me/api/portraits/women/6.jpg',
    'https://randomuser.me/api/portraits/women/7.jpg',
    'https://randomuser.me/api/portraits/women/8.jpg',
    'https://randomuser.me/api/portraits/women/9.jpg',
    'https://randomuser.me/api/portraits/women/10.jpg',
    'https://randomuser.me/api/portraits/women/11.jpg',
    'https://randomuser.me/api/portraits/women/12.jpg',
    'https://randomuser.me/api/portraits/women/13.jpg',
    'https://randomuser.me/api/portraits/women/14.jpg',
    'https://randomuser.me/api/portraits/women/15.jpg',
    'https://randomuser.me/api/portraits/women/16.jpg',
    'https://randomuser.me/api/portraits/women/17.jpg',
    'https://randomuser.me/api/portraits/women/18.jpg',
    'https://randomuser.me/api/portraits/women/19.jpg',
    'https://randomuser.me/api/portraits/women/20.jpg',
    'https://randomuser.me/api/portraits/women/21.jpg',
    'https://randomuser.me/api/portraits/women/22.jpg',
    'https://randomuser.me/api/portraits/women/23.jpg',
    'https://randomuser.me/api/portraits/women/24.jpg',
    'https://randomuser.me/api/portraits/women/25.jpg',
    'https://randomuser.me/api/portraits/women/26.jpg',
    'https://randomuser.me/api/portraits/women/27.jpg',
    'https://randomuser.me/api/portraits/women/28.jpg',
    'https://randomuser.me/api/portraits/women/29.jpg',
    'https://randomuser.me/api/portraits/women/30.jpg',
    'https://randomuser.me/api/portraits/women/31.jpg',
    'https://randomuser.me/api/portraits/women/32.jpg',
    'https://randomuser.me/api/portraits/women/33.jpg',
    'https://randomuser.me/api/portraits/women/34.jpg',
    'https://randomuser.me/api/portraits/women/35.jpg',
    'https://randomuser.me/api/portraits/women/36.jpg',
    'https://randomuser.me/api/portraits/women/37.jpg',
    'https://randomuser.me/api/portraits/women/38.jpg',
    'https://randomuser.me/api/portraits/women/39.jpg',
    'https://randomuser.me/api/portraits/women/40.jpg',
    'https://randomuser.me/api/portraits/women/41.jpg',
    'https://randomuser.me/api/portraits/women/42.jpg',
    'https://randomuser.me/api/portraits/women/43.jpg',
    'https://randomuser.me/api/portraits/women/44.jpg',
    'https://randomuser.me/api/portraits/women/45.jpg',
    'https://randomuser.me/api/portraits/women/46.jpg',
    'https://randomuser.me/api/portraits/women/47.jpg',
    'https://randomuser.me/api/portraits/women/48.jpg',
    'https://randomuser.me/api/portraits/women/49.jpg',
    'https://randomuser.me/api/portraits/women/50.jpg',
    'https://randomuser.me/api/portraits/women/51.jpg',
    'https://randomuser.me/api/portraits/women/52.jpg',
    'https://randomuser.me/api/portraits/women/53.jpg',
    'https://randomuser.me/api/portraits/women/54.jpg',
    'https://randomuser.me/api/portraits/women/55.jpg',
    'https://randomuser.me/api/portraits/women/56.jpg',
    'https://randomuser.me/api/portraits/women/57.jpg',
    'https://randomuser.me/api/portraits/women/58.jpg',
    'https://randomuser.me/api/portraits/women/59.jpg',
    'https://randomuser.me/api/portraits/women/60.jpg',
    'https://randomuser.me/api/portraits/women/61.jpg',
    'https://randomuser.me/api/portraits/women/62.jpg',
    'https://randomuser.me/api/portraits/women/63.jpg',
    'https://randomuser.me/api/portraits/women/64.jpg',
    'https://randomuser.me/api/portraits/women/65.jpg',
    'https://randomuser.me/api/portraits/women/66.jpg',
    'https://randomuser.me/api/portraits/women/67.jpg',
    'https://randomuser.me/api/portraits/women/68.jpg',
    'https://randomuser.me/api/portraits/women/69.jpg',
    'https://randomuser.me/api/portraits/women/70.jpg',
    'https://randomuser.me/api/portraits/women/71.jpg',
    'https://randomuser.me/api/portraits/women/72.jpg',
    'https://randomuser.me/api/portraits/women/73.jpg',
    'https://randomuser.me/api/portraits/women/74.jpg',
    'https://randomuser.me/api/portraits/women/75.jpg',
    'https://randomuser.me/api/portraits/women/76.jpg',
    'https://randomuser.me/api/portraits/women/77.jpg',
    'https://randomuser.me/api/portraits/women/78.jpg',
    'https://randomuser.me/api/portraits/women/79.jpg',
    'https://randomuser.me/api/portraits/women/80.jpg',
    'https://randomuser.me/api/portraits/women/81.jpg',
    'https://randomuser.me/api/portraits/women/82.jpg',
    'https://randomuser.me/api/portraits/women/83.jpg',
    'https://randomuser.me/api/portraits/women/84.jpg',
    'https://randomuser.me/api/portraits/women/85.jpg',
    'https://randomuser.me/api/portraits/women/86.jpg',
    'https://randomuser.me/api/portraits/women/87.jpg',
    'https://randomuser.me/api/portraits/women/88.jpg',
    'https://randomuser.me/api/portraits/women/89.jpg',
    'https://randomuser.me/api/portraits/women/90.jpg',
    'https://randomuser.me/api/portraits/women/91.jpg',
    'https://randomuser.me/api/portraits/women/92.jpg',
    'https://randomuser.me/api/portraits/women/93.jpg',
    'https://randomuser.me/api/portraits/women/94.jpg',
    'https://randomuser.me/api/portraits/women/95.jpg',
    'https://randomuser.me/api/portraits/women/96.jpg',
    'https://randomuser.me/api/portraits/women/97.jpg',
    'https://randomuser.me/api/portraits/women/98.jpg',
    'https://randomuser.me/api/portraits/women/99.jpg',
];

export const data = Array.from({ length: 150 }, (_, index) => ({
    itemId: `${index + 1}`,
    userAvatar: faker.random.arrayElement(userImages),
    userStatus: faker.random.arrayElement(userStates),
    userNickName: faker.internet.userName(),
    userFirstName: faker.name.firstName(),
    userLastName: faker.name.lastName(),
    userActivity: {
        percent: faker.datatype.number({ max: 100 }),
        activity: faker.random.arrayElement(activities),
    },
    userJobTitle: faker.name.jobTitle(),
    userEmail: faker.internet.email(),
    userPhoneNumber: faker.phone.phoneNumberFormat(),
}));

// Transform data in order to:
// - Translate item props as we search and sort accordingly with respect to translations or composed values
// - format values as they might be relevant for search
export const transformData = memoize(items => {
    return items.map(item => {
        return {
            ...item,
            userActivityLabel: `${item.userActivity.percent} min`,
            userActivityValue: item.userActivity.percent,
            dataKey: item.itemId,
        };
    });
});

const defaultColumnOrder = [
    'itemId',
    'userImageStatus',
    'userNickName',
    'userFirstName',
    'userLastName',
    'userActivity',
    'userJobTitle',
    'userEmail',
    'userPhoneNumber',
];

const disabledColumns = ['userFirstName'];

const defaultHiddenColumns = ['itemId'];

const defaultSortKey = 'userNickName';

const columnLabels = {
    itemId: 'Id',
    userImageStatus: 'User',
    userNickName: 'Nickname',
    userFirstName: 'First name',
    userLastName: 'Last name',
    userActivity: 'Activity',
    userJobTitle: 'Job',
    userEmail: 'Email',
    userPhoneNumber: 'Phone',
};

const defaultColumnsDetails = {
    itemId: {
        width: 60,
        defaultWidth: 60,
        maxWidth: 60,
    },
    userImageStatus: {
        width: 55,
        defaultWidth: 55,
        maxWidth: 100,
    },
    userNickName: {},
    userFirstName: {},
    lastFirstName: {},
    userActivity: {
        width: 200,
        defaultWidth: 200,
    },
    userJobTitle: {},
    userEmail: {},
    userPhoneNumber: {},
};

const rawSortKeys = [];

const sortKeyReplacement = {
    userActivity: 'userActivityLabel',
    userImageStatus: 'userStatus',
};

export const tableConfig = {
    defaultColumnOrder,
    defaultHiddenColumns,
    defaultColumnsDetails,
    disabledColumns,
    columnLabels,
    rawSortKeys,
    sortKeyReplacement,
    defaultSortKey,
    TABLE_CHUNK_SIZE: 50,
};
