export const iconsMetaData: Record<string, string> = {
    'rioglyph-addressbook': '',
    'rioglyph-am': '',
    'rioglyph-angle-double-down': '',
    'rioglyph-angle-double-left': '',
    'rioglyph-angle-double-right': '',
    'rioglyph-angle-double-up': '',
    'rioglyph-antenna': '',
    'rioglyph-api': '',
    'rioglyph-area-in': '',
    'rioglyph-area-out': '',
    'rioglyph-arrow-down': 'bottom',
    'rioglyph-arrow-left': 'back previous',
    'rioglyph-arrow-right': 'forward next',
    'rioglyph-arrow-up': 'top',
    'rioglyph-axle': '',
    'rioglyph-ban-circle': 'forbidden',
    'rioglyph-battery-level-empty': '',
    'rioglyph-battery-level-full': '',
    'rioglyph-battery-level-low': '',
    'rioglyph-beacon': '',
    'rioglyph-border-crossing': '',
    'rioglyph-brake': '',
    'rioglyph-bus-baseline': 'asset',
    'rioglyph-bus-breakdown': 'asset',
    'rioglyph-bus': 'asset',
    'rioglyph-businessman': '',
    'rioglyph-calendar-today': '',
    'rioglyph-calendar': '',
    'rioglyph-camera': '',
    'rioglyph-car-baseline': '',
    'rioglyph-car': 'asset',
    'rioglyph-card-hotel': '',
    'rioglyph-card-reader': '',
    'rioglyph-cards-grid': 'table',
    'rioglyph-cards-list': 'table',
    'rioglyph-cards-table': '',
    'rioglyph-carrier': '',
    'rioglyph-chat': '',
    'rioglyph-checkbox-checked': '',
    'rioglyph-checkbox': '',
    'rioglyph-checkboxes': '',
    'rioglyph-chevron-down': '',
    'rioglyph-chevron-left': 'back previous',
    'rioglyph-chevron-right': 'forward next',
    'rioglyph-chevron-up': '',
    'rioglyph-click': '',
    'rioglyph-climate-control': '',
    'rioglyph-clipboard': '',
    'rioglyph-cloud-download': '',
    'rioglyph-cloud-not-saved': '',
    'rioglyph-cloud-upload': '',
    'rioglyph-cloud': '',
    'rioglyph-coasting': '',
    'rioglyph-cog': 'settings',
    'rioglyph-color-swatch': 'theme',
    'rioglyph-comment': '',
    'rioglyph-compare': '',
    'rioglyph-component-custom-recurrent': '',
    'rioglyph-component-custom': '',
    'rioglyph-component': '',
    'rioglyph-construction': '',
    'rioglyph-cookie': '',
    'rioglyph-cooling': '',
    'rioglyph-cost-efficency': '',
    'rioglyph-cruise-control': '',
    'rioglyph-csv': 'excel',
    'rioglyph-currency-euro': '',
    'rioglyph-damages': '',
    'rioglyph-dangerousgoods': '',
    'rioglyph-dashboard': '',
    'rioglyph-delivery-completed': '',
    'rioglyph-delivery-error': '',
    'rioglyph-delivery-late': '',
    'rioglyph-delivery-on-track': '',
    'rioglyph-delivery-warning': '',
    'rioglyph-delivery': '',
    'rioglyph-desktop': 'screen',
    'rioglyph-detail-view-info': '',
    'rioglyph-detail-view': '',
    'rioglyph-direction': '',
    'rioglyph-document-out': '',
    'rioglyph-document': 'file',
    'rioglyph-download': '',
    'rioglyph-drag-n-drop': 'grip six dots',
    'rioglyph-drive-history': '',
    'rioglyph-driver-card': '',
    'rioglyph-driver': '',
    'rioglyph-drivercard-in': '',
    'rioglyph-drivercard-out': '',
    'rioglyph-duplicate': '',
    'rioglyph-earphone': '',
    'rioglyph-empty': '',
    'rioglyph-envelope': '',
    'rioglyph-erase': '',
    'rioglyph-error-sign': '',
    'rioglyph-exclamation-sign': 'warning',
    'rioglyph-exclamation': 'warning',
    'rioglyph-eye-close': '',
    'rioglyph-eye-option': '',
    'rioglyph-facetime-video': '',
    'rioglyph-factory': 'plant production',
    'rioglyph-files': 'documents',
    'rioglyph-fill': '',
    'rioglyph-filled-chart': '',
    'rioglyph-filling-e-station': 'charging battery electric',
    'rioglyph-filling-station': '',
    'rioglyph-filter-active': '',
    'rioglyph-filter': '',
    'rioglyph-finish': '',
    'rioglyph-flash': 'error',
    'rioglyph-folder-closed': '',
    'rioglyph-folder-open': '',
    'rioglyph-fuel-electric': 'charging',
    'rioglyph-fuel-hydrogen': '',
    'rioglyph-fuel-gas': '',
    'rioglyph-fuel-diesel': '',
    'rioglyph-fullscreen': '',
    'rioglyph-geofence': '',
    'rioglyph-globe': '',
    'rioglyph-group': '',
    'rioglyph-hand-down': '',
    'rioglyph-hand-left': '',
    'rioglyph-hand-right': '',
    'rioglyph-hand-up': '',
    'rioglyph-heart': '',
    'rioglyph-heating': '',
    'rioglyph-home-sign': '',
    'rioglyph-home': '',
    'rioglyph-hour-glass': '',
    'rioglyph-info-sign': '',
    'rioglyph-info': '',
    'rioglyph-key': '',
    'rioglyph-kickdown': '',
    'rioglyph-language': '',
    'rioglyph-layer': '',
    'rioglyph-line-chart': '',
    'rioglyph-link': '',
    'rioglyph-load-unload': '',
    'rioglyph-load': '',
    'rioglyph-lock-open': '',
    'rioglyph-lock': '',
    'rioglyph-log-in': '',
    'rioglyph-logout': '',
    'rioglyph-looking-glass-man': '',
    'rioglyph-looking-glass': '',
    'rioglyph-maintenance-components': '',
    'rioglyph-map-marker': '',
    'rioglyph-menu-hamburger': 'option',
    'rioglyph-merge': '',
    'rioglyph-milage': '',
    'rioglyph-minus-light': '',
    'rioglyph-minus-sign': '',
    'rioglyph-minus': '',
    'rioglyph-missing': '',
    'rioglyph-mode-dark-light': '',
    'rioglyph-more': 'option',
    'rioglyph-new-window': 'open',
    'rioglyph-notification': '',
    'rioglyph-number-1': '',
    'rioglyph-number-2': '',
    'rioglyph-number-3': '',
    'rioglyph-off': '',
    'rioglyph-ok-circle': '',
    'rioglyph-ok-sign': '',
    'rioglyph-ok': '',
    'rioglyph-ongoing': '',
    'rioglyph-option-horizontal': '',
    'rioglyph-option-vertical': '',
    'rioglyph-order': '',
    'rioglyph-palette-broken': 'load',
    'rioglyph-palette': 'load',
    'rioglyph-parcel-broken': '',
    'rioglyph-parcel': 'load',
    'rioglyph-parking': '',
    'rioglyph-pause-circle': '',
    'rioglyph-pause': '',
    'rioglyph-pdf-file': 'document',
    'rioglyph-pencil': '',
    'rioglyph-phone': 'smartphone mobile',
    'rioglyph-pictures': '',
    'rioglyph-play-circle': '',
    'rioglyph-play': '',
    'rioglyph-plugged-off': '',
    'rioglyph-plugged-on': '',
    'rioglyph-plus-light': '',
    'rioglyph-plus-sign': 'add',
    'rioglyph-plus': 'add new',
    'rioglyph-pm': '',
    'rioglyph-poi': '',
    'rioglyph-polygon': 'geofence',
    'rioglyph-position': 'location',
    'rioglyph-print': '',
    'rioglyph-progress-ongoing': '',
    'rioglyph-progress-remaining': '',
    'rioglyph-pto-off': '',
    'rioglyph-pto-on': '',
    'rioglyph-pushpin': '',
    'rioglyph-pylon': '',
    'rioglyph-qr-code': '',
    'rioglyph-question-sign': '',
    'rioglyph-question': '',
    'rioglyph-record': '',
    'rioglyph-refresh': '',
    'rioglyph-remove-circle': '',
    'rioglyph-remove-sign': '',
    'rioglyph-remove': '',
    'rioglyph-repeat': '',
    'rioglyph-resize-full': '',
    'rioglyph-resize-horizontal': '',
    'rioglyph-resize-small': '',
    'rioglyph-resize-vertical': '',
    'rioglyph-retrofit': '',
    'rioglyph-retweet': '',
    'rioglyph-revert': 'undo',
    'rioglyph-rio-marker': '',
    'rioglyph-rio': 'company',
    'rioglyph-road-restrictions': '',
    'rioglyph-road': '',
    'rioglyph-robot': '',
    'rioglyph-role-management': '',
    'rioglyph-route-option': '',
    'rioglyph-route-view': '',
    'rioglyph-route': '',
    'rioglyph-save': '',
    'rioglyph-scale': '',
    'rioglyph-scan': '',
    'rioglyph-scissors': '',
    'rioglyph-search-truck': '',
    'rioglyph-search': '',
    'rioglyph-send': '',
    'rioglyph-series': '',
    'rioglyph-settings': '',
    'rioglyph-share-alt': '',
    'rioglyph-ship': 'asset',
    'rioglyph-shopping-cart': '',
    'rioglyph-slope-down-max': '',
    'rioglyph-slope-down-min': '',
    'rioglyph-slope-up-max': '',
    'rioglyph-slope-up-min': '',
    'rioglyph-sort-by-attributes-alt': '',
    'rioglyph-sort-by-attributes': '',
    'rioglyph-sort': '',
    'rioglyph-speed': '',
    'rioglyph-sphere': '',
    'rioglyph-spinner': 'loading',
    'rioglyph-split-view': '',
    'rioglyph-star-empty': '',
    'rioglyph-star': '',
    'rioglyph-stars': '',
    'rioglyph-start': '',
    'rioglyph-stats': '',
    'rioglyph-status-available': '',
    'rioglyph-status-driving': '',
    'rioglyph-status-resting': '',
    'rioglyph-status-working': '',
    'rioglyph-steering-wheel': '',
    'rioglyph-stopover': '',
    'rioglyph-support': '',
    'rioglyph-table-view': '',
    'rioglyph-tachograph-download': '',
    'rioglyph-tachograph': '',
    'rioglyph-tag': '',
    'rioglyph-temperature': '',
    'rioglyph-th-list': '',
    'rioglyph-thumbs-down': '',
    'rioglyph-thumbs-up': '',
    'rioglyph-tickets': '',
    'rioglyph-time-alt': '',
    'rioglyph-time-cancle': '',
    'rioglyph-time': '',
    'rioglyph-tire': '',
    'rioglyph-tms': '',
    'rioglyph-to-bottom': '',
    'rioglyph-to-left': '',
    'rioglyph-to-right': '',
    'rioglyph-to-top': '',
    'rioglyph-traffic-lights': '',
    'rioglyph-trailer-baseline': '',
    'rioglyph-trailer': 'asset',
    'rioglyph-trailerposition': '',
    'rioglyph-transfer': '',
    'rioglyph-trash': 'delete remove',
    'rioglyph-triangle-bottom': '',
    'rioglyph-triangle-left': '',
    'rioglyph-triangle-right': '',
    'rioglyph-triangle-top': '',
    'rioglyph-truck-baseline': 'asset',
    'rioglyph-truck-breakdown': 'asset',
    'rioglyph-truck': 'asset',
    'rioglyph-unlink': '',
    'rioglyph-upload': '',
    'rioglyph-user-sign': '',
    'rioglyph-user': '',
    'rioglyph-van-baseline': '',
    'rioglyph-van': 'asset',
    'rioglyph-volume': '',
    'rioglyph-warning-sign': '',
    'rioglyph-weather-cloudy': '',
    'rioglyph-weather-icy': '',
    'rioglyph-weather-overcast': '',
    'rioglyph-weather-raining': '',
    'rioglyph-weather-snowing': '',
    'rioglyph-weather-stormy': '',
    'rioglyph-weather-sunny': '',
    'rioglyph-weather-thundering': '',
    'rioglyph-weather-windy': '',
    'rioglyph-weight': '',
    'rioglyph-width': '',
    'rioglyph-wifi-off': 'network',
    'rioglyph-wifi': 'network',
    'rioglyph-workshop': '',
    'rioglyph-wrench': '',
    'rioglyph-xmas-raindeer': 'christmas',
    'rioglyph-xmas-santa': 'christmas',
};
