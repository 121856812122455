import React, {
    type ChangeEventHandler,
    type KeyboardEvent,
    type MouseEventHandler,
    type PropsWithChildren,
    type ReactNode,
    type Ref,
    useRef,
    HTMLProps,
} from 'react';
import classNames from 'classnames';
import noop from 'lodash/noop';

type RadioButtonIconProps = {
    icon: string;
    iconSize: number;
    iconLabelPosition: 'vertical' | 'horizontal';
    text: string | ReactNode;
};

const RadioButtonIcon = (props: RadioButtonIconProps) => {
    const { icon, iconSize, iconLabelPosition, text } = props;

    const iconStyles = {
        width: `${iconSize}px`,
        height: `${iconSize}px`,
        fontSize: `${iconSize}px`,
        lineHeight: `${iconSize}px`,
    };

    return (
        <span className={`radio-icon label-${iconLabelPosition}`}>
            <span className={`rioglyph ${icon}`} style={iconStyles} />
            <span className='radio-label'>{text}</span>
        </span>
    );
};

export type RadioButtonProps = Omit<HTMLProps<HTMLLabelElement>, 'label' | 'onClick'> & {
    /**
     * Define any rioglyph icon like "rioglyph-truck".
     */
    icon?: string;

    /**
     * The label position.
     *
     * Possible values are: "horizontal" or "vertical".
     *
     * @default 'vertical'
     */
    iconLabelPosition?: 'vertical' | 'horizontal';

    /**
     * The icon Size in px.
     *
     * @default 16
     */
    iconSize?: number;

    /**
     * Defines the label text.
     */
    label?: string | ReactNode;

    /**
     * Callback function that is invoked when the radio button is clicked.
     *
     * @default noop
     */
    onClick?: MouseEventHandler<{ value: string | string[] | number }>;

    /**
     * Callback function that is invoked when the radio button is toggled and the state should change (for controlled
     * usage).
     *
     * @default noop
     */
    onChange?: ChangeEventHandler;

    /**
     * Defines whether the radio is checked (for controlled usage).
     */
    checked?: boolean;

    /**
     * Defines whether the radio is initially checked (state can be changed on click).
     */
    defaultChecked?: boolean;

    /**
     * Defines whether the checkbox is disabled.
     *
     * @default false
     */
    disabled?: boolean;

    /**
     * Additional classes to be set on the input element.
     */
    className?: string;

    /**
     * Defines whether the radio button is applying an inline style.
     *
     * Use this in combination with other radio buttons.
     *
     * @default false
     */
    inline?: boolean;

    /**
     * Displays the icon on the right side of the text.
     *
     * @default false
     */
    right?: boolean;

    /**
     * Allows for rendering a completely different layout with or without a radio tick.
     *
     * @default false
     */
    custom?: boolean;

    /**
     * Name to be given to the input element.
     */
    name?: string;

    /**
     * The value attribute is a string containing the radio button's value but __it is never shown to the user__.
     * It serves a special purpose for inputs of type radio: when a form is submitted, only radio buttons which are
     * currently checked are submitted, and the reported value is the value of the value attribute.
     * If the value is not otherwise specified, it is the string on by default.
     *
     * This is useful when using native `FormData` when submitting a form to get the selected radio button value.
     */
    value?: string;

    /**
     * Number of the index used for keyboard support.
     *
     * An index of 0 means that the element should be focusable in sequential keyboard navigation, but its order is
     * defined by the document's source order. To disable the focus set the value to -1. A positive value means the
     * element should be focusable in sequential keyboard navigation, with its order defined by the value of the number.
     *
     * @default 0
     */
    tabIndex?: number;

    /**
     * Ref which is added to the input element.
     */
    inputRef?: Ref<HTMLInputElement>;
};

const RadioButton = (props: PropsWithChildren<RadioButtonProps>) => {
    const {
        icon = '',
        iconLabelPosition = 'vertical',
        iconSize = 16,
        label,
        onClick = noop,
        onChange = noop,
        checked,
        defaultChecked,
        disabled = false,
        className,
        inline = false,
        right = false,
        custom = false,
        name,
        value,
        tabIndex = 0,
        inputRef,
        children,
        ...remainingProps
    } = props;

    const isControlled = checked !== null && checked !== undefined;

    const labelRef = useRef<HTMLLabelElement>(null);

    const handleToggleKeyDown = (event: KeyboardEvent) => {
        switch (event.key) {
            case ' ': // toggle on space
            case 'Enter': // open on enter
                toggle(event);
                break;

            default:
                break;
        }
    };

    const toggle = (event: KeyboardEvent) => {
        event.preventDefault();

        if (disabled) {
            return;
        }

        // Controlled case - uses "onChange()" instead of "onClick()" callback
        if (isControlled) {
            onChange(event);
            return;
        }

        // Uncontrolled case - set the input checked or unchecked
        if (labelRef.current) {
            const checkbox = labelRef.current.firstChild as HTMLInputElement;
            checkbox.checked = !checkbox.checked;
        }

        onClick(event);
    };

    const text = label || children;

    const labelClassnames = classNames('radio', inline && 'radio-inline', className);
    const inputClassnames = classNames(right && 'icon-right', className);

    const renderCustomIcon = !!icon;
    const renderCustomContent = custom && children;
    const renderDefault = !icon && !custom;

    return (
        <label
            {...remainingProps}
            className={labelClassnames}
            tabIndex={tabIndex}
            onKeyDown={handleToggleKeyDown}
            ref={labelRef}
        >
            <input
                type='radio'
                defaultChecked={defaultChecked}
                checked={checked}
                disabled={disabled}
                className={inputClassnames}
                ref={inputRef}
                onClick={onClick}
                onChange={onChange}
                name={name}
                value={value}
            />
            {renderCustomIcon && (
                <RadioButtonIcon icon={icon} iconSize={iconSize} iconLabelPosition={iconLabelPosition} text={text} />
            )}
            {renderDefault && (
                <span className='radio-text'>
                    <span>{text}</span>
                </span>
            )}
            {renderCustomContent && children}
        </label>
    );
};

RadioButton.ICON_LABEL_VERTICAL = 'vertical';
RadioButton.ICON_LABEL_HORIZONTAL = 'horizontal';

export default RadioButton;
