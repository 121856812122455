import { Playground, PLAYGROUNG_BG_LIGHTER } from '../../../components/playground/Playground';
import { PlaygroundClasses } from '../../../components/playground/PlaygroundClasses';
import { Description } from '../../../components/Description';

export const TableFixed = () => (
    <div>
        <Description headline='Fixed table layout' anchor='table-fixed' />
        <p>
            The <code>table-layout-fixed</code> class avoids a horizontal overflow by squeezing all columns together.
            Use this option with caution since columns with a fixed width cannot be compressed any further, and the
            content of the squeezed columns may not be readable anymore.
        </p>
        <Playground
            example={tableLayoutFixedExample}
            classes={tableLayoutFixedClasses}
            background={PLAYGROUNG_BG_LIGHTER}
            className='overflow-hidden'
        />
    </div>
);

const tableLayoutFixedExample = (
    <table className='table table-head-filled table-layout-fixed'>
        <thead>
            <tr>
                {[...Array(5)].map(() => (
                    <th key={crypto.randomUUID()} className='ellipsis-1'>
                        Head column
                    </th>
                ))}
            </tr>
        </thead>
        <tbody>
            {[...Array(4)].map(() => (
                <tr key={crypto.randomUUID()}>
                    <td>Every</td>
                    <td>column has a different</td>
                    <td>length of content</td>
                    <td>but the width of each colum is always</td>
                    <td>the same</td>
                </tr>
            ))}
        </tbody>
    </table>
);

const tableLayoutFixedClasses = (
    <>
        <PlaygroundClasses label='<table>' classes='table' />
        <PlaygroundClasses label='Variants' classes='table-layout-fixed' />
    </>
);
