// @ts-ignore-next-line importsNotUsedAsValues
import React from 'react';
import classNames from 'classnames';
import isNil from 'lodash/fp/isNil';

import type { SelectOption } from './Select';

export type ClearButtonProps = {
    showClear: boolean;
    selectedItem: SelectOption | null;
    onClear: () => void;
};

const ClearButton = (props: ClearButtonProps) => {
    const { showClear, selectedItem, onClear } = props;

    const clearButtonClassNames = classNames(
        'clearButton',
        (!showClear || isNil(selectedItem)) && 'hide pointer-events-none'
    );

    return (
        <span className={clearButtonClassNames} onClick={onClear}>
            <span className='clearButtonIcon rioglyph rioglyph-remove-sign' />
        </span>
    );
};

export default ClearButton;
