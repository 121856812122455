import { colors, getColorNames } from '../../../utils/colors';
import { getRandomIcon } from '../../../utils/icons';
import { Title } from '../../../components/Title';

const eachColor: string[] = getColorNames(colors.brand, colors.status);

export default () => {
    return (
        <>
            <div className='display-grid grid-cols-1 gap-20 margin-bottom-25'>
                <div className='display-flex flex-wrap gap-20'>
                    <div>
                        <Title>Default buttons</Title>
                        <div className='btn-toolbar'>
                            <button type='button' className='btn btn-default'>
                                Default
                            </button>
                            <button type='button' className='btn btn-default'>
                                <span className={`rioglyph ${getRandomIcon()}`} aria-hidden='true' />
                                Default
                            </button>
                        </div>
                    </div>
                    <div>
                        <Title>Icon buttons</Title>
                        <div className='btn-toolbar'>
                            <button type='button' className='btn btn-default btn-icon-only'>
                                <span className={`rioglyph ${getRandomIcon()}`} aria-hidden='true' />
                            </button>
                            {eachColor.map((color, index) => (
                                <button type='button' className={`btn btn-${color} btn-icon-only`} key={index}>
                                    <span className={`rioglyph ${getRandomIcon()}`} aria-hidden='true' />
                                </button>
                            ))}
                        </div>
                    </div>
                    <div>
                        <Title>Muted buttons</Title>
                        <div className='btn-toolbar'>
                            <button type='button' className='btn btn-muted'>
                                Muted
                            </button>
                            <button type='button' className='btn btn-muted btn-icon-only'>
                                <span className={`rioglyph ${getRandomIcon()}`} aria-hidden='true' />
                            </button>
                        </div>
                    </div>
                </div>
                <div>
                    <Title>Link buttons</Title>
                    <div className='btn-toolbar'>
                        {eachColor.map((color, index) => (
                            <button type='button' className={`btn btn-${color} btn-link`} key={index}>
                                <span className={`rioglyph ${getRandomIcon()}`} aria-hidden='true' />
                                <span className='text-capitalize'>{color}</span>
                            </button>
                        ))}
                    </div>
                </div>
            </div>

            <div className='display-grid grid-cols-1 gap-20 margin-bottom-25'>
                <div>
                    <Title>Colored buttons</Title>
                    <div className='btn-toolbar'>
                        {eachColor.map((color, index) => (
                            <button type='button' className={`btn btn-${color}`} key={index}>
                                <span className={`rioglyph ${getRandomIcon()}`} aria-hidden='true' />
                                <span className='text-capitalize'>{color}</span>
                            </button>
                        ))}
                    </div>
                </div>
                {/* <div>
                    <Title>Disabled buttons</Title>
                    <div className='btn-toolbar'>
                        {['default', ...eachColor].map((color, index) => (
                            <button type='button' className={`btn btn-${color} disabled`} key={index}>
                                <span className='text-capitalize'>{color}</span>
                            </button>
                        ))}
                    </div>
                </div> */}
            </div>

            <div className='display-grid grid-cols-1 gap-20 margin-bottom-25'>
                <div>
                    <Title>Outline buttons</Title>
                    <div className='btn-toolbar padding-10 bg-checkerboard rounded'>
                        {eachColor.map((color, index) => (
                            <button type='button' className={`btn btn-${color} btn-outline`} key={index}>
                                <span className={`rioglyph ${getRandomIcon()}`} aria-hidden='true' />
                                <span className='text-capitalize'>{color}</span>
                            </button>
                        ))}
                    </div>
                </div>
                <div>
                    <Title>Split buttons</Title>
                    <div className='btn-toolbar'>
                        {['default', ...eachColor].map((color, index) => (
                            <div className='btn-group' key={index}>
                                <button type='button' className={`btn btn-${color}`}>
                                    <span className='text-capitalize'>{color}</span>
                                </button>
                                <button
                                    type='button'
                                    className={`btn btn-${color} dropdown-toggle`}
                                    data-toggle='dropdown'
                                >
                                    <span className='caret' />
                                </button>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
};
