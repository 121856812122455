export const createButtonRipple = (event: MouseEvent | TouchEvent, target: HTMLElement | null = null) => {
    const button = target || (event.currentTarget as HTMLElement);
    const buttonRect = button.getBoundingClientRect();

    const circle = document.createElement('span');
    const diameter = Math.max(button.clientWidth, button.clientHeight);
    const radius = diameter / 2;

    circle.style.width = circle.style.height = `${diameter}px`;

    if (event instanceof MouseEvent) {
        circle.style.left = `${event.clientX - buttonRect.left - radius}px`;
        circle.style.top = `${event.clientY - buttonRect.top - radius}px`;
    }

    // The TouchEvent does not contain clientX/clientY by definition. Most browsers will emulate/add it, though.
    // Nevertheless, we read the client values from the first touch object to be compliant.
    // Note: some browsers don't provide the global "TouchEvent" constructor!
    if (!!window.TouchEvent && event instanceof window.TouchEvent) {
        const firstTouch = event.touches[0];
        circle.style.left = `${firstTouch.clientX - buttonRect.left - radius}px`;
        circle.style.top = `${firstTouch.clientY - buttonRect.top - radius}px`;
    }

    circle.classList.add('btn-ripple');

    const rippleElement = button.getElementsByClassName('btn-ripple')[0];

    // Cleanup previous ripple element from current button in case there
    // are multiple clicks on the button itself
    if (rippleElement) {
        rippleElement.remove();
    }

    button.appendChild(circle);

    // Cleanup ripple element after some time
    setTimeout(() => circle.remove(), 1000);
};

const isTargetEligibleForEffect = (target: HTMLElement | null) => {
    if (!target || !target.className) {
        return false;
    }

    const { className = '' } = target;

    // In case the target is not a button but an SVG or anything else
    if (typeof className !== 'string') {
        return false;
    }

    const isButton = className.includes('btn ');
    const isNotButtonComponent = !className.includes('btn-component');
    const isNotLinkButton = !className.includes('btn-link');
    const isNotCloseButton = !className.includes('close');
    const isNotActionButton = !className.includes('btn-action');
    const isNotDisabled = !className.includes('disabled');

    return (
        isButton && isNotButtonComponent && isNotDisabled && isNotLinkButton && isNotCloseButton && isNotActionButton
    );
};

const enableButtonRipple = (event: MouseEvent | TouchEvent) => {
    const target = event.target as HTMLElement;
    if (target) {
        const isButton = isTargetEligibleForEffect(target);
        const isChildOfButton = !isButton && isTargetEligibleForEffect(target.parentElement);

        if (isButton || isChildOfButton) {
            createButtonRipple(event, isChildOfButton ? target.parentElement : target);
        }
    }
};

// Register button effect automatically (especially for non-React environments loading the UIKIT from CDN)
(document => {
    if (!document) {
        return;
    }
    document.addEventListener('mousedown', enableButtonRipple, false);
    document.addEventListener('touchstart', enableButtonRipple, false);
})(document);
