import { Playground } from '../../../components/playground/Playground';
import { Description } from '../../../components/Description';

export const Breadcrumb = () => (
    <div>
        <Description headline='Breadcrumb' anchor='breadcrumb' />
        <Playground example={breadcrumbExample} />
    </div>
);

const breadcrumbExample = (
    <ul className='breadcrumb'>
        <li>
            <span className='btn btn-link btn-link-inline'>
                <span className='rioglyph rioglyph-home margin-right-0' />
            </span>
        </li>
        <li>
            <span className='link'>level 1</span>
        </li>
        <li>
            <span className='link'>level 2</span>
        </li>
        <li>
            <span className='link'>level 3</span>
        </li>
        <li className='active'>level 4</li>
    </ul>
);
