import React, { useState, forwardRef, type ForwardedRef, type PropsWithChildren } from 'react';
import classNames from 'classnames';
import isEmpty from 'lodash/fp/isEmpty';
import noop from 'lodash/fp/noop';

import useClickOutside from '../../hooks/useClickOutside';

type ToggleButtonProps = {
    title: string | React.ReactNode;
    caret: boolean;
};

const ToggleButton = (props: ToggleButtonProps) => {
    const { title, caret } = props;
    const toggleClassName = classNames('ModuleNavigation-dropdown', 'dropdown-toggle justify-content-between', {
        inactive: !caret,
    });

    return (
        <a className={toggleClassName} role='button' id='basic-nav-dropdown'>
            <span>{title}</span>
            {caret && <span className='caret' />}
        </a>
    );
};

const DropdownMenu = (props: PropsWithChildren) => {
    const { children } = props;

    if (isEmpty(children)) {
        return null;
    }

    return (
        <ul role='menu' className='dropdown-menu ModuleNavigation-dropdown-menu'>
            {children}
        </ul>
    );
};

type AppMenuDropdownOpenerProps = {
    open: boolean;
    onDropdownClick: () => void;
};

type Ref = ForwardedRef<HTMLLIElement>;
type Props = PropsWithChildren<AppMenuDropdownOpenerProps>;

const AppMenuDropdownOpener = forwardRef((props: Props, ref: Ref) => {
    const { open, children, onDropdownClick } = props;
    return (
        <li ref={ref} className={classNames('dropdown', { open })} onClick={onDropdownClick}>
            {children}
        </li>
    );
});

export type AppMenuDropdownProps = {
    title: string | React.ReactNode;
    caret?: boolean;
    onToggleAppMenu?: (isOpen: boolean) => void;
};

export const AppMenuDropdown = (props: PropsWithChildren<AppMenuDropdownProps>) => {
    const { title, caret = false, onToggleAppMenu = noop, children } = props;

    const [isOpen, setIsOpen] = useState(false);

    const wrapperRef = useClickOutside(() => setIsOpen(false));

    const handleDropdownClick = () => {
        const newState = !isOpen;
        setIsOpen(newState);
        onToggleAppMenu(newState);
    };

    const openWithChildren = !isEmpty(children) && isOpen;

    return (
        <AppMenuDropdownOpener open={openWithChildren} onDropdownClick={handleDropdownClick} ref={wrapperRef}>
            <ToggleButton title={title} caret={caret} />
            <DropdownMenu>{children}</DropdownMenu>
        </AppMenuDropdownOpener>
    );
};

export default AppMenuDropdown;
