// forked from https://github.com/chakra-ui/chakra-ui/blob/main/packages/hooks/use-merge-refs/src/index.ts

import React, { useMemo } from 'react';

export type ReactRef<T> = React.RefCallback<T> | React.MutableRefObject<T>;

/**
 * Assigns a value to a React reference.
 *
 * @param ref - The React reference to assign the value to.
 * @param value - The value to assign to the reference.
 */
const assignRef = <T = any>(ref: ReactRef<T> | null | undefined, value: T) => {
    if (ref == null) {
        return;
    }

    if (typeof ref === 'function') {
        ref(value);
        return;
    }

    try {
        ref.current = value;
    } catch (error) {
        throw new Error(`Cannot assign value '${value}' to ref '${ref}'`);
    }
};

/**
 * Merges multiple React references into a single callback ref.
 *
 * @param refs - The React references to merge.
 * @returns A callback function to assign the merged references to a node.
 */
const mergeRefs = <T>(...refs: (ReactRef<T> | null | undefined)[]) => {
    return (node: T | null) => {
        refs.forEach(ref => {
            assignRef(ref, node);
        });
    };
};

/**
 * Custom hook to merge multiple React references into a single callback ref.
 *
 * @param refs - The React references to merge.
 * @returns A memoized callback function to assign the merged references to a node.
 */
const useMergeRefs = <T>(...refs: (ReactRef<T> | null | undefined)[]) => {
    return useMemo(() => mergeRefs(...refs), refs);
};

export default useMergeRefs;
