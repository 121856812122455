// @ts-ignore-next-line importsNotUsedAsValues
import React, { forwardRef, type HTMLAttributes, type PropsWithChildren } from 'react';
import classNames from 'classnames';

type ActionBarItemIconProps = HTMLAttributes<HTMLDivElement> & {
    className?: string;
};

const ActionBarItemIcon = forwardRef<HTMLDivElement, PropsWithChildren<ActionBarItemIconProps>>((props, ref) => {
    const { className, children, ...remainingProps } = props;

    const classes = classNames('ActionBarItemIcon', className);
    return (
        <div {...remainingProps} className={classes} ref={ref}>
            {children}
        </div>
    );
});

// The displayName is used in ActionBarItem for checking the component "isActionBarItemIcon"
ActionBarItemIcon.displayName = 'ActionBarItemIcon';

export default ActionBarItemIcon;
