import { useState } from 'react';

import Button from '../../../../../src/Button';

const tableData = [
    {
        id: 1,
        name: 'Lorem',
        vin: '7354',
        status: 'Active',
    },
    {
        id: 2,
        name: 'Ipsum',
        vin: '456457',
        status: 'Inactive',
    },
    {
        id: 3,
        name: 'Dolor',
        vin: '895',
        status: 'Active',
    },
    {
        id: 4,
        name: 'Sit',
        vin: '3456',
        status: 'Active',
    },
    {
        id: 5,
        name: 'Amet',
        vin: '2445',
        status: 'Active',
    },
    {
        id: 6,
        name: 'Consectetur',
        vin: '878',
        status: 'Active',
    },
];

const ROW_ANIMATION_CLASSNAME =
    'bg-white opacity-50 pointer-events-none animated-05s repeat-1 ease-in restart slide-out-right';

const ANIMATION_DURATION = 500;

const TableRowAnimationExample = () => {
    const [data, setData] = useState(tableData);
    const [rowIdToDelete, setRowIdToDelete] = useState<number | undefined>();

    const handleRemoveRow = (fatalRow: number) => {
        setRowIdToDelete(fatalRow);

        setTimeout(() => {
            setData(data.filter(item => item.id !== fatalRow));
            setRowIdToDelete(undefined);
        }, ANIMATION_DURATION);
    };

    const handleReset = () => setData(tableData);

    return (
        <div>
            <div className='overflow-hidden'>
                <table className='table table-head-filled table-bordered'>
                    <thead>
                        <tr>
                            <th>Id</th>
                            <th>Name</th>
                            <th>VIN</th>
                            <th>Status</th>
                            <th className='width-50' />
                        </tr>
                    </thead>
                    <tbody>
                        {data.map(item => {
                            const rowClassName = rowIdToDelete === item.id ? ROW_ANIMATION_CLASSNAME : '';
                            return (
                                <tr key={item.id} className={rowClassName}>
                                    <td>{item.id}</td>
                                    <td>{item.name}</td>
                                    <td>{item.vin}</td>
                                    <td>{item.status}</td>
                                    <td className='padding-0 text-center'>
                                        <span>
                                            <Button
                                                iconOnly
                                                bsStyle={Button.MUTED}
                                                iconName='rioglyph-trash'
                                                onClick={() => handleRemoveRow(item.id)}
                                            />
                                        </span>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
            <div className='btn-toolbar margin-top-15'>
                <Button bsStyle={Button.PRIMARY} disabled={!!rowIdToDelete} onClick={() => handleRemoveRow(3)}>
                    Remove row
                </Button>
                <Button onClick={handleReset}>Reset table</Button>
            </div>
        </div>
    );
};

export default TableRowAnimationExample;
