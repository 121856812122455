/* eslint-disable import/order */
import React, {
    forwardRef,
    type PropsWithChildren,
    type ForwardedRef,
    type MutableRefObject,
    type RefAttributes,
    type ForwardRefExoticComponent,
} from 'react';
import classNames from 'classnames';

import ApplicationLayoutHeader from './ApplicationLayoutHeader';
import ApplicationLayoutSidebar from './ApplicationLayoutSidebar';
import ApplicationLayoutBody from './ApplicationLayoutBody';
import { initDocumentBootstrapping } from '../../utils/init/initDocumentBootstrapping';
import { isTest } from '../../utils/init/initConfig';

// initializes document bootstrapping - keep the import to avoid being tree-shaken
// noinspection JSIgnoredPromiseFromCall
initDocumentBootstrapping();

export type ApplicationLayoutProps = {
    /**
     * A React ref function assigned to the wrapper element.
     */
    layoutRef?: MutableRefObject<HTMLDivElement | null>;

    /**
     * Additional class names that are added to the respective component
     */
    className?: string;
};

type Ref = ForwardedRef<HTMLDivElement>;
type Props = PropsWithChildren<ApplicationLayoutProps>;

type ApplicationLayoutType = ForwardRefExoticComponent<Props & RefAttributes<HTMLDivElement>> & {
    Header: typeof ApplicationLayoutHeader;
    Sidebar: typeof ApplicationLayoutSidebar;
    Body: typeof ApplicationLayoutBody;
};

const ApplicationLayout = forwardRef((props: Props, ref: Ref) => {
    const { className, layoutRef, children, ...remainingProps } = props;

    const classes = classNames('ApplicationLayout', className);

    return (
        <div {...remainingProps} ref={ref || layoutRef} className={classes}>
            {children}
        </div>
    );
}) as ApplicationLayoutType;

ApplicationLayout.Header = ApplicationLayoutHeader;
ApplicationLayout.Sidebar = ApplicationLayoutSidebar;
ApplicationLayout.Body = ApplicationLayoutBody;

export default ApplicationLayout;
