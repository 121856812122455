// @ts-ignore-next-line importsNotUsedAsValues
import React, { type ReactNode } from 'react';
import classNames from 'classnames';

import Button from '../../Button';

export type TableSettingsDialogFooterProps = {
    hasChanged: boolean;
    isResetAll: boolean;
    immediateChange: boolean;
    resetButtonText: string | ReactNode;
    closeButtonText: string | ReactNode;
    cancelButtonText: string | ReactNode;
    applyButtonText: string | ReactNode;
    onHide: () => void;
    onResetColumnChanges: () => void;
    onDiscardChanges: () => void;
    onApplyChanges: () => void;
    onConfirmResetColumnChanges: () => void;
    onCancelResetColumnChanges: () => void;
};

export const TableSettingsDialogFooter = (props: TableSettingsDialogFooterProps) => {
    const {
        hasChanged,
        isResetAll,
        immediateChange,
        resetButtonText,
        closeButtonText,
        cancelButtonText,
        applyButtonText,
        onHide,
        onResetColumnChanges,
        onDiscardChanges,
        onApplyChanges,
        onConfirmResetColumnChanges,
        onCancelResetColumnChanges,
    } = props;

    const restButtonClassNames = classNames('btn', 'btn-link', !hasChanged && 'disabled');

    return (
        <div className='display-flex justify-content-between'>
            <div>
                {!isResetAll && (
                    <div className={restButtonClassNames} onClick={onResetColumnChanges}>
                        <span className='rioglyph rioglyph-revert text-size-xs margin-right-10' />
                        {resetButtonText}
                    </div>
                )}
                {isResetAll && (
                    <div className='btn-group'>
                        <Button
                            iconOnly
                            iconName='rioglyph-remove'
                            className='margin-right-5 btn-outline'
                            onClick={onCancelResetColumnChanges}
                        />
                        <Button
                            iconOnly
                            bsStyle={Button.PRIMARY}
                            iconName='rioglyph-ok'
                            onClick={onConfirmResetColumnChanges}
                        />
                    </div>
                )}
            </div>
            {immediateChange ? (
                <Button onClick={onHide}>{closeButtonText}</Button>
            ) : (
                <div className='btn-toolbar'>
                    <Button onClick={onDiscardChanges}>{cancelButtonText}</Button>
                    <Button bsStyle={Button.PRIMARY} onClick={onApplyChanges}>
                        {applyButtonText}
                    </Button>
                </div>
            )}
        </div>
    );
};
