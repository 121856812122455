import { useState } from 'react';

import Spinner from '../../../../../src/Spinner';
import SimpleTooltip from '../../../../../src/SimpleTooltip';
import useInterval from '../../../../../src/useInterval';

export default () => {
    const [value, setValue] = useState(0);

    useInterval(() => {
        setValue(oldValue => (oldValue + 1) % 101);
    }, 150);

    return (
        <div className='panel panel-default panel-body max-width-500 display-flex flex-column gap-5'>
            <div className='display-flex justify-content-between gap-5'>
                <div className='display-flex'>
                    <div className='margin-right-3 text-color-dark'>Label</div>
                    <SimpleTooltip content='Some helper text' placement='top'>
                        <span className='text-color-dark'>
                            <span className='rioglyph rioglyph-question-sign' />
                        </span>
                    </SimpleTooltip>
                </div>
                <span className='display-flex gap-5'>
                    <span className='text-color-darker'>{value}%</span>
                    <span className='scale-80'>
                        <Spinner />
                    </span>
                </span>
            </div>
            <div className='progress'>
                <div className='progress-bar bg-info' style={{ width: `${value}%` }} />
            </div>
            <div className='text-color-dark text-size-12 margin-top-5'>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam vehicula magna quis ante tincidunt, at
                volutpat nunc facilisis. Sed auctor.
            </div>
        </div>
    );
};
