// @ts-ignore-next-line importsNotUsedAsValues
import React from 'react';
import classNames from 'classnames';

import SimpleTooltip from '../tooltip/SimpleTooltip';
import type { TooltipWidth } from '../tooltip/Tooltip';
import { PLACEMENT } from '../../values/Placement';
import type { ObjectValues } from '../../utils/ObjectValues';

export type NoDataProps = {
    /**
     * The actual translated "No data" text.
     */
    text: string | React.ReactNode;

    /**
     * Additional explanation shown inside a tooltip.
     */
    tooltip?: string | React.ReactNode;

    /**
     * Sets the direction the Tooltip is positioned towards.
     *
     * Possible values are:
     * `auto-start`, `auto`, `auto-end`,
     * `top-start`, `top`, `top-end`,
     * `bottom-start`, `bottom`, `bottom-end`,
     * `right-start`, `right`, `right-end`,
     * `left-start`, `left`, `left-end`
     */
    tooltipPlacement?: ObjectValues<typeof PLACEMENT>;

    /**
     * The width of the tooltip.
     *
     * Possible values are:
     * `auto`, `100`, `150`, `200`, `250`, `300`, `350`, `400`, `450`, `500`
     */
    tooltipWidth?: TooltipWidth;

    /**
     * Additional class names to be added to the wrapping element.
     */
    className?: string;
};

const NoData = (props: NoDataProps) => {
    const {
        text,
        className = '',
        tooltip,
        tooltipPlacement = PLACEMENT.BOTTOM,
        tooltipWidth = 'auto',
        ...remainingProps
    } = props;

    const noDataClasses = classNames('NoData', className);

    if (tooltip) {
        return (
            <span {...remainingProps} className={noDataClasses}>
                <span className='pointer-events-none user-select-none'>{text}</span>
                <SimpleTooltip content={tooltip} placement={tooltipPlacement} width={tooltipWidth}>
                    <span className='rioglyph rioglyph-info-sign margin-left-5' />
                </SimpleTooltip>
            </span>
        );
    }

    return (
        <span {...remainingProps} className={noDataClasses}>
            <span className='pointer-events-none user-select-none'>{text}</span>
        </span>
    );
};

export default NoData;
