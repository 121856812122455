import { Playground } from '../../../components/playground/Playground';
import { PlaygroundClasses } from '../../../components/playground/PlaygroundClasses';
import { Description } from '../../../components/Description';

const logo = 'https://cdn.rio.cloud/svg/common/ico_rio_colored.svg';

const eachBackgroundSize = ['10', '20', '30', '40', '50', '60', '70', '80', '90', '100'];
const eachBackgroundPositions = [
    'top-left',
    'top',
    'top-right',
    'center-right',
    'bottom-right',
    'bottom',
    'bottom-left',
    'center-left',
];
const eachBackgroundRepeat = ['no', 'xy', 'x', 'y'];

export const Background = () => (
    <div>
        <Description headline='Background' anchor='background' />
        <Playground example={backgroundExample} classes={opacityClasses} />
    </div>
);

const backgroundExample = (
    <div>
        <Description headline='Background size' headlineType='h4' headlineNoTopSpace />
        <div className='display-flex flex-wrap gap-10'>
            {eachBackgroundSize.map((value, index) => (
                <div
                    className={`width-100 aspect-ratio-1 bg-size-${value}pct bg-repeat-no bg-light`}
                    style={{ backgroundImage: `url(${logo})` }}
                    key={index}
                />
            ))}
        </div>
        <Description headline='Background position' headlineType='h4' />
        <div className='display-flex flex-wrap gap-10'>
            {eachBackgroundPositions.map((value, index) => (
                <div
                    className={`width-100 aspect-ratio-1 bg-size-20pct bg-position-${value} bg-repeat-no bg-light`}
                    style={{ backgroundImage: `url(${logo})` }}
                    key={index}
                />
            ))}
        </div>
        <Description headline='Background repeat' headlineType='h4' />
        <div className='display-flex flex-wrap gap-10'>
            {eachBackgroundRepeat.map((value, index) => (
                <div
                    className={`width-100 aspect-ratio-1 bg-size-20pct bg-position-center bg-repeat-${value} bg-light`}
                    style={{ backgroundImage: `url(${logo})` }}
                    key={index}
                />
            ))}
        </div>
    </div>
);

const opacityClasses = (
    <>
        <PlaygroundClasses label='Hide' classes='bg-none' />
        <PlaygroundClasses label='Size' prefix='bg-size-' classes={['cover', ...eachBackgroundSize]} />
        <PlaygroundClasses label='Position' prefix='bg-position-' classes={eachBackgroundPositions} />
        <PlaygroundClasses label='Repeat' prefix='bg-repeat-' classes={eachBackgroundRepeat} />
        <PlaygroundClasses label='Fixed' classes='bg-attachment-fixed' />
    </>
);
