import React, { type ReactNode, useCallback, useRef } from 'react';
import Dropzone, { type DropzoneProps, type DropzoneRef } from 'react-dropzone';
import noop from 'lodash/noop';

import Button from '../button/Button';

type FilePickerRenderProps = {
    isDragActive: boolean;
};

export type FilePickerProps = {
    /**
     * Defines the file picker display mode.
     *
     * Possible values are `'button'` for a single button, `'dropzone'` for a custom dropzone passed as child function,
     * or `'full'` for showing both.
     *
     * @default 'button'
     */
    displayMode?: 'button' | 'dropzone' | 'full';

    /**
     * Indicating if multiple files may be selected.
     *
     * @default true
     */
    multiple?: boolean; // multi select

    /**
     * Text to display on Button if displayMode is set to "button".
     *
     * @default 'Select files'
     */
    label?: string | ReactNode;

    /**
     * Maximum file size.
     */
    maxSize?: number;

    /**
     * Function called after one or multiple files have been picked.
     */
    onPick?: (files: FileList | null) => void;

    /**
     * Additional classes for the select button.
     *
     * @default ''
     */
    className?: string;

    /**
     * Object list of accepted Mime Types as keys and file extensions array as value.
     */
    accept?: { [mimeType: string]: string[] };

    /**
     * Pass a custom dropzone element as function receiving some render props.
     */
    children?: ({ isDragActive }: FilePickerRenderProps) => any;
};

const FilePicker = (props: FilePickerProps) => {
    const {
        displayMode = 'button',
        multiple = true,
        label = 'Select files',
        maxSize,
        onPick = noop,
        className = '',
        accept,
        children,
        ...remainingProps
    } = props;

    const dropzoneRef = useRef<DropzoneRef>(null);

    const isFull = displayMode === 'full';
    const showButton = isFull || displayMode === 'button';
    const showDropzone = isFull || displayMode === 'dropzone';

    const handleDrop: NonNullable<DropzoneProps['onDrop']> = useCallback(
        (acceptedFiles, rejectedFiles) => {
            const hasImagesType = accept && Object.keys(accept).some(mimeType => mimeType.startsWith('image'));
            const files = hasImagesType
                ? acceptedFiles.map(file => Object.assign(file, { preview: URL.createObjectURL(file) }))
                : acceptedFiles;

            onPick(files, rejectedFiles);
        },
        [onPick, accept]
    );

    const handleClick = () => dropzoneRef.current?.open();

    return (
        <div className='FilePicker'>
            {showButton && (
                <Button onClick={handleClick} className={className}>
                    {label}
                </Button>
            )}
            <Dropzone
                {...remainingProps}
                onDrop={handleDrop}
                accept={accept}
                multiple={multiple}
                maxSize={maxSize}
                ref={dropzoneRef}
            >
                {({ getRootProps, getInputProps, isDragActive }) => {
                    return (
                        <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            {showDropzone && children && children({ isDragActive })}
                        </div>
                    );
                }}
            </Dropzone>
        </div>
    );
};

FilePicker.DISPLAY_MODE_BUTTON = 'button' as const;
FilePicker.DISPLAY_MODE_DROPZONE = 'dropzone' as const;
FilePicker.DISPLAY_MODE_FULL = 'full' as const;

export default FilePicker;
