import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import classNames from 'classnames';
import isEmpty from 'lodash/fp/isEmpty';

import useClickOutside from '../../hooks/useClickOutside';
import CollapsedNavItem from './CollapsedNavItem';
import type { ModulePropType } from './ApplicationHeader';

const collapsedDropdownWidth = 50;
const paddingLeft = 15;

export type NavItemsProps = {
    navItems?: ModulePropType[];
    containerWidth: number;
    actionBarItems?: React.ReactNode[];
};

export const NavItems = (props: NavItemsProps) => {
    const { navItems: externalNavItems = [], actionBarItems = [], containerWidth } = props;

    const [isCollapsedNavItemOpen, setIsCollapsedNavItemOpen] = useState(false);
    const [collapsedNavItems, setCollapsedNavItems] = useState<ModulePropType[]>([]);
    const [visibleNavItems, setVisibleNavItems] = useState<ModulePropType[]>([]);

    const collapsedDropdownRef = useClickOutside(() => setIsCollapsedNavItemOpen(false));

    const navigationRef = useRef(null);
    const isOffscreen = useRef(true);

    // console.log({ containerWidth });

    // Before any new changes are applied
    useEffect(() => {
        // After the component received new props, every nav item has to be rendered offscreen
        // again without collapsing them into the dropdown to measure its width
        isOffscreen.current = true;
    }, [externalNavItems, actionBarItems, containerWidth]);

    // After the component has been rendered
    useLayoutEffect(() => {
        // After every nav item was rendered offscreen again we need to recalculate the width
        // of the items again and render the result with possible collapsible dropdown.
        if (isOffscreen.current) {
            computeNavItems();
        }
    });

    const getAvailableWidth = (element: HTMLElement) => {
        // Get relevant elements
        // const parent = element.parentNode;

        // const actionBar = head(parent.getElementsByClassName('ApplicationActionBar'));
        // const actionBarWidth = actionBar && actionBar.childElementCount > 0 ? actionBar.scrollWidth : 0;

        // Since the collapsed dropdown has not been rendered yet,
        // use the fixed with for it to compute available width
        const availableWidth = element.scrollWidth - collapsedDropdownWidth - paddingLeft;
        return availableWidth;
    };

    const computeNavItems = () => {
        const element: HTMLElement | null = navigationRef.current;

        if (!element) {
            return;
        }

        const navItems = [...((element as HTMLElement).children || [])];

        const availableWidth = getAvailableWidth(element);

        if (availableWidth < 0) {
            return;
        }

        // Iterate over all rendered navItems to figure out their widths in order to figure out
        // which navItems need to be rendered in the collapsed dropdown
        let requiredNavItemsSize = 0;

        const updatedVisibleItems: ModulePropType[] = [];
        const updatedCollapsedItems: ModulePropType[] = [];

        navItems.forEach(item => {
            if (!item.className) {
                return;
            }

            // Calculate the remaining width
            requiredNavItemsSize = requiredNavItemsSize + item.scrollWidth;

            // console.log({ requiredNavItemsSize });

            // Use the navItems.key to find corresponding navItems for each DOM node
            const matchedNavItem = externalNavItems.find(navItem => {
                const dataAttr = item.attributes.getNamedItem('data-nav-item-key');
                return dataAttr && navItem.key === dataAttr.value;
            });

            if (!matchedNavItem) {
                return;
            }

            // Add navItems to the visible group until the available with exceeds.
            // All other navItems will be added to the collapsed group
            if (availableWidth > requiredNavItemsSize) {
                updatedVisibleItems.push(matchedNavItem);
            } else {
                updatedCollapsedItems.push(matchedNavItem);
            }
        });

        // Set the state to render the navItems again
        isOffscreen.current = false;

        setVisibleNavItems(updatedVisibleItems);
        setCollapsedNavItems(updatedCollapsedItems);
    };

    const handleCollapsedDropdown = () => {
        // When the dropdown is open, set showCollapsedNavItems to false so all submodule items
        // are rendered and their size can be computed
        if (isCollapsedNavItemOpen) {
            setIsCollapsedNavItemOpen(false);
        } else {
            // When the dropdown is closed, just set the flag to open is enough
            setIsCollapsedNavItemOpen(true);
        }
    };

    const handleCollapsedNavItemSelected = () => {
        // Close collapsed dropdown on item select
        if (isCollapsedNavItemOpen) {
            setIsCollapsedNavItemOpen(false);
        }
    };

    const renderNavItem = (navItem: ModulePropType, isItemOffscreen: boolean) => {
        return (
            <li
                key={navItem.key}
                className={`submodule ${isItemOffscreen ? 'offscreen' : ''}`}
                data-nav-item-key={navItem.key}
            >
                {navItem.route}
            </li>
        );
    };

    const renderNavItems = () => {
        if (!isOffscreen.current && !isEmpty(collapsedNavItems)) {
            const visibleItems = visibleNavItems.map(navItem => renderNavItem(navItem, isOffscreen.current));

            return [
                ...visibleItems,
                <CollapsedNavItem
                    key='CollapsedNavItem'
                    ref={collapsedDropdownRef}
                    show={isCollapsedNavItemOpen}
                    navItems={collapsedNavItems}
                    actionBarItems={actionBarItems}
                    onDropdownClick={handleCollapsedDropdown}
                    onItemClick={handleCollapsedNavItemSelected}
                />,
            ];
        }

        return externalNavItems.map(navItem => renderNavItem(navItem, isOffscreen.current));
    };

    // As all navItems need to be added to the DOM first in order to get their real size
    // the computation whether a navItem need to be shown under the collapsed dropdown or not
    // needs to be done after the component did mount to the DOM.

    const classes = classNames('SubmoduleNavigation', 'nav');

    return (
        <ul className={classes} ref={navigationRef}>
            {renderNavItems()}
        </ul>
    );
};

export default NavItems;
