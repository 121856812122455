import { applyMiddleware, createStore } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createHashHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';

import { rootReducer } from './reducers';

const history = createHashHistory({
    hashType: 'noslash',
});

function configureStore(preloadedState) {
    const middlewares = [thunkMiddleware, routerMiddleware(history)];
    const middlewareEnhancer = applyMiddleware(...middlewares);

    const enhancers = [middlewareEnhancer];
    const composedEnhancers = composeWithDevTools(...enhancers);

    const store = createStore(rootReducer(history), preloadedState, composedEnhancers);

    return store;
}

const store = configureStore();

export { history, store };
