import React from 'react';
import noop from 'lodash/fp/noop';
import classNames from 'classnames';

import TreeSidebarCategories from './TreeSidebarCategories';
import type { TreeCategoryNode } from './TreeCategory';

export type TreeSidebarProps = {
    currentCategoryId?: string;
    onSelectCategory?: (selectedCategory: string) => void;
    onClick?: VoidFunction;
    className?: string;
    children?: TreeCategoryNode[];
};

const TreeSidebar = React.memo((props: TreeSidebarProps) => {
    const { className, onClick = noop, ...remainingProps } = props;

    const classes = classNames('TreeSidebar', className);

    return (
        <div className={classes}>
            <ul className='TreeSidebarNavigation' onClick={onClick}>
                <TreeSidebarCategories {...remainingProps} />
            </ul>
            <div className='TreeSidebarToggle' onClick={onClick}>
                <span className='rioglyph rioglyph-chevron-left' />
            </div>
        </div>
    );
});

export default TreeSidebar;
