import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import appReducer from '../sites/demoService/reducer';

export const rootReducer = history =>
    combineReducers({
        app: appReducer,
        router: connectRouter(history),
    });
