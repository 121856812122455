import { Playground } from '../../../components/playground/Playground';
import {
    PlaygroundClasses,
    PlaygroundResponsiveClasses,
    PlaygroundClassesSpacer,
} from '../../../components/playground/PlaygroundClasses';
import { Description } from '../../../components/Description';
import { Note } from '../../../components/Note';

import { spacings } from '../../../utils/spacings';
const spacingsWithoutZero = spacings.slice(1);

export const Gap = () => (
    <div>
        <Description headline='Gap' anchor='gap'>
            Gap classes can be used for flexbox and grid layouts.
        </Description>
        <Playground example={gapExample} classes={gapClasses} />
        <Note>
            Since using a percentage vertical gap requires a fixed height of the wrapping element, we decided to offer
            only horizontal percentage gap classes. Vertical gap in px is of course possible.
        </Note>
    </div>
);

const gapExample = (
    <>
        <div className='display-grid grid-cols-1 gap-10'>
            {spacings.map(gap => (
                <div className={`display-grid grid-cols-3 gap-${gap}`} key={crypto.randomUUID()}>
                    {[...Array(3)].map(() => (
                        <div className='bg-info padding-10 rounded' key={crypto.randomUUID()}>
                            gap-{gap}
                        </div>
                    ))}
                </div>
            ))}
        </div>
    </>
);

const gapClasses = (
    <>
        <PlaygroundClassesSpacer label='Pixel Values' />
        <PlaygroundClasses label='Gap x & y' prefix='gap-' classes={spacings} />
        <PlaygroundClasses label='Column Gap' prefix='column-gap-' classes={spacings} />
        <PlaygroundClasses label='Row Gap' prefix='row-gap-' classes={spacings} />
        <PlaygroundClassesSpacer label='Percentage Values' />
        <PlaygroundClasses label='Column Gap' prefix='column-gap-' classes={spacingsWithoutZero} suffix='pct' />
        <PlaygroundResponsiveClasses />
    </>
);
