// @ts-ignore-next-line importsNotUsedAsValues
import React from 'react';
import classNames from 'classnames';

import Fade from '../fade/Fade';

type BaseSpinnerProps = {
    isInverse?: boolean;
    isDoubleSized?: boolean;
};

const BaseSpinner = (props: BaseSpinnerProps) => {
    const { isInverse, isDoubleSized, ...remainingProps } = props;
    const className = classNames('spinner', { inverse: isInverse, double: isDoubleSized });
    return (
        <div {...remainingProps} className={className}>
            <div />
            <div />
            <div />
            <div />
        </div>
    );
};

export interface SpinnerProps {
    /**
     * Defines whether the spinner is shown on bright or dark background.
     *
     * @default false
     */
    isInverse?: boolean;

    /**
     * If set to "true" the spinner will be rendered twice as big.
     *
     * @default false
     */
    isDoubleSized?: boolean;

    /**
     * Renders the spinner as overlay in full-size mode.
     *
     * This will be relative to the parent container. Set the parent element to "position-relative" in this case.
     *
     * @default false
     */
    isFullSized?: boolean;

    /**
     * Renders the spinner in fullscreen mode, relative to the body.
     *
     * @default false
     */
    isFullScreen?: boolean;

    /**
     * Renders the spinner without centered wrapper.
     *
     * @default false
     */
    isInline?: boolean;

    /**
     * Flag to control the visibility of the spinner.
     *
     * Only relevant in combination with {@link isFullSized} or {@link isFullScreen}.
     *
     * @default false
     */
    show?: boolean;

    /**
     * The text shown next to the spinner icon.
     */
    text?: string | React.ReactNode;
}

const Spinner = (props: SpinnerProps) => {
    const {
        isInverse = false,
        isFullSized = false,
        isFullScreen = false,
        isDoubleSized = false,
        isInline = false,
        show = false,
        text,
        ...remainingProps
    } = props;

    const baseSpinner = <BaseSpinner isDoubleSized={isDoubleSized} isInverse={isInverse} {...remainingProps} />;

    if (isInline) {
        return baseSpinner;
    }

    const classes = classNames(
        ['spinnerInfoBox', 'display-flex', 'justify-content-center', 'align-items-center', 'height-100pct'],
        { fullsized: isFullSized, fullscreen: isFullScreen }
    );

    const wrappedSpinner = (
        <div className={classes}>
            {baseSpinner}
            {text && <div className='spinnerText'>{text}</div>}
        </div>
    );

    if (isFullScreen || isFullSized) {
        return (
            <Fade show={show} duration={0.1}>
                {wrappedSpinner}
            </Fade>
        );
    }

    return wrappedSpinner;
};

export default Spinner;
