import React, { memo, useState, useRef, forwardRef, PropsWithChildren } from 'react';
import classNames from 'classnames';
import head from 'lodash/fp/head';
import isArray from 'lodash/fp/isArray';
import noop from 'lodash/fp/noop';
import invariant from 'tiny-invariant';

import TreeCategory, { type TreeCategoryProps } from './TreeCategory';
import Resizer from '../resizer/Resizer';
import TreeSidebar from './TreeSidebar';
import getWidthInBoundaries from '../../utils/getWidthInBoundaries';
import mergeRefs from '../../utils/mergeRefs';
import usePrevious from '../../usePrevious';
import SmoothScrollbars from '../smoothScrollbars/SmoothScrollbars';

const DEFAULT_WIDTH = 350;
const DEFAULT_MIN_WIDTH = 100;
const DEFAULT_MAX_WIDTH = 0;

const TreeMode = {
    MODE_FLY: 'fly',
    MODE_FLUID: 'fluid',
} as const;

const getSidebarBodyRef = (sidebarRef: React.MutableRefObject<HTMLDivElement | null>) =>
    head(sidebarRef?.current?.getElementsByClassName('AssetTreeBody'));

const getCurrentCategoryElement = (children: React.ReactElement[], currentCategoryId: string) => {
    return isArray(children) ? children.find(child => child && child.props.id === currentCategoryId) : children;
};

const renderTreesOffscreen = (children: React.ReactElement[], categoryId: string | undefined) => {
    return React.Children.map(children, child => {
        const offscreenClasses = classNames(
            'TreeOffscreenWrapper',
            child && child.props.id !== categoryId && 'position-offscreen pointer-events-none'
        );
        return <div className={offscreenClasses}>{child}</div>;
    });
};

export type AssetTreeProps = {
    /**
     * Defines if the component will overlap the body content.
     *
     * @default false
     */
    fly?: boolean;

    /**
     * Defines whether or not the sidebar is resizable.
     *
     * @default true
     */
    resizable?: boolean;

    /**
     * Defines whether the component has a border or not.
     *
     * @default false
     */
    bordered?: boolean;

    /**
     * Defines the width of the component. The value is set as inline style.
     * Note: when component is resizable it will take the provided
     * width in px only and convert it to number in case.
     *
     * @default 350
     */
    width?: number;

    /**
     * Defines the minimum width of the component in px that will take effect when resizing.
     *
     * @default 100
     */
    minWidth?: number;

    /**
     * Defines the maximum width of the component in px that will take effect when resizing.
     *
     * @default 0
     */
    maxWidth?: number;

    /**
     * Defines the height of the component in px.
     */
    height?: number;

    /**
     * Defines whether the component is open or not.
     *
     * @default true
     */
    isOpen?: boolean;

    /**
     * Callback for when the tree visibility is toggled.
     * @param isOpen
     * @returns
     */
    onToggleTree?: (isOpen: boolean) => void;

    /**
     * The id of the category which is currently active and shall be shown.
     */
    currentCategoryId?: string;

    /**
     * Callback for handling change of category.
     * @param selectedCategoryId
     * @returns
     */
    onCategoryChange?: (selectedCategoryId: string) => void;

    /**
     * Callback for when the resize is done.
     */
    onResizeEnd?: VoidFunction;

    /**
     * Defines whether the tree components are rendered offscreen and kept mounted in the DOM.
     *
     * @default false
     */
    useOffscreen?: boolean;

    /**
     * Additional classes added on the wrapper element.
     */
    className?: string;
};

// Check if the child is a React element and if it has the type TreeCategory
const isTreeCategory = (child: React.ReactNode): child is React.ReactElement<TreeCategoryProps> => {
    return React.isValidElement(child) && child.type === TreeCategory;
};

const AssetTree = memo(
    forwardRef<HTMLDivElement, PropsWithChildren<AssetTreeProps>>((props, ref) => {
        const {
            className,
            resizable = true,
            width = DEFAULT_WIDTH,
            minWidth = DEFAULT_MIN_WIDTH,
            maxWidth = DEFAULT_MAX_WIDTH,
            height,
            bordered = false,
            currentCategoryId,
            isOpen = true,
            useOffscreen = false,
            fly = false,
            onCategoryChange = noop,
            onToggleTree = noop,
            onResizeEnd = noop,
            children = [],
            ...remainingProps
        } = props;

        const getSidebarMode = (isFly: boolean) => (isFly ? TreeMode.MODE_FLY : TreeMode.MODE_FLUID);

        const [treeWidth, setTreeWidth] = useState(width);
        const [isResize, setIsResize] = useState(false);
        const [sidebarMode, setSidebarMode] = useState(getSidebarMode(fly));

        const sidebarRef = useRef<HTMLDivElement>(null);

        const mergedRefs = mergeRefs([ref, sidebarRef]);

        // Update internal state when props change
        const previousWidth = usePrevious(width);
        if (previousWidth !== width) {
            setTreeWidth(width);
        }

        const [previousMode, setPreviousMode] = useState(fly);
        if (previousMode !== fly) {
            setSidebarMode(getSidebarMode(fly));
            setPreviousMode(fly);
        }

        const childrenArray = React.Children.toArray(children);

        // check for children type of TreeCategory and throw error in case
        invariant(childrenArray.every(isTreeCategory), 'AssetTree only excepts children of type "TreeCategory"');

        const classes = classNames(
            'AssetTree',
            className,
            !isOpen && 'closed',
            bordered && 'panel panel-default',
            sidebarMode === TreeMode.MODE_FLY ? 'fly' : 'fluid'
        );

        const resizeLimitClasses = classNames('AssetTreeResizeLimit', isResize && 'display-block');

        const resizeIndicatorPosition = maxWidth || window.innerWidth * 0.5;
        const resizeLimitStyle = { left: resizeIndicatorPosition };

        const firstChild = head(childrenArray);

        const category = currentCategoryId ? getCurrentCategoryElement(childrenArray, currentCategoryId) : firstChild;

        const style = {
            width: treeWidth,
            height,
        };

        const handleToggleTreeContent = () => onToggleTree(!isOpen);

        const handleSelectCategory = (selectedCategoryId: string) => {
            onCategoryChange(selectedCategoryId);

            if (!isOpen) {
                handleToggleTreeContent();
            } else if (isOpen && currentCategoryId === selectedCategoryId) {
                handleToggleTreeContent();
            }
        };

        const handleResize = (diff: number) => {
            const halfWindowWidth = window.innerWidth * 0.5;
            const usedMaxWidth = maxWidth || halfWindowWidth;

            // Check for sidebar width if it is half window size. If it was before but the sidebar was resized so it is
            // no longer half window size, set the sidebar width to half the window size to avoid jumping sidebar
            // to old width
            setTreeWidth(oldWidth => {
                const updatedWidth = oldWidth - diff;
                return getWidthInBoundaries(minWidth, usedMaxWidth, updatedWidth);
            });
        };

        const handleResizeStart = () => {
            const body = getSidebarBodyRef(sidebarRef);
            if (body) {
                body.classList.add('pointer-events-none');
            }
            setIsResize(true);
        };

        const handleResizeEnd = () => {
            const body = getSidebarBodyRef(sidebarRef);
            if (body) {
                body.classList.remove('pointer-events-none');
            }
            setIsResize(false);
            onResizeEnd();
        };

        return (
            <div {...remainingProps} className={classes} style={style} ref={mergedRefs}>
                <div className={resizeLimitClasses} style={resizeLimitStyle} />
                <div className='AssetTreeContent'>
                    <TreeSidebar
                        onSelectCategory={handleSelectCategory}
                        currentCategoryId={currentCategoryId}
                        onClick={handleToggleTreeContent}
                    >
                        {isArray(children) ? children : [children]}
                    </TreeSidebar>
                    <SmoothScrollbars slideIn className='AssetTreeBody'>
                        {useOffscreen ? renderTreesOffscreen(childrenArray, currentCategoryId) : category}
                    </SmoothScrollbars>
                </div>
                {resizable && isOpen && (
                    <Resizer
                        onResizeStart={handleResizeStart}
                        onResize={handleResize}
                        onResizeEnd={handleResizeEnd}
                        direction={Resizer.HORIZONTAL}
                        position={Resizer.RIGHT}
                    />
                )}
            </div>
        );
    })
);

Object.assign(AssetTree, TreeMode);

export default AssetTree;
