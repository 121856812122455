import React from 'react';
import classNames from 'classnames';
import noop from 'lodash/noop';

import type { TableColumnDetails } from './TableSettingsDialog.types';

export type TableSettingsColumnButtonsProps = {
    column: string;
    index: number;
    columnOrder: string[];
    openColumnsDetails: Record<string, string>;
    disabled?: boolean;
    columnDetails?: TableColumnDetails;
    onMoveColumn?: (columnName: string, newIndex: number, changeMovedColumn: boolean) => void;
    onOpenDetails?: (column: string, index: number, something: boolean) => void;
};

export const TableSettingsColumnButtons = (props: TableSettingsColumnButtonsProps) => {
    const {
        column = '',
        index = 0,
        columnDetails,
        columnOrder = [],
        openColumnsDetails = {},
        disabled,
        onMoveColumn = noop,
        onOpenDetails = noop,
    } = props;

    const navButtonBase = classNames('btn btn-muted btn-icon-only btn-sm');

    const columnUpClasses = classNames(navButtonBase, index === 0 && 'disabled');

    const columnDownClasses = classNames(navButtonBase, index === columnOrder.length - 1 && 'disabled');

    const toggleIconClassNames = classNames(
        'rioglyph',
        openColumnsDetails[column] ? 'rioglyph-remove' : 'rioglyph-width'
    );

    return (
        <div className='table-settings-item-item-buttons'>
            {!disabled && (
                <div
                    className={columnUpClasses}
                    onClick={event => {
                        event.preventDefault();
                        event.stopPropagation();
                        onMoveColumn(column, index - 1, true);
                    }}
                >
                    <span className='rioglyph rioglyph-arrow-up' />
                </div>
            )}
            {!disabled && (
                <div
                    className={columnDownClasses}
                    onClick={event => {
                        event.preventDefault();
                        event.stopPropagation();
                        onMoveColumn(column, index + 1, true);
                    }}
                >
                    <span className='rioglyph rioglyph-arrow-down' />
                </div>
            )}
            {columnDetails && (
                <div className={navButtonBase} onClick={() => onOpenDetails(column)}>
                    <span className={toggleIconClassNames} />
                </div>
            )}
        </div>
    );
};
