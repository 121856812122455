import { useEffect } from 'react';

type WindowEventMapWithFullscreen = {
    fullscreenchange: Event;
    webkitfullscreenchange: Event;
    mozfullscreenchange: Event;

    fullscreenerror: Event;
    webkitfullscreenerror: Event;
    mozfullscreenerror: Event;
};

/**
 * Custom hook to add event listeners to the specified target.
 *
 * @param eventType - The type of event to listen for.
 * @param callback - The function to execute when the event occurs.
 * @param options - Optional. An object that specifies characteristics about the event listener.
 * @param target - Optional. The target element to which to attach the event listener.
 */
const useEvent = <
    K extends keyof WindowEventMap,
    W extends keyof WindowEventMapWithFullscreen,
    T extends HTMLElement = HTMLDivElement,
>(
    eventType: K | W,
    callback: EventListener,
    options: boolean | AddEventListenerOptions = false,
    target: Document | Window | T | null = document
) => {
    useEffect(() => {
        if (!target || !target.addEventListener) {
            return;
        }

        target.addEventListener(eventType, callback, options);

        return () => {
            target.removeEventListener(eventType, callback, options);
        };
    }, [eventType, options, target]);
};

export default useEvent;
