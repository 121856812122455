import { OverlayTrigger, Tooltip } from '../../../index';
import { Title } from '../../../components/Title';

const tooltip = <Tooltip id='tooltip'>Tooltip!</Tooltip>;

export default () => (
    <div>
        <div className='row'>
            <div className='col-md-3 col-lg-2 margin-bottom-20'>
                <Title>Button toolbar</Title>
                <div className='btn-toolbar'>
                    <button type='button' className='btn btn-primary'>
                        <span className='rioglyph rioglyph-chevron-left' aria-hidden='true' />
                        <span>Prev</span>
                    </button>
                    <button type='button' className='btn btn-primary btn-icon-right'>
                        <span className='rioglyph rioglyph-chevron-right' aria-hidden='true' />
                        <span>Next</span>
                    </button>
                </div>
            </div>

            <div className='col-md-3 col-lg-2 margin-bottom-20'>
                <Title>Button group</Title>
                <div className='btn-toolbar margin-bottom-20'>
                    <div className='btn-group'>
                        <button type='button' className='btn btn-default'>
                            1
                        </button>
                        <button type='button' className='btn btn-default'>
                            2
                        </button>
                        <button type='button' className='btn btn-default'>
                            3
                        </button>
                        <button type='button' className='btn btn-default'>
                            4
                        </button>
                    </div>
                </div>
                <div className='btn-group-vertical'>
                    <button type='button' className='btn btn-default'>
                        Button
                    </button>
                    <button type='button' className='btn btn-default'>
                        Button
                    </button>
                    <button type='button' className='btn btn-default'>
                        Button
                    </button>
                </div>
            </div>

            <div className='col-md-3 col-lg-2 margin-bottom-20'>
                <Title>Multiline button</Title>
                <button type='button' className='btn btn-primary btn-lg btn-multiline'>
                    This is a multiline button with a lot of Text
                </button>
            </div>

            <div className='col-md-3 col-lg-2 margin-bottom-20'>
                <Title>Block button</Title>
                <button type='button' className='btn btn-default btn-block'>
                    Block level button
                </button>
            </div>

            <div className='col-md-12 col-lg-4 margin-bottom-20'>
                <Title>Tooltip buttons</Title>
                <div className='btn-toolbar'>
                    <OverlayTrigger placement='bottom' overlay={tooltip}>
                        <span>
                            <button type='button' className='btn btn-info'>
                                Button with tooltip
                            </button>
                        </span>
                    </OverlayTrigger>
                    <OverlayTrigger placement='top' overlay={tooltip}>
                        <span className='cursor-not-allowed'>
                            <button type='button' className='btn btn-info disabled'>
                                Disabled button with tooltip
                            </button>
                        </span>
                    </OverlayTrigger>
                </div>
            </div>
        </div>
    </div>
);
