// @ts-ignore-next-line importsNotUsedAsValues
import React from 'react';

import TreeLeaf from './TreeLeaf';
import { excludeFromList } from './treeUtils';
import type { TreeItem } from './Tree';

export type TreeLeafListProps = {
    leafList: TreeItem[];
    hasMultiselect: boolean;
    showRadioButtons: boolean;
    selectedItems: string[];
    selectedGroups: string[];
    onSelectionChange: (selectedItems: string[], selectedGroups: string[]) => void;
};

const TreeLeafList = (props: TreeLeafListProps) => {
    const { leafList, hasMultiselect, showRadioButtons, selectedItems, selectedGroups, onSelectionChange } = props;

    const handleItemSelection = (itemId: string) => {
        const isSelected = selectedItems.includes(itemId);
        const newSelectedItems: string[] = isSelected
            ? excludeFromList(selectedItems, itemId)
            : [...selectedItems, itemId];

        onSelectionChange(newSelectedItems, selectedGroups);
    };

    const handleSetItemActive = (itemId: string) => onSelectionChange([itemId], []);

    return (
        <>
            {leafList.map(item => (
                <TreeLeaf
                    key={item.id}
                    item={item}
                    hasMultiselect={hasMultiselect}
                    showRadioButtons={showRadioButtons}
                    isSelected={selectedItems.includes(item.id)}
                    onSelectItem={() => handleItemSelection(item.id)}
                    onActiveItem={() => handleSetItemActive(item.id)}
                />
            ))}
        </>
    );
};

export default TreeLeafList;
