import { Playground } from '../../../components/playground/Playground';
import { Description } from '../../../components/Description';
import { Title } from '../../../components/Title';

export const Transition = () => (
    <div>
        <Description headline='Transition' anchor='transition' />
        <Playground example={transitionExample} />
    </div>
);

const transitionExample = (
    <div className='display-grid grid-cols-1 grid-cols-3-sm gap-50'>
        <div>
            <Title>Transition properties</Title>
            <div>
                <code className='code margin-right-5'>transition-opacity</code>
            </div>
            <div>
                <code className='code margin-right-5'>transition-position</code>
            </div>
            <div>
                <code className='code margin-right-5'>transition-all</code>
            </div>
        </div>

        <div>
            <Title>Transition timing functions</Title>
            <div>
                <code className='code margin-right-5'>transition-ease</code>
            </div>
            <div>
                <code className='code margin-right-5'>transition-ease-in-out</code>
            </div>
        </div>

        <div>
            <Title>Transition duration</Title>
            <div>
                <code className='code margin-right-5'>transition-duration-01</code>
            </div>
            <div>
                <code className='code margin-right-5'>transition-duration-02</code>
            </div>
            <div>
                <code className='code margin-right-5'>transition-duration-03</code>
            </div>
            <div>
                <code className='code margin-right-5'>transition-duration-04</code>
            </div>
            <div>
                <code className='code margin-right-5'>transition-duration-05</code>
            </div>
        </div>
    </div>
);
