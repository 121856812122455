import React, { useEffect, useRef } from 'react';

/**
 * This custom hook allows to keep the focus inside a defined wrapper element.
 * This might be a dialog or a custom overlay.
 *
 * This hook can be used in two ways:
 * - When you have a external component that will be re-rendered after the overlay opens, you can use
 *   the built-in ref. This ref will be returned from this hook and can be set on the respective wrapper element.
 * - When you have an element that does not re-render, use a state setter function to set as the ref.
 *   This element can then be passed as a parameter to this hook and it will be used instead of the built-in ref.
 *
 * @param element Optional parameter to pass in the existing DOM reference. Use this instead of the built-in ref.
 * @returns
 */
const useFocusTrap = <T extends HTMLElement>(element?: HTMLDivElement | null) => {
    const ref = useRef<HTMLDivElement>(null);

    const handleFocus = (event: React.KeyboardEvent<T>) => {
        const wrapper = element ?? ref.current;
        const focusableElements = wrapper?.querySelectorAll(
            [
                'a[href]',
                'button',
                'textarea',
                'input',
                'input[type="text"]',
                'input[type="radio"]',
                'input[type="checkbox"]',
                'select',
            ].join(', ')
        );

        const firstFocusableEl = focusableElements?.[0] as HTMLElement | undefined;
        const lastFocusableEl = focusableElements?.[focusableElements.length - 1] as HTMLElement | undefined;

        const isTabPressed = event.key === 'Tab';

        if (!isTabPressed) {
            return;
        }

        // Handle: shift + tab
        if (event.shiftKey) {
            if (document.activeElement === firstFocusableEl) {
                lastFocusableEl?.focus();
                event.preventDefault();
            }
            return;
        }

        // Handle: tab
        if (document.activeElement === lastFocusableEl) {
            firstFocusableEl?.focus();
            event.preventDefault();
        }
    };

    useEffect(() => {
        const wrapper = element ?? ref.current;

        if (wrapper) {
            wrapper.addEventListener('keydown', handleFocus as unknown as EventListener);
        }

        return () => {
            if (wrapper) {
                wrapper.removeEventListener('keydown', handleFocus as unknown as EventListener);
            }
        };
    }, [ref.current, element]);

    return ref;
};

export default useFocusTrap;
