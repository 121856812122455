import invariant from 'tiny-invariant';

import type { GroupedItem, TreeItem, TreeGroup } from './Tree';
import type { TreeSummaryCounts, AssetType } from './TreeSummary';

export type State = {
    groupedItems: GroupedItem[];
    flatItems: TreeItem[];
    allChecked: boolean;
    searchValue: string;
    assetCounts: TreeSummaryCounts;
    typeFilter: string[];
    visibleTypeCounters: AssetType[];
    emptyGroups: TreeGroup[];
};

type Action =
    | { type: 'assetCounted'; payload: TreeSummaryCounts }
    | { type: 'allCheckedChanged'; payload: boolean }
    | { type: 'searchValueChanged'; payload: string }
    | { type: 'flatItemsChanged'; payload: TreeItem[] }
    | { type: 'groupedItemsChanged'; payload: GroupedItem[] }
    | { type: 'typeFilterChanged'; payload: string[] }
    | { type: 'visibleTypeCountersChanged'; payload: AssetType[] } // Adjust the type based on the actual structure
    | { type: 'emptyGroupsChanged'; payload: TreeGroup[] };

export const assetCounted = (payload: TreeSummaryCounts): Action => ({ type: 'assetCounted', payload });
export const allCheckedChanged = (payload: boolean): Action => ({ type: 'allCheckedChanged', payload });
export const searchValueChanged = (payload: string): Action => ({ type: 'searchValueChanged', payload });
export const flatItemsChanged = (payload: TreeItem[]): Action => ({ type: 'flatItemsChanged', payload });
export const groupedItemsChanged = (payload: GroupedItem[]): Action => ({ type: 'groupedItemsChanged', payload });
export const typeFilterChanged = (payload: string[]): Action => ({ type: 'typeFilterChanged', payload });
export const visibleTypeCountersChanged = (payload: AssetType[]): Action => ({
    type: 'visibleTypeCountersChanged',
    payload,
});
export const emptyGroupsChanged = (payload: TreeGroup[]): Action => ({ type: 'emptyGroupsChanged', payload });

export const treeReducer = (state: State, action: Action): State => {
    switch (action.type) {
        case 'assetCounted':
            return { ...state, assetCounts: action.payload };
        case 'allCheckedChanged':
            return { ...state, allChecked: action.payload };
        case 'searchValueChanged':
            return { ...state, searchValue: action.payload, allChecked: false };
        case 'flatItemsChanged':
            return { ...state, flatItems: action.payload };
        case 'groupedItemsChanged':
            return { ...state, groupedItems: action.payload };
        case 'typeFilterChanged':
            return { ...state, typeFilter: action.payload };
        case 'visibleTypeCountersChanged':
            return { ...state, visibleTypeCounters: action.payload };
        case 'emptyGroupsChanged':
            return { ...state, emptyGroups: action.payload };
        default:
            invariant(false, 'Unknown action type in tree reducer');
    }
};
