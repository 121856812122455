import { Playground, PLAYGROUNG_BG_LIGHTER } from '../../../components/playground/Playground';
import { Description } from '../../../components/Description';
import { replaceUikitImport } from '../../../utils/replaceUikitImport';

import TableRowAnimationExample from './TableRowAnimationExample';
import TableRowAnimationExampleRaw from './TableRowAnimationExample.tsx?raw';

export const TableRowAnimation = () => (
    <div>
        <Description headline='Table row animation' anchor='table-row-animation' />
        <Playground
            example={<TableRowAnimationExample />}
            codeReact={replaceUikitImport(TableRowAnimationExampleRaw)}
            background={PLAYGROUNG_BG_LIGHTER}
            className='overflow-hidden'
        />
    </div>
);
