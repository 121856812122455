import React, { useState, forwardRef } from 'react';
import classNames from 'classnames';
import type { Moment } from 'moment';
import Datetime, { type DatetimepickerProps } from 'react-datetime';
import noop from 'lodash/fp/noop';

const DEFAULT_LOCALE = 'en-GB';
const DEFAULT_MIN_WIDTH = 0;

export type DatePickerProps = Omit<DatetimepickerProps, 'onChange' | 'initialValue'> & {
    /**
     * An id used for the internal input element.
     */
    id?: string;

    /**
     * The moment locale to be used for the date picker.
     * Make sure to import the respective moment locale files.
     *
     * @default 'en-GB'
     * @example import 'moment/dist/locale/de';
     */
    locale?: string;

    /**
     * Once the day has been selected, the date picker will be closed automatically.
     *
     * @default true
     */
    closeOnSelect?: boolean;

    /**
     * Default value of the DatePicker (date or moment).
     */
    initialValue?: Date | Moment;

    /**
     * Value of the DatePicker (date or moment). Use this only if you want to use
     * this component as a controlled component
     */
    value?: Date | string | Moment;

    /*
     * Defines the format for the date. It accepts any moment.js date format.
     * If true the date will be displayed using the defaults for the current locale.
     * If false the date picker is disabled and the component can be used as time picker.
     */
    dateFormat?: boolean | string;

    /*
     * Defines the format for the time. It accepts any moment.js time format.
     * If true the time will be displayed using the defaults for the current locale.
     * If false the time picker is disabled and the component can be used as date picker.
     */
    timeFormat?: boolean | string;

    /*
     * Defines additional attributes for the input element of the component.
     */
    inputProps?: React.HTMLProps<HTMLInputElement>;

    /**
     * Defines whether the dropdown opens upwards or not.
     *
     * @default false
     */
    dropup?: boolean;

    /**
     * Opens the picker right aligned.
     *
     * @default false
     */

    alignRight?: boolean;

    /**
     * Defines whether the clearableInput button is shown.
     *
     * @default false
     */
    clearableInput?: boolean;

    /**
     * Optional min-width value (without px). Since the DatePicker has a max-width of 100%
     * you can also set a high minWidth number to use 100% with of the parent element.
     * A value of "0" means no extra width is set and it becomes 100% width of the parent.
     *
     * @default 0
     */
    minWidth?: number;

    /**
     * Callback function when the value changes. Receives the new date (moment)
     * and a boolean (whether date is valid or not) as arguments.
     *
     * @param value
     * @param isValid
     * @returns
     */
    onChange?: (value: Moment | string, isValid: boolean) => void;

    /**
     * Defines whether the input shows an error when the date is invalid or cleared.
     *
     * @default true
     */
    mandatory?: boolean;

    /**
     * Overwrites the internal date validation function in case you need to customize it.
     *
     * @param date
     * @returns
     */
    dateValidation?: (date: Moment | string) => boolean;

    /**
     * Additional classes to be set on the DatePicker element.
     */
    className?: string;
};

const DatePicker = forwardRef((props: DatePickerProps, ref) => {
    const {
        id,
        dropup = false,
        alignRight = false,
        locale = DEFAULT_LOCALE,
        minWidth = DEFAULT_MIN_WIDTH,
        onChange = noop,
        mandatory = true,
        dateValidation,
        clearableInput = false,
        closeOnSelect = true,
        inputProps,
        className,
        ...remainingProp
    } = props;

    const [hasError, setHasError] = useState(false);

    const validateDate = (dateToValidate: Moment | string) => {
        // If the entered date complies with the defined dateFormat, the Datetime component
        // returns a moment object, otherwise the return value is a string.
        // Note, using a isValid() function from moment or the Date object itself will result in
        // different outcome in various browsers.
        // For instance `new Date('1')` or `moment('1')` are valid dates in Chrome where it defaults
        // to "01/01/2001" whereas in Firefox it is an invalid date.
        // The solution is to compare the entered value to the date format which is done by React Datetime.
        const isDateObject = typeof dateToValidate === 'object';

        return dateValidation ? dateValidation(dateToValidate) : isDateObject;
    };

    const handleChange = (date: Moment | string) => {
        const isValid = mandatory ? validateDate(date) : true;
        setHasError(!isValid);
        onChange(date, isValid);
    };

    const classes = classNames(
        'DatePicker',
        'form-group',
        hasError && 'has-error',
        dropup && 'dropup',
        alignRight && 'align-right',
        className && className
    );

    // This way we can expose the "id" as top level prop and not as part of the inputProps which
    // makes the use of the id much more convenient
    const enhancedInputProps = { id, ...inputProps };

    return (
        <Datetime
            // TODO: add support for setting ref to the input. Maybe add a "inputRef" prop to react-datetime
            // ref={ref}
            {...remainingProp}
            locale={locale}
            clearableInput={clearableInput}
            closeOnSelect={closeOnSelect}
            onChange={handleChange}
            minWidth={minWidth}
            inputProps={enhancedInputProps}
            className={classes}
        />
    );
});

export default DatePicker;
