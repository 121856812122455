import { createAction } from 'redux-actions';

export const actions = {
    deleteUsers: createAction('DEMO_SERVICE_DELETE_USERS'),
    selectedUserIds: createAction('DEMO_SERVICE_SELECTED_USER_IDS'),
    activeUser: createAction('DEMO_SERVICE_ACTIVE_USER'),
    showConfirmDelete: createAction('DEMO_SERVICE_SHOW_CONFIRM_DELETE'),
    showCreateAppointment: createAction('DEMO_SERVICE_SHOW_CREATE_APPOINTMENT'),
    showCreateSingleAppointment: createAction('DEMO_SERVICE_SHOW_CREATE_SINGLE_APPOINTMENT'),
    printSidebar: createAction('DEMO_SERVICE_PRINT_SIDEBAR'),
    printTable: createAction('DEMO_SERVICE_PRINT_TABLE'),
};
