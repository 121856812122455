import { Playground, PLAYGROUNG_BG_LIGHTER } from '../../../components/playground/Playground';
import { PlaygroundColors } from '../../../components/playground/PlaygroundColors';
import { PlaygroundClasses } from '../../../components/playground/PlaygroundClasses';
import { Description } from '../../../components/Description';
import { colors, getColorNames } from '../../../utils/colors';
import { dummyTextShort } from '../../../utils/data';

export const Panels = () => (
    <div>
        <Description headline='Panels' anchor='panels' />
        <Playground
            example={panelsExample}
            classes={panelsClasses}
            availableColors={<PlaygroundColors bgDefault bgPrimary bgSecondary bgInfo bgSuccess bgWarning bgDanger />}
        />
        <Description headline='Different panel examples' headlineType='h3' />
        <Playground example={panelsExample2} background={PLAYGROUNG_BG_LIGHTER} />
        <Playground example={panelsExample3} />
        <Playground example={panelsExample4} background={PLAYGROUNG_BG_LIGHTER} />
        <Playground example={panelsExample5} />
    </div>
);

const eachColor = ['default', ...getColorNames(colors.brand, colors.status)];

const panelsExample = (
    <div className='display-grid grid-cols-1 grid-cols-2-ls grid-cols-4-md gap-20'>
        {eachColor.map((color, index) => (
            <div className={`panel panel-${color} margin-bottom-0`} key={index}>
                <div className='panel-heading'>
                    <div className='display-flex column-gap-10 justify-content-between'>
                        <span>panel-heading</span>
                        <div className='display-flex column-gap-5'>
                            <span>{color !== 'default' && <span className='badge'>1</span>}</span>
                            <span>
                                <span className={`badge badge-${color === 'primary' ? 'secondary' : 'primary'}`}>
                                    1
                                </span>
                            </span>
                        </div>
                    </div>
                </div>
                <div className='panel-body'>panel-body</div>
                <div className='panel-footer'>panel-footer</div>
            </div>
        ))}
    </div>
);

const panelsExample2 = (
    <div className='display-grid grid-cols-1 grid-cols-2-ls grid-cols-4-md gap-20'>
        {eachColor.map((color, index) => (
            <div
                className={`
                    panel panel-${color} panel-body panel-blank shadow-default padding-25 margin-bottom-0
                    position-relative hover-scale-105 transition-all transition-duration-03 transition-ease-in-out
                `}
                key={index}
            >
                <div className={`text-size-18 text-uppercase text-color-${color}`}>What is new?</div>
                <span className={`position-absolute left--1 top-25 height-25 width-3 bg-${color}`} />
                <div className='text-color-darker'>Cras sit amet nibh libero, in gravida nulla. Nulla vel metus</div>
            </div>
        ))}
    </div>
);

const panelsExample3 = (
    <div className='display-grid grid-cols-1 grid-cols-2-ls grid-cols-3-md gap-20'>
        <div className='panel panel-default active'>
            <div className='panel-heading display-flex align-items-center justify-content-between'>
                <div>panel-heading</div>
                <span className='rioglyph rioglyph-cog' aria-hidden='true' />
            </div>
            <div className='panel-body'>
                <p>{dummyTextShort}</p>
                <div className='form-group'>
                    <div className='select dropdown'>
                        <button
                            aria-expanded='false'
                            aria-haspopup='true'
                            type='button'
                            className='dropdown-toggle form-control text-left'
                            data-toggle='dropdown'
                        >
                            <span>All components</span>
                            <span className='caret' />
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div className='panel panel-default table-responsive'>
            <div className='panel-heading'>panel-heading</div>
            <div className='panel-body'>
                <p>Some default panel body here. Nulla vitae elit libero, a pharetra augue.</p>
            </div>
            <table className='table'>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>First name</th>
                        <th>Last name</th>
                        <th>Username</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th scope='row'>1</th>
                        <td>Mark</td>
                        <td>Otto</td>
                        <td>@mdo</td>
                    </tr>
                    <tr>
                        <th scope='row'>2</th>
                        <td>Jacob</td>
                        <td>Thornton</td>
                        <td>@fat</td>
                    </tr>
                    <tr>
                        <th scope='row'>3</th>
                        <td>Larry</td>
                        <td>the Bird</td>
                        <td>@twitter</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div className='panel panel-info'>
            <div className='panel-heading'>panel-heading</div>
            <div className='panel-body'>
                <p>... some content goes here ...</p>
            </div>
            <ul className='list-group'>
                <li className='list-group-item'>Cras justo odio</li>
                <li className='list-group-item  display-flex justify-content-between'>
                    <div>Dapibus ac facilisis in</div>
                    <span className='label label-info white-space-nowrap'>Some info</span>
                </li>
                <li className='list-group-item'>Morbi leo risus</li>
                <li className='list-group-item'>Porta ac consectetur ac</li>
            </ul>
        </div>
    </div>
);

type PanelProps = {
    title: React.ReactNode;
    subtitle: React.ReactNode;
    icon: React.ReactNode;
    description: React.ReactNode;
    cta: React.ReactNode;
};

const Panel = ({ title, subtitle, icon, description, cta }: PanelProps) => (
    <div className='panel panel-default panel-body shadow-smooth padding-25'>
        <div className='text-center text-color-darker margin-top-15'>
            <div className='text-size-16'>{subtitle}</div>
            <div className='text-size-12'>
                <div className='text-size-200pct padding-bottom-10 text-capitalize text-medium'>{title}</div>
            </div>
            <div className='text-size-20 padding-15'>
                <span className={`rioglyph ${icon} text-size-300pct`} />
            </div>
            <p className='margin-bottom-20 text-color-dark'>{description}</p>
            {cta}
        </div>
    </div>
);

const panelsExample4 = (
    <div className='display-flex flex-wrap margin-top-25'>
        <div className='margin-10 position-relative width-300'>
            <Panel
                title='Import your own data'
                subtitle='Customize'
                icon='rioglyph-stats'
                description={
                    'Cras sit amet nibh libero, in gravida nulla. Nulla vel metus ' +
                    'scelerisque ante sollicitudin commodo.'
                }
                cta={<button className='btn btn-primary btn-lg btn-block'>Read more</button>}
            />
        </div>
        <div className='margin-10 position-relative width-300'>
            <Panel
                title='Get the app'
                subtitle='Use on mobile'
                icon='rioglyph-phone'
                description={
                    'Cras sit amet nibh libero, in gravida nulla. Nulla vel metus ' +
                    'scelerisque ante sollicitudin commodo.'
                }
                cta={<button className='btn btn-primary btn-lg btn-block'>Download now</button>}
            />
        </div>
        <div className='margin-10 position-relative width-300'>
            <Panel
                title='Discover new features'
                subtitle='Tips'
                icon='rioglyph-cost-efficency'
                description={
                    'Cras sit amet nibh libero, in gravida nulla. Nulla vel metus ' +
                    'scelerisque ante sollicitudin commodo.'
                }
                cta={<button className='btn btn-primary btn-lg btn-block'>Read more</button>}
            />
        </div>
    </div>
);

const panelsExample5 = (
    <div className='display-grid place-items-center margin-25'>
        <div className='width-100pct max-width-500'>
            <div className='panel panel-default position-relative'>
                <div
                    className={
                        'position-absolute top-0pct left-50pct translate-x-50pct-y-50pct ' +
                        'border rounded-circle width-60 height-60 display-grid place-items-center bg-white'
                    }
                >
                    <span className='rioglyph rioglyph-ok text-color-success text-size-16 scale-150' />
                </div>
                <div className='panel-body padding-25'>
                    <div className='border border-bottom-only padding-x-20 padding-top-25 padding-bottom-15 text-center'>
                        <span className='text-color-success text-size-20'>Lorem ipsum dolor sit amet</span>
                    </div>
                    <div className='padding-20'>
                        <div className='display-grid grid-cols-2 gap-20 padding-15'>
                            <label className='text-right margin-0 line-height-20'>Diam nonummy</label>
                            <span>Consetetur sadipscing</span>
                        </div>
                        <div className='display-grid grid-cols-2 gap-20 padding-15'>
                            <label className='text-right margin-0 line-height-20'>Voluptua</label>
                            <span>Eirmod tempor</span>
                        </div>
                        <div className='display-grid grid-cols-2 gap-20 padding-15'>
                            <label className='text-right margin-0 line-height-20'>Eum iriure</label>
                            <span>500</span>
                        </div>
                        <div className='display-grid grid-cols-2 gap-20 padding-15'>
                            <label className='text-right margin-0 line-height-20'>Sanctus</label>
                            <span className='text-medium'>No sea takimata</span>
                        </div>
                        <div className='display-grid grid-cols-2 gap-20 padding-15'>
                            <label className='text-right margin-0 line-height-20'>Vulputate</label>
                            <div>
                                <div>Tempor invidunt</div>
                                <div className='margin-top-5 text-color-gray'>Duis autem vel eum</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
);

const panelsClasses = (
    <>
        <PlaygroundClasses label='Colors' prefix='panel-' classes={eachColor} />
        <PlaygroundClasses label='Types' prefix='panel-' classes={['heading', 'body', 'footer']} />
    </>
);
