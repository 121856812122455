import { Playground } from '../../../components/playground/Playground';
import {
    PlaygroundClasses,
    PlaygroundResponsiveClasses,
    PlaygroundPrintClasses,
    PlaygroundNegativeClasses,
    PlaygroundClassesSpacer,
} from '../../../components/playground/PlaygroundClasses';
import { Description } from '../../../components/Description';
import { Title } from '../../../components/Title';

import { spacings } from '../../../utils/spacings';
const spacingsWithoutZero = spacings.slice(1);

export const Margin = () => (
    <div>
        <Description headline='Margin' anchor='margin' />
        <Playground example={marginExample} classes={marginClasses} />
        <Description headline='Margin helper' />
        <Playground example={marginHelperExample} classes={marginHelperClasses} />
    </div>
);

const marginExample = (
    <div className='display-grid grid-cols-1 grid-cols-4-md grid-cols-7-lg gap-20'>
        <div>
            <Title>Surrouding</Title>
            {spacings.map(spacing => (
                <div className='display-flex justify-content-center margin-bottom-2' key={crypto.randomUUID()}>
                    <div className='rounded shorthand-style'>
                        <div className={`margin-${spacing} padding-2 text-center bg-info rounded`}>
                            margin-{spacing}
                        </div>
                    </div>
                </div>
            ))}
        </div>
        <div>
            <Title>Horizontal</Title>
            {spacings.map(spacing => (
                <div className='display-flex justify-content-center margin-bottom-2' key={crypto.randomUUID()}>
                    <div className='rounded shorthand-style'>
                        <div className={`margin-x-${spacing} padding-2 text-center bg-info rounded`}>
                            margin-x-{spacing}
                        </div>
                    </div>
                </div>
            ))}
        </div>
        <div>
            <Title>Vertical</Title>
            {spacings.map(spacing => (
                <div className='display-flex justify-content-center margin-bottom-2' key={crypto.randomUUID()}>
                    <div className='rounded shorthand-style'>
                        <div className={`margin-y-${spacing} padding-2 text-center bg-info rounded`}>
                            margin-y-{spacing}
                        </div>
                    </div>
                </div>
            ))}
        </div>
        <div>
            <Title>Left</Title>
            {spacings.map(spacing => (
                <div className='display-flex justify-content-center margin-bottom-2' key={crypto.randomUUID()}>
                    <div className='rounded shorthand-style'>
                        <div className={`margin-left-${spacing} padding-2 text-center bg-info rounded`}>
                            margin-left-{spacing}
                        </div>
                    </div>
                </div>
            ))}
        </div>
        <div>
            <Title>Right</Title>
            {spacings.map(spacing => (
                <div className='display-flex justify-content-center margin-bottom-2' key={crypto.randomUUID()}>
                    <div className='rounded shorthand-style'>
                        <div className={`margin-right-${spacing} padding-2 text-center bg-info rounded`}>
                            margin-right-{spacing}
                        </div>
                    </div>
                </div>
            ))}
        </div>
        <div>
            <Title>Top</Title>
            {spacings.map(spacing => (
                <div className='display-flex justify-content-center margin-bottom-2' key={crypto.randomUUID()}>
                    <div className='rounded shorthand-style'>
                        <div className={`margin-top-${spacing} padding-2 text-center bg-info rounded`}>
                            margin-top-{spacing}
                        </div>
                    </div>
                </div>
            ))}
        </div>
        <div>
            <Title>Bottom</Title>
            {spacings.map(spacing => (
                <div className='display-flex justify-content-center margin-bottom-2' key={crypto.randomUUID()}>
                    <div className='rounded shorthand-style'>
                        <div className={`margin-bottom-${spacing} padding-2 text-center bg-info rounded`}>
                            margin-bottom-{spacing}
                        </div>
                    </div>
                </div>
            ))}
        </div>
    </div>
);

const marginClasses = (
    <>
        <PlaygroundClassesSpacer label='Pixel Values' />
        <PlaygroundClasses label='Surrouding' prefix='margin-' classes={['auto', ...spacings]} />
        <PlaygroundClasses label='Horizontal' prefix='margin-x-' classes={['auto', ...spacings]} />
        <PlaygroundClasses label='Vertical' prefix='margin-y-' classes={['auto', ...spacings]} />
        <PlaygroundClasses label='Left' prefix='margin-left-' classes={['auto', ...spacings]} />
        <PlaygroundClasses label='Right' prefix='margin-right-' classes={['auto', ...spacings]} />
        <PlaygroundClasses label='Top' prefix='margin-top-' classes={['auto', ...spacings]} />
        <PlaygroundClasses label='Bottom' prefix='margin-bottom-' classes={['auto', ...spacings]} />
        <PlaygroundClassesSpacer label='Percentage values' />
        <PlaygroundClasses label='Surrouding' prefix='margin-' classes={spacingsWithoutZero} suffix='pct' />
        <PlaygroundClasses label='Horizontal' prefix='margin-x-' classes={spacingsWithoutZero} suffix='pct' />
        <PlaygroundClasses label='Vertical' prefix='margin-y-' classes={spacingsWithoutZero} suffix='pct' />
        <PlaygroundClasses label='Left' prefix='margin-left-' classes={spacingsWithoutZero} suffix='pct' />
        <PlaygroundClasses label='Right' prefix='margin-right-' classes={spacingsWithoutZero} suffix='pct' />
        <PlaygroundClasses label='Top' prefix='margin-top-' classes={spacingsWithoutZero} suffix='pct' />
        <PlaygroundClasses label='Bottom' prefix='margin-bottom-' classes={spacingsWithoutZero} suffix='pct' />
        <PlaygroundNegativeClasses />
        <PlaygroundResponsiveClasses />
        <PlaygroundPrintClasses />
    </>
);

const marginHelperExample = (
    <div className='display-grid grid-cols-1 grid-cols-4-sm gap-20'>
        <div>
            <Title>Last child (self class)</Title>
            <div>
                {[...Array(5)].map(index => (
                    <div
                        className={`margin-bottom-10 padding-2 text-center rounded ${
                            index === 5 ? 'bg-success last-child-margin-bottom-0' : 'bg-info'
                        }`}
                        key={crypto.randomUUID()}
                    >
                        {index === 5 ? 'Last child' : 'Child'}
                    </div>
                ))}
            </div>
        </div>
        <div>
            <Title>Last child (parent class)</Title>
            <div className='children-last-margin-bottom-0'>
                {[...Array(5)].map(index => (
                    <div
                        className={`margin-bottom-10 padding-2 text-center rounded ${
                            index === 5 ? 'bg-success' : 'bg-info'
                        }`}
                        key={crypto.randomUUID()}
                    >
                        {index === 5 ? 'Last child' : 'Child'}
                    </div>
                ))}
            </div>
        </div>
        <div>
            <Title>First child (self class)</Title>
            <div>
                {[...Array(5)].map(index => (
                    <div
                        className={`margin-top-10 first-child-margin-top-0 padding-2 text-center rounded ${
                            index === 1 ? 'bg-success' : 'bg-info'
                        }`}
                        key={crypto.randomUUID()}
                    >
                        {index === 1 ? 'First child' : 'Child'}
                    </div>
                ))}
            </div>
        </div>
        <div>
            <Title>First child (parent class)</Title>
            <div className='children-first-margin-top-0'>
                {[...Array(5)].map(index => (
                    <div
                        className={`margin-bottom-10 padding-2 text-center rounded ${
                            index === 1 ? 'bg-success' : 'bg-info'
                        }`}
                        key={crypto.randomUUID()}
                    >
                        {index === 1 ? 'First child' : 'Child'}
                    </div>
                ))}
            </div>
        </div>
    </div>
);

const marginHelperClasses = (
    <>
        <PlaygroundClasses label='Parent' classes={['children-first-margin-top-0', 'children-last-margin-bottom-0']} />
        <PlaygroundClasses label='Self' classes={['first-child-margin-top-0', 'last-child-margin-bottom-0']} />
    </>
);
