import React from 'react';

const DEFAULT_HEADLINE = 'h2';

type DescriptionProps = {
    anchor?: string;
    headline: string;
    headlineType?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5';
    headlineNoTopSpace?: boolean;
    subline?: string;
    noMaxWidth?: boolean;
    children?: React.ReactNode;
    className?: string;
};

export const Description = (props: DescriptionProps) => {
    const {
        anchor,
        headline,
        headlineType = DEFAULT_HEADLINE,
        headlineNoTopSpace,
        subline,
        noMaxWidth,
        children,
        className = 'margin-bottom-20',
    } = props;

    const headlineText = React.createElement(
        headlineType,
        { className: headlineNoTopSpace ? 'margin-top-0' : '' },
        headline
    );

    return (
        <div className={className}>
            {anchor && <a className='anchor' id={anchor} />}
            <div className='description-headline'>{headlineText}</div>
            {subline && <div className='description-subline'>{subline}</div>}
            {children && <div className={`${noMaxWidth ? '' : 'max-width-1000'}`}>{children}</div>}
        </div>
    );
};
