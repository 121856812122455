import classNames from 'classnames';

import { Playground, PLAYGROUNG_BG_CHECKERBOARD } from '../../../components/playground/Playground';
import { PlaygroundClasses } from '../../../components/playground/PlaygroundClasses';
import { Description } from '../../../components/Description';
import { Note } from '../../../components/Note';

import useDarkMode from '../../../../../src/hooks/useDarkMode';

import { colors, getColorNames, hasWarmup, hasColdplay, showWarmup, showColdplay } from '../../../utils/colors';

export const BackgroundColors = () => (
    <>
        <Description anchor='colors-backgrounds' headline='Background colors' />
        {useDarkMode() && (
            <div className='alert alert-info margin-bottom-20'>
                <Note defaultContent='darkmode' />
            </div>
        )}
        <Playground example={backgroundsExample} classes={backgroundClasses} background={PLAYGROUNG_BG_CHECKERBOARD} />
        <Description headline='Hover background colors' headlineType='h3' />
        <Playground example={hoverBackgroundColorsExample} classes={hoverBackgroundClasses} />
    </>
);

const eachText = getColorNames(colors.text);
const eachBrand = getColorNames(colors.brand);
const eachStatus = getColorNames(colors.status);
const eachDrivingStatus = getColorNames(colors.drivingStatus);
const eachMapMarker = getColorNames(colors.mapMarker);
const eachRating = getColorNames(colors.rating);
const eachGray = getColorNames(colors.gray);
const eachHighlight = getColorNames(colors.highlight);
const eachColdplay = getColorNames(colors.coldplay);
const eachWarmup = getColorNames(colors.warmup);

type BackgroundTileProps = {
    label: string;
    eachColor: string[];
    prefix: string;
    showBorder?: boolean;
};

const BackgroundTile = (props: BackgroundTileProps) => {
    const { label, eachColor, prefix, showBorder } = props;
    const classes = classNames('rounded padding-5 text-center text-size-12 display-grid place-items-center');

    return (
        <div
            className={
                'display-flex align-items-center-sm margin-bottom-15pct margin-bottom-20-sm ' +
                'last-child-margin-bottom-0 line-height-125rel'
            }
        >
            <div className='text-medium text-size-14 width-100 margin-right-20'>{label}</div>
            <div className='width-100pct display-grid grid-cols-1 grid-cols-2-ls grid-cols-4-sm grid-cols-8-md gap-10'>
                {eachColor.map((color: string, index: number) => (
                    <div className={`${prefix}${color} ${classes} ${showBorder ? 'border' : ''}`} key={index}>
                        {prefix}
                        {color}
                    </div>
                ))}
            </div>
        </div>
    );
};

const backgroundsExample = (
    <>
        <BackgroundTile label='Basic' prefix='bg-' eachColor={eachText} />
        <BackgroundTile label='Brand' prefix='bg-' eachColor={eachBrand} />
        <BackgroundTile label='Status' prefix='bg-' eachColor={eachStatus} />
        <BackgroundTile label='Driving status' prefix='bg-' eachColor={eachDrivingStatus} />
        <BackgroundTile label='Map marker' prefix='bg-' eachColor={eachMapMarker} />
        <BackgroundTile label='Rating' prefix='bg-' eachColor={eachRating} />
        <BackgroundTile label='Gray' prefix='bg-' eachColor={eachGray} />
        <BackgroundTile label='Highlight' prefix='bg-' eachColor={eachHighlight} />
        {showColdplay && <BackgroundTile label='Coldplay' prefix='bg-' eachColor={eachColdplay} />}
        {showWarmup && <BackgroundTile label='Warmup' prefix='bg-' eachColor={eachWarmup} />}
    </>
);

const hoverBackgroundColorsExample = (
    <>
        <BackgroundTile label='Basic' prefix='hover-bg-' eachColor={eachText} showBorder />
        <BackgroundTile label='Brand' prefix='hover-bg-' eachColor={eachBrand} showBorder />
        <BackgroundTile label='Status' prefix='hover-bg-' eachColor={eachStatus} showBorder />
        <BackgroundTile label='Driving status' prefix='hover-bg-' eachColor={eachDrivingStatus} showBorder />
        <BackgroundTile label='Map Marker' prefix='hover-bg-' eachColor={eachMapMarker} showBorder />
        <BackgroundTile label='Rating' prefix='hover-bg-' eachColor={eachRating} showBorder />
        <BackgroundTile label='Gray' prefix='hover-bg-' eachColor={eachGray} showBorder />
        <BackgroundTile label='Highlight' prefix='hover-bg-' eachColor={eachHighlight} showBorder />
        {showColdplay && <BackgroundTile label='Coldplay' prefix='hover-bg-' eachColor={eachColdplay} showBorder />}
        {showWarmup && <BackgroundTile label='Warmup' prefix='hover-bg-' eachColor={eachWarmup} showBorder />}
    </>
);

const backgroundClasses = (
    <>
        <PlaygroundClasses label='Basic' prefix='bg-' classes={[...eachText, 'none']} />
        <PlaygroundClasses label='Brand' prefix='bg-' classes={eachBrand} />
        <PlaygroundClasses label='Status' prefix='bg-' classes={eachStatus} />
        <PlaygroundClasses label='Driving status' prefix='bg-' classes={eachDrivingStatus} />
        <PlaygroundClasses label='Map marker' prefix='bg-' classes={eachMapMarker} />
        <PlaygroundClasses label='Rating' prefix='bg-' classes={eachRating} />
        <PlaygroundClasses label='Gray' prefix='bg-' classes={eachGray} />
        <PlaygroundClasses label='Highlight' prefix='bg-' classes={eachHighlight} />
        {hasColdplay && <PlaygroundClasses label='Coldplay' prefix='bg-' classes={eachColdplay} />}
        {hasWarmup && <PlaygroundClasses label='Warmup' prefix='bg-' classes={eachWarmup} />}
    </>
);

const hoverBackgroundClasses = (
    <>
        <PlaygroundClasses label='Basic' prefix='hover-bg-' classes={[...eachText, 'none']} />
        <PlaygroundClasses label='Brand' prefix='hover-bg-' classes={eachBrand} />
        <PlaygroundClasses label='Status' prefix='hover-bg-' classes={eachStatus} />
        <PlaygroundClasses label='Driving status' prefix='hover-bg-' classes={eachDrivingStatus} />
        <PlaygroundClasses label='Map marker' prefix='hover-bg-' classes={eachMapMarker} />
        <PlaygroundClasses label='Rating' prefix='hover-bg-' classes={eachRating} />
        <PlaygroundClasses label='Gray' prefix='hover-bg-' classes={eachGray} />
        <PlaygroundClasses label='Highlight' prefix='hover-bg-' classes={eachHighlight} />
        {hasColdplay && <PlaygroundClasses label='Coldplay' prefix='hover-bg-' classes={eachColdplay} />}
        {hasWarmup && <PlaygroundClasses label='Warmup' prefix='hover-bg-' classes={eachWarmup} />}
    </>
);
