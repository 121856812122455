import React, { useState } from 'react';
import classNames from 'classnames';

import { RadioButton } from '../../../index';

export default () => {
    const [currentAnimationType, setCurrentAnimationType] = useState('slide-out');
    const [currentAnimationDirection, setCurrentAnimationDirection] = useState('left');
    const [currentAnimation, setCurrentAnimation] = useState('infinite');
    const [currentAnimationLooping, setCurrentAnimationLooping] = useState('loop');
    const [currentAnimationEasing, setCurrentAnimationEasing] = useState('ease-in-out');
    const [currentAnimationDuration, setCurrentAnimationDuration] = useState('1s');
    const [playAnimation, setPlayAnimation] = useState(false);

    const handleSelectAnimationType = (selectedAnimationType: string) => {
        setCurrentAnimationType(selectedAnimationType);
        setPlayAnimation(false);
    };

    const handleSelectAnimationDirection = (selectedAnimationDirection: string) => {
        setCurrentAnimationDirection(selectedAnimationDirection);
        setPlayAnimation(false);
    };

    const handleSelectAnimation = (selectedAnimation: string) => {
        setCurrentAnimation(selectedAnimation);
        setPlayAnimation(false);
    };

    const handleSelectAnimationLooping = (selectedAnimationLooping: string) => {
        setCurrentAnimationLooping(selectedAnimationLooping);
        setPlayAnimation(false);
    };

    const handleSelectAnimationEasing = (selectedAnimationEasing: string) => {
        setCurrentAnimationEasing(selectedAnimationEasing);
        setPlayAnimation(false);
    };

    const handleSelectAnimationDuration = (selectedAnimationDuration: string) => {
        setCurrentAnimationDuration(selectedAnimationDuration);
        setPlayAnimation(false);
    };

    const handleStartAnimation = () => {
        setPlayAnimation(true);
    };

    const handleStopAnimation = () => {
        setPlayAnimation(false);
    };

    const animationClassName = classNames(
        playAnimation &&
            `animated-${currentAnimationDuration} ${currentAnimation} ${currentAnimationEasing}
            ${currentAnimationLooping}`,
        playAnimation && `${currentAnimationType}-${currentAnimationDirection}`
    );

    return (
        <>
            <div className='display-flex justify-content-center'>
                <RadioButton
                    name='type'
                    onChange={() => handleSelectAnimationType('slide-out')}
                    checked={currentAnimationType === 'slide-out'}
                    inline
                >
                    slide-out
                </RadioButton>
            </div>
            <hr />
            <div className='display-flex justify-content-center'>
                <RadioButton
                    name='direction'
                    onChange={() => handleSelectAnimationDirection('left')}
                    checked={currentAnimationDirection === 'left'}
                    inline
                >
                    left
                </RadioButton>
                <RadioButton
                    name='direction'
                    onChange={() => handleSelectAnimationDirection('up')}
                    checked={currentAnimationDirection === 'up'}
                    inline
                >
                    up
                </RadioButton>
                <RadioButton
                    name='direction'
                    onChange={() => handleSelectAnimationDirection('down')}
                    checked={currentAnimationDirection === 'down'}
                    inline
                >
                    down
                </RadioButton>
                <RadioButton
                    name='direction'
                    onChange={() => handleSelectAnimationDirection('right')}
                    checked={currentAnimationDirection === 'right'}
                    inline
                >
                    right
                </RadioButton>
            </div>
            <hr />
            <div className='display-flex flex-wrap justify-content-center'>
                <RadioButton
                    name='duration'
                    onChange={() => handleSelectAnimationDuration('3s')}
                    checked={currentAnimationDuration === '3s'}
                    inline
                >
                    3s
                </RadioButton>
                <RadioButton
                    name='duration'
                    onChange={() => handleSelectAnimationDuration('2s')}
                    checked={currentAnimationDuration === '2s'}
                    inline
                >
                    2s
                </RadioButton>
                <RadioButton
                    name='duration'
                    onChange={() => handleSelectAnimationDuration('1s')}
                    checked={currentAnimationDuration === '1s'}
                    inline
                >
                    1s
                </RadioButton>
                <RadioButton
                    name='duration'
                    onChange={() => handleSelectAnimationDuration('09s')}
                    checked={currentAnimationDuration === '09s'}
                    inline
                >
                    09s
                </RadioButton>
                <RadioButton
                    name='duration'
                    onChange={() => handleSelectAnimationDuration('08s')}
                    checked={currentAnimationDuration === '08s'}
                    inline
                >
                    08s
                </RadioButton>
                <RadioButton
                    name='duration'
                    onChange={() => handleSelectAnimationDuration('07s')}
                    checked={currentAnimationDuration === '07s'}
                    inline
                >
                    07s
                </RadioButton>
                <RadioButton
                    name='duration'
                    onChange={() => handleSelectAnimationDuration('06s')}
                    checked={currentAnimationDuration === '06s'}
                    inline
                >
                    06s
                </RadioButton>
                <RadioButton
                    name='duration'
                    onChange={() => handleSelectAnimationDuration('05s')}
                    checked={currentAnimationDuration === '05s'}
                    inline
                >
                    05s
                </RadioButton>
                <RadioButton
                    name='duration'
                    onChange={() => handleSelectAnimationDuration('04s')}
                    checked={currentAnimationDuration === '04s'}
                    inline
                >
                    04s
                </RadioButton>
                <RadioButton
                    name='duration'
                    onChange={() => handleSelectAnimationDuration('03s')}
                    checked={currentAnimationDuration === '03s'}
                    inline
                >
                    03s
                </RadioButton>
                <RadioButton
                    name='duration'
                    onChange={() => handleSelectAnimationDuration('02s')}
                    checked={currentAnimationDuration === '02s'}
                    inline
                >
                    02s
                </RadioButton>
                <RadioButton
                    name='duration'
                    onChange={() => handleSelectAnimationDuration('01s')}
                    checked={currentAnimationDuration === '01s'}
                    inline
                >
                    01s
                </RadioButton>
            </div>
            <hr />
            <div className='display-flex justify-content-center'>
                <RadioButton
                    name='looping'
                    onChange={() => handleSelectAnimationLooping('loop')}
                    checked={currentAnimationLooping === 'loop'}
                    inline
                >
                    loop
                </RadioButton>
                <RadioButton
                    name='looping'
                    onChange={() => handleSelectAnimationLooping('restart')}
                    checked={currentAnimationLooping === 'restart'}
                    inline
                >
                    restart
                </RadioButton>
            </div>
            <hr />
            <div className='display-flex justify-content-center'>
                <RadioButton
                    name='easeing'
                    onChange={() => handleSelectAnimationEasing('linear')}
                    checked={currentAnimationEasing === 'linear'}
                    inline
                >
                    linear
                </RadioButton>
                <RadioButton
                    name='easeing'
                    onChange={() => handleSelectAnimationEasing('ease')}
                    checked={currentAnimationEasing === 'ease'}
                    inline
                >
                    ease
                </RadioButton>
                <RadioButton
                    name='easeing'
                    onChange={() => handleSelectAnimationEasing('ease-in')}
                    checked={currentAnimationEasing === 'ease-in'}
                    inline
                >
                    ease-in
                </RadioButton>
                <RadioButton
                    name='easeing'
                    onChange={() => handleSelectAnimationEasing('ease-out')}
                    checked={currentAnimationEasing === 'ease-out'}
                    inline
                >
                    ease-out
                </RadioButton>
                <RadioButton
                    name='easeing'
                    onChange={() => handleSelectAnimationEasing('ease-in-out')}
                    checked={currentAnimationEasing === 'ease-in-out'}
                    inline
                >
                    ease-in-out
                </RadioButton>
            </div>
            <hr />
            <div className='display-flex justify-content-center'>
                <RadioButton
                    name='animation'
                    onChange={() => handleSelectAnimation('infinite')}
                    checked={currentAnimation === 'infinite'}
                    inline
                >
                    infinite
                </RadioButton>
                <RadioButton
                    name='animation'
                    onChange={() => handleSelectAnimation('repeat-1')}
                    checked={currentAnimation === 'repeat-1'}
                    inline
                >
                    repeat-1
                </RadioButton>
                <RadioButton
                    name='animation'
                    onChange={() => handleSelectAnimation('repeat-2')}
                    checked={currentAnimation === 'repeat-2'}
                    inline
                >
                    repeat-2
                </RadioButton>
                <RadioButton
                    name='animation'
                    onChange={() => handleSelectAnimation('repeat-3')}
                    checked={currentAnimation === 'repeat-3'}
                    inline
                >
                    repeat-3
                </RadioButton>
            </div>
            <hr />
            <div className='display-flex justify-content-center'>
                <button className='btn btn-primary margin-right-10' onClick={handleStartAnimation}>
                    Test animation
                </button>
                <button className='btn btn-default margin-left-10' onClick={handleStopAnimation}>
                    Reset
                </button>
            </div>
            <hr />
            <div className='display-flex justify-content-center align-items-center height-300'>
                <div className={animationClassName}>
                    <svg
                        className='margin-5 margin-left-0 scale-200'
                        width='50'
                        height='50'
                        xmlns='http://www.w3.org/2000/svg'
                    >
                        <path
                            d={
                                'M0 50V0h50v50H0zm45.3-23.95c0-4.55-3.7-8.25-8.25-8.25s-8.25 ' +
                                '3.7-8.25 8.25 3.7 8.25 8.25 8.25 8.25-3.7 8.25-8.25zm-23.6 7.' +
                                '8h5.05V18.2H21.7v15.65zm-8-15.65H5.25v15.65h4.9V27.6l4.25 ' +
                                '6.25h5.35l-4.1-6.25c.45-.15.85-.4 1.15-.7 1.1-.9 1.8-2.3 1.8-3.8 ' +
                                '0-2.7-2.2-4.9-4.9-4.9z'
                            }
                            fillRule='evenodd'
                        />
                    </svg>
                </div>
            </div>
            <hr />
            <div className='display-flex justify-content-center'>
                <code className='code hidden-empty'>{`${animationClassName}`}</code>
            </div>
        </>
    );
};
