import React, { type PropsWithChildren } from 'react';

type CalloutProps = {
    type?: 'default' | 'info' | 'success' | 'warning' | 'danger';
    className?: string;
};

export const Callout = ({ type = 'info', className = '', children }: PropsWithChildren<CalloutProps>) => (
    <div className={`callout callout-${type} ${className} margin-bottom-20`}>{children}</div>
);
