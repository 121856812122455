/* eslint-disable no-use-before-define */
import React, { useEffect, useReducer, useRef, useState } from 'react';
import classNames from 'classnames';
import isNil from 'lodash/fp/isNil';
import isEmpty from 'lodash/fp/isEmpty';
import isEqual from 'lodash/fp/isEqual';
import map from 'lodash/fp/map';
import find from 'lodash/fp/find';
import cond from 'lodash/fp/cond';
import without from 'lodash/fp/without';
import flow from 'lodash/fp/flow';
import filter from 'lodash/fp/filter';
import size from 'lodash/fp/size';
import otherwise from 'lodash/fp/stubTrue';
import omit from 'lodash/fp/omit';
import noop from 'lodash/fp/noop';

import TreeSearch from './TreeSearch';
import TreeSelectAll from './TreeSelectAll';
import TreeSummary, { type AssetType } from './TreeSummary';
import TreeNodeContainer from './TreeNodeContainer';
import TreeNode from './TreeNode';
import TreeLeafList from './TreeLeafList';
import TreeNothingFound from './TreeNothingFound';
import TreeOptions from './TreeOptions';
import TreeRoot from './TreeRoot';
import TypeCounter from './TypeCounter';
import {
    containsItemById,
    debounceFn,
    filterAssetByType,
    filterEmptyGroups,
    filterOutByItemId,
    getTypeCounts,
    getFlatItems,
    getListIds,
    notEmpty,
    notEqual,
    excludeFromList,
    getMappedItemsToGroups,
    sortGroupItemsByName,
    sortGroupsByName,
    addOrRemoveFromList,
} from './treeUtils';
import {
    treeReducer,
    assetCounted,
    allCheckedChanged,
    visibleTypeCountersChanged,
    searchValueChanged,
    flatItemsChanged,
    emptyGroupsChanged,
    groupedItemsChanged,
    typeFilterChanged,
    type State,
} from './treeReducer';

export { getTypeCounts, getSubTypeCounts } from './treeUtils';

export type TreeItemName = {
    firstName?: string;
    lastName: string;
};

export type TreeGroup = {
    /**
     * A unique identifier of a group.
     */
    id: string;

    /**
     * The name of a group.
     */
    name: string | React.ReactNode;

    /**
     * Can be set to "last" to enforce the last position in the tree.
     */
    position?: 'last';

    /**
     * Disallows the selection of the group itself.
     */
    disabled?: boolean;

    /**
     * The rioglyph icon name for a group.
     */
    icon?: string;

    /**
     * Additional classes added to the group element.
     */
    className?: string;
};

export type TreeItem = {
    /**
     * A unique identifier of an item.
     */
    id: string;

    /**
     * The name of an item. Either it is a plain string or an object composed of:
     * `firstName` and `lastName` where __lastName__ is mandatory.
     */
    name: string | TreeItemName;

    /**
     * The subline of an item. This can e used to show additional information for that item.
     */
    info?: string | React.ReactNode;

    /**
     * The type of an item which is also the name of the respective rioplyph icon without the prefix
     * `rioglyph-`.
     */
    type: string;

    /**
     * The sub type of an item which is also the name of the respective rioplyph icon without the prefix.
     * This could be used to show a secondary paired icon like for fuel type.
     */
    subType?: string;

    /**
     * List of group ids the items is associated with.
     *
     * @default []
     */
    groupIds?: string[];

    /**
     * Additional classes added to the item element.
     */
    className?: string;
};

export type GroupedItem = TreeGroup & {
    items: TreeItem[];
};

export type GroupedItems = {
    [key: string]: GroupedItem;
};

export type SelectionChangeResponse = { items: string[]; groups: string[] };

export type TreeProps = {
    /**
     * The list of groups of the items. If no groups are provided all items
     * are rendered as flat list.
     *
     * @default []
     */
    groups?: TreeGroup[];

    /**
     * The list of items.
     *
     * @default []
     */
    items?: TreeItem[];

    /**
     * List of selected group ids.
     *
     * @default []
     */
    selectedGroups?: string[];

    /**
     * List of selected item ids.
     *
     * @default []
     */
    selectedItems?: string[];

    /**
     * Merged Callback for item and group selection changes.
     * It responds with a selection object that contains the selected itemIds and groupIds:
     * `{ items: [], groups: [] }`
     * @returns
     */
    onSelectionChange?: ({ items, groups }: SelectionChangeResponse) => void;

    /**
     * Defines the selection behavior of the tree.
     *
     * @default true
     */
    hasMultiselect?: boolean;

    /**
     * Defines if the single selection should also show radios.
     *
     * @default false
     */
    showRadioButtons?: boolean;

    /**
     * Defines whether or not the built-in Search is shown.
     *
     * @default false
     */
    hideSearch?: boolean;

    /**
     * The text used as placeholder for the search input.
     */
    searchPlaceholder?: string;

    /**
     * Callback for when the search value changes.
     * @param value
     * @returns
     */
    onSearchChange?: (value: string) => void;

    /**
     * Used to define custom search component which replaces the built-in search.
     */
    search?: React.ReactNode;

    /**
     * Used to define custom asset type counter component which replaces the built-in summary.
     */
    summary?: React.ReactNode;

    /**
     * Defines whether a summary is shown.
     *
     * @default false
     */
    hideSummary?: boolean;

    /**
     * Defines whether the entire area below the search field is shown or not. Note: Disabling the
     * tree head will hide the select all checkbox and the tree options as well as the tree summary.
     *
     * @default false
     */
    hideTreeHead?: boolean;

    treeHeaderContent?: React.ReactElement;

    /**
     * Defines the max-height of the scrollable list.
     */
    scrollHeight?: number;

    /**
     * List of group ids which are expanded.
     */
    expandedGroups?: string[];

    /**
     * Callback function triggered when a group expands or collapses.
     * @param newExpandedGroups
     * @returns
     */
    onExpandGroupsChange?: (newExpandedGroups: string[]) => void;

    /**
     * Defines whether empty groups are shown or not.
     *
     * @default true
     */
    showEmptyGroups?: boolean;

    /**
     * Component to offer customization options for the tree.
     */
    treeOptions?: React.ReactNode[];

    /**
     * Tooltip content for the tree options dropdown.
     */
    treeOptionsTooltip?: React.ReactNode;

    /**
     * Disables animation when filtering or using search
     *
     * @default false
     */
    disableAnimation?: boolean;

    /**
     * Additional classes added to the wrapping element.
     */
    className?: string;
};

const filterProps = omit([
    'expandedGroups',
    'onExpandGroupsChange',
    'onSearchChange',
    'onSelectionChange',
    'treeOptions',
]);

const customCompare = (prevProps: TreeProps, nextProps: TreeProps) =>
    isEqual(filterProps(prevProps), filterProps(nextProps));

const Tree = React.memo((props: TreeProps) => {
    const {
        groups = [],
        items = [],
        selectedGroups = [],
        selectedItems = [],
        onSelectionChange = noop,
        hasMultiselect = true,
        showRadioButtons = false,
        hideSearch = false,
        hideTreeHead,
        treeHeaderContent,
        summary,
        hideSummary = false,
        search,
        searchPlaceholder = 'Type here to filter by name',
        onSearchChange = noop,
        className,
        scrollHeight,
        expandedGroups,
        onExpandGroupsChange = noop,
        showEmptyGroups = true,
        treeOptions = [],
        treeOptionsTooltip,
        disableAnimation = false,
        ...remainingProps
    } = props;

    const [state, dispatch] = useReducer(treeReducer, {
        groupedItems: [],
        flatItems: [],
        allChecked: false,
        searchValue: '',
        assetCounts: {},
        typeFilter: [],
        visibleTypeCounters: [],
        emptyGroups: [],
    } as State);

    const treeRef = useRef<HTMLDivElement>(null);

    const previousItems = useRef<TreeItem[]>();
    const previousGroups = useRef<TreeGroup[]>();
    const previousSearchValue = useRef('');

    const internalExpandedGroups = useRef(expandedGroups);

    useEffect(() => {
        // Update Tree when items or groups have changed
        if (notEqual(previousItems.current, items) || notEqual(previousGroups.current, groups)) {
            previousItems.current = items;
            previousGroups.current = groups;

            const typeCounts = getTypeCounts(items);

            dispatch(assetCounted(typeCounts));

            const allChecked = checkAllSelected({ items, groups, selectedItems, selectedGroups }, state.flatItems);
            dispatch(allCheckedChanged(allChecked));

            // Get the distinct asset types from the asset list that is passed into the component
            // to know which asset type counter to render
            dispatch(visibleTypeCountersChanged(Object.keys(typeCounts) as AssetType[]));

            makeTree(groups, items);
        }
    }, [items, groups]);

    const debouncedMakeTree = debounceFn((losGroupos: TreeGroup[], losItems: TreeItem[]) =>
        makeTree(losGroupos, losItems)
    );

    useEffect(() => {
        // To prevent executing the effect on first render, use a ref to check previous render values
        if (notEqual(previousSearchValue.current, state.searchValue)) {
            debouncedMakeTree(groups, items);
            previousSearchValue.current = state.searchValue;
        }
    }, [state.searchValue]);

    useEffect(() => makeTree(groups, items), [state.typeFilter]);

    // Update tree when empty groups are toggled from outside
    useEffect(() => makeTree(groups, items), [showEmptyGroups]);

    // Update expanded groups from outside
    const [previousExpandedGroups, setPreviousExpandedGroups] = useState(expandedGroups);
    if (!isEqual(expandedGroups, previousExpandedGroups)) {
        internalExpandedGroups.current = expandedGroups;
        setPreviousExpandedGroups(expandedGroups);
    }

    // Update "select all" state from outside when groups are selected outside programmatically
    // without using the "select all" checkbox
    useEffect(() => {
        const numOfAllGroups = size(groups);
        const numOfSelectedGroups = size(selectedGroups);

        if (numOfSelectedGroups !== numOfAllGroups && state.allChecked) {
            dispatch(allCheckedChanged(false));
        } else if (numOfSelectedGroups !== 0 && numOfSelectedGroups === numOfAllGroups && !state.allChecked) {
            dispatch(allCheckedChanged(true));
        }
    }, [selectedGroups]);

    const checkAllSelected = (
        updatedProps: { items: TreeItem[]; groups: TreeGroup[]; selectedItems: string[]; selectedGroups: string[] },
        flatItems: TreeItem[]
    ) => {
        const {
            items: updatedItems,
            groups: updatedGroups,
            selectedItems: updatedSelectedItems,
            selectedGroups: updatedSelectedGroups,
        } = updatedProps;

        if (
            (!hasGroups() && isEmpty(updatedSelectedItems)) ||
            (hasNoSearchAndGroups() && isEmpty(updatedSelectedGroups)) ||
            (hasSearchAndGroups() && isEmpty(updatedSelectedItems))
        ) {
            return false;
        }

        if (hasNoSearchAndGroups()) {
            const unselectedGroups = filter(filterOutByItemId(updatedSelectedGroups))(updatedGroups);
            return isEmpty(unselectedGroups);
        }

        if (hasSearchAndGroups()) {
            const unselectedSearchItems = filter(filterOutByItemId(updatedSelectedItems))(flatItems);
            return isEmpty(unselectedSearchItems);
        }

        const unselectedItems = updatedItems.filter(filterOutByItemId(updatedSelectedItems));
        return isEmpty(unselectedItems);
    };

    const handleToggleNode = (nodeId: string) => {
        const nodeContainer = getNodeContainerDomElementById(nodeId);

        if (!internalExpandedGroups?.current || !nodeContainer) {
            return;
        }

        const openGroups = internalExpandedGroups.current;

        const newExpandedNodes = openGroups.includes(nodeId)
            ? openGroups.filter(item => item !== nodeId)
            : [...openGroups, nodeId];

        // Performance improvement to skip on render cycle and change "open" class directly
        if (openGroups.includes(nodeId)) {
            nodeContainer.classList.remove('open');
        } else {
            nodeContainer.classList.add('open');
        }

        internalExpandedGroups.current = newExpandedNodes;

        onExpandGroupsChange(newExpandedNodes);
    };

    const getNodeContainerDomElementById = (nodeId: string) => {
        return treeRef?.current?.querySelector(`.TreeNodeContainer[data-id="${nodeId}"]`);
    };

    const selectAllSearchResultItems = (shouldSelect: boolean) => selectAllFlatItems(shouldSelect);

    const handleSelectAll = (shouldSelect: boolean, isStateIndeterminate: boolean) => {
        const shouldSelectAll = shouldSelect && !isStateIndeterminate;
        dispatch(allCheckedChanged(shouldSelectAll));

        cond([
            [hasNoSearchAndGroups, () => selectAllGroups(shouldSelectAll)],
            [hasSearchAndGroups, () => selectAllSearchResultItems(shouldSelectAll)],
            [otherwise, () => selectAllFlatItems(shouldSelectAll)],
        ])();
    };

    const selectAllGroups = (shouldSelect: boolean) => respondSelection([], shouldSelect ? getListIds(groups) : []);

    const selectAllFlatItems = (shouldSelect: boolean) =>
        respondSelection(shouldSelect ? getListIds(state.flatItems) : [], []);

    const respondSelection = (updatedSelectedItemIds: string[], updatedSelectedGroupIds: string[]) => {
        onSelectionChange({
            items: updatedSelectedItemIds,
            groups: updatedSelectedGroupIds,
        });
    };

    const handleGroupSelection = (group: TreeGroup, isStateIndeterminate: boolean) => {
        const groupId = group.id;

        const isSelected = selectedGroups.includes(groupId);
        const shouldSelectGroup = !isSelected && !isStateIndeterminate;

        // handle group selection
        const newSelectedGroups = shouldSelectGroup
            ? [...selectedGroups, groupId]
            : excludeFromList(selectedGroups, groupId);

        // deselect all items of a node since they will be selected inherently via the group itself
        const itemsInGroup = find((entry: GroupedItem) => entry.id === groupId)(state.groupedItems);
        const itemIdsOfGroup = map((item: TreeItem) => item.id)(itemsInGroup?.items);
        const updatedSelectedItems = without(itemIdsOfGroup, selectedItems);

        // check if all groups are selected to change the state of TreeSelectAll
        const groupAmount = groups.length;
        const emptyGroupAmount = showEmptyGroups ? 0 : state.emptyGroups.length;
        const totalGroupAmount = groupAmount - emptyGroupAmount;
        const areAllGroupsChecked = totalGroupAmount === newSelectedGroups.length;
        dispatch(allCheckedChanged(areAllGroupsChecked));

        respondSelection(updatedSelectedItems, newSelectedGroups);
    };

    const handleSearchChange = (updatedSearchValue: string) => {
        onSearchChange(updatedSearchValue);
        dispatch(searchValueChanged(updatedSearchValue));
    };

    const hasGroups = () => groups && notEmpty(groups);
    const hasSearchAndGroups = () => hasInternalSearchValue() && hasGroups();
    const hasNoSearchAndGroups = () => !hasInternalSearchValue() && hasGroups();

    const setFlatItemList = (updatedItems: TreeItem[], searchValue: string) => {
        const flatItems = getFlatItems(updatedItems, searchValue);
        dispatch(flatItemsChanged(flatItems));
    };

    const setGroupedItemList = (
        groupsToProcess: TreeGroup[],
        itemsToProcess: TreeItem[],
        considerEmptyGroups: boolean
    ) => {
        // Map items to groups with filtered items
        const mappedItemsToGroups = getMappedItemsToGroups(groupsToProcess, itemsToProcess);
        const newGroupedItems = flow(sortGroupsByName, sortGroupItemsByName)(mappedItemsToGroups);
        const groupedItems = considerEmptyGroups ? newGroupedItems : filterEmptyGroups(newGroupedItems);

        // Keep the empty groups in memory for later access in select all, without re-iterating on every
        // group selection
        const emptyGroups = filter((group: GroupedItem) => isEmpty(group.items))(newGroupedItems);

        dispatch(emptyGroupsChanged(emptyGroups));
        dispatch(assetCounted(getTypeCounts(items)));
        dispatch(groupedItemsChanged(groupedItems));

        // Update expanded groups again
    };

    const makeTree = (updatedGroups: TreeGroup[], updatedItems: TreeItem[]) => {
        const internalSearchValue = state.searchValue;
        const internalTypeFilter = state.typeFilter;

        const groupsToProcess = updatedGroups;
        const itemsToProcess = updatedItems;

        const hasGroupList = (groupList: TreeGroup[]) => groupList && notEmpty(groupList);

        const hasNoInternalSearchAndGroups = () => isEmpty(internalSearchValue) && hasGroupList(groupsToProcess);
        const hasInternalSearchAndGroups = () => notEmpty(internalSearchValue) && hasGroupList(groupsToProcess);

        const filteredItems = isEmpty(internalTypeFilter)
            ? itemsToProcess
            : filterAssetByType(internalTypeFilter)(itemsToProcess);

        const setGroupedItems = () => setGroupedItemList(groupsToProcess, filteredItems, showEmptyGroups);

        const setFlatItems = () => setFlatItemList(filteredItems, internalSearchValue);

        cond([
            [hasNoInternalSearchAndGroups, setGroupedItems],
            [hasInternalSearchAndGroups, setFlatItems],
            [otherwise, setFlatItems],
        ])();
    };

    const renderTree = () => {
        const { groupedItems } = state;

        if (isEmpty(groupedItems)) {
            return <TreeNothingFound />;
        }

        const result = map((group: GroupedItem) => {
            const groupId = group.id;
            const groupItems = group.items;

            const isOpen = internalExpandedGroups.current?.includes(groupId) ?? false;

            const numSelectedGroupItems = filter(containsItemById(selectedItems))(groupItems).length;

            const isGroupSelected = selectedGroups.includes(groupId);
            const isStateIndeterminate = !isGroupSelected && numSelectedGroupItems > 0;

            return (
                <TreeNodeContainer key={groupId} groupId={groupId} isOpen={isOpen} disableAnimation={disableAnimation}>
                    <TreeNode
                        node={group}
                        hasMultiselect={hasMultiselect}
                        onToggleNode={handleToggleNode}
                        onSelect={handleGroupSelection}
                        isSelected={isGroupSelected}
                        isIndeterminate={isStateIndeterminate}
                    />
                    <TreeLeafList
                        leafList={groupItems}
                        hasMultiselect={hasMultiselect}
                        showRadioButtons={showRadioButtons}
                        selectedItems={selectedItems}
                        selectedGroups={selectedGroups}
                        onSelectionChange={respondSelection}
                    />
                </TreeNodeContainer>
            );
        })(groupedItems);

        return result;
    };

    const renderFlatList = () => {
        const { flatItems } = state;
        const hasLeafs = isEmpty(flatItems);

        const getLeafs = () => (
            <TreeLeafList
                leafList={flatItems}
                hasMultiselect={hasMultiselect}
                showRadioButtons={showRadioButtons}
                selectedItems={selectedItems}
                selectedGroups={selectedGroups}
                onSelectionChange={respondSelection}
            />
        );

        return (
            <TreeNodeContainer disableAnimation={disableAnimation} isOpen>
                {hasLeafs ? <TreeNothingFound /> : getLeafs()}
            </TreeNodeContainer>
        );
    };

    const hasExternalGroups = notEmpty(groups);

    const hasInternalSearchValue = () => notEmpty(state.searchValue);

    const hasSelectedAllItems = () => isEqual(size(selectedItems), size(state.flatItems));

    const hasPartiallySelectedItems = () => notEmpty(selectedItems) && !hasSelectedAllItems();

    const hasSelectedAllGroups = () => {
        const emptyGroupAmount = showEmptyGroups ? 0 : state.emptyGroups.length;
        return isEqual(size(selectedGroups), size(groups) - emptyGroupAmount);
    };

    const hasPartiallySelectedGroups = () => hasExternalGroups && notEmpty(selectedGroups) && !hasSelectedAllGroups();

    const hasSearchAndNoItems = hasInternalSearchValue() && isEmpty(state.flatItems);
    const hasSearchAndNoGroups = hasInternalSearchValue() && isEmpty(state.groupedItems) && hasExternalGroups;
    const hideSelectAll = hasSearchAndNoItems || hasSearchAndNoGroups;

    const isIndeterminate = hasPartiallySelectedGroups() || hasPartiallySelectedItems();

    const treeClassNames = classNames('Tree', className);

    const treeHeadClasses = classNames('TreeHead', 'display-flex gap-5', 'padding-15');

    const shouldRenderTree = () => hasGroups() && !hasInternalSearchValue();

    const content = cond([
        [shouldRenderTree, () => renderTree()],
        [otherwise, () => renderFlatList()],
    ])();

    const handleFilterByType = (type: string) => {
        dispatch(typeFilterChanged(addOrRemoveFromList(state.typeFilter, type)));
    };

    const enableActivity = size(state.visibleTypeCounters) !== 1;
    const isFilterActive = notEmpty(state.typeFilter);

    const showTreeHead = !hideTreeHead;
    const showSelectAll = !hideSelectAll;
    const showSearch = !hideSearch;
    const showSummary = !hideSummary;

    const hasCustomSearch = !isNil(search);

    return (
        <div {...remainingProps} className={treeClassNames} ref={treeRef}>
            <div className='TreeHeader'>
                {treeHeaderContent}
                {showSearch && !hasCustomSearch && (
                    <TreeSearch
                        value={state.searchValue}
                        onChange={handleSearchChange}
                        placeholder={searchPlaceholder}
                    />
                )}
                {hasCustomSearch && search}
                {showTreeHead && (
                    <div className={treeHeadClasses}>
                        {showSelectAll && (
                            <div className='border border-right-only hidden-empty padding-right-10 margin-right-2'>
                                <TreeSelectAll
                                    isChecked={state.allChecked}
                                    isEnabled={hasMultiselect}
                                    isIndeterminate={isIndeterminate}
                                    onSelect={handleSelectAll}
                                />
                            </div>
                        )}
                        <div className='display-flex justify-content-between align-items-start width-100pct'>
                            {showSummary
                                ? summary || (
                                      <TreeSummary>
                                          {map((typeCounter: AssetType) => (
                                              <TypeCounter
                                                  key={typeCounter}
                                                  type={typeCounter}
                                                  icon={`${typeCounter}`}
                                                  value={state.assetCounts[typeCounter]}
                                                  onClick={handleFilterByType}
                                                  isActive={state.typeFilter.includes(typeCounter)}
                                                  hasFilter={isFilterActive}
                                                  enableActivity={enableActivity}
                                              />
                                          ))(state.visibleTypeCounters)}
                                      </TreeSummary>
                                  )
                                : null}
                        </div>
                        <TreeOptions treeOptions={treeOptions} treeOptionsTooltip={treeOptionsTooltip} />
                    </div>
                )}
            </div>
            <TreeRoot maxHeight={scrollHeight} disableAnimation={disableAnimation}>
                {content}
            </TreeRoot>
        </div>
    );
}, customCompare);

export default Tree;
