// @ts-ignore-next-line importsNotUsedAsValues
import React from 'react';
import classNames from 'classnames';

import type { BUTTON_STYLE } from '../../Button';

export type StateButtonProps = {
    /**
     * The button text.
     *
     * This can also be a node with an icon to be shown on the button.
     */
    text: string | React.ReactNode;

    /**
     * Callback fired after the component starts to collapse.
     *
     * @default () => {}
     */
    onClick?: VoidFunction;

    /**
     * Instead of a callback for the button you can specify a "href".
     *
     * This way it will render a link button instead.
     */
    href?: string;

    /**
     * The style of the button.
     *
     * @default 'primary'
     */
    bsStyle?: BUTTON_STYLE;

    /**
     * Additional classes assigned to the button.
     */
    className?: string;
};

const StateButton = (props: StateButtonProps) => {
    const { text, bsStyle = 'primary', className, onClick = () => {}, href } = props;

    // In case a href is given, render a link instead
    if (href) {
        return (
            <a className={classNames('margin-top-15', className)} href={href} onClick={onClick}>
                {text}
            </a>
        );
    }

    const buttonClassNames = classNames('btn', 'margin-top-10', `btn-${bsStyle}`, className);

    return (
        <button className={buttonClassNames} type='button' onClick={onClick}>
            {text}
        </button>
    );
};

export default StateButton;
