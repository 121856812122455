import React, { useLayoutEffect, useRef, useState, type MouseEvent, type ReactNode } from 'react';
import isEmpty from 'lodash/fp/isEmpty';
import debounce from 'lodash/fp/debounce';
import classNames from 'classnames';

import useEffectOnce from '../../hooks/useEffectOnce';
import ClearableInput from '../clearableInput/ClearableInput';
import ExpanderPanel from '../expander/ExpanderPanel';
import ListMenuGroup, { type ListMenuNavItem, type ListMenuItem } from './ListMenuGroup';
import useEsc from '../../hooks/useEsc';
import useWindowResize from '../../hooks/useWindowResize';
import useKey from '../../hooks/useKey';

const RESIZE_THROTTLING = 10;
const MOBILE_MAX_WIDTH = 580;

const filterMenuItems = <T extends ListMenuNavItem>(
    items: ListMenuItem<T>[],
    value: string,
    filterKey: keyof T
): ListMenuItem<T>[] =>
    items.map(item => ({
        ...item,
        navItems: item.navItems.filter(navItem =>
            (navItem[filterKey] as string).toLowerCase().includes(value.toLowerCase())
        ),
    }));

const hasMenuItems = <T extends ListMenuNavItem>(items: ListMenuItem<T>[]) =>
    items.find(({ navItems }) => !isEmpty(navItems));

export type ListMenuProps<T extends ListMenuNavItem> = {
    /**
     * List of menu item groups to be shown.
     */
    menuItems: ListMenuItem<T>[];

    /**
     * Enables the filter.
     *
     * @default false
     */
    enableFilter?: boolean;

    /**
     * Focus the filter input.
     *
     * @default false
     */
    focusFilter?: boolean;

    /**
     * Define the attribute key for filtering.
     *
     * @default 'key'
     */
    filterKey?: keyof T;

    /**
     * The placeholder text for the filter input.
     */
    filterPlaceholder?: string;

    /**
     * Gets called when the filter input changes.
     *
     * @param value
     * @returns
     */
    onFilterChange?: (value: string) => void;

    /**
     * A localized message to be shown when the filter result is empty.
     */
    notFoundMessage?: string | ReactNode;

    /**
     * The menu uses collapses on smaller screens using an expander panel.
     *
     * @default true
     */
    responsive?: boolean;

    /**
     * Enables automatic closing of the expander panel.
     *
     * Only relevant when using the `responsive` flag. Note: Make sure to not stop the events from bubbling up when
     * clicking on a list item!
     *
     * Using `event.stopPropagation()` will prevent the panel from closing.
     *
     * @default true
     */
    autoClose?: boolean;

    /**
     * Additional classes to be set on the menu group element.
     */
    groupClassName?: string;

    /**
     * Additional addon for the input group.
     */
    trailingInputAddon?: React.ReactNode;

    /**
     * Additional classes to be set on the wrapper element.
     */
    className?: string;
};

const ListMenu = <T extends ListMenuNavItem>(props: ListMenuProps<T>) => {
    const {
        menuItems,
        focusFilter = false,
        enableFilter = false,
        filterPlaceholder,
        onFilterChange = () => {},
        notFoundMessage,
        className = '',
        groupClassName = '',
        responsive = true,
        autoClose = true,
        filterKey = 'key',
        trailingInputAddon,
        ...remainingProps
    } = props;

    const [filterValue, setFilterValue] = useState('');
    const [isMobileMode, setIsMobileMode] = useState(false);
    const [isExpanderOpen, setIsExpanderOpen] = useState(false);
    const [mobileHeader, setMobileHeader] = useState<ReactNode>('');

    const inputRef = useRef<HTMLInputElement>(null);
    const listRef = useRef<HTMLDivElement>(null);

    const buildMobileHeader = () => {
        if (responsive && listRef.current) {
            const [activeElement] = listRef.current.getElementsByClassName('active') as HTMLCollectionOf<HTMLElement>;
            setMobileHeader(
                <div className='display-flex align-items-center'>
                    <span className='rioglyph rioglyph-menu-hamburger margin-right-10' />
                    <span>{activeElement?.innerText}</span>
                </div>
            );
        }
    };

    // clear filter input on esc
    useEsc(() => {
        if (enableFilter && inputRef.current === document.activeElement) {
            setFilterValue('');
        }
    });

    // Convert the menu to an expandable panel for smaller screens
    const handleResize = debounce(RESIZE_THROTTLING)(() => {
        buildMobileHeader();
        setIsMobileMode(window.innerWidth < MOBILE_MAX_WIDTH);
    });

    useWindowResize(handleResize);

    const focusInput = () => {
        inputRef.current?.focus();
    };

    useKey((event: KeyboardEvent) => {
        if ((event.metaKey || event.ctrlKey) && event.key === 'k') {
            event.preventDefault();
            focusInput();
        }
    });

    // Focus filter input if requested
    useEffectOnce(() => {
        focusFilter && focusInput();
    });

    useLayoutEffect(() => buildMobileHeader, [menuItems]);

    const handleClear = () => focusFilter && focusInput();

    const handleFilterChange = (value: string) => {
        setFilterValue(value);
        onFilterChange(value);
    };

    const filteredMenuItems = filterMenuItems(menuItems, filterValue, filterKey);

    const handleExpanderBodyClick = (event: MouseEvent) => {
        const isListItem = (event.target as HTMLDivElement).parentElement?.tagName.toLowerCase() === 'li';
        if (autoClose && isListItem) {
            setIsExpanderOpen(false);
        }
    };

    const formClassNames = classNames(
        'form-group',
        'margin-bottom-5',
        'padding-left-15',
        'padding-right-15',
        'padding-bottom-15',
        'position-sticky',
        'top-0'
    );

    const listMenu = (
        <div {...remainingProps} className={`ListMenu ${className}`} ref={listRef}>
            {enableFilter && (
                <div className={formClassNames}>
                    <div className='input-group width-100pct'>
                        <span className='input-group-addon'>
                            <span className='rioglyph rioglyph-search' aria-hidden='true' />
                        </span>
                        <ClearableInput
                            value={filterValue}
                            inputRef={inputRef}
                            placeholder={filterPlaceholder}
                            onChange={handleFilterChange}
                            onClear={handleClear}
                        />
                        {trailingInputAddon && trailingInputAddon}
                    </div>
                </div>
            )}

            {!hasMenuItems(filteredMenuItems) && (
                <div className='padding-top-25 text-center text-color-gray'>{notFoundMessage}</div>
            )}

            {filteredMenuItems.map(menuGroup => (
                <ListMenuGroup key={crypto.randomUUID()} className={groupClassName} menuGroup={menuGroup} />
            ))}
        </div>
    );

    if (responsive && isMobileMode) {
        return (
            <ExpanderPanel
                title={mobileHeader}
                bsStyle='default'
                open={isExpanderOpen}
                onToggle={() => setIsExpanderOpen(!isExpanderOpen)}
                unmountOnExit={false}
                className='shadow-default'
            >
                <div onClick={handleExpanderBodyClick}>{listMenu}</div>
            </ExpanderPanel>
        );
    }

    return listMenu;
};

export default ListMenu;
