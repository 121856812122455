import { Playground, PLAYGROUNG_BG_LIGHTER } from '../../../components/playground/Playground';
import { PlaygroundClasses } from '../../../components/playground/PlaygroundClasses';
import { Description } from '../../../components/Description';

import TableCommonDemo from '../../components/tables/general/TableCommonDemo';
import TableViewToggles from '../../../../../src/components/table/TableViewToggles';

export const TableResponsive = () => (
    <div>
        <Description headline='Responsive tables' anchor='table-responsive' />
        <div className='panel panel-default panel-body shadow-default'>
            <p>
                Tables - by design - are not great for small screens. Columns get squeezed together, and content is hard
                to read or gets cut off.
            </p>
            <p>There are some options to tackle this problem</p>
            <ul className='margin-left-20'>
                <li className='margin-top-15'>
                    Wrapping the table in another div element and using the class <code>table-responsive</code> on that
                    wrapper. That will create a horizontal overflow of the table when the available space exceeds.
                </li>
                <li className='margin-top-15'>
                    Try to avoid defining a fixed width on all columns as that avoids shrinking columns automatically
                    for smaller screens.
                </li>
                <li className='margin-top-15'>
                    Use alternative representations for the table by either rendering a list instead of on small screens
                    or using the <b>table cards view</b> on mobile screens.
                </li>
                <li className='margin-top-15'>
                    Use class <code>ellipsis-1</code> for column header or table cell content when the text gets
                    truncated and to show the "..." at the end.
                </li>
            </ul>
        </div>

        <Description headline='Table cards' headlineType='h3' headlineNoTopSpace />
        <Playground
            example={<TableCommonDemo viewType={TableViewToggles.VIEW_TYPE_MULTI_CARDS} />}
            classes={tableViewCardClasses}
            background={PLAYGROUNG_BG_LIGHTER}
            className='overflow-hidden'
        />
    </div>
);

const tableViewCardClasses = (
    <>
        <PlaygroundClasses label='<table>' classes='table-cards' />
        <PlaygroundClasses label='Variants' classes={['table-single-card', 'table-multi-cards']} />
    </>
);
