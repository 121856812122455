import { Playground, PLAYGROUNG_BG_LIGHTER } from '../../../components/playground/Playground';
import { Description } from '../../../components/Description';
import { replaceUikitImport } from '../../../utils/replaceUikitImport';

import TablePanelExample from './TablePanelExample';
import TablePanelExampleRaw from './TablePanelExample.tsx?raw';

export const TablePanel = () => (
    <div>
        <Description headline='Combined tables in ExpandablePanel' anchor='table-panel' />
        <Playground
            example={<TablePanelExample />}
            codeReact={replaceUikitImport(TablePanelExampleRaw)}
            background={PLAYGROUNG_BG_LIGHTER}
        />
    </div>
);
