// @ts-ignore-next-line importsNotUsedAsValues
import React from 'react';
import classNames from 'classnames';

import AppMenuDropdown from './AppMenuDropdown';
import AppMenuContent from './AppMenuContent';
import type { ModulePropType } from './ApplicationHeader';

// Support a list of items for services without the app navigator like internal MDM services
export type AppMenuProps = {
    label?: string | React.ReactNode;
    appMenuItems?: ModulePropType[];
    appNavigator?: React.ReactNode;
    appNavigatorClassName?: string;
    onToggleAppMenu?: (isShown: boolean) => void;
};

const AppMenu = (props: AppMenuProps) => {
    const {
        label = 'Unknown',
        appMenuItems = [],
        appNavigatorClassName,
        appNavigator,
        onToggleAppMenu,
        ...remainingProps
    } = props;

    const hasItems = !!(appMenuItems?.length || appNavigator);

    const wrapperClassNames = classNames(
        'ModuleNavigation AppMenu',
        'user-select-none',
        { hasModules: hasItems },
        !appNavigator && 'pointer-events-none',
        appNavigatorClassName && appNavigatorClassName
    );

    return (
        <ul {...remainingProps} className={wrapperClassNames}>
            <AppMenuDropdown title={label} caret={hasItems} onToggleAppMenu={onToggleAppMenu}>
                {appNavigator && <AppMenuContent appMenuItems={appMenuItems} appNavigator={appNavigator} />}
            </AppMenuDropdown>
        </ul>
    );
};

export default AppMenu;
