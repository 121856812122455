import orderBy from 'lodash/fp/orderBy';
import { orderBy as naturalOrderBy, type Identifier } from 'natural-orderby';

import type { ObjectValues } from './ObjectValues';

export const SortDirection = {
    ASCENDING: 'asc',
    DESCENDING: 'desc',
} as const;

export type SortDirectionType = ObjectValues<typeof SortDirection>;

export default SortDirection;

const toLowerCaseProp = <T>(element: T, prop: keyof T): string | keyof T => {
    if (typeof element[prop] === 'string') {
        return (element[prop] as string).toLowerCase();
    }
    return element[prop] as keyof T;
};

export const sortByProperty = <T>(
    array: T[],
    property: keyof T,
    sortDirection: SortDirectionType = SortDirection.ASCENDING,
    isCaseSensitive?: boolean
) => {
    if (!isCaseSensitive) {
        return orderBy((element: T) => toLowerCaseProp(element, property), sortDirection, array);
    }
    return orderBy([property], sortDirection, array);
};

export const naturalSortByProperty = <T>(
    array: T[],
    property: keyof T,
    sortDirection: SortDirectionType = SortDirection.ASCENDING,
    isCaseSensitive?: boolean
) => {
    if (!isCaseSensitive) {
        return naturalOrderBy(array, (element: T) => toLowerCaseProp(element, property), sortDirection);
    }
    return naturalOrderBy(array, property as Identifier<T>, [sortDirection]);
};
