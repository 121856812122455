import React, { useState } from 'react';
import classNames from 'classnames';
import isEmpty from 'lodash/fp/isEmpty';

import MobileHeaderModal from './MobileHeaderModal';
import type { ModulePropType } from './ApplicationHeader';

export type MobileSubmoduleNavigationProps = {
    label: React.ReactNode;
    navItems?: ModulePropType[];
    className?: string;
};

const MobileSubmoduleNavigation = (props: MobileSubmoduleNavigationProps) => {
    const { navItems = [], label, className } = props;

    const [isShown, setIsShown] = useState(false);

    const handleToggleNavigation = () => setIsShown(!isShown);

    const hasNavItems = !isEmpty(navItems);

    const classes = classNames(
        'MobileSubmoduleNavigation',
        'display-flex gap-10 align-items-center',
        'padding-x-10',
        'cursor-pointer',
        className
    );

    const labelClasses = classNames(
        hasNavItems
            ? 'text-size-12 text-color-dark line-height-14'
            : 'text-size-16 line-height-16 text-color-darkest text-medium text-uppercase',
        'width-100pct ellipsis-1'
    );

    return (
        <div className={classes} onClick={handleToggleNavigation}>
            <div className='flex-1-1 display-flex flex-column align-items-center width-0'>
                <div className={labelClasses}>{label}</div>
                <div className='text-medium width-100pct'>
                    {navItems.map(navItem => (
                        <div key={navItem.key} className='ellipsis-1'>
                            {React.cloneElement(navItem.route as React.ReactElement, {
                                className: 'text-size-16 line-height-16 text-color-darkest text-decoration-none',
                                onClick: (event: MouseEvent) => event.preventDefault(),
                            })}
                        </div>
                    ))}
                </div>
            </div>
            {hasNavItems && (
                <div className='text-size-16 text-color-dark'>
                    <span className='rioglyph rioglyph-chevron-down' />
                </div>
            )}
            {hasNavItems && (
                <MobileHeaderModal show={isShown}>
                    {navItems.map(navItem => (
                        <div key={navItem.key} className='margin-y-15 font-size-20'>
                            {React.cloneElement(navItem.route as React.ReactElement, {
                                className: 'text-size-20 text-color-darker',
                            })}
                        </div>
                    ))}
                </MobileHeaderModal>
            )}
        </div>
    );
};

export default MobileSubmoduleNavigation;
