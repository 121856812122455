const getWidthInBoundaries = (minWidth: number, maxWidth: number, width: number) => {
    if (width > 0 && width < minWidth) {
        return minWidth;
    }
    if (width > 0 && width > maxWidth) {
        return maxWidth;
    }
    if (width > 0 && width > maxWidth) {
        return maxWidth;
    }
    return width;
};

export default getWidthInBoundaries;
