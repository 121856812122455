import { Playground, PLAYGROUNG_BG_LIGHTER } from '../../../components/playground/Playground';
import {
    PlaygroundClasses,
    PlaygroundResponsiveClasses,
    PlaygroundPrintClasses,
    PlaygroundNegativeClasses,
    PlaygroundClassesSpacer,
} from '../../../components/playground/PlaygroundClasses';
import { Description } from '../../../components/Description';
import { dummyText } from '../../../utils/data';
import { positions } from '../../../utils/positions';

export const Position = () => (
    <div>
        <Description headline='Position' anchor='position' />
        <Playground background={PLAYGROUNG_BG_LIGHTER} example={positionExample} classes={PositionClasses} />
    </div>
);

const positionExample = (
    <div>
        <div className='panel panel-default panel-body position-relative'>
            <button className='btn btn-link position-absolute top-10 right-10 padding-5'>
                <span className='rioglyph rioglyph-cog' aria-hidden='true' />
            </button>
            <h3 className='margin-top-0 margin-bottom-10'>Lorem ipsum dolor sit amet</h3>
            <div>{dummyText}</div>
        </div>
    </div>
);

const PositionClasses = (
    <>
        <PlaygroundClassesSpacer label='Pixel values' />
        <PlaygroundClasses
            label='Typo'
            classes={['position-relative', 'position-absolute', 'position-fixed', 'position-sticky', 'position-static']}
        />
        <PlaygroundClassesSpacer label='Pixel values' />
        <PlaygroundClasses label='Left' prefix='left-' classes={['none', ...positions.px]} />
        <PlaygroundClasses label='Right' prefix='right-' classes={['none', ...positions.px]} />
        <PlaygroundClasses label='Top' prefix='top-' classes={['none', ...positions.px]} />
        <PlaygroundClasses label='Bottom' prefix='bottom-' classes={['none', ...positions.px]} />
        <PlaygroundClassesSpacer label='Percentage values' />
        <PlaygroundClasses label='Left' prefix='left-' classes={positions.pct} suffix='pct' />
        <PlaygroundClasses label='Right' prefix='right-' classes={positions.pct} suffix='pct' />
        <PlaygroundClasses label='Top' prefix='top-' classes={positions.pct} suffix='pct' />
        <PlaygroundClasses label='Bottom' prefix='bottom-' classes={positions.pct} suffix='pct' />
        <PlaygroundNegativeClasses />
        <PlaygroundResponsiveClasses />
        <PlaygroundPrintClasses />
    </>
);
