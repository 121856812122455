import type { PropsWithChildren } from 'react';

type LabelType = 'default' | 'info' | 'success' | 'warning' | 'danger';

// When using labels:
//  - label-default: Beta
//  - label-info: Updates
//  - label-success: New
//  - label-warning: Changes
//  - label-danger: Breaking Changes
const Label = ({ type, children }: PropsWithChildren<{ type: LabelType }>) => (
    <span className={`label label-${type} label-filled label-condensed`}>{children}</span>
);

type NavLinkItemProps = PropsWithChildren<{ type: LabelType; label: string }>;

const NavLinkItem = ({ type, label, children }: NavLinkItemProps) => (
    <div className='display-flex justify-content-between'>
        {children}
        <div className='margin-top--2'>
            <Label type={type}>{label}</Label>
        </div>
    </div>
);

export const Beta = ({ type = 'default', label = 'Beta', children }: NavLinkItemProps) => (
    <NavLinkItem type={type} label={label}>
        {children}
    </NavLinkItem>
);

export const Update = ({ type = 'info', label = 'Update', children }: NavLinkItemProps) => (
    <NavLinkItem type={type} label={label}>
        {children}
    </NavLinkItem>
);

export const New = ({ type = 'success', label = 'New', children }: NavLinkItemProps) => (
    <NavLinkItem type={type} label={label}>
        {children}
    </NavLinkItem>
);

export const Fixed = ({ type = 'warning', label = 'Fixed', children }: NavLinkItemProps) => (
    <NavLinkItem type={type} label={label}>
        {children}
    </NavLinkItem>
);

export const Breaking = ({ type = 'danger', label = 'Breaking', children }: NavLinkItemProps) => (
    <NavLinkItem type={type} label={label}>
        {children}
    </NavLinkItem>
);
