import type { CartesianGrid } from 'recharts';

import colorsJson from '../../styles/variables/colors/colors.json';

type Colors = {
    [key: string]: string;
};

const colors: Colors = colorsJson;

export type DataType = Record<string, unknown> & { color?: string };

export const CURSOR_BACKGROUND_COLOR = '#a7afbb24';

const brightColors = ['color-warmup-corn', 'color-coldplay-khaki'];

export const defaultChartColors = Object.keys(colors)
    .reverse()
    .filter(key => !brightColors.includes(key))
    .map(key => colors[key]);

export const getFromDefaultColors = (index: number) => defaultChartColors[index];

export const getColor = (colorKey?: string) => (colorKey ? colors[colorKey] : undefined);

export const mapGridOptions = <T extends { stroke?: string }>(gridOptions?: T) => {
    if (!gridOptions) {
        return;
    }

    const mappedGridOptions = { ...gridOptions };
    const { stroke } = mappedGridOptions;

    mappedGridOptions.stroke = stroke && !stroke.startsWith('#') ? getColor(stroke) : stroke;

    return mappedGridOptions;
};
