// @ts-ignore-next-line importsNotUsedAsValues
import React, { forwardRef, type PropsWithChildren, type ForwardedRef } from 'react';
import classNames from 'classnames';
import noop from 'lodash/fp/noop';

import Popover, { PLACEMENT_MAP } from '../popover/Popover';
import type { ObjectValues } from '../../utils/ObjectValues';

const DEFAULT_PLACEMENT = PLACEMENT_MAP.BOTTOM;
const DEFAULT_WIDTH = 250;

export type ActionBarOverlayWidth = 100 | 150 | 200 | 250 | 300 | 350 | 400 | 450 | 500;

export type ActionBarOverlayProps = {
    id: string;
    title?: string | React.ReactNode;
    preRender?: boolean;
    show?: boolean;
    width?: ActionBarOverlayWidth;
    onClick?: () => void;
    style?: object;
    placement?: ObjectValues<typeof PLACEMENT_MAP>;
    className?: string;
};

type Ref = ForwardedRef<HTMLDivElement>;
type Props = PropsWithChildren<ActionBarOverlayProps>;

const ActionBarOverlay = forwardRef((props: Props, ref: Ref) => {
    const {
        children,
        show,
        preRender,
        id,
        title,
        onClick = noop,
        width = DEFAULT_WIDTH,
        style = {},
        className,
        placement = DEFAULT_PLACEMENT,
        ...remainingProps
    } = props;

    const adjustedStyles = { ...style, width };
    const adjustedClassName = preRender && !show ? classNames(className, 'position-offscreen') : className;

    const popoverTitleClasses = classNames('ActionBarItemPopover text-color-dark', className);
    const popoverContentClasses = classNames('ActionBarItemPopover', className);

    return (
        <Popover
            ref={ref}
            id={id}
            onClick={onClick}
            placement={placement}
            {...remainingProps}
            style={adjustedStyles}
            className={adjustedClassName}
        >
            {title && <Popover.Title className={popoverTitleClasses}>{title}</Popover.Title>}
            <Popover.Content className={popoverContentClasses}>{children}</Popover.Content>
        </Popover>
    );
});

export default ActionBarOverlay;
