import { Playground } from '../../../components/playground/Playground';
import { PlaygroundClasses } from '../../../components/playground/PlaygroundClasses';
import { Description } from '../../../components/Description';

export const TextAlignment = () => {
    return (
        <div>
            <Description headline='Alignment' anchor='alignment' />
            <Playground example={alignmentExample} classes={alignmentClasses} />
        </div>
    );
};

const alignmentExample = (
    <div className='display-grid grid-cols-1 gap-5'>
        <div className='text-left bg-lightest padding-10 rounded'>text-left</div>
        <div className='text-center bg-lightest padding-10 rounded'>text-center</div>
        <div className='text-right bg-lightest padding-10 rounded'>text-right</div>
    </div>
);

const alignmentClasses = <PlaygroundClasses prefix='text-' classes={['left', 'center', 'right']} />;
