// @ts-ignore-next-line importsNotUsedAsValues
import React, { useCallback, useEffect, useRef, useState, type PropsWithChildren } from 'react';
import ReactDOM from 'react-dom';
import { usePopper, type Modifier } from 'react-popper';
import type { Options } from '@popperjs/core';

import { PLACEMENT, type Placement } from '../../values/Placement';
import { TRIGGER, type TriggerType } from '../../values/Trigger';
import mergeRefs from '../../utils/mergeRefs';
import { useClickOutsideWithRef } from '../../hooks/useClickOutside';
import { useChainedTimeout } from '../../hooks/useTimeout';
import { useUncontrolledProp } from '../../hooks/useUncontrollable';

type PopperConfig = Partial<Options>;

export type OverlayTriggerProps = {
    /**
     * The visibility of the Overlay. `show` is a _controlled_ prop so should be paired
     * with `onToggle` to avoid breaking user interactions.
     *
     * Manually toggling `show` does **not** wait for `delay` to change the visibility.
     *
     * @controllable onToggle
     * @default: false
     */
    show?: boolean;

    /**
     * The initial visibility state of the Overlay.
     *
     * @default false
     */
    defaultShow?: boolean;

    /**
     * Defines the usage of React Portal.
     *
     * @default true
     */
    enablePortal?: boolean;

    /**
     * Specify which action or actions trigger Overlay visibility
     *
     * The `click` trigger ignores the configured `delay`.
     *
     * @default 'hover'
     */
    trigger?: TriggerType;

    /**
     * A millisecond delay amount to show and hide the Overlay once triggered
     */
    delay?: number | { show?: number; hide?: number };

    /**
     * An element or text to overlay next to the target.
     */
    overlay: React.ReactElement;

    /**
     * The placement of the Overlay in relation to it's `target`.
     *
     * @default 'top'
     */
    placement?: Placement;

    /**
     * A Popper.js config object passed to the underlying popper instance.
     * If no custom config is provided, a default config will be used. This default config
     * includes an arrow element. In case you want to use a custom config and an arrow, include the arrow
     * modifier so the correct arrow element can be injected for the arrow modifier.
     *
     * @example
     * popperConfig={{
     *     modifiers: [
     *          {
     *              name: 'offset',
     *              options: {
     *                  offset: [0, 5],
     *              },
     *          },
     *          {
     *              name: 'arrow',
     *              options: {},
     *          },
     *      ],
     *  }}
     */
    popperConfig?: PopperConfig;

    /**
     * Specify whether the overlay should trigger onHide when the user clicks outside the overlay.
     *
     * @default true
     */
    rootClose?: boolean;

    /**
     * A callback that fires when the user triggers a change in tooltip visibility.
     *
     * `onToggle` is called with the desired next `show`, and generally should be passed
     * back to the `show` prop. `onToggle` fires _after_ the configured `delay`
     *
     * @controllable `show`
     */
    onToggle?: (show: boolean) => void;
};

type TriggerProps = {
    ref: React.Ref<unknown>;
    onClick?: React.MouseEventHandler;
    onFocus?: React.FocusEventHandler;
    onBlur?: React.FocusEventHandler;
    onMouseOver?: React.MouseEventHandler;
    onMouseOut?: React.MouseEventHandler;
};

const OverlayTrigger = (props: PropsWithChildren<OverlayTriggerProps>) => {
    const {
        show: propsShow,
        defaultShow = false,
        enablePortal = true,
        trigger = TRIGGER.HOVER,
        delay,
        overlay,
        placement = 'top',
        popperConfig,
        onToggle = () => {},
        rootClose = true,
        children,
    } = props;

    // Use a hook to handle controlled props that work in pairs like in this case the "show"  and "onToggle"
    // props. It returns a setter function that automatically triggers the callback.
    const [show, setShow] = useUncontrolledProp(propsShow, defaultShow, onToggle);

    const hoverStateRef = useRef<string>('');

    const timeout = useChainedTimeout();

    const { onFocus, onBlur, onClick } =
        typeof children !== 'function' ? React.Children.only(children as any).props : ({} as any);

    // Simple implementation of mouseEnter and mouseLeave.
    // React's built version is broken: https://github.com/facebook/react/issues/4251
    // for cases when the trigger is disabled and mouseOut/Over can cause flicker
    // moving from one child element to another.
    const handleMouseOverOut = (
        handler: (...args: [React.MouseEvent, ...any[]]) => any,
        args: [React.MouseEvent, ...any[]],
        relatedNative: 'fromElement' | 'toElement'
    ) => {
        const [event] = args;
        const target = event.currentTarget as Node;

        const related = event.relatedTarget as Node | null;

        if ((!related || related !== target) && !target.contains(related)) {
            handler(...args);
        }
    };

    const handleShow = () => {
        timeout.clear();
        hoverStateRef.current = 'show';

        if (typeof delay === 'number' || !delay?.show) {
            setShow(true);
            return;
        }

        timeout.set(
            () => {
                if (hoverStateRef.current === 'show') {
                    setShow(true);
                }
            },
            typeof delay === 'number' ? delay : delay?.show
        );
    };

    const handleHide = () => {
        timeout.clear();
        hoverStateRef.current = 'hide';

        if (typeof delay === 'number' || !delay?.hide) {
            setShow(false);
            return;
        }

        timeout.set(
            () => {
                if (hoverStateRef.current === 'hide') {
                    setShow(false);
                }
            },
            typeof delay === 'number' ? delay : delay?.hide
        );
    };

    const handleFocus = useCallback(
        (...args: any[]) => {
            handleShow();
            onFocus?.(...args);
        },
        [handleShow, onFocus]
    );

    const handleBlur = useCallback(
        (...args: any[]) => {
            handleHide();
            onBlur?.(...args);
        },
        [handleHide, onBlur]
    );

    const handleClick = useCallback(
        (...args: any[]) => {
            setShow(!show);
            onClick?.(...args);
        },
        [onClick, setShow, show]
    );

    const handleMouseOver = useCallback(
        (...args: [React.MouseEvent, ...any[]]) => {
            handleMouseOverOut(handleShow, args, 'fromElement');
        },
        [handleShow]
    );

    const handleMouseOut = useCallback(
        (...args: [React.MouseEvent, ...any[]]) => {
            handleMouseOverOut(handleHide, args, 'toElement');
        },
        [handleHide]
    );

    const [triggerNode, setTriggerNode] = useState<HTMLButtonElement | null>(null);
    const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
    const [arrowElement, setArrowElement] = useState<HTMLDivElement | null>(null);

    const [arrowPlacement, setArrowPlacement] = useState(placement);

    const handleClickOutside = useCallback(
        (event: MouseEvent | TouchEvent) => {
            const isToggleTarget = triggerNode?.contains(event.target as Node);
            const isOverlayTarget = popperElement?.contains(event.target as Node);

            // Check if the click occurred outside the trigger element. Clicking on the trigger
            // itself is handled by the onClick handler
            if (show && rootClose && !isToggleTarget && !isOverlayTarget) {
                handleHide();
            }
        },
        [triggerNode, popperElement, rootClose, handleHide]
    );

    useClickOutsideWithRef(triggerNode, handleClickOutside);

    const triggerProps: TriggerProps = {
        ref: mergeRefs([(children as any).ref, setTriggerNode]),
    };

    if (trigger === 'click') {
        triggerProps.onClick = handleClick;
    } else if (trigger === 'focus') {
        triggerProps.onFocus = handleFocus;
        triggerProps.onBlur = handleBlur;
    } else if (trigger === 'hover') {
        triggerProps.onMouseOver = handleMouseOver;
        triggerProps.onMouseOut = handleMouseOut;
    }

    const defaultPopperConfig = {
        placement,
        modifiers: [
            {
                name: 'arrow',
                options: {
                    element: arrowElement,
                },
            },
            {
                name: 'flip',
                options: {
                    fallbackPlacements: ['right', 'left', 'top'],
                },
            },
        ],
    };

    if (popperConfig) {
        popperConfig.placement = placement;

        // if it has an arrow modifier, inject the arrow element
        const updatedModifiers: Modifier<any, any>[] = [];
        popperConfig.modifiers?.forEach((mod: Modifier<any, any>) => {
            if (mod.name !== 'arrow') {
                return updatedModifiers.push(mod);
            }
            return updatedModifiers.push({
                ...mod,
                options: {
                    ...mod.options,
                    element: arrowElement,
                },
            });
        });

        popperConfig.modifiers = updatedModifiers;
    }

    const popper = usePopper(triggerNode, popperElement, popperConfig || defaultPopperConfig);

    // In case the overlay causes an overflow and the "flip" modifier
    // changes the overlays placement, we nee to update the arrow placement as well
    useEffect(() => {
        if (popper.state) {
            // Adjust arrow styles based on placement if necessary
            setArrowPlacement(popper.state.placement);
        }
    }, [popper.state?.placement]);

    const overlayElement = React.cloneElement(overlay, {
        ...popper.attributes.popper,
        ref: setPopperElement,
        placement: arrowPlacement,
        style: { ...popper.styles.popper },
        arrowProps: {
            ...popper.attributes.arrow,
            style: popper.styles.arrow,
            ref: setArrowElement,
        },
    });

    return (
        <>
            {show && (enablePortal ? ReactDOM.createPortal(overlayElement, document.body) : overlayElement)}
            {React.cloneElement(children as any, triggerProps)}
        </>
    );
};

OverlayTrigger.TRIGGER_CLICK = TRIGGER.CLICK;
OverlayTrigger.TRIGGER_HOVER = TRIGGER.HOVER;
OverlayTrigger.TRIGGER_FOCUS = TRIGGER.FOCUS;

// placement
OverlayTrigger.AUTO_START = PLACEMENT.AUTO_START;
OverlayTrigger.AUTO = PLACEMENT.AUTO;
OverlayTrigger.AUTO_END = PLACEMENT.AUTO_END;
OverlayTrigger.TOP_START = PLACEMENT.TOP_START;
OverlayTrigger.TOP = PLACEMENT.TOP;
OverlayTrigger.TOP_END = PLACEMENT.TOP_END;
OverlayTrigger.RIGHT_START = PLACEMENT.RIGHT_START;
OverlayTrigger.RIGHT = PLACEMENT.RIGHT;
OverlayTrigger.RIGHT_END = PLACEMENT.RIGHT_END;
OverlayTrigger.BOTTOM_START = PLACEMENT.BOTTOM_START;
OverlayTrigger.BOTTOM = PLACEMENT.BOTTOM;
OverlayTrigger.BOTTOM_END = PLACEMENT.BOTTOM_END;
OverlayTrigger.LEFT_START = PLACEMENT.LEFT_START;
OverlayTrigger.LEFT = PLACEMENT.LEFT;
OverlayTrigger.LEFT_END = PLACEMENT.LEFT_END;

export default OverlayTrigger;
