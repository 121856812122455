import React from 'react';

import { Playground, PLAYGROUNG_BG_LIGHTER } from '../../../components/playground/Playground';
import { PlaygroundClasses } from '../../../components/playground/PlaygroundClasses';
import { Description } from '../../../components/Description';

export const Wells = () => (
    <div>
        <Description headline='Wells' anchor='wells' />
        <Playground example={wellsExample} classes={wellsExampleClasses} background={PLAYGROUNG_BG_LIGHTER} />
    </div>
);

const wellsExample = (
    <div className='row'>
        <div className='col-lg-4'>
            <div className='well well-sm'>
                Hi, my name is <code className='code-inline'>well-sm</code>
            </div>
        </div>
        <div className='col-lg-4'>
            <div className='well'>
                Hi, my name is <code className='code-inline'>well</code>
            </div>
        </div>
        <div className='col-lg-4'>
            <div className='well well-lg'>
                Hi, my name is <code className='code-inline'>well-lg</code>
            </div>
        </div>
    </div>
);

const wellsExampleClasses = (
    <>
        <PlaygroundClasses label='Basic' classes='well' />
        <PlaygroundClasses label='Sizes' prefix='well-' classes={['sm', 'lg']} />
    </>
);
