import React from 'react';

import { Playground } from '../../../components/playground/Playground';
import { PlaygroundClasses, PlaygroundClassesSpacer } from '../../../components/playground/PlaygroundClasses';
import { Description } from '../../../components/Description';

import { colors, getColorNames } from '../../../utils/colors';

const eachText = getColorNames(colors.text);
const eachGray = getColorNames(colors.gray);
const eachDividerVariant = ['solid', 'dotted', 'dashed'];

export const Divider = () => (
    <div>
        <Description headline='Divider' anchor='divider'>
            Dividing line between child elements, so that you do not have to set a border class on each child.
            <p className='margin-top-0'>
                You can use the the shorthand
                <code className='code-inline'>divider-y</code>
                for the automatic combination of
                <code className='code-inline'>divider-y-1</code>,
                <code className='code-inline'>divider-style-solid</code>
                and
                <code className='code-inline'>divider-color-light</code>
            </p>
        </Description>
        <Playground example={dividerExample} classes={dividerClasses} />
    </div>
);

const dividerExample = (
    <>
        <div className='display-flex flex-wrap gap-10 text-center'>
            <div className='flex-1-1 display-flex gap-10'>
                {[...Array(3)].map((_, index) => (
                    <div
                        className={`flex-1-1 bg-white border rounded divider-y-${index + 1}
                            divider-color-light divider-style-solid`}
                        key={index}
                    >
                        <div className='padding-10'>divider-y-{index + 1}</div>
                        <div className='padding-10'>divider-y-{index + 1}</div>
                        <div className='padding-10'>divider-y-{index + 1}</div>
                        <div className='padding-10'>divider-y-{index + 1}</div>
                    </div>
                ))}
            </div>
            <div className='flex-1-1 display-flex flex-column gap-10'>
                <div
                    className={
                        'display-flex bg-lightest border rounded divider-x-1 divider-color-light ' +
                        'divider-style-dashed overflow-hidden user-select-none text-medium text-color-dark'
                    }
                >
                    <div className='flex-1-1 padding-10 hover-bg-primary hover-text-color-white'>divider-x-1</div>
                    <div className='flex-1-1 padding-10 hover-bg-primary hover-text-color-white'>divider-x-1</div>
                    <div className='flex-1-1 padding-10 hover-bg-primary hover-text-color-white'>divider-x-1</div>
                </div>
                <div
                    className={
                        'display-flex bg-info text-color-white rounded divider-x-1 divider-color-white ' +
                        'divider-style-dashed'
                    }
                >
                    <div className='flex-1-1 padding-10'>divider-x-1</div>
                    <div className='flex-1-1 padding-10'>divider-x-1</div>
                    <div className='flex-1-1 padding-10'>divider-x-1</div>
                </div>
                <div
                    className={
                        'display-flex bg-info text-color-white rounded divider-x-2 divider-color-white ' +
                        'divider-style-dotted'
                    }
                >
                    <div className='flex-1-1 padding-10'>divider-x-2</div>
                    <div className='flex-1-1 padding-10'>divider-x-2</div>
                    <div className='flex-1-1 padding-10'>divider-x-2</div>
                </div>
            </div>
        </div>
    </>
);

const dividerClasses = (
    <>
        <PlaygroundClassesSpacer label='Values' />
        <PlaygroundClasses label='Base' classes={['divider-x', 'divider-y']} />
        <PlaygroundClasses label='X-width' prefix='divider-x-' classes={[...Array(3)]} />
        <PlaygroundClasses label='Y-width' prefix='divider-y-' classes={[...Array(3)]} />
        <PlaygroundClasses label='Style' prefix='divider-style-' classes={eachDividerVariant} />
        <PlaygroundClassesSpacer label='Colors' />
        <PlaygroundClasses label='Basic' prefix='divider-color-' classes={eachText} />
        <PlaygroundClasses label='Gray' prefix='divider-color-' classes={eachGray} />
    </>
);
