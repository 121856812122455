import { Playground } from '../../../components/playground/Playground';
import { PlaygroundPrintClasses, PlaygroundResponsiveClasses } from '../../../components/playground/PlaygroundClasses';
import { Description } from '../../../components/Description';

export const Display = () => (
    <div>
        <Description headline='Display' anchor='display' />
        <Playground example={displayExample} classes={displayClasses} />
    </div>
);

const displayExample = (
    <div>
        <code className='code-inline-first'>display-block</code>
        <code className='code-inline'>display-inline-block</code>
        <code className='code-inline'>display-flex</code>
        <code className='code-inline'>display-inline-flex</code>
        <code className='code-inline'>display-inline</code>
        <code className='code-inline'>display-none</code>
    </div>
);

const displayClasses = (
    <>
        <PlaygroundResponsiveClasses />
        <PlaygroundPrintClasses />
    </>
);
