// https://www.youtube.com/watch?v=d2O-8n-UnrI&list=WL&t=337s

export default () => (
    <div className='display-grid grid-cols-1 grid-cols-2-sm gap-20'>
        <fieldset>
            <div className='panel panel-default panel-body height-100pct shadow-default'>
                <legend>A form with fieldset</legend>
                <div className='form-group'>
                    <label className='width-100pct'>
                        <div>Lorem ipsum</div>
                        <input className='form-control margin-y-5' type='text' placeholder='Lorem Ipsum Dolor' />
                        <div>Dolor Sit amet </div>
                    </label>
                </div>

                <div className='form-group'>
                    <label className='width-100pct'>
                        <div className='margin-bottom-5'>Lorem ipsum</div>
                        <div className='input-group'>
                            <span className='input-group-addon'>
                                <span className='rioglyph rioglyph-user' />
                            </span>
                            <input className='form-control' type='text' placeholder='Lorem Ipsum Dolor' />
                        </div>
                    </label>
                </div>

                <div className='form-group has-error has-feedback'>
                    <label htmlFor='fieldset_1'>Lorem ipsum</label>
                    <input id='fieldset_1' type='text' className='form-control' />
                    <span className='form-control-feedback rioglyph rioglyph-error-sign' />
                </div>
            </div>
        </fieldset>

        <fieldset disabled>
            <div className='panel panel-default panel-body height-100pct shadow-default'>
                <legend>A form with fieldset disabled</legend>
                <div className='form-group'>
                    <label className='width-100pct'>
                        <div className='margin-bottom-5'>Lorem ipsum</div>
                        <input className='form-control margin-y-5' type='text' placeholder='Lorem Ipsum Dolor' />
                        <div>Dolor Sit amet </div>
                    </label>
                </div>

                <div className='form-group'>
                    <label className='width-100pct'>
                        <div className='margin-bottom-5'>Lorem ipsum</div>
                        <div className='input-group'>
                            <span className='input-group-addon'>
                                <span className='rioglyph rioglyph-user' />
                            </span>
                            <input className='form-control' type='text' placeholder='Lorem Ipsum Dolor' />
                        </div>
                    </label>
                </div>

                <div className='form-group has-error has-feedback'>
                    <label htmlFor='fieldset_2'>Lorem ipsum</label>
                    <input id='fieldset_2' type='text' className='form-control' />
                    <span className='form-control-feedback rioglyph rioglyph-error-sign' />
                </div>
            </div>
        </fieldset>
    </div>
);
