import { useState } from 'react';

import { Playground } from '../../../components/playground/Playground';
import {
    PlaygroundClasses,
    PlaygroundResponsiveClasses,
    PlaygroundClassesSpacer,
} from '../../../components/playground/PlaygroundClasses';

import { Description } from '../../../components/Description';
import Button from '../../../../../src/Button';
import Checkbox from '../../../../../src/Checkbox';
import Switch from '../../../../../src/Switch';

export const Subgrid = () => {
    return (
        <div>
            <Description headline='Subgrid' anchor='subgrid' />
            <p>
                The <b>subgrid</b> value in CSS Grid Layout is a feature that allows a nested grid to inherit the column
                and/or row definitions from its parent grid container. This means that the subgrid can align its items
                according to the tracks defined in the parent grid, creating a more consistent and aligned layout
                structure. It is particularly useful for complex layouts where nested elements need to align with the
                overall grid structure without redefining the grid tracks. By using subgrid, you can maintain a cohesive
                design while simplifying the CSS code needed for layout alignment. This feature enhances the flexibility
                and power of CSS Grid, making it easier to manage nested grid layouts.
            </p>
            <Playground example={<SubgridFormExample />} classes={subgridClasses} />
            <Playground example={<SubgridFormExample2 />} classes={subgridClasses} />
            <Playground example={<SubgridCardsExample />} classes={subgridClasses} />
        </div>
    );
};

const subgridClasses = (
    <>
        <PlaygroundClasses label='Col' classes='grid-cols-subgrid' />
        <PlaygroundClasses label='Row' classes='grid-rows-subgrid' />
    </>
);

const SubgridFormExample = () => {
    const [hasSubgrid, setHasSubgrid] = useState(true);

    const subgridClass = hasSubgrid ? 'grid-cols-subgrid' : 'grid-cols-2-max-content-1fr';

    return (
        <div className='max-width-500'>
            <form className='display-grid grid-cols-2-max-content-1fr row-gap-15 align-items-center'>
                <div className='grid-colspan-2 text-size-h4 text-color-darker'>Form example</div>
                <section
                    className={`display-grid ${subgridClass} grid-column-start-1 grid-column-end-3 gap-15 align-items-center form-group margin-0`}
                >
                    <div className='grid-colspan-2 text-size-h5 text-color-darker'>Connection</div>

                    <label className='margin-0' htmlFor='subgrid-host'>
                        Host
                    </label>
                    <input id='subgrid-host' type='text' className='form-control' />

                    <label className='margin-0' htmlFor='subgrid-port'>
                        Port
                    </label>
                    <input id='subgrid-port' type='number' className='form-control' />

                    <label className='margin-0' htmlFor='subgrid-date'>
                        Really long label
                    </label>
                    <input id='subgrid-date' type='date' className='form-control' />
                </section>
                <section
                    className={`display-grid ${subgridClass} grid-column-start-1 grid-column-end-3 gap-15 align-items-center form-group margin-0`}
                >
                    <div className='grid-colspan-2 text-size-h5 text-color-darker'>Credentials</div>

                    <label className='margin-0' htmlFor='subgrid-username'>
                        Username
                    </label>
                    <input id='subgrid-username' type='text' className='form-control' />

                    <label className='margin-0' htmlFor='subgrid-password'>
                        Password
                    </label>
                    <input id='subgrid-password' type='password' className='form-control' />

                    <div
                        className={`display-grid ${subgridClass} grid-column-start-1 grid-column-end-3 gap-15 align-items-center form-group margin-0`}
                    >
                        <label className='margin-0'>Checkbox</label>
                        <Checkbox>Yet another level of nesting</Checkbox>
                    </div>
                </section>
                <footer className='display-flex grid-colspan-2 justify-content-between align-items-center'>
                    <Switch checked={hasSubgrid} onChange={() => setHasSubgrid(val => !val)}>
                        Subgrid enabled
                    </Switch>
                    <Button>Submit</Button>
                </footer>
            </form>
        </div>
    );
};

const SubgridFormExample2 = () => {
    const [hasSubgrid, setHasSubgrid] = useState(true);

    const subgridClass = hasSubgrid ? 'grid-cols-subgrid' : '';

    return (
        <div className='max-width-500'>
            <form className='display-grid grid-cols-2-max-content-1fr gap-15'>
                <label
                    className={`margin-0 display-grid ${subgridClass} grid-colspan-2 align-items-center form-group margin-0`}
                    htmlFor='subgrid2-name'
                >
                    <span>Full Name</span>
                    <input id='subgrid2-name' type='text' className='form-control' />
                </label>

                <label
                    className={`margin-0 display-grid ${subgridClass} grid-colspan-2 align-items-center form-group margin-0`}
                    htmlFor='subgrid2-email'
                >
                    <span>Email</span>
                    <input id='subgrid2-email' type='text' className='form-control' />
                </label>

                <label
                    className={`margin-0 display-grid ${subgridClass} grid-colspan-2 form-group margin-0`}
                    htmlFor='subgrid2-text'
                >
                    <span>Message</span>
                    <textarea id='subgrid2-text' className='form-control' />
                </label>
                <footer className='grid-column-start-2 display-flex justify-content-between align-items-center'>
                    <Switch checked={hasSubgrid} onChange={() => setHasSubgrid(val => !val)}>
                        Subgrid enabled
                    </Switch>
                    <Button>Submit</Button>
                </footer>
            </form>
        </div>
    );
};

const SubgridCardsExample = () => {
    const [hasSubgrid, setHasSubgrid] = useState(true);

    const panelClasses = 'panel panel-default display-grid';
    const panelColumnClasses = hasSubgrid ? 'grid-cols-subgrid grid-colspan-2' : 'grid-colspan-2';
    const panelRowClasses = hasSubgrid ? 'grid-rowspan-3 grid-rows-subgrid row-gap-0' : 'grid-rowspan-3 row-gap-0';

    return (
        <div className='max-width-800 display-flex flex-column gap-20'>
            <div className='text-size-h4 text-color-darker'>Subgrid Cards</div>
            <div className='display-grid grid-cols-1-xs grid-cols-4-1fr-max-content gap-15'>
                <article className={`${panelClasses} ${panelColumnClasses} ${panelRowClasses}`}>
                    <div className='panel-heading grid-colspan-2 text-size-h6 text-medium text-color-darker'>
                        Article title
                    </div>
                    <div className='panel-body grid-colspan-2'>
                        Lorem ipsum dolor, sit amet consectetur adipisicing elit. Dolor itaque, vitae dicta autem sunt
                        nesciunt atque nulla? Consectetur adipisci illum ipsa reprehenderit deleniti quia qui aliquid
                        tempora ea accusamus. Quibusdam.
                    </div>
                    <p className='align-self-center margin-x-15'>
                        <span>
                            by <a href='#'>Mia</a>
                        </span>
                        <span>
                            {' '}
                            at <a href='#'>Company</a>
                        </span>
                    </p>
                    <Button className='margin-15'>Love it</Button>
                </article>

                <article className={`${panelClasses} ${panelColumnClasses} ${panelRowClasses}`}>
                    <div className='panel-heading grid-colspan-2 text-size-h6 text-medium text-color-darker'>
                        Article title
                    </div>
                    <div className='panel-body grid-colspan-2'>
                        Lorem ipsum dolor, sit amet consectetur adipisicing elit. Dolor itaque, vitae dicta autem sunt
                        nesciunt atque nulla?
                    </div>
                    <p className='align-self-center margin-x-15'>
                        <span>
                            by <a href='#'>Mia</a>
                        </span>
                        <span>
                            {' '}
                            at <a href='#'>Company</a>
                        </span>
                    </p>
                    <Button className='margin-15'>Love it</Button>
                </article>

                <article className={`${panelClasses} ${panelColumnClasses} ${panelRowClasses}`}>
                    <div className='panel-heading grid-colspan-2 text-size-h6 text-medium text-color-darker'>
                        What if we have a much longer article title?
                    </div>
                    <div className='panel-body grid-colspan-2'>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Ducimus eveniet, tempore error maxime
                        praesentium eos.
                    </div>
                    <p className='align-self-center margin-x-15'>
                        <span>
                            by <a href='#'>Jen "Boss" Simmons</a>
                        </span>
                        <span>
                            {' '}
                            at <a href='#'>Company</a>
                        </span>
                    </p>
                    <Button className='margin-15'>Love it so</Button>
                </article>

                <article className={`${panelClasses} ${panelColumnClasses} ${panelRowClasses}`}>
                    <div className='panel-heading grid-colspan-2 text-size-h6 text-medium text-color-darker'>
                        Article titles aren't consistent
                    </div>
                    <div className='panel-body grid-colspan-2'>
                        Lorem, ipsum dolor sit amet consectetur adipisicing elit. Excepturi molestiae autem saepe libero
                        assumenda dolorum quae laboriosam aliquam magni sint, voluptates officia dignissimos a incidunt,
                        unde suscipit fugiat illum optio.
                    </div>
                    <p className='align-self-center margin-x-15'>
                        <span>
                            by <a href='#'>Deja "Empathic Dev" Hodge</a>
                        </span>
                        <span>
                            {' '}
                            at <a href='#'>Company</a>
                        </span>
                    </p>
                    <Button className='margin-15'>Love it</Button>
                </article>

                <article className={`${panelClasses} ${panelColumnClasses} ${panelRowClasses}`}>
                    <div className='panel-heading grid-colspan-2 text-size-h6 text-medium text-color-darker'>
                        This article title will need to be fixed in Post-Production with a much shorter and catchier
                        click-bait title
                    </div>
                    <div className='panel-body grid-colspan-2'>
                        Lorem, ipsum dolor sit amet consectetur adipisicing elit. Excepturi molestiae autem saepe libero
                        assumenda dolorum quae laboriosam aliquam magni sint, voluptates officia dignissimos a incidunt,
                        unde suscipit fugiat illum optio.
                    </div>
                    <p className='align-self-center margin-x-15'>
                        <span>
                            by <a href='#'>Ryanne "Fix It In Post" Hodson</a>
                        </span>
                        <span>
                            {' '}
                            at <a href='#'>Company</a>
                        </span>
                    </p>
                    <Button className='margin-15'>Love it</Button>
                </article>

                <article className={`${panelClasses} ${panelColumnClasses} ${panelRowClasses}`}>
                    <div className='panel-heading grid-colspan-2 text-size-h6 text-medium text-color-darker'>
                        Short title
                    </div>
                    <div className='panel-body grid-colspan-2'>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Optio enim maxime repellat illo!
                    </div>
                    <p className='align-self-center margin-x-15'>
                        <span>
                            by <a href='#'>Jay "Do It" Dedman</a>
                        </span>
                        <span>
                            {' '}
                            at <a href='#'>Company</a>
                        </span>
                    </p>
                    <Button className='margin-15'>Love it</Button>
                </article>
            </div>
            <Switch checked={hasSubgrid} onChange={() => setHasSubgrid(val => !val)}>
                Subgrid enabled
            </Switch>
        </div>
    );
};
