// @ts-ignore-next-line importsNotUsedAsValues
import React, { type PropsWithChildren } from 'react';
import { default as _ContentLoader } from 'react-content-loader';
import cond from 'lodash/fp/cond';
import isNil from 'lodash/fp/isNil';

import useDarkMode from '../../hooks/useDarkMode';

const RECT = 'rect';
const CIRCLE = 'circle';

const DEFAULT_SPEED = 2;
const DEFAULT_HEIGHT = 14;
const DEFAULT_WIDTH = '100%';
const DEFAULT_RADIUS = 16;
const DEFAULT_TYPE = RECT;

export type ContentLoaderProps = {
    /**
     * Sets the animation speed.
     * @default 2
     */
    speed?: number;
    /**
     * Sets the height of the svg element.
     * @default 14
     */
    height?: string | number;
    /**
     * Sets the width of the svg element.
     * @default 100%
     */
    width?: string | number;
    /**
     * Sets the radius of the svg element. Only relevant for circle elements.
     * @default 16
     */
    radius?: number;
    /**
     * Renders either a rectangle or circle.
     *
     * Possible values are:
     * `ContentLoader.RECT`, `ContentLoader.CIRCLE` or `rect`, `circle`
     */
    type?: typeof RECT | typeof CIRCLE;
    /**
     * Additional class names to ab added to the wrapping svg element.
     */
    className?: string;
    /** Rect object prop. Used internally. */
    rx?: number;
    /** Rect object prop. Used internally. */
    ry?: number;
};

type ContentLoaderPropsWithChildren = PropsWithChildren<ContentLoaderProps>;

const hasChildren = ({ children }: ContentLoaderPropsWithChildren) => !isNil(children);
const renderChildren = ({ children }: ContentLoaderPropsWithChildren) => children;

const isRect = ({ type }: ContentLoaderPropsWithChildren) => type === RECT;
const isCircle = ({ type }: ContentLoaderPropsWithChildren) => type === CIRCLE;

const renderRect = ({ rx = 3, ry = 3 }: ContentLoaderPropsWithChildren) => (
    <rect width='100%' height='100%' rx={rx} ry={ry} />
);
const renderCircle = ({ radius }: ContentLoaderPropsWithChildren) => <circle cx={radius} cy={radius} r={radius} />;

/**
 * This UIKIT component is simple wrapper to make it easier to use with a predefined look and feel. It still
 * allows for more complex configurations as all additional props are passed down to the third-party
 * ContentLoader component. For individual customization, it allows passing svg components as rect or circle
 * directly as children.
 * @param props
 * @returns
 */
const ContentLoader = (props: ContentLoaderPropsWithChildren) => {
    const {
        speed = DEFAULT_SPEED,
        width = DEFAULT_WIDTH,
        height = DEFAULT_HEIGHT,
        radius = DEFAULT_RADIUS,
        type = DEFAULT_TYPE,
        className = '',
        children,
        ...remainingProps
    } = props;

    const isDarkMode = useDarkMode();

    // Since we define the default values while destructuring the props, we have to build up a new object
    // with that defaults to be used in the conditional rendering, otherwise it would use only the props as
    // passed into the component without the defined defaults.
    const propsWithDefaults = {
        type,
        radius,
        children,
    } as ContentLoaderPropsWithChildren;

    const content = cond([
        [hasChildren, renderChildren],
        [isRect, renderRect],
        [isCircle, renderCircle],
    ])(propsWithDefaults);

    const wrapperHeight = isCircle(propsWithDefaults) ? radius * 2 : height;
    const wrapperWidth = isCircle(propsWithDefaults) ? wrapperHeight : width;

    return (
        <_ContentLoader
            backgroundColor='#f5f6f7'
            foregroundColor={isDarkMode ? '#aaaaaa' : '#eeeeee'}
            speed={speed}
            height={wrapperHeight}
            width={wrapperWidth}
            className={`ContentLoader ${className} ${isDarkMode ? 'use-mix-blend-mode' : ''}`}
            {...remainingProps}
        >
            {content}
        </_ContentLoader>
    );
};

ContentLoader.RECT = RECT as typeof RECT;
ContentLoader.CIRCLE = CIRCLE as typeof CIRCLE;

export default ContentLoader;
