import { Playground } from '../../../components/playground/Playground';
import { PlaygroundColors } from '../../../components/playground/PlaygroundColors';
import { PlaygroundClasses } from '../../../components/playground/PlaygroundClasses';
import { Description } from '../../../components/Description';

import { colors } from '../../../utils/colors';

export const Blockquote = () => (
    <div>
        <Description headline='Blockquote' anchor='blockquote' />
        <Playground
            example={blockquote}
            classes={blockquoteClasses}
            availableColors={
                <PlaygroundColors
                    textWhite
                    textBlack
                    textLightest
                    textLighter
                    textLight
                    textGray
                    textDark
                    textDarker
                    textDarkest
                />
            }
        />
    </div>
);

const eachColor = [...colors.text.map(color => color.name), ...colors.gray.map(color => color.name)];

const blockquote = (
    <div className='display-grid grid-cols-1 grid-cols-2-ls grid-cols-3-md gap-20'>
        {eachColor.map((color, index) => (
            <blockquote className={`quote-color-${color}`} key={index}>{`Lorem ipsum ${color}.`}</blockquote>
        ))}
    </div>
);

const blockquoteClasses = <PlaygroundClasses prefix='quote-color-' classes={eachColor} />;
