// @ts-ignore-next-line importsNotUsedAsValues
import React, { type Ref } from 'react';

// Utility function to merge multiple refs
const mergeRefs = <T>(refs: Ref<T>[]): Ref<T> => {
    return (value: T) => {
        refs.forEach(ref => {
            if (typeof ref === 'function') {
                ref(value);
            } else if (ref) {
                (ref as React.MutableRefObject<T | null>).current = value;
            }
        });
    };
};

export default mergeRefs;
