import React, { type RefObject, useEffect, useState } from 'react';

/**
 * Custom hook to detect hover state for a given HTML element.
 *
 * @param elementRef - The reference to the HTML element.
 * @returns A boolean value representing whether the element is being hovered over or not.
 */
const useHover = <T extends HTMLElement = HTMLElement>(elementRef: RefObject<T>): boolean => {
    const [value, setValue] = useState<boolean>(false);

    const handleMouseEnter = () => setValue(true);
    const handleMouseLeave = () => setValue(false);

    useEffect(() => {
        if (elementRef.current) {
            elementRef.current.addEventListener('mouseenter', handleMouseEnter);
        }
        return () => {
            if (elementRef.current) {
                elementRef.current.removeEventListener('mouseenter', handleMouseEnter);
            }
        };
    }, [elementRef.current]);

    useEffect(() => {
        if (elementRef.current) {
            elementRef.current.addEventListener('mouseleave', handleMouseLeave);
        }
        return () => {
            if (elementRef.current) {
                elementRef.current.removeEventListener('mouseleave', handleMouseLeave);
            }
        };
    }, [elementRef.current]);

    return value;
};

export default useHover;
