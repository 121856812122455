import { Playground, PLAYGROUNG_BG_LIGHTER } from '../../../components/playground/Playground';
import { PlaygroundClasses } from '../../../components/playground/PlaygroundClasses';
import { Description } from '../../../components/Description';
import { colors, getColorNames } from '../../../utils/colors';

const eachText = getColorNames(colors.text);
const eachBrand = getColorNames(colors.brand);
const eachStatus = getColorNames(colors.status);
const eachDrivingStatus = getColorNames(colors.drivingStatus);
const eachMapMarker = getColorNames(colors.mapMarker);
const eachRating = getColorNames(colors.rating);
const eachGray = getColorNames(colors.gray);
const eachHighlight = getColorNames(colors.highlight);

const eachColor = getColorNames(colors.text, colors.brand, colors.status) as string[];

export const SvgColors = () => (
    <>
        <Description anchor='colors-svgs' headline='SVGs' />
        <Playground example={svgExample} classes={svgClasses} background={PLAYGROUNG_BG_LIGHTER} />
    </>
);

type ImageSVGProps = {
    fillColor: string;
};

const ImageSVG = (props: ImageSVGProps) => {
    const { fillColor } = props;

    return (
        <svg className={`fill-${fillColor}`} width='50' height='50' xmlns='http://www.w3.org/2000/svg'>
            <path
                // eslint-disable-next-line max-len
                d='M0 50V0h50v50H0zm45.3-23.95c0-4.55-3.7-8.25-8.25-8.25s-8.25 3.7-8.25 8.25 3.7 8.25 8.25 8.25 8.25-3.7 8.25-8.25zm-23.6 7.8h5.05V18.2H21.7v15.65zm-8-15.65H5.25v15.65h4.9V27.6l4.25 6.25h5.35l-4.1-6.25c.45-.15.85-.4 1.15-.7 1.1-.9 1.8-2.3 1.8-3.8 0-2.7-2.2-4.9-4.9-4.9z'
                fillRule='evenodd'
            />
        </svg>
    );
};

const svgExample = (
    <div className='display-flex flex-wrap gap-10'>
        {eachColor.map((color, index) => (
            <ImageSVG fillColor={color} key={index} />
        ))}
    </div>
);

const svgClasses = (
    <>
        <PlaygroundClasses label='Basic' prefix='fill-' classes={eachText} />
        <PlaygroundClasses label='Brand' prefix='fill-' classes={eachBrand} />
        <PlaygroundClasses label='Status' prefix='fill-' classes={eachStatus} />
        <PlaygroundClasses label='Driving Status' prefix='fill-' classes={eachDrivingStatus} />
        <PlaygroundClasses label='Map Marker' prefix='fill-' classes={eachMapMarker} />
        <PlaygroundClasses label='Rating' prefix='fill-' classes={eachRating} />
        <PlaygroundClasses label='Gray' prefix='fill-' classes={eachGray} />
        <PlaygroundClasses label='Highlight' prefix='fill-' classes={eachHighlight} />
    </>
);
