// @ts-ignore-next-line importsNotUsedAsValues
import React, { type ComponentProps, type PropsWithChildren } from 'react';
import classNames from 'classnames';

import { useDialogContext } from './dialogContext';

export type DialogFooterProps = ComponentProps<'div'> & {
    /**
     * Additional classes for the modal-footer element.
     */
    className?: string;
};

const DialogFooter = (props: PropsWithChildren<DialogFooterProps>) => {
    const { className = '', children, ...remainingProps } = props;

    const { isSmallestDialog, showXsDialogBorders } = useDialogContext();

    const doShowXsDialogBorders = isSmallestDialog ? showXsDialogBorders : true;

    const footerClassNames = classNames(
        'modal-footer',
        !doShowXsDialogBorders && 'border-top-none padding-top-0',
        className
    );

    return (
        <div {...remainingProps} className={footerClassNames} aria-label='dialog footer'>
            {children}
        </div>
    );
};

export default DialogFooter;
