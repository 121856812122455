import { Playground } from '../../../components/playground/Playground';
import {
    PlaygroundClasses,
    PlaygroundResponsiveClasses,
    PlaygroundClassesSpacer,
    PlaygroundPrintClasses,
} from '../../../components/playground/PlaygroundClasses';
import { Description } from '../../../components/Description';
import { Note } from '../../../components/Note';

import { spacings } from '../../../utils/spacings';

export const Space = () => (
    <div>
        <Description headline='Space' anchor='space'>
            Space between child elements of <b>non-flex</b> and <b>non-grid</b> solutions.
        </Description>
        <Playground example={spaceExample} classes={spaceClasses} />
        <Note>For flex or grid solutions please use gap instead.</Note>
    </div>
);

const spaceExample = (
    <>
        <div className='display-flex flex-wrap gap-10'>
            {spacings.map((space, index) => (
                <div className={`space-y-${space}`} key={index}>
                    <div className='bg-info padding-10 rounded'>space-y-{space}</div>
                    <div className='bg-info padding-10 rounded'>space-y-{space}</div>
                    <div className='bg-info padding-10 rounded'>space-y-{space}</div>
                </div>
            ))}
        </div>
    </>
);

const spaceClasses = (
    <>
        <PlaygroundClassesSpacer label='Pixel values' />
        <PlaygroundClasses label='Space X' prefix='space-x-' classes={spacings} />
        <PlaygroundClasses label='Space Y' prefix='space-y-' classes={spacings} />
        <PlaygroundClassesSpacer label='Negative pixel values' />
        <PlaygroundClasses label='Space X' prefix='space-x-' classes={['-1', '-2', '-3']} />
        <PlaygroundClasses label='Space Y' prefix='space-y-' classes={['-1', '-2', '-3']} />
        <PlaygroundResponsiveClasses />
        <PlaygroundPrintClasses />
    </>
);
