import React, { useState } from 'react';
import classNames from 'classnames';
import { usePopper } from 'react-popper';

import MenuItems from '../menuItems/MenuItems';
import MenuItemList from '../menuItems/MenuItemList';
import type { MenuItemProps as MenuItem } from '../menuItems/MenuItem';

export type DropdownSubmenuProps = {
    /**
     * Displayed button title.
     */
    title?: string | React.ReactNode;

    /**
     * Items to display in the dropdown sub menu.
     */
    items?: MenuItem[];

    /**
     * Disables every entry on the title list item.
     */
    disabled?: boolean;

    /**
     * Additional classes to be set on the dropdown-submenu element.
     */
    className?: string;
};

const DropdownSubmenu = (props: DropdownSubmenuProps) => {
    const { title = '', items = [], disabled = false, className, ...remainingProps } = props;

    const classes = classNames('dropdown-submenu', disabled && 'disabled', className);

    const [refParentPopper, setRefParentPopper] = useState<HTMLLIElement | null>(null);
    const [refDropdownSubmenu, setRefDropdownSubmenu] = useState<HTMLUListElement | null>(null);

    // We have to use the workaround of checking the hover state as popper may render
    // the sub menu on an odd place and fixes it's position on the next render cycle
    const [isHover, setIsHover] = useState(false);
    const handleMouseEnter = () => setIsHover(true);
    const handleMouseLeave = () => setIsHover(false);

    const { styles, attributes } = usePopper(refParentPopper, refDropdownSubmenu, {
        placement: 'right-start',
        modifiers: [
            {
                name: 'offset',
                options: {
                    offset: [-4, 0],
                },
            },
        ],
    });

    // return a list item that is also automatically the new toggle for the submenu
    return (
        <li
            ref={setRefParentPopper}
            {...remainingProps}
            className={classes}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            {/* biome-ignore lint/a11y/useValidAnchor: <explanation> */}
            <a className='submenu-title' role='menuitem'>
                <span className='submenu-title-text'>{title}</span>
                <span className='rioglyph rioglyph-chevron-right' />
            </a>
            {/* The actual submenu items */}
            {isHover && !disabled && (
                <MenuItemList ref={setRefDropdownSubmenu} style={styles.popper} {...attributes.popper}>
                    <MenuItems items={items} />
                </MenuItemList>
            )}
        </li>
    );
};

export default DropdownSubmenu;
