import React, { useState, type PropsWithChildren } from 'react';
import noop from 'lodash/fp/noop';
import classNames from 'classnames';

import Collapse from '../collapse/Collapse';

type ChildrenType = React.ReactNode | ((isOpen: boolean) => string | React.ReactNode | JSX.Element);

export type ExpanderPanelProps = {
    /**
     * The title to be shown in the expander header.
     */
    title: string | React.ReactNode;

    /**
     * Component visual or contextual style variants.
     */
    bsStyle?: 'blank' | 'default' | 'separator' | 'primary' | 'secondary' | 'info' | 'warning' | 'danger' | 'success';

    /**
     * Defines if the icon will be align left, otherwise it is aligned right.
     * @default false
     */
    iconLeft?: boolean;

    /**
     * Defines if the panel will be opened or closed by default.
     * The open/closed state will be handled internally.
     * @default false
     */
    open?: boolean;

    /**
     * It unmounts the body component (remove it from the DOM) when it is collapsed.
     * Set it to false to avoid the unmount.
     * @default true
     */
    unmountOnExit?: boolean;

    /**
     * Callback function for when the header is clicked and the expander toggles.
     * @param isOpen
     * @returns
     */
    onToggle?: (isOpen: boolean) => void;

    /**
     * Callback fired after the component has expanded.
     */
    onEntered?: VoidFunction;

    /**
     * Callback fired after the component has collapsed.
     */
    onExited?: VoidFunction;

    /**
     * Callback fired when the animation starts for either expand or collapse.
     */
    onAnimationStart?: VoidFunction;

    /**
     * Additional classes to be set on the panel header.
     */
    headerClassName?: string;

    /**
     * Additional classes to be set on the header title.
     */
    titleClassName?: string;

    /**
     * Additional classes to be set on the panel body.
     */
    bodyClassName?: string;

    /**
     * Additional classes added to the chevron icon
     */
    iconClassName?: string;

    /**
     * Additional classes to be set on the wrapper element.
     */
    className?: string;

    /**
     * Any element to be rendered inside the panel body.
     * Providing a function enables the render prop approach. The function gets the
     * `isOpen` state passed and is responsible for rendering the custom content.
     */
    children?: ChildrenType;
};

const ExpanderPanel = (props: PropsWithChildren<ExpanderPanelProps>) => {
    const {
        open = false,
        iconLeft = false,
        bsStyle = 'blank',
        title,
        headerClassName,
        titleClassName,
        bodyClassName,
        iconClassName,
        unmountOnExit = true,
        onEntered = noop,
        onExited = noop,
        onAnimationStart = noop,
        onToggle = noop,
        className,
        children,
        ...remainingProps
    } = props;

    const [isOpen, setIsOpen] = useState(open);

    // Update internal state from external prop change
    const [previousOpen, setPreviousOpen] = useState(open);
    if (previousOpen !== open) {
        setIsOpen(open);
        setPreviousOpen(open);
    }

    const handleToggle = () => {
        const newState = !isOpen;
        setIsOpen(newState);
        onToggle(newState);
    };

    const wrapperClassNames = classNames('expander-panel panel', `panel-${bsStyle}`, className);

    const iconClassNames = classNames('expander-icon', iconClassName, 'rioglyph', 'rioglyph-chevron-down');

    const headerClassNames = classNames(
        'panel-heading',
        isOpen && 'open',
        iconLeft && 'icon-left',
        headerClassName && headerClassName
    );

    const titleClassNames = classNames('title', titleClassName && titleClassName);

    const bodyClassNames = classNames('panel-body', bodyClassName && bodyClassName);

    const isRenderCallback = children && typeof children === 'function';

    return (
        <div {...remainingProps} className={wrapperClassNames} aria-label='expander panel'>
            <div className={headerClassNames} onClick={handleToggle} aria-label='panel heading'>
                <span className={titleClassNames}>{title}</span>
                <span className={iconClassNames} />
                {bsStyle === 'separator' && (
                    <div className='separator'>
                        <hr />
                    </div>
                )}
            </div>
            <Collapse
                open={isOpen}
                unmountOnExit={unmountOnExit}
                onEntered={onEntered}
                onExited={onExited}
                onAnimationStart={() => onAnimationStart()}
            >
                <div>
                    <div className={bodyClassNames}>{isRenderCallback ? children(isOpen) : children}</div>
                </div>
            </Collapse>
        </div>
    );
};

export default ExpanderPanel;
