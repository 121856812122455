import React, {
    useEffect,
    useState,
    forwardRef,
    type CSSProperties,
    type ForwardedRef,
    type PropsWithChildren,
    type HTMLAttributes,
} from 'react';
import classNames from 'classnames';
import omit from 'lodash/fp/omit';

import { isDesktop } from '../../utils/deviceUtils';
import { PLACEMENT } from '../../values/Placement';
import { TEXT_ALIGNMENT } from '../../values/TextAlignment';
import type { ObjectValues } from '../../utils/ObjectValues';
import type { RefComponent } from '../../utils/RefComponent';

const STYLE_MAP = {
    STYLE_DEFAULT: 'default',
    STYLE_ONBOARDING: 'onboarding',
} as const;

const TEXT_ALIGNMENT_MAP = {
    TEXT_ALIGNMENT_LEFT: TEXT_ALIGNMENT.LEFT,
    TEXT_ALIGNMENT_CENTER: TEXT_ALIGNMENT.CENTER,
    TEXT_ALIGNMENT_RIGHT: TEXT_ALIGNMENT.RIGHT,
} as const;

export type TooltipWidth = 'auto' | 100 | 150 | 200 | 250 | 300 | 350 | 400 | 450 | 500;

export type TooltipProps = {
    /**
     * An HTML id attribute, necessary for accessibility.
     */
    id?: string;

    /**
     * Sets the direction the Tooltip is positioned towards. This is generally provided by the
     * OverlayTrigger component positioning the tooltip.
     * @default 'bottom'
     */
    placement?: ObjectValues<typeof PLACEMENT>;

    /**
     * Defines the position of the text content.
     * Possible values are: `"center"` | `"left"` | `"right"`
     * @default 'center'
     */
    textAlignment?: ObjectValues<typeof TEXT_ALIGNMENT>;

    /**
     * Defines the look of the tooltip.
     * Possible values are: `default` and `onboarding`
     * @default 'default'
     */
    tooltipStyle?: ObjectValues<typeof STYLE_MAP>;

    /**
     * Defines the width of 'the tooltip.
     * Possible values are: 'auto' | 100 | 150 | 200 | 250 | 300 | 350 | 400 | 450 | 500
     */
    width?: TooltipWidth;

    /**
     * Render Tooltips on mobile devices.
     * @default false
     */
    allowOnTouch?: boolean;

    /**
     * Additional props assigned to the arrow element. Internally used.
     */
    arrowProps?: Record<string, unknown>;

    /**
     * Additional style properties assigned to the wrapper element.
     */
    style?: CSSProperties;

    /**
     * Additional classes to be set on the wrapper element.
     */
    className?: string;

    /**
     * Additional classes to be set on the inner element.
     */
    innerClassName?: string;
} & Omit<HTMLAttributes<HTMLDivElement>, 'id' | 'style' | 'className'>;

export type TooltipType = RefComponent<PropsWithChildren<TooltipProps>> &
    typeof STYLE_MAP &
    typeof PLACEMENT &
    typeof TEXT_ALIGNMENT_MAP;

const Tooltip = forwardRef((props: PropsWithChildren<TooltipProps>, ref: ForwardedRef<HTMLDivElement>) => {
    const {
        allowOnTouch = false,
        arrowProps,
        children,
        className = '',
        innerClassName = '',
        placement = 'bottom',
        style,
        textAlignment = 'center',
        tooltipStyle = 'default',
        width = 'auto',
        ...remainingProps
    } = omit(['popper', 'positionLeft', 'positionTop'], props);

    // Mount the component but don't show it yet via CSS.
    // After it is mounted, we set the visibility class to use CSS animation
    const [isMounted, setIsMounted] = useState(false);
    useEffect(() => setIsMounted(true), []);

    if (!isDesktop() && !allowOnTouch) {
        // Workaround: we have to return a DOM node as otherwise the
        // react-transition library would throw an error on mobile
        // when triggering a browser reflow and accessing the node directly.
        return <span className='display-none' />;
    }

    const wrapperClasses = classNames(
        'tooltip',
        tooltipStyle && `tooltip-${tooltipStyle}`,
        placement,
        'fade',
        isMounted && 'show',
        className
    );

    const innerClasses = classNames(
        'tooltip-inner',
        textAlignment && `text-${textAlignment}`,
        width && `width-${width}`,
        innerClassName && innerClassName
    );

    /* eslint-disable react/no-unknown-property */
    return (
        <div
            {...remainingProps}
            role='tooltip'
            // x-placement is used by the css to define how to position the arrow.
            x-placement={placement}
            ref={ref}
            className={wrapperClasses}
            style={{ ...style }}
            data-offset={20}
        >
            <div className='tooltip-arrow' {...arrowProps} />
            <div className={innerClasses}>{children}</div>
        </div>
    );
}) as TooltipType;

// statics
Object.assign(Tooltip, STYLE_MAP);
Object.assign(Tooltip, PLACEMENT); // is keyed as-is
Object.assign(Tooltip, TEXT_ALIGNMENT_MAP);

export default Tooltip;
