import React, { type ForwardedRef, type PropsWithChildren, forwardRef } from 'react';
import classNames from 'classnames';

export type ApplicationLayoutHeaderProps = {
    /**
     * Additional class names that are added to the respective component.
     */
    className?: string;
};

type Ref = ForwardedRef<HTMLDivElement>;
type Props = PropsWithChildren<ApplicationLayoutHeaderProps>;

const ApplicationLayoutHeader = forwardRef((props: Props, ref: Ref) => {
    const { className, children, ...remainingProps } = props;

    const classes = classNames('ApplicationLayoutHeader', className);

    return (
        <div ref={ref} {...remainingProps} className={classes} id='ApplicationLayoutHeader'>
            {children}
        </div>
    );
});

export default ApplicationLayoutHeader;
