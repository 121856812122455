import React, { useEffect, useState } from 'react';

import getDropDirection from './getDropDirection';

export type DropDirection = {
    pullRight: boolean;
    dropup: boolean;
};

export type UseDropDirection = {
    pullRight: boolean;
    dropup: boolean;
    autoDropDirection: boolean;
    dropdownMenuRef: React.RefObject<HTMLUListElement>;
};

export const useDropDirection = (
    { pullRight = false, dropup = false, autoDropDirection = false, dropdownMenuRef }: UseDropDirection,
    dependencies: unknown[]
): DropDirection => {
    // Note: Using an object for local state has lead to tests getting stuck here.
    // The solution was to split the values into dedicated state primitives here.
    const [internalPullRight, setInternalPullRight] = useState(pullRight);
    const [internalDropup, setInternalDropup] = useState(dropup);

    const isAutoDropActive = () => !(!autoDropDirection || dropup || pullRight);

    // Overwrite position of dropdown menu in case auto drop is enabled
    useEffect(() => {
        if (dropdownMenuRef.current && isAutoDropActive()) {
            const result = getDropDirection(dropdownMenuRef.current.parentNode as HTMLElement, dropdownMenuRef.current);
            setInternalPullRight(result.pullRight);
            setInternalDropup(result.dropup);
        }
    }, [...dependencies, dropdownMenuRef.current]);

    return {
        pullRight: internalPullRight,
        dropup: internalDropup,
    };
};
