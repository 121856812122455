import { createContext, useContext } from 'react';
import noop from 'lodash/noop';

import { STYLE_RIO_LIGHT } from './header/Themes';
import { isMobile, isMobileScreen } from '../../src/utils/deviceUtils';
import type { ListMenuItem } from '../../src/components/listMenu/ListMenuGroup';

type UikitContextType = {
    theme: string;
    showMenu: boolean;
    onMenuIconClicked: () => void;
    onMenuOutsideClicked: (event: MouseEvent | TouchEvent) => void;
    startMenu: any;
    designMenu: any;
    componentsMenu: ListMenuItem[];
    mapMenu: any;
    chartsMenu: any;
};

export const UikitContext = createContext<UikitContextType>({
    theme: STYLE_RIO_LIGHT,
    showMenu: !(isMobile() || isMobileScreen()),
    onMenuIconClicked: noop,
    onMenuOutsideClicked: noop,
    startMenu: {},
    designMenu: {},
    componentsMenu: [],
    mapMenu: {},
    chartsMenu: {},
});

export const useUikitContext = () => useContext(UikitContext);
