// @ts-ignore-next-line importsNotUsedAsValues
import React, { type HTMLAttributes, type PropsWithChildren } from 'react';
import classNames from 'classnames';

const getBorderColorFromBg = (bg: string) => bg.replace('bg', 'border-color');

const commonRoundedClasses = classNames(
    'width-20',
    'height-20',
    'rounded-circle',
    'position-relative',
    'z-index-2',
    'box-sizing-content-box'
);

const commonRoundedBorderClippingClasses = classNames('position-relative', 'width-20', 'height-20');

export type RuleConnectorProps = {
    /**
     * The placement for the connector.
     *
     * Possible values are `start`, `center` and `end`.
     *
     * @default 'center'
     */
    placement?: 'start' | 'center' | 'end';

    /**
     * The rioglyph icon name.
     *
     * @default 'rioglyph-plus'
     */
    icon?: string;

    /**
     * Defines whether the connector is shown.
     *
     * @default false
     */
    hidden?: boolean;

    /**
     * Background color of the connector itself.
     *
     * @default 'bg-lightest'
     */
    background?: string;

    /**
     * Background color of the surrounding area where the rule containers are placed.
     *
     * @default 'bg-white'
     */
    pageBackground?: string;

    /**
     * Classes to be set on the connector content.
     *
     * @default 'text-color-light'
     */
    contentClassName?: string;

    /**
     * Defines whether the connector has a border.
     *
     * @default false
     */
    hasBorder?: boolean;

    /**
     * The border color class name.
     */
    borderColor?: string;

    /**
     * Additional classes for the wrapper element.
     */
    className?: string;
} & Omit<HTMLAttributes<HTMLDivElement>, 'className'>;

const RuleConnector = (props: PropsWithChildren<RuleConnectorProps>) => {
    const {
        placement = 'center',
        icon = 'rioglyph-plus',
        hidden = false,
        background = 'bg-lightest',
        pageBackground = 'bg-white',
        contentClassName = 'text-color-light',
        hasBorder = false,
        borderColor = '',
        className = '',
        children,
        ...remainingProps
    } = props;

    const wrapperClassNames = classNames(
        'RuleConnector',
        'height-20',
        'display-flex',
        `justify-content-${placement}`,
        'align-items-center',
        'position-relative',
        hidden && 'opacity-0',
        className
    );

    const leftRoundedBox = classNames(
        'margin-right--10',
        commonRoundedClasses,
        pageBackground,
        hasBorder && `border ${borderColor}`
    );

    const leftRoundedBoxBorderClipping = classNames('right-50pct', commonRoundedBorderClippingClasses, pageBackground);

    const contentWrapperClassNames = classNames(
        'padding-left-20 padding-right-20',
        'z-index-1',
        background,
        hasBorder && `border ${getBorderColorFromBg(background)}`
    );

    const rightRoundedBox = classNames(
        'margin-left--10',
        commonRoundedClasses,
        pageBackground,
        hasBorder && `border ${borderColor}`
    );

    const rightRoundedBoxBorderClipping = classNames('left-50pct', commonRoundedBorderClippingClasses, pageBackground);

    return (
        <div data-testid='ruleConnector' {...remainingProps} className={wrapperClassNames}>
            <div className={leftRoundedBox}>
                <div className={leftRoundedBoxBorderClipping} />
            </div>
            <span className={contentWrapperClassNames}>
                <span className={contentClassName}>
                    {children ? children : <span className={`rioglyph ${icon}`} data-testid='ruleConnectorIcon' />}
                </span>
            </span>
            <div className={rightRoundedBox}>
                <div className={rightRoundedBoxBorderClipping} />
            </div>
        </div>
    );
};

RuleConnector.START = 'start';
RuleConnector.CENTER = 'center';
RuleConnector.END = 'end';

export default RuleConnector;
