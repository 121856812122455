import React, {
    memo,
    useCallback,
    useEffect,
    useLayoutEffect,
    useState,
    useRef,
    type PropsWithChildren,
    type HTMLAttributes,
    type MemoExoticComponent,
    type FC,
} from 'react';
import classNames from 'classnames';
import head from 'lodash/fp/head';
import negate from 'lodash/fp/negate';
import delay from 'lodash/fp/delay';

import ActionBarOverlay from './ActionBarOverlay';
import ActionBarItemPopoverContent from './ActionBarItemPopoverContent';
import ActionBarItemIcon from './ActionBarItemIcon';
import ActionBarItemList from './ActionBarItemList';
import ActionBarItemListItem from './ActionBarItemListItem';
import ActionBarItemListSeparator from './ActionBarItemListSeparator';
import OverlayTrigger from '../overlay/OverlayTrigger';
import Dialog from '../dialog/Dialog';

const EVENT = 'mousedown';
const CLASSNAME_SHOW = 'show';
const CLASSNAME_OFFSCREEN = 'position-offscreen';

const DEFAULT_POPOVER_WIDTH = 250;
const POPOVER_CLOSE_DELAY_IN_MS = 200;
const SMALL_RESOLUTION_THRESHOLD_IN_PX = 580;

const getRandomString = () => (Math.random() + 1).toString(36).toUpperCase().substring(2);

const hasClass = (element: HTMLElement, className: string) => element.classList.contains(className);
const hasNotClass = negate(hasClass);

const isNotChildNodeOf = (childNode: HTMLElement, parentNode: HTMLElement) => !parentNode.contains(childNode);

const checkIfSmallResolution = () => {
    const [isSmallResolution, setIsSmallResolution] = useState(false);

    // We only execute this once. Before it was re-rendered on every change.
    // Users probably don't switch between display sizes that often therefore it probably makes sense to
    // avoid the re-rendering or applying an event listener.
    useEffect(() => {
        const header = head(document.getElementsByClassName('ApplicationHeader')) as HTMLElement | undefined;
        const isSmall = header ? header.offsetWidth < SMALL_RESOLUTION_THRESHOLD_IN_PX : false;
        setIsSmallResolution(isSmall);
    }, []);

    return isSmallResolution;
};

const isActionBarItemPopover = (child: React.ReactElement) =>
    child.type && (child.type as React.ComponentType<unknown>).displayName === ActionBarItemPopoverContent.displayName;

const isActionBarItemIcon = (child: React.ReactElement) =>
    child.type && (child.type as React.ComponentType<unknown>).displayName === ActionBarItemIcon.displayName;

export type ActionBarItemPopoverWidth = 100 | 150 | 200 | 250 | 300 | 350 | 400 | 450 | 500;

export type ActionBarItemProps = {
    /**
     * The id is used to identify the item in the DOM.
     *
     * If not provided, a random id is used instead.
     */
    id?: string;

    /**
     * The title property for the subcomponent ActionBarItem.Popover.
     *
     * This can be a String or another component as well as a React-Intl component.
     */
    title?: string | React.ReactNode;

    /**
     * Additional class names that are added to the respective component.
     *
     * It can be defined for the parent and all subcomponents.
     */
    className?: string;

    /**
     * Defines if the popover should close when any child element is being clicked.
     *
     * @default true
     */
    hidePopoverOnClick?: boolean;

    /**
     * Possible values are `100`, `150`, `200`, `250`, `300`, `350`, `400`, `450` or `500`.
     *
     * @default 250
     */
    popoverWidth?: ActionBarItemPopoverWidth;

    /**
     * Additional class names that are added to dialog fallback modal-body element.
     */
    mobileDialogBodyClassName?: string;
};

export type ActionBarItemComponents = {
    Icon: typeof ActionBarItemIcon;
    Popover: typeof ActionBarItemPopoverContent;
    List: typeof ActionBarItemList;
    ListItem: typeof ActionBarItemListItem;
    ListSeparator: typeof ActionBarItemListSeparator;
};

type ActionBarItemPropsWithChildren = PropsWithChildren<ActionBarItemProps>;

const ActionBarItemBase = (props: ActionBarItemPropsWithChildren) => {
    const {
        id = getRandomString(),
        className,
        mobileDialogBodyClassName = '',
        children,
        popoverWidth = DEFAULT_POPOVER_WIDTH,
        hidePopoverOnClick = true,
        ...remainingProps
    } = props;

    const [isShown, setIsShown] = useState(false);

    const clickOutsideRef = useRef(null);

    useLayoutEffect(() => {
        const listener = (event: MouseEvent) => {
            if (!clickOutsideRef || !clickOutsideRef.current || !isShown) {
                return;
            }

            // Since the popover component is based on React Portal and might be offscreen, we need to use
            // old-school approach and query the DOM for the item ID.
            const popoverEl = document.getElementById(id);

            if (!popoverEl) {
                return;
            }

            // Abort when the ActionBarItemIcon itself has been clicked as there is a toggle function
            // applied to the icon that takes care of opening and closing
            const hasIconClickedToClose = (event.target as HTMLElement).offsetParent === clickOutsideRef.current;
            if (hasIconClickedToClose) {
                return;
            }

            // Handle click outside the popover to close it
            const isClickOutsidePopover = isNotChildNodeOf(event.target as HTMLElement, popoverEl);
            const isPopoverVisible = hasClass(popoverEl, CLASSNAME_SHOW) && hasNotClass(popoverEl, CLASSNAME_OFFSCREEN);

            if (isPopoverVisible && isClickOutsidePopover) {
                setIsShown(false);
                return;
            }

            // Handle click inside the popover.
            // Delay the closing of the popover to execute possible actions from within the popover
            // like clicks on links or buttons
            if (hidePopoverOnClick) {
                delay(POPOVER_CLOSE_DELAY_IN_MS)(() => setIsShown(false));
            }
        };

        document.addEventListener(EVENT, listener);
        return () => {
            document.removeEventListener(EVENT, listener);
        };
    }, [clickOutsideRef, id, isShown]);

    const classes = classNames('ActionBarItem', className);

    const isSmallScreen = checkIfSmallResolution();

    const onToggle = useCallback(() => setIsShown(!isShown), [setIsShown, isShown]);

    // The children depend on each other, that's why it's easier to convert them once to an array
    // and then pick the correct elements. In most cases there are only 2 or 3 elements in the children
    // array so the looping shouldn't be too worrisome.
    const childrenAsList = React.Children.toArray(children) as React.ReactElement[];
    const itemPopover = childrenAsList.find(isActionBarItemPopover);
    const itemIcon = childrenAsList.find(isActionBarItemIcon);

    if (!itemPopover) {
        return <div className={classes}>{children}</div>;
    }

    const { useOffscreen = false, title } = itemPopover.props;

    // Filter out the popover and icon component from the list of children as they are handled separately
    const childrenWithoutPopover = childrenAsList.filter(child => !isActionBarItemPopover(child));
    const childrenWithoutPopoverAndIcon = childrenWithoutPopover.filter(child => !isActionBarItemIcon(child));

    if (isSmallScreen) {
        return (
            <div {...(remainingProps as HTMLAttributes<HTMLDivElement>)} className={classes} ref={clickOutsideRef}>
                {React.cloneElement(itemIcon as React.ReactElement, { onClick: onToggle })}
                <div onClick={() => setIsShown(false)}>
                    <Dialog
                        show={isShown}
                        onClose={() => setIsShown(false)}
                        body={itemPopover}
                        bodyClassName={mobileDialogBodyClassName}
                        title={title}
                    />
                </div>
                {childrenWithoutPopoverAndIcon}
            </div>
        );
    }

    const overlay = (
        <ActionBarOverlay
            id={id}
            key='child'
            title={title}
            width={popoverWidth}
            preRender={useOffscreen}
            show={isShown}
        >
            {itemPopover}
        </ActionBarOverlay>
    );

    return (
        <div {...(remainingProps as HTMLAttributes<HTMLDivElement>)} className={classes} ref={clickOutsideRef}>
            <OverlayTrigger
                onToggle={onToggle}
                show={isShown || useOffscreen}
                placement={OverlayTrigger.BOTTOM_END}
                overlay={overlay}
                rootClose={false}
                trigger='click'
                popperConfig={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, 5],
                            },
                        },
                        {
                            name: 'arrow',
                            options: {},
                        },
                    ],
                }}
            >
                {itemIcon}
            </OverlayTrigger>
            {childrenWithoutPopoverAndIcon}
        </div>
    );
};

const ActionBarItem = memo(ActionBarItemBase) as ActionBarItemComponents &
    MemoExoticComponent<FC<ActionBarItemPropsWithChildren>>;

// Define static variables on the component type
ActionBarItem.Icon = ActionBarItemIcon;
ActionBarItem.Popover = ActionBarItemPopoverContent;
ActionBarItem.List = ActionBarItemList;
ActionBarItem.ListItem = ActionBarItemListItem;
ActionBarItem.ListSeparator = ActionBarItemListSeparator;

export default ActionBarItem;
