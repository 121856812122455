// @ts-ignore-next-line importsNotUsedAsValues
import React, { type HTMLAttributes, type PropsWithChildren } from 'react';
import classNames from 'classnames';

export type ActionBarItemListProps = HTMLAttributes<HTMLUListElement> & {
    /**
     * Additional class names that are added to the wrapping list item component.
     */
    className?: string;
};

const ActionBarItemList = ({ className, children, ...remainingProps }: PropsWithChildren<ActionBarItemListProps>) => {
    const classes = classNames('ActionBarItemList', className);

    return (
        <ul {...remainingProps} className={classes}>
            {children}
        </ul>
    );
};

export default ActionBarItemList;
