import React from 'react';
import toLower from 'lodash/fp/toLower';

export type SortArrowsProps = {
    direction?: string;
};

const SortArrows = ({ direction }: SortArrowsProps) => (
    <span className={`sort-arrows ${direction ? `sort-${toLower(direction)}` : ''}`} />
);

export default SortArrows;
