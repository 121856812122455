import { Playground } from '../../../components/playground/Playground';
import { PlaygroundClasses } from '../../../components/playground/PlaygroundClasses';
import { Description } from '../../../components/Description';
import { Title } from '../../../components/Title';

const eachAspectRatio = ['1', '1-2', '2-1', '4-3', '16-9', '16-10'];

export const AspectRatio = () => (
    <div>
        <Description headline='Aspect ratio' anchor='aspect-ratio'>
            The <code className='code-inline'>aspect-ratio</code> class property sets a preferred aspect ratio for the
            box, which will be used in the calculation of auto sizes and some other layout functions.
        </Description>
        <Playground example={AspectRatioExample} classes={AspectRatioClasses} />
    </div>
);

const AspectRatioExample = (
    <div className='display-flex flex-wrap gap-20'>
        <div className='margin-bottom-20'>
            <Title>With fixed width</Title>
            <div className='display-flex flex-column flex-wrap gap-20'>
                {eachAspectRatio.map((value, index) => (
                    <div
                        className={`width-50 bg-info rounded aspect-ratio-${value} display-grid place-items-center`}
                        key={index}
                    >
                        {value}
                    </div>
                ))}
            </div>
        </div>
        <div>
            <Title>With fixed height</Title>
            <div className='display-flex flex-wrap gap-20'>
                {eachAspectRatio.map((value, index) => (
                    <div
                        className={`height-50 bg-info rounded aspect-ratio-${value} display-grid place-items-center`}
                        key={index}
                    >
                        {value}
                    </div>
                ))}
            </div>
        </div>
    </div>
);

const AspectRatioClasses = <PlaygroundClasses prefix='aspect-ratio-' classes={eachAspectRatio} />;
