const ADDITIONAL_SPACING_IN_PX = 10;

// It requires the DOM node for the toggle (e.g. the dropdown button) and the DOM element
// that shall be used to calculate remaining space (i.e. the dropdown menu)
const getDropDirection = (toggleNode: HTMLElement | null, menuNode: HTMLElement | null) => {
    if (!toggleNode || !menuNode) {
        return {
            dropup: false,
            pullRight: false,
        };
    }

    const clientRect = toggleNode.getBoundingClientRect();
    const pageX = clientRect.x;
    const pageY = clientRect.y;

    const toggleHeight = toggleNode.offsetHeight;

    // menu has its height and width already when closed because visibility is collapse
    const menuWidth = menuNode.offsetWidth;
    const menuHeight = menuNode.offsetHeight;

    const spaceUp = pageY - toggleHeight - menuHeight - window.scrollY;
    const spaceDown = window.innerHeight - (pageY + menuHeight + toggleHeight + ADDITIONAL_SPACING_IN_PX);

    const spaceLeft = pageX - menuWidth - window.scrollX;
    const spaceRight = window.innerWidth - (pageX + menuWidth);

    const dropup = spaceDown < 0 && (spaceUp >= 0 || spaceUp > spaceDown);
    const pullRight = spaceRight < 0 && (spaceLeft >= 0 || spaceLeft > spaceRight);

    return {
        dropup,
        pullRight,
    };
};

export default getDropDirection;
