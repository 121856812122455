import React, { useState } from 'react';
import classNames from 'classnames';

import type { ObjectValues } from '../../utils/ObjectValues';

export const PagerVariant = {
    VARIANT_FULL: 'full',
    VARIANT_COMPACT: 'compact',
} as const;

export type PagerProps = {
    /**
     * The title or name of the section next/previous section.
     */
    title: string | React.ReactNode;

    /**
     * The optional label for the full variant.
     */
    label?: string | React.ReactNode;

    /**
     * Set right alignment for "previous" pager content to be aligned
     * to the right side of the pager and the arrow on the left side.
     * @default false
     */
    alignRight?: boolean;

    /**
     * Define how large the component should be rendered.
     *
     * Possible values are:
     * `Pager.VARIANT_FULL`, `Pager.VARIANT_COMPACT` or
     * `full`, `compact`.
     *
     * @default `full`
     */
    variant?: ObjectValues<typeof PagerVariant>;

    /**
     * Sets the pager disabled.
     * @default false
     */
    disabled?: boolean;

    /**
     * Callback function for when the component is clicked.
     * @param event
     * @returns
     */
    onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;

    /**
     * Additional classes for the wrapper element.
     */
    className?: string;
};

const Pager = (props: PagerProps) => {
    const {
        title,
        label,
        alignRight = false,
        variant = PagerVariant.VARIANT_FULL,
        disabled = false,
        onClick,
        className,
        ...remainingProps
    } = props;

    const [isHover, setIsHover] = useState(false);

    const handleEnter = () => !disabled && setIsHover(true);
    const handleLeave = () => setIsHover(false);

    const isCompactVariant = variant === Pager.VARIANT_COMPACT;

    const wrapperClassesBase = classNames(
        'Pager',
        'bg-white',
        'border',
        'display-flex justify-space-between align-items-center',
        isHover && 'border-color-highlight',
        alignRight && 'flex-row-reverse',
        disabled && 'pointer-events-none',
        'cursor-pointer user-select-none'
    );

    const iconClassesBase = classNames(
        'rioplyph',
        disabled ? 'text-color-light' : 'text-color-darker',
        isHover && 'text-color-highlight'
    );

    const titleClassesBase = classNames(
        disabled ? 'text-color-light' : 'text-color-darker',
        isHover && 'text-color-highlight'
    );

    // The compact version has a slightly different markup and different classes for the
    // individual parts, hence we extend the base classes
    if (isCompactVariant) {
        const wrapperClassesCompact = classNames(
            wrapperClassesBase,
            'rounded-circle',
            'padding-y-5 padding-x-15',
            'width-auto',
            className
        );

        const iconClassesCompact = classNames(
            iconClassesBase,
            alignRight ? 'rioglyph-chevron-left margin-right-5' : 'rioglyph-chevron-right margin-left-5',
            'text-size-10'
        );

        return (
            <div
                {...remainingProps}
                className={wrapperClassesCompact}
                onMouseEnter={handleEnter}
                onMouseLeave={handleLeave}
                onClick={onClick}
            >
                <div className={`display-flex flex-1-1 flex-column ${alignRight ? 'align-items-end' : ''}`}>
                    <div className={titleClassesBase}>{title}</div>
                </div>
                <span className={iconClassesCompact} />
            </div>
        );
    }

    const wrapperClassesFull = classNames(
        wrapperClassesBase,
        'rounded',
        'padding-y-15 padding-x-20',
        'width-100pct',
        className
    );

    const iconClassesFull = classNames(
        iconClassesBase,
        alignRight ? 'rioglyph-arrow-left margin-right-10' : 'rioglyph-arrow-right margin-left-10',
        'text-size-200pct'
    );

    const titleClassesFull = classNames(titleClassesBase, alignRight && 'text-right', 'text-size-16 text-medium');

    return (
        <div
            {...remainingProps}
            className={wrapperClassesFull}
            onMouseEnter={handleEnter}
            onMouseLeave={handleLeave}
            onClick={onClick}
        >
            <div className={`display-flex flex-1-1 flex-column ${alignRight ? 'align-items-end' : ''}`}>
                <div className='text-color-gray line-height-16'>{label}</div>
                <div className={titleClassesFull}>{title}</div>
            </div>
            <div className='text-size-12'>
                <span className={iconClassesFull} />
            </div>
        </div>
    );
};

Pager.VARIANT_FULL = PagerVariant.VARIANT_FULL;
Pager.VARIANT_COMPACT = PagerVariant.VARIANT_COMPACT;

export default Pager;
