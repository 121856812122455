import { useEffect } from 'react';

/**
 * Custom hook to set the document title.
 *
 * @param title - The new title to set for the document.
 */
const useDocumentTitle = (title: string) => {
    useEffect(() => {
        if (document) {
            document.title = title;
        }
    }, [title]);
};

export default useDocumentTitle;
