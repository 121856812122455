import React, { useRef, useState } from 'react';
import classNames from 'classnames';
import isEqual from 'lodash/fp/isEqual';
import isEmpty from 'lodash/fp/isEmpty';
import noop from 'lodash/fp/noop';

import BaseSelectDropdown, { filterOptions, type OptionDOMValue, type SelectOption } from './BaseSelectDropdown';
import useClickOutside from '../../useClickOutside';
import useEffectOnce from '../../hooks/useEffectOnce';
import useMergeRefs from '../../hooks/useMergeRefs';
import ClearButton from './ClearButton';
import SelectFilter from './SelectFilter';
import SelectedOption from './SelectedOption';
import WithFeedbackAndAddon, { type WithFeedbackAndAddonProps } from './WithFeedbackAndAddon';

export type { SelectOption } from './BaseSelectDropdown';

const DEFAULT_FOCUSED_ITEM_INDEX = -1;

export type SelectProps<T extends SelectOption> = Omit<WithFeedbackAndAddonProps, 'bsSize'> & {
    /**
     * Passed through as HTML attribute to the toggle button.
     */
    name?: string;

    /**
     * Passed through as HTML attribute to the toggle button.
     *
     * @default Uses the value given to `name` if not provided explicitly.
     */
    id?: string;

    /**
     * The toggle label in front of the selected text.
     */
    label?: string | React.ReactNode;

    /**
     * Items to display in the dropdown menu.
     *
     * @default []
     */
    options?: T[];

    /**
     * Sets the ids of the selected options when the component is already mounted.
     */
    value?: string[];

    /**
     * Callback function triggered when an item is selected.
     *
     * @param selectedOption The option that was selected (or undefined if none was).
     *
     * @default () => {}
     */
    onChange?: (selectOption: T | undefined) => void;

    /**
     * Text to display when nothing is selected.
     */
    placeholder?: string | React.ReactNode;

    /**
     * Defines whether the dropdown opens upwards.
     *
     * Set to `true` additionally disables autoDrop feature.
     *
     * @default false
     */
    dropup?: boolean;

    /**
     * Defines whether the dropdown opens right aligned to the dropdown toggle.
     *
     * Set to 'true' additionally disables autoDrop feature.
     *
     * @default false
     */
    pullRight?: boolean;

    /**
     * Enables or disables the autoDrop positioning feature.
     *
     * When enabled, the option list opens below or above the input depending on the surrounding space.
     *
     * @default true
     */
    autoDropDirection?: boolean;

    /**
     * Defines the size of the select to be rendered.
     *
     * @default 'md'
     */
    bsSize?: 'sm' | 'md' | 'lg';

    /**
     * Option to disable the opening of the option list.
     *
     * @default false
     */
    disabled?: boolean;

    /**
     * Sets the input's tabindex attribute.
     *
     * The tabindex attribute allows developers to make HTML elements focusable, allow or prevent them from being
     * sequentially focusable (usually with the Tab key, hence the name) and determine their relative ordering for
     * sequential focus navigation.
     *
     * @default 0
     */
    tabIndex?: number;

    /**
     * Defines whether the Bootstrap error classes shall be added to the toggle element.
     *
     * @default false
     */
    hasError?: boolean;

    /**
     * Defines whether the component should be filterable.
     *
     * @default false
     */
    useFilter?: boolean;

    /**
     * Set to show a clear button.
     *
     * @default false
     */
    useClear?: boolean;

    /**
     * Shows a loading spinner instead of the menu items if set to true.
     * @default false
     */
    isLoading?: boolean;

    /**
     * Text that shall be shown when not match was found when filtering.
     */
    noItemMessage?: string | React.ReactNode;

    /**
     * Text or node to be rendered on the toggle select instead of the selected item label.
     */
    selectedOptionText?: string | React.ReactNode;

    /**
     * Set to show only the icon and not the label of selected item.
     *
     * @default false
     */
    showSelectedItemIcon?: boolean;

    /**
     * Set to show all item icons within the toggle element.
     *
     * Selected items are highlighted and unselected items are shown as inactive.
     *
     * @default false
     */
    showUnselectedItemIcons?: boolean;

    /**
     * Additional classes to be set to the dropdown.
     */
    dropdownClassName?: string;

    /**
     * Additional classes to be set to the select/input.
     */
    btnClassName?: string;

    /**
     * Additional classes to be set to the select wrapper.
     */
    className?: string;
};

const Select = <T extends SelectOption>(props: SelectProps<T>) => {
    const {
        name,
        id = name,
        label,
        options = [],
        value,
        onChange = noop,
        placeholder,
        isLoading = false,
        dropup = false,
        pullRight = false,
        autoDropDirection = true,
        bsSize = 'md',
        disabled = false,
        tabIndex = 0,
        hasError = false,
        useFilter = false,
        useClear = false,
        noItemMessage,
        selectedOptionText,
        showSelectedItemIcon = false,
        showUnselectedItemIcons = false,
        dropdownClassName,
        btnClassName,
        className,

        inputAddon,
        errorMessage,
        warningMessage,
        messageWhiteSpace = 'normal',

        ...remainingProps
    } = props;

    const [isOpen, setIsOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState<SelectOption | null>(null);
    const [isFilterActive, setIsFilterActive] = useState(false);
    const [filterValue, setFilterValue] = useState('');
    const [filteredOptions, setFilteredOptions] = useState(options);
    const [itemDOMValues, setItemDOMValues] = useState<OptionDOMValue[]>([]);
    const [focusedItemIndex, setFocusedItemIndex] = useState(DEFAULT_FOCUSED_ITEM_INDEX);
    const [keyboardUsed, setKeyboardUsed] = useState(false);

    const refSelect = useRef();
    const refToggle = useRef<HTMLButtonElement>(null);

    const ref = useClickOutside(() => closeMenu());
    const mergedSelectRefs = useMergeRefs(refSelect, ref);

    const updateSelectedItem = (selectOptions: SelectOption[], updatedValue: string[] | undefined) => {
        if (updatedValue && !isEmpty(updatedValue)) {
            setSelectedItem(selectOptions.find(item => item.id === updatedValue[0]) || null);
        } else if (selectOptions) {
            setSelectedItem(selectOptions.find(item => item.selected) || null);
        }
    };

    useEffectOnce(() => {
        updateSelectedItem(options, value);
    });

    const [previousOptions, setPreviousOptions] = useState(options);
    if (!isEqual(options, previousOptions)) {
        updateSelectedItem(options, value);
        setFilteredOptions(options);
        setPreviousOptions(options);
    }

    const [previousValue, setPreviousValue] = useState(value);
    if (!isEqual(value, previousValue)) {
        updateSelectedItem(options, value);
        setPreviousValue(value);
    }

    const updateDOMValues = (itemDOMValuesToUpdated: OptionDOMValue[] = []) => {
        setItemDOMValues(itemDOMValuesToUpdated);
    };

    const handleClearSelectedItem = () => {
        setSelectedItem(null);
        setIsFilterActive(false);
        setFilterValue('');

        onChange(undefined);
    };

    const renderToggle = () => {
        const toggleClasses = classNames(
            'dropdown-toggle',
            'form-control',
            'text-left',
            btnClassName && btnClassName,
            bsSize === 'sm' && 'input-sm',
            bsSize === 'lg' && 'input-lg',
            disabled && 'disabled'
        );

        const toggleButton = (
            <button
                type='button'
                id={id}
                name={name}
                className={toggleClasses}
                data-toggle='dropdown'
                tabIndex={tabIndex}
                aria-haspopup='true'
                aria-expanded={isOpen}
                onClick={onToggle}
                ref={refToggle}
            >
                {useFilter && isOpen && (
                    <SelectFilter
                        isFilterActive={isFilterActive}
                        filterValue={filterValue}
                        onChange={handleFilterChange}
                    />
                )}
                {selectedOptionText ? (
                    selectedOptionText
                ) : (
                    <SelectedOption
                        label={label}
                        placeholder={placeholder}
                        selectedItem={selectedItem}
                        options={options}
                        showSelectedItemIcon={showSelectedItemIcon}
                        showUnselectedItemIcons={showUnselectedItemIcons}
                    />
                )}
                <ClearButton showClear={useClear} selectedItem={selectedItem} onClear={handleClearSelectedItem} />
                <span className='caret' />
            </button>
        );

        if (!inputAddon && !errorMessage && !warningMessage) {
            return toggleButton;
        }

        return (
            <WithFeedbackAndAddon
                bsSize={bsSize}
                inputAddon={inputAddon}
                errorMessage={errorMessage}
                warningMessage={warningMessage}
                messageWhiteSpace={messageWhiteSpace}
            >
                {toggleButton}
            </WithFeedbackAndAddon>
        );
    };

    const renderDropdownMenu = () => {
        // When an option was already selected, highlight this option by setting the focusedItemIndex accordingly.
        // In case there was nothing preselected, set the focusedItemIndex to the first item if keyboard was used.
        let currentFocusedItemIndex = focusedItemIndex;
        if (selectedItem) {
            currentFocusedItemIndex = options.findIndex(option => {
                return option.id === selectedItem.id;
            });
        } else if (keyboardUsed) {
            currentFocusedItemIndex = 0;
        }

        return (
            <BaseSelectDropdown
                isOpen={isOpen}
                isLoading={isLoading}
                options={filteredOptions}
                focusedItemIndex={currentFocusedItemIndex}
                keyboardUsed={keyboardUsed}
                updateDOMValues={updateDOMValues}
                onSelect={onOptionChange}
                onClose={closeMenu}
                noItemMessage={noItemMessage}
                pullRight={pullRight}
                dropup={dropup}
                autoDropDirection={autoDropDirection}
                dropdownClassName={dropdownClassName}
            />
        );
    };

    const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();

        const targetFilterValue = event.currentTarget.value;
        const currentFilteredOptions = filterOptions(itemDOMValues, targetFilterValue, options);

        // highlight the first item of the search result if at least one item was found
        const newFocusedItemIndex = currentFilteredOptions.length > 0 ? 0 : DEFAULT_FOCUSED_ITEM_INDEX;

        setIsFilterActive(true);
        setFilterValue(targetFilterValue);
        setFilteredOptions(currentFilteredOptions);
        setKeyboardUsed(true);
        setFocusedItemIndex(newFocusedItemIndex);
    };

    const onOptionChange = (currentSelectedItem: T | undefined) => {
        setSelectedItem(currentSelectedItem || null);
        setIsFilterActive(false);
        setFilterValue('');
        setFilteredOptions(options);

        onChange(currentSelectedItem);

        closeMenu();
    };

    const onToggle = (event: React.MouseEvent<HTMLButtonElement>) => {
        // Don't toggle when component is disabled or
        // when filter is active, means entering some filter value
        // in order to avoid closing menu on space but allow to use it for filtering
        if (disabled || isFilterActive) {
            return;
        }

        // using the enter key on the toggle button will trigger a synthetic click event as all buttons are of
        // type submit by default in HTML. In order to differentiate between real click and a synthetic event
        // caused by they keyboard, use the event details. A synthetic event is always 0.
        const isKeyboardUsed = event.detail === 0;

        setIsOpen(!isOpen);
        setKeyboardUsed(isKeyboardUsed);
    };

    const closeMenu = () => {
        if (isOpen) {
            setIsOpen(false);
            setIsFilterActive(false);
            setFilterValue('');
            setFilteredOptions(options);
            setKeyboardUsed(false);
            setFocusedItemIndex(DEFAULT_FOCUSED_ITEM_INDEX);
            refToggle.current?.focus();
        }
    };

    const classes = classNames('select', 'dropdown', isOpen && 'open', hasError && 'has-error', className && className);

    return (
        <div {...remainingProps} className={classes} ref={mergedSelectRefs}>
            {renderToggle()}
            {renderDropdownMenu()}
        </div>
    );
};

export default Select;
