// @ts-ignore-next-line importsNotUsedAsValues
import React from 'react';
import classNames from 'classnames';
import noop from 'lodash/fp/noop';

import OverlayTrigger from '../overlay/OverlayTrigger';
import Tooltip from '../tooltip/Tooltip';
import type { TreeCategoryNode } from './TreeCategory';

const ACTIVE_CLASS_NAME = 'active';
const SELECTED_CLASS_NAME = 'selected';

const TOOLTIP_DELAY = 1500;

export type TreeSidebarCategoriesProps = {
    currentCategoryId?: string;
    onSelectCategory?: (selectedCategory: string) => void;
    children?: TreeCategoryNode[];
};

const TreeSidebarCategories = (props: TreeSidebarCategoriesProps) => {
    const { children, currentCategoryId, onSelectCategory = noop } = props;

    if (!children) {
        return null;
    }

    return (
        <>
            {children.map(child => {
                const id = child.props.id;
                const icon = child.props.icon || '';
                const label = child.props.label;

                const statusClassName = classNames(
                    currentCategoryId === id ? ACTIVE_CLASS_NAME : '',
                    child.props.hasSelection && SELECTED_CLASS_NAME
                );

                const handleSelectCategory = (event: React.MouseEvent<HTMLLIElement>) => {
                    event.stopPropagation();
                    onSelectCategory(id);
                };

                const category = (
                    <li key={id} onClick={handleSelectCategory} className={statusClassName}>
                        <div className='selection-bubble'>
                            <span className={`rioglyph ${icon}`} />
                        </div>
                    </li>
                );

                if (label) {
                    const tooltip = <span>{label}</span>;
                    return (
                        <OverlayTrigger
                            key={`category-tooltip-${id}`}
                            placement={Tooltip.RIGHT}
                            delay={{ show: TOOLTIP_DELAY, hide: 0 }}
                            overlay={
                                <Tooltip id='tooltip' width='auto'>
                                    {tooltip}
                                </Tooltip>
                            }
                        >
                            {category}
                        </OverlayTrigger>
                    );
                }

                return category;
            })}
        </>
    );
};

export default TreeSidebarCategories;
