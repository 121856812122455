import { Playground } from '../../../components/playground/Playground';
import { Description } from '../../../components/Description';
import Divider from '../../../../../src/Divider';

export const Visibility = () => (
    <div>
        <Description headline='Visibility' anchor='visibility' />
        <Playground example={visibilityExample} />
    </div>
);

const visibilityExample = (
    <div className='visible-min-sm'>
        <div>
            <Description headline='Visible only at a certain breakpoint' headlineType='h5' headlineNoTopSpace />
            <code className='code-inline-first'>visible-xs</code>
            <code className='code-inline'>visible-ls</code>
            <code className='code-inline'>visible-sm</code>
            <code className='code-inline'>visible-md</code>
            <code className='code-inline'>visible-lg</code>
            <code className='code-inline'>visible-xl</code>
        </div>
        <div>
            <Description
                headline='Visible from a certain breakpoint'
                headlineType='h5'
                className='margin-top-25 margin-bottom--5'
            />
            <code className='code-inline-first'>visible-min-ls</code>
            <code className='code-inline'>visible-min-sm</code>
            <code className='code-inline'>visible-min-md</code>
            <code className='code-inline'>visible-min-lg</code>
        </div>
        <div>
            <Description
                headline='Hidden only at a certain breakpoint'
                headlineType='h5'
                className='margin-top-25 margin-bottom--5'
            />
            <code className='code-inline-first'>hidden-xs</code>
            <code className='code-inline'>hidden-ls</code>
            <code className='code-inline'>hidden-sm</code>
            <code className='code-inline'>hidden-md</code>
            <code className='code-inline'>hidden-lg</code>
            <code className='code-inline'>hidden-xl</code>
        </div>
        <div>
            <Description
                headline='Hidden from a certain breakpoint'
                headlineType='h5'
                className='margin-top-25 margin-bottom--5'
            />
            <code className='code-inline-first'>hidden-min-ls</code>
            <code className='code-inline'>hidden-min-sm</code>
            <code className='code-inline'>hidden-min-md</code>
            <code className='code-inline'>hidden-min-lg</code>
        </div>
        <div>
            <Description
                headline='Hidden when element is empty'
                headlineType='h5'
                className='margin-top-25 margin-bottom--5'
            />
            <code className='code-inline-first'>hidden-empty</code>
        </div>
        <div>
            <Description headline='Print' headlineType='h5' className='margin-top-25 margin-bottom--5' />
            <code className='code-inline-first'>visible-print</code>
            <code className='code-inline'>visible-print-inline</code>
            <code className='code-inline'>hidden-print</code>
        </div>
        <Divider className='margin-top-25' />
        <div>
            <Description headline='Visibility only classes' headlineType='h5' className='margin-bottom-0' />
            <p>
                All the above classes for hidden and visible are based on "display: none". The following class affects
                only visibility, allowing it to impact surrounding elements.
            </p>
            <code className='code-inline-first'>visibility-hidden</code>
        </div>
    </div>
);
