import React from 'react';
import noop from 'lodash/fp/noop';

import Button from '../button/Button';

export type SidebarCloseButtonProps = {
    onClick?: VoidFunction;
};

const SidebarCloseButton = ({ onClick = noop }: SidebarCloseButtonProps) => {
    return (
        <Button bsStyle={Button.MUTED} iconOnly onClick={onClick} data-testid='sidebarCloseButton'>
            <span className='rioglyph rioglyph-remove' />
        </Button>
    );
};

export default SidebarCloseButton;
