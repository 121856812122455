// @ts-ignore-next-line importsNotUsedAsValues
import React, { type PropsWithChildren } from 'react';

import Button, { type ButtonProps } from './Button';

export type ToggleButtonProps = Omit<ButtonProps, 'onClick'> & {
    /**
     * Callback function triggered when clicking the button.
     * @param value The new value of the toggle button.
     */
    onClick?: (value: boolean) => void;
};

// @ts-ignore - the Button throws TS error due to refs that may be checked at a later point in time
const ToggleButton = (props: PropsWithChildren<ToggleButtonProps>) => <Button {...props} asToggle />;

export default ToggleButton;
