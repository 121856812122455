// @ts-ignore-next-line importsNotUsedAsValues
import React from 'react';
import classNames from 'classnames';

import { isEmpty } from 'lodash';

import ButtonDropdown from '../dropdown/ButtonDropdown';
import SimpleTooltip from '../tooltip/SimpleTooltip';

export type TreeOptionsProps = {
    treeOptions?: React.ReactNode[];
    treeOptionsTooltip?: React.ReactNode;
    className?: string;
};

const TreeOptions = (props: TreeOptionsProps) => {
    const { treeOptions = [], treeOptionsTooltip, className } = props;

    if (isEmpty(treeOptions)) {
        return null;
    }

    const dropdownClasses = classNames('display-flex flex-column gap-10', 'width-250', 'padding-15', className);

    const customDropdown = <div className={dropdownClasses}>{treeOptions}</div>;

    const dropdown = (
        <ButtonDropdown
            title={<span className='rioglyph rioglyph-option-vertical' aria-hidden='true' />}
            bsStyle='default'
            bsSize='sm'
            customDropdown={customDropdown}
            toggleClassName='hover-bg-white border-none text-color-gray hover-text-color-dark'
            className='TreeHeaderOptions height-20'
            pullRight
            iconOnly
        />
    );

    if (treeOptionsTooltip) {
        return (
            <SimpleTooltip
                content={treeOptionsTooltip}
                placement='bottom-end'
                delay={{ show: 1000, hide: 0 }}
                popperConfig={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [10, 5],
                            },
                        },
                        {
                            name: 'arrow',
                            options: {},
                        },
                    ],
                }}
            >
                <div>{dropdown}</div>
            </SimpleTooltip>
        );
    }

    return dropdown;
};

export default TreeOptions;
