// @ts-ignore-next-line importsNotUsedAsValues
import React, { type PropsWithChildren } from 'react';
import classNames from 'classnames';

export type StatsWidgetFooterProps = {
    /**
     * Additional class names to be added to the element.
     */
    className?: string;
};

const StatsWidgetFooter = (props: PropsWithChildren<StatsWidgetFooterProps>) => {
    const { children, className, ...remainingProps } = props;

    const wrapperClasses = classNames('StatsWidget-footer', className);

    return (
        <div {...remainingProps} className={wrapperClasses}>
            {children}
        </div>
    );
};

export default StatsWidgetFooter;
