import { useEffect, useState, type RefObject } from 'react';

/**
 * Custom hook to track the size of a DOM element.
 *
 * @param elementRef - Reference to the DOM element whose size is to be tracked.
 * @returns An array containing the width and height of the element.
 */
const useElementSize = (elementRef: RefObject<HTMLElement>) => {
    const [width, setWidth] = useState<number | undefined>();
    const [height, setHeight] = useState<number | undefined>();

    useEffect(() => {
        const resizeObserver = new ResizeObserver(entries => {
            for (const entry of entries) {
                if (entry.contentRect) {
                    setWidth(entry.contentRect.width);
                    setHeight(entry.contentRect.height);
                }
            }
        });

        if (elementRef.current) {
            resizeObserver.observe(elementRef.current);
        }

        return () => {
            resizeObserver.disconnect();
        };
    }, [elementRef]);

    return [width, height];
};

export default useElementSize;
