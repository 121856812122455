// @ts-ignore-next-line importsNotUsedAsValues
import React, { type PropsWithChildren } from 'react';
import classNames from 'classnames';

import TypeCounter from './TypeCounter';
import TreeSummaryRow from './TreeSummaryRow';

export type AssetType = 'truck' | 'trailer' | 'bus' | 'van' | 'driver';

export type TreeSummaryCounts = {
    /**
     * The amount of trucks
     */
    truck?: number;

    /**
     * The amount of trailers
     */
    trailer?: number;

    /**
     * The amount of buses
     */
    bus?: number;

    /**
     * The amount of vans
     */
    van?: number;

    /**
     * The amount of drivers
     */
    driver?: number;
};

export type DefaultAssetCountsProps = {
    assetCounts: TreeSummaryCounts;
};

const DefaultAssetCounts = ({ assetCounts }: DefaultAssetCountsProps) => {
    const { truck, bus, van, trailer, driver } = assetCounts;
    return (
        <>
            <TypeCounter hideOnZero icon='truck-baseline' value={truck} />
            <TypeCounter hideOnZero icon='trailer-baseline' value={trailer} />
            <TypeCounter hideOnZero icon='bus-baseline' value={bus} />
            <TypeCounter hideOnZero icon='van-baseline' value={van} />
            <TypeCounter hideOnZero icon='driver' value={driver} />
        </>
    );
};

export type TreeSummaryProps = {
    /**
     * The object containing various asset type counter.
     */
    assetCounts?: TreeSummaryCounts;

    /**
     * The number of grid columns used for the built-in `TreeSummaryRow`.
     *
     * @default 4
     */
    gridCols?: number;

    /**
     * Additional classes added to the wrapping element.
     */
    className?: string;
};

const TreeSummary = React.memo((props: PropsWithChildren<TreeSummaryProps>) => {
    const { className = '', assetCounts = {}, gridCols, children } = props;

    const classes = classNames(
        'TreeSummary',
        'display-flex flex-wrap',
        'align-items-center',
        'gap-10',
        'padding-left-3',
        className
    );

    return (
        <div className={classes}>
            {children || (
                <TreeSummaryRow gridCols={gridCols}>
                    <DefaultAssetCounts assetCounts={assetCounts} />
                </TreeSummaryRow>
            )}
        </div>
    );
});

export default TreeSummary;
