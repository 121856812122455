import React, { type PropsWithChildren, type ForwardedRef, forwardRef } from 'react';
import classNames from 'classnames';

const DEFAULT_TEXT_COLOR = 'text-color-white';
const DEFAULT_BACKGROUND_COLOR = 'bg-primary';

export type ApplicationLayoutBodyBannerProps = {
    /**
     * One of the `text-color` utility classes.
     * @default `text-color-white`
     */
    textColor?: string;
    /**
     * One of the `bg-color` utility classes.
     * @default `bg-primary`
     */
    backgroundColor?: string;
    /**
     * Allows sticky banner while scrolling.
     * @default false
     */
    isSticky?: boolean;
    /**
     * Additional class names that are added to the respective component.
     */
    className?: string;
};

type Ref = ForwardedRef<HTMLDivElement>;
type Props = PropsWithChildren<ApplicationLayoutBodyBannerProps>;

const ApplicationLayoutBodyBanner = forwardRef((props: Props, ref: Ref) => {
    const {
        textColor = DEFAULT_TEXT_COLOR,
        backgroundColor = DEFAULT_BACKGROUND_COLOR,
        isSticky = true,
        className,
        children,
        ...remainingProps
    } = props;

    const bannerClasses = classNames(
        'ApplicationLayoutBodyBanner',
        textColor,
        backgroundColor,
        isSticky && 'position-sticky top-0 z-index-3',
        className
    );

    return (
        <div {...remainingProps} ref={ref} className={bannerClasses}>
            {children}
        </div>
    );
});

export default ApplicationLayoutBodyBanner;
