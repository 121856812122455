import React, {
    forwardRef,
    type PropsWithChildren,
    type ForwardedRef,
    type ForwardRefExoticComponent,
    type RefAttributes,
} from 'react';
import classNames from 'classnames';

type ButtonAlignmentLeft = 'left';
type ButtonAlignmentRight = 'right';

export type ApplicationLayoutBodyBottomBarProps = {
    /**
     * Additional class names that are added to the respective component
     */
    className?: string;
    /**
     * Use same paddings (left and right) as the ApplicationLayoutBody.
     * @default false
     */
    useBodyPaddings?: boolean;
    /**
     * Tells the buttons if they are left or right aligned.
     * Default behavior is: 1st button left and 2nd button right.
     *
     * Possible values are:
     * `ApplicationLayoutBodyBottomBar.LEFT`, `ApplicationLayoutBodyBottomBar.RIGHT` or
     * `left`, `right`
     */
    buttonAlignment?: ButtonAlignmentLeft | ButtonAlignmentRight;
};

type Ref = ForwardedRef<HTMLDivElement>;
type Props = PropsWithChildren<ApplicationLayoutBodyBottomBarProps>;

type ApplicationLayoutBodyBottomBarType = ForwardRefExoticComponent<Props & RefAttributes<HTMLDivElement>> & {
    LEFT: ButtonAlignmentLeft;
    RIGHT: ButtonAlignmentRight;
};

const ApplicationLayoutBodyBottomBar = forwardRef((props: Props, ref: Ref) => {
    const { className, buttonAlignment, useBodyPaddings = false, children, ...remainingProps } = props;

    const classes = classNames(
        'ApplicationLayoutBodyBottomBar',
        buttonAlignment === 'left' && 'justify-content-start',
        buttonAlignment === 'right' && 'justify-content-end',
        buttonAlignment !== 'left' && buttonAlignment !== 'right' && 'justify-content-between',
        useBodyPaddings && 'use-body-padding',
        className && className
    );

    return (
        <div ref={ref} {...remainingProps} className={classes}>
            {children}
        </div>
    );
}) as ApplicationLayoutBodyBottomBarType;

ApplicationLayoutBodyBottomBar.LEFT = 'left';
ApplicationLayoutBodyBottomBar.RIGHT = 'right';

export default ApplicationLayoutBodyBottomBar;
