// @ts-ignore-next-line importsNotUsedAsValues
import React from 'react';
import classNames from 'classnames';

export type SelectFilterProps = {
    isFilterActive: boolean;
    filterValue: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const SelectFilter = (props: SelectFilterProps) => {
    const { isFilterActive, filterValue, onChange } = props;

    const inputClasses = classNames(
        'select-filter-input',
        (isFilterActive || filterValue) && 'select-filter-input-active'
    );

    // biome-ignore lint/a11y/noAutofocus: autofocus is intentionally set to allow instant typing to filter
    return <input type='text' className={inputClasses} autoFocus onChange={onChange} value={filterValue} />;
};

export default SelectFilter;
