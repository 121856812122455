import React, { type ForwardedRef, forwardRef, type ReactNode } from 'react';
import classNames from 'classnames';

import type { ObjectValues } from '../../utils/ObjectValues';
import type { RefComponent } from '../../utils/RefComponent';

const STATUS_MAP = {
    AVAILABLE: 'available',
    DRIVING: 'driving',
    RESTING: 'resting',
    WORKING: 'working',
} as const;

type ActivityStatus = ObjectValues<typeof STATUS_MAP>;

const icons: Record<ActivityStatus, string> = {
    [STATUS_MAP.AVAILABLE]: 'rioglyph-status-available',
    [STATUS_MAP.WORKING]: 'rioglyph-status-working',
    [STATUS_MAP.DRIVING]: 'rioglyph-status-driving',
    [STATUS_MAP.RESTING]: 'rioglyph-status-resting',
};

const SIZE_MAP = { SIZE_SM: 'sm', SIZE_LG: 'lg', SIZE_XL: 'xl' } as const;

type ActivitySize = ObjectValues<typeof SIZE_MAP>;

export type ActivityProps = {
    /**
     * Defines the type of activity.
     *
     * Possible values are `Activity.AVAILABLE`, `Activity.DRIVING`, `Activity.RESTING` or `Activity.WORKING`.
     */
    activity: ActivityStatus;

    /**
     * Define how large the component should be rendered.
     *
     * Possible values are `Activity.SIZE_SM`, `Activity.SIZE_LG` or `Activity.SIZE_XL`.
     */
    bsSize?: ActivitySize;

    /**
     * The actual duration value to be shown.
     */
    duration?: string | ReactNode;

    /**
     * Indicates whether the activity is outdated.
     *
     * @default false
     */
    isOutdated?: boolean;

    /**
     * Callback function for when the component is clicked.
     */
    onClick?: VoidFunction;

    /**
     * Additional classes for the wrapper element.
     */
    className?: string;
};

type ActivityType = RefComponent<ActivityProps> & typeof STATUS_MAP & typeof SIZE_MAP;

const Activity = forwardRef((props: ActivityProps, ref: ForwardedRef<HTMLDivElement>) => {
    const {
        activity,
        duration,
        isOutdated = false,
        bsSize,
        onClick = () => {},
        className = '',
        ...remainingProps
    } = props;

    if (!activity) {
        return null;
    }

    const wrapperClassNames = classNames(
        'activity',
        `activity-${activity}`,
        isOutdated && 'opacity-50',
        bsSize && `activity-${bsSize}`,
        className && className
    );

    return (
        <div ref={ref} {...remainingProps} className={wrapperClassNames} onClick={onClick}>
            <span className='activity-icon'>
                <span className={`rioglyph ${icons[activity]}`} />
            </span>
            {duration && <span className='activity-text'>{duration}</span>}
        </div>
    );
}) as ActivityType;

// statics
Object.assign(Activity, STATUS_MAP);
Object.assign(Activity, SIZE_MAP);

export default Activity;
