import { Playground } from '../../../components/playground/Playground';
import { PlaygroundClasses } from '../../../components/playground/PlaygroundClasses';
import { Description } from '../../../components/Description';
import { Note } from '../../../components/Note';

const eachXTranslate = ['x-50pct', 'x50pct', 'x-100pct', 'x100pct'];
const eachYTranslate = ['y-50pct', 'y50pct', 'y-100pct', 'y100pct'];
const eachXYTranslate = [
    'x-50pct-y-50pct',
    'x50pct-y-50pct',
    'x-50pct-y50pct',
    'x50pct-y50pct',
    'x-100pct-y-100pct',
    'x100pct-y100pct',
    'x100pct-y-100pct',
    'x-100pct-y100pct',
];

export const Translate = () => (
    <div>
        <Description headline='Translate' anchor='translate'>
            Translate classes to move certain element with css transform. For example you can use
            <code className='code-inline'>position-relative</code> for the parent element and for the child element a
            combination of something like
            <code>position-absolute</code>
            <code>top-50pct</code>
            <code>translate-x-50pct-y-50pct</code>
            <code>left-50pct</code>
        </Description>
        <Playground example={translateExample} classes={translateClasses} />
        <Note>
            The transform classes can not be combined. For example rotate or scale. It is because of the CSS syntax. For
            more information see{' '}
            <a href='https://developer.mozilla.org/en-US/docs/Web/CSS/transform#Syntax'>Transform Syntax</a>.
        </Note>
    </div>
);

const translateExample = (
    <div>
        <div className='position-relative padding-top-25 padding-bottom-25'>
            <hr className='margin-0 border-width-2' />
            <span
                className='
                    bg-white
                    padding-10
                    text-size-h3
                    position-absolute
                    top-50pct
                    left-50pct
                    translate-x-50pct-y-50pct'
            >
                <span className='rioglyph rioglyph-driver' />
                <span className='rioglyph rioglyph-arrow-right margin-left-5 margin-right-5' />
                <span className='rioglyph rioglyph-driver' />
            </span>
        </div>
    </div>
);

const translateClasses = (
    <>
        <PlaygroundClasses label='Horizontal' prefix='translate-' classes={eachXTranslate} />
        <PlaygroundClasses label='Vertical' prefix='translate-' classes={eachYTranslate} />
        <PlaygroundClasses label='Both' prefix='translate-' classes={eachXYTranslate} />
    </>
);
