import { Playground } from '../../../components/playground/Playground';
import { PlaygroundClasses } from '../../../components/playground/PlaygroundClasses';
import { Description } from '../../../components/Description';
import { PlaygroundColors } from '../../../components/playground/PlaygroundColors';
import { colors, getColorNames } from '../../../utils/colors';

export const ListGroups = () => (
    <>
        <Description headline='List groups' anchor='list-groups' />
        <Playground
            example={listGroupsExample}
            classes={listGroupsClasses}
            availableColors={<PlaygroundColors bgDefault bgPrimary bgSecondary bgInfo bgSuccess bgWarning bgDanger />}
        />
    </>
);

const eachColor = ['default', ...getColorNames(colors.brand, colors.status)];

const listGroupsExample = (
    <div>
        <div className='row'>
            <div className='col-md-4'>
                <ul className='list-group border rounded margin-bottom-20'>
                    {eachColor.map(color => (
                        <li className={`list-group-item list-group-item-${color}`} key={crypto.randomUUID()}>
                            List group item <span>{color}</span>
                        </li>
                    ))}
                </ul>
            </div>
            <div className='col-md-4'>
                <ul className='list-group border rounded margin-bottom-20'>
                    {eachColor.map(color => (
                        <li className='list-group-item' key={crypto.randomUUID()}>
                            <div className='display-flex'>
                                <div className='width-100pct'>Lorem ipsum dolor sit amet</div>
                                <div className='flex-1-1-0'>
                                    <span className={`badge badge-${color}`}>3</span>
                                </div>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
            <div className='col-md-4'>
                <div className='list-group border rounded margin-bottom-20'>
                    {[...Array(4)].map(() => (
                        <a href='#' className='list-group-item' key={crypto.randomUUID()}>
                            List group item link (a)
                        </a>
                    ))}
                </div>

                <div className='list-group border rounded margin-bottom-20'>
                    {[...Array(4)].map(() => (
                        <div className='list-group-item list-group-item-link' key={crypto.randomUUID()}>
                            List group item link (.list-group-item-link)
                        </div>
                    ))}
                </div>
            </div>
        </div>

        <div className='row'>
            <div className='col-lg-6'>
                <div className='list-group border rounded margin-bottom-20'>
                    {eachColor.map(color => (
                        <button className={`list-group-item list-group-item-${color}`} key={crypto.randomUUID()}>
                            <div className='display-flex gap-10'>
                                <div className='width-100pct'>
                                    <div className='text-size-20 text-medium'>
                                        Headline <span>{color}</span>
                                    </div>
                                    <div className='text-color-dark'>
                                        Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod
                                    </div>
                                </div>
                                <div className='flex-1-1-0'>
                                    <span className={`label label-${color} label-filled`}>
                                        <span className='text-capitalize'>{color}</span>
                                    </span>
                                </div>
                            </div>
                        </button>
                    ))}
                </div>
            </div>
            <div className='col-lg-6'>
                <div className='list-group border rounded'>
                    {eachColor.map(color => (
                        <div className='list-group-item' key={crypto.randomUUID()}>
                            <div className='display-flex gap-10'>
                                <div className='width-100pct'>
                                    <div className='text-size-20 text-medium'>Headline</div>
                                    <div className='text-color-dark'>
                                        Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod
                                    </div>
                                </div>
                                <div className='flex-1-1-0'>
                                    <span className={`badge badge-${color}`}>3</span>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    </div>
);

const listGroupsClasses = (
    <>
        <PlaygroundClasses label='Wrapper' classes='list-group border rounded' />
        <PlaygroundClasses label='Child' classes={['list-group-item', 'list-group-item-link']} />
        <PlaygroundClasses label='Colors' prefix='list-group-item-' classes={eachColor} />
    </>
);
