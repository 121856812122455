import type { ObjectValues } from '../utils/ObjectValues';

export const TRIGGER = {
    HOVER: 'hover',
    CLICK: 'click',
    FOCUS: 'focus',
} as const;

export type TriggerType = ObjectValues<typeof TRIGGER>;

export type Trigger = TriggerType | TriggerType[];
