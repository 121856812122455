import { Playground } from '../../../components/playground/Playground';
import { PlaygroundClasses } from '../../../components/playground/PlaygroundClasses';
import { Description } from '../../../components/Description';
import { Note } from '../../../components/Note';

import { dummyTextLong } from '../../../utils/data';

export const Ellipsis = () => (
    <div>
        <Description
            headline='Ellipsis'
            anchor='ellipsis'
            subline='Single- or multi-line utility to truncate text content at max width with ...'
        />
        <Playground example={ellipsisExample} classes={ellipsisClasses} />
        <Note>
            Ellipsis only works with block elements. It does not work with
            <code className='code-inline text-decoration-line-through'>display-grid</code>. Combine the classes with
            <code className='code-inline'>display-block</code> or <code className='code-inline'>display-flex</code>
        </Note>
    </div>
);

const ellipsisExample = (
    <div>
        <p className='ellipsis-1 text-size-16'>{`${dummyTextLong}`}</p>
        <hr />
        <p className='ellipsis-2 text-size-16'>{`${dummyTextLong}`}</p>
        <hr />
        <p className='ellipsis-3 text-size-16'>{`${dummyTextLong}`}</p>
    </div>
);

const ellipsisClasses = (
    <PlaygroundClasses
        classes={[
            'ellipsis-1',
            'ellipsis-2',
            'ellipsis-3',
            'ellipsis-4',
            'ellipsis-5',
            'ellipsis-6',
            'ellipsis-7',
            'ellipsis-8',
            'ellipsis-9',
            'ellipsis-10',
        ]}
    />
);
