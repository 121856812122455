import useKey from './useKey';

/**
 * Custom hook to execute a callback when the 'Escape' key is pressed.
 *
 * @param callback - The function to execute when the 'Escape' key is pressed.
 */
const useEsc = (callback: (event: KeyboardEvent) => void) => {
    useKey(event => event.key === 'Escape' && callback(event));
};

export default useEsc;
