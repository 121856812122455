import { getNames } from './mapUtils';

export const getColorNames = getNames;

// These values must always be kept in sync with those of the less folder
// src/themes/RIO/css/mapping/colors-map.less

// Display Coldplay and Warmup classes
export const hasColdplay = true;
export const hasWarmup = true;

// Display Coldplay and Warmup Demo
export const showColdplay = false;
export const showWarmup = false;

export const colors = {
    text: [
        {
            hex: '#ffffff',
            name: 'white',
            text: 'text-color-white',
            background: 'bg-white',
        },
        {
            hex: '#000000',
            name: 'black',
            text: 'text-color-black',
            background: 'bg-black',
        },
    ],
    brand: [
        {
            hex: '#30b4c0',
            name: 'primary',
            text: 'text-color-primary',
            background: 'bg-primary',
        },
        {
            hex: '#b23672',
            name: 'secondary',
            text: 'text-color-secondary',
            background: 'bg-secondary',
        },
    ],
    status: [
        {
            hex: '#4B80A6',
            name: 'info',
            text: 'text-color-info',
            background: 'bg-info',
        },
        {
            hex: '#5cb85c',
            name: 'success',
            text: 'text-color-success',
            background: 'bg-success',
        },
        {
            hex: '#ff8e3c',
            name: 'warning',
            text: 'text-color-warning',
            background: 'bg-warning',
        },
        {
            hex: '#d90000',
            name: 'danger',
            text: 'text-color-danger',
            background: 'bg-danger',
        },
    ],
    drivingStatus: [
        {
            hex: '#239b7d',
            name: 'status-available',
            text: 'text-color-status-available',
            background: 'bg-status-available',
        },
        {
            hex: '#3690ae',
            name: 'status-driving',
            text: 'text-color-status-driving',
            background: 'bg-status-driving',
        },
        {
            hex: '#626b72',
            name: 'status-resting',
            text: 'text-color-status-resting',
            background: 'bg-status-resting',
        },
        {
            hex: '#94488b',
            name: 'status-working',
            text: 'text-color-status-working',
            background: 'bg-status-working',
        },
    ],
    mapMarker: [
        {
            hex: '#2a3740',
            name: 'map-marker-asset',
            text: 'color-map-marker-text-asset',
            background: 'bg-map-marker-asset',
        },
        {
            hex: '#b23672',
            name: 'map-marker-poi',
            text: 'color-map-marker-text-poi',
            background: 'bg-map-marker-poi',
        },
        {
            hex: '#5a4876',
            name: 'map-marker-geofence',
            text: 'color-map-marker-text-geofence',
            background: 'bg-map-marker-geofence',
        },
        {
            hex: '#3690ae',
            name: 'map-marker-route',
            text: 'color-map-marker-text-route',
            background: 'bg-map-marker-route',
        },
    ],
    gray: [
        {
            hex: '#fdfdfd',
            name: 'decent',
            text: 'text-color-darkest',
            background: 'bg-decent',
        },
        {
            hex: '#f6f8f9',
            name: 'lightest',
            text: 'text-color-lightest',
            background: 'bg-lightest',
        },
        {
            hex: '#E5EBF0',
            name: 'lighter',
            text: 'text-color-lighter',
            background: 'bg-lighter',
        },
        {
            hex: '#D0D8DE',
            name: 'light',
            text: 'text-color-light',
            background: 'bg-light',
        },
        {
            hex: '#A7AFBB',
            name: 'gray',
            text: 'text-color-gray',
            background: 'bg-gray',
        },
        {
            hex: '#697A8B',
            name: 'dark',
            text: 'text-color-dark',
            background: 'bg-dark',
        },
        {
            hex: '#4C5667',
            name: 'darker',
            text: 'text-color-darker',
            background: 'bg-darker',
        },
        {
            hex: '#2a3740',
            name: 'darkest',
            text: 'text-color-darkest',
            background: 'bg-darkest',
        },
    ],
    highlight: [
        {
            hex: '#f3fbfc',
            name: 'highlight-decent',
            text: 'text-color-highlight-decent',
            background: 'bg-highlight-decent',
        },
        {
            hex: '#ebf9fa',
            name: 'highlight-lightest',
            text: 'text-color-highlight-lightest',
            background: 'bg-highlight-lightest',
        },
        {
            hex: '#cef0f3',
            name: 'highlight-lighter',
            text: 'text-color-highlight-lighter',
            background: 'bg-highlight-lighter',
        },
        {
            hex: '#8ddbe3',
            name: 'highlight-light',
            text: 'text-color-highlight-light',
            background: 'bg-highlight-light',
        },
        {
            hex: '#30b4c0',
            name: 'highlight',
            text: 'text-color-highlight',
            background: 'bg-highlight',
        },
        {
            hex: '#268e97',
            name: 'highlight-dark',
            text: 'text-color-highlight-dark',
            background: 'bg-highlight-dark',
        },
        {
            hex: '#1c686e',
            name: 'highlight-darker',
            text: 'text-color-highlight-darker',
            background: 'bg-highlight-darker',
        },
        {
            hex: '#114146',
            name: 'highlight-darkest',
            text: 'text-color-highlight-darkest',
            background: 'bg-highlight-darkest',
        },
    ],
    rating: [
        {
            hex: '#d90000',
            name: 'rating-1',
            text: 'text-color-rating-1',
            background: 'bg-rating-1',
        },
        {
            hex: '#ff8e3c',
            name: 'rating-2',
            text: 'text-color-rating-2',
            background: 'bg-rating-2',
        },
        {
            hex: '#f8c575',
            name: 'rating-3',
            text: 'text-color-rating-3',
            background: 'bg-rating-3',
        },
        {
            hex: '#5cb85c',
            name: 'rating-4',
            text: 'text-color-rating-4',
            background: 'bg-rating-4',
        },
        {
            hex: '#4b924c',
            name: 'rating-5',
            text: 'text-color-rating-5',
            background: 'bg-rating-5',
        },
    ],
    coldplay: [
        {
            hex: '#520D4E',
            name: 'coldplay-wine',
            text: 'text-color-coldplay-wine',
            background: 'bg-coldplay-wine',
        },
        {
            hex: '#5a4876',
            name: 'coldplay-aubergine',
            text: 'text-color-coldplay-aubergine',
            background: 'bg-coldplay-aubergine',
        },
        {
            hex: '#536E8B',
            name: 'coldplay-kashmir',
            text: 'text-color-coldplay-kashmir',
            background: 'bg-coldplay-kashmir',
        },
        {
            hex: '#67abc5',
            name: 'coldplay-fountain',
            text: 'text-color-coldplay-fountain',
            background: 'bg-coldplay-fountain',
        },
        {
            hex: '#4ECCC1',
            name: 'coldplay-turquoise',
            text: 'text-color-coldplay-turquoise',
            background: 'bg-coldplay-turquoise',
        },
        {
            hex: '#79D4B3',
            name: 'coldplay-bermuda',
            text: 'text-color-coldplay-bermuda',
            background: 'bg-coldplay-bermuda',
        },
        {
            hex: '#A1DAA3',
            name: 'coldplay-moos',
            text: 'text-color-coldplay-moos',
            background: 'bg-coldplay-moos',
        },
        {
            hex: '#C7E291',
            name: 'coldplay-primrose',
            text: 'text-color-coldplay-primrose',
            background: 'bg-coldplay-primrose',
        },
        {
            hex: '#F0EB83',
            name: 'coldplay-khaki',
            text: 'text-color-coldplay-khaki',
            background: 'bg-coldplay-khaki',
        },
    ],
    warmup: [
        {
            hex: '#31144F',
            name: 'warmup-crimson',
            text: 'text-color-warmup-crimson',
            background: 'bg-warmup-crimson',
        },
        {
            hex: '#493D6D',
            name: 'warmup-victoria',
            text: 'text-color-warmup-victoria',
            background: 'bg-warmup-victoria',
        },
        {
            hex: '#7E3E72',
            name: 'warmup-cadillac',
            text: 'text-color-warmup-cadillac',
            background: 'bg-warmup-cadillac',
        },
        {
            hex: '#B23672',
            name: 'warmup-raspberry',
            text: 'text-color-warmup-raspberry',
            background: 'bg-warmup-raspberry',
        },
        {
            hex: '#E72472',
            name: 'warmup-cerise',
            text: 'text-color-warmup-cerise',
            background: 'bg-warmup-cerise',
        },
        {
            hex: '#C9778D',
            name: 'warmup-charm',
            text: 'text-color-warmup-charm',
            background: 'bg-warmup-charm',
        },
        {
            hex: '#F19588',
            name: 'warmup-salmon',
            text: 'text-color-warmup-salmon',
            background: 'bg-warmup-salmon',
        },
        {
            hex: '#F5BB89',
            name: 'warmup-cherokee',
            text: 'text-color-warmup-cherokee',
            background: 'bg-warmup-cherokee',
        },
        {
            hex: '#FDE082',
            name: 'warmup-corn',
            text: 'text-color-warmup-corn',
            background: 'bg-warmup-corn',
        },
    ],
    playground: [
        {
            name: 'checkerboard',
            background: 'bg-checkerboard',
        },
        {
            name: 'white',
            background: 'bg-white',
        },
        {
            name: 'lightest',
            background: 'bg-lightest',
        },
        {
            name: 'lighter',
            background: 'bg-lighter',
        },
        {
            name: 'light',
            background: 'bg-light',
        },
    ],
};
