// @ts-ignore-next-line importsNotUsedAsValues
import React, { type PropsWithChildren } from 'react';
import classNames from 'classnames';

export type WithFeedbackAndAddonProps = {
    /**
     * Defines the size of the select to be rendered.
     *
     * @default 'md'
     */
    bsSize?: 'sm' | 'md' | 'lg';

    /**
     * Option to add an icon as a leading input addon to the select or multiselect component.
     */
    inputAddon?: string;

    /**
     * Input error message.
     */
    errorMessage?: string | React.ReactNode;

    /**
     * Input warning message.
     */
    warningMessage?: string | React.ReactNode;

    /**
     * Optional warning message white-space setting.
     *
     * @default 'normal'
     */
    messageWhiteSpace?: 'normal' | 'nowrap' | 'pre-line';
};

const WithFeedbackAndAddon = (props: PropsWithChildren<WithFeedbackAndAddonProps>) => {
    const { bsSize = 'md', inputAddon, errorMessage, warningMessage, messageWhiteSpace = 'normal', children } = props;

    const formGroupClassName = classNames(
        'form-group margin-bottom-0',
        (errorMessage || warningMessage) && 'has-feedback',
        errorMessage && 'has-error',
        warningMessage && 'has-warning'
    );

    const inputGroupClassNames = classNames(
        'input-group',
        bsSize === 'sm' && 'input-group-sm',
        bsSize === 'lg' && 'input-group-lg'
    );

    const hasFeedbackMessage = errorMessage || warningMessage;

    return (
        <div className={formGroupClassName}>
            <div className={inputGroupClassNames}>
                {inputAddon && (
                    <div className='input-group-addon'>
                        <span className={inputAddon} />
                    </div>
                )}
                <div className='form-control-feedback-wrapper'>
                    {children}
                    {hasFeedbackMessage && (
                        <>
                            {errorMessage && <span className='form-control-feedback rioglyph rioglyph-error-sign' />}
                            {warningMessage && (
                                <span className='form-control-feedback rioglyph rioglyph-warning-sign' />
                            )}
                            <span className={`help-block white-space-${messageWhiteSpace}`}>
                                <span>{errorMessage || warningMessage}</span>
                            </span>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default WithFeedbackAndAddon;
