import { Playground } from '../../../components/playground/Playground';
import { PlaygroundClasses, PlaygroundClassesSpacer } from '../../../components/playground/PlaygroundClasses';
import { Description } from '../../../components/Description';
import { Title } from '../../../components/Title';
import { Note } from '../../../components/Note';

const each360Degree = ['0', '45', '90', '135', '180', '225', '270', '315', '360'];
const each5Degree = ['1', '2', '3', '4', '5'];
const each5DegreeReversed = Array.from(each5Degree).reverse();
const eachOriginTop = ['top-left', 'top', 'top-right'];
const eachOriginRight = ['right-top', 'right', 'right-bottom'];
const eachOriginBottom = ['bottom-left', 'bottom', 'bottom-right'];
const eachOriginLeft = ['left-bottom', 'left', 'left-top'];

export const Rotate = () => (
    <div>
        <Description headline='Rotate' anchor='rotate' />
        <Playground example={rotateExample} classes={rotateClasses} />
        <Note>
            The transform classes can not be combined. For example rotate or scale. It is because of the CSS syntax. For
            more information see{' '}
            <a href='https://developer.mozilla.org/en-US/docs/Web/CSS/transform#Syntax'>Transform syntax</a>.
        </Note>
    </div>
);

const rotateExample = (
    <div>
        <Title>Rotate 360°</Title>
        <div className='display-flex flex-wrap gap-20 margin-bottom-20'>
            {each360Degree.map((value, index) => (
                <div className={`width-50 height-50 bg-info rounded position-relative rotate-${value}`} key={index}>
                    <div className='width-10 height-10 bg-white position-absolute left-5 top-5' />
                </div>
            ))}
        </div>
        <Title>Rotate -5° to 5°</Title>
        <div className='display-flex flex-wrap gap-20'>
            {each5DegreeReversed.map((value, index) => (
                <div className={`width-50 height-50 bg-info rounded position-relative rotate--${value}`} key={index}>
                    <div className='width-10 height-10 bg-white position-absolute left-5 top-5' />
                </div>
            ))}
            {each5Degree.map((value, index) => (
                <div className={`width-50 height-50 bg-info rounded position-relative rotate-${value}`} key={index}>
                    <div className='width-10 height-10 bg-white position-absolute left-5 top-5' />
                </div>
            ))}
        </div>
    </div>
);

const rotateClasses = (
    <>
        <PlaygroundClassesSpacer label='Rotate' />
        <PlaygroundClasses label='360' prefix='rotate-' classes={each360Degree} />
        <PlaygroundClasses label='1 to 5' prefix='rotate-' classes={each5Degree} />
        <PlaygroundClasses label='-5 to -1' prefix='rotate--' classes={each5DegreeReversed} />
        <PlaygroundClassesSpacer label='Transform origin' />
        <PlaygroundClasses label='Center' classes='transform-origin-center' />
        <PlaygroundClasses label='Top' prefix='transform-origin-' classes={eachOriginTop} />
        <PlaygroundClasses label='Bottom' prefix='transform-origin-' classes={eachOriginBottom} />
        <PlaygroundClasses label='Left' prefix='transform-origin-' classes={eachOriginLeft} />
        <PlaygroundClasses label='Right' prefix='transform-origin-' classes={eachOriginRight} />
    </>
);
