import React, { type ForwardedRef, forwardRef, type MouseEvent, type ReactNode } from 'react';
import classNames from 'classnames';
import noop from 'lodash/fp/noop';

import Button from '../button/Button';

export type AppNavigationBarProps = {
    /**
     * The title for the content that is shown.
     */
    title?: string | ReactNode;

    /**
     * Right side area for additional options of buttons like filter etc. that is relevant for the shown content..
     */
    right?: ReactNode;

    /**
     * Callback function for when the back navigation button is clicked.
     */
    onNavigateBack?: (event: MouseEvent<HTMLSpanElement>) => void;

    /**
     * Additional classes for the wrapper element.
     */
    className?: string;
};

export const AppNavigationBar = forwardRef((props: AppNavigationBarProps, ref: ForwardedRef<HTMLDivElement>) => {
    const { className, title, right = <span />, onNavigateBack = noop, ...remainingProps } = props;

    const navigationClasses = classNames(
        'AppNavigationBar',
        'width-100pct display-flex align-items-center justify-content-between height-45 user-select-none padding-x-10',
        className
    );

    return (
        <div ref={ref} {...remainingProps} className={navigationClasses}>
            <div className='display-flex align-items-center gap-5'>
                <Button
                    bsStyle='muted'
                    className='text-color-darkest'
                    iconOnly
                    iconName='rioglyph-arrow-left'
                    onClick={onNavigateBack}
                />
                <div className='text-medium text-color-darker'>{title}</div>
            </div>
            <div>{right}</div>
        </div>
    );
});

export default AppNavigationBar;
