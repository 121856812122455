export const rioglypIcons = [
    'rioglyph-academic-cap',
    'rioglyph-addressbook',
    'rioglyph-air-pay',
    'rioglyph-am',
    'rioglyph-angle-double-down',
    'rioglyph-angle-double-left',
    'rioglyph-angle-double-right',
    'rioglyph-angle-double-up',
    'rioglyph-antenna',
    'rioglyph-api',
    'rioglyph-archive',
    'rioglyph-area-in',
    'rioglyph-area-out',
    'rioglyph-arrow-down',
    'rioglyph-arrow-left',
    'rioglyph-arrow-right',
    'rioglyph-arrow-up',
    'rioglyph-axle',
    'rioglyph-ban-circle',
    'rioglyph-battery-level-empty',
    'rioglyph-battery-level-full',
    'rioglyph-battery-level-low',
    'rioglyph-beacon',
    'rioglyph-book',
    'rioglyph-bookable-poi',
    'rioglyph-bookmark-square',
    'rioglyph-bookmark',
    'rioglyph-border-crossing',
    'rioglyph-brake',
    'rioglyph-building',
    'rioglyph-bus-baseline',
    'rioglyph-bus-breakdown',
    'rioglyph-bus',
    'rioglyph-businessman',
    'rioglyph-calendar-parking',
    'rioglyph-calendar-plus',
    'rioglyph-calendar-today',
    'rioglyph-calendar',
    'rioglyph-camera',
    'rioglyph-car-baseline',
    'rioglyph-car-wash',
    'rioglyph-car',
    'rioglyph-card-hotel',
    'rioglyph-card-reader',
    'rioglyph-cards-add',
    'rioglyph-cards-grid',
    'rioglyph-cards-list',
    'rioglyph-cards-table',
    'rioglyph-carrier',
    'rioglyph-chat',
    'rioglyph-check-badge',
    'rioglyph-check-shield',
    'rioglyph-checkbox-checked',
    'rioglyph-checkbox',
    'rioglyph-checkboxes',
    'rioglyph-chevron-down',
    'rioglyph-chevron-left',
    'rioglyph-chevron-right',
    'rioglyph-chevron-up',
    'rioglyph-circle-stack',
    'rioglyph-click',
    'rioglyph-climate-control',
    'rioglyph-clipboard',
    'rioglyph-cloud-download',
    'rioglyph-cloud-not-saved',
    'rioglyph-cloud-upload',
    'rioglyph-cloud',
    'rioglyph-coasting',
    'rioglyph-code-braces',
    'rioglyph-code-brackets',
    'rioglyph-cog',
    'rioglyph-color-swatch',
    'rioglyph-comment',
    'rioglyph-compare',
    'rioglyph-component-custom-recurrent',
    'rioglyph-component-custom',
    'rioglyph-component',
    'rioglyph-construction',
    'rioglyph-cookie',
    'rioglyph-cooling',
    'rioglyph-cost-efficency',
    'rioglyph-cruise-control',
    'rioglyph-csv',
    'rioglyph-currency-euro',
    'rioglyph-damages',
    'rioglyph-dangerousgoods',
    'rioglyph-dashboard',
    'rioglyph-delivery-completed',
    'rioglyph-delivery-error',
    'rioglyph-delivery-late',
    'rioglyph-delivery-on-track',
    'rioglyph-delivery-warning',
    'rioglyph-delivery',
    'rioglyph-desktop',
    'rioglyph-detail-view-info',
    'rioglyph-detail-view',
    'rioglyph-direction',
    'rioglyph-document-out',
    'rioglyph-document',
    'rioglyph-download',
    'rioglyph-drag-n-drop',
    'rioglyph-drive-history',
    'rioglyph-driver-card',
    'rioglyph-driver',
    'rioglyph-drivercard-in',
    'rioglyph-drivercard-out',
    'rioglyph-duplicate',
    'rioglyph-earphone',
    'rioglyph-empty',
    'rioglyph-engine',
    'rioglyph-envelope',
    'rioglyph-erase',
    'rioglyph-error-sign',
    'rioglyph-euro-note',
    'rioglyph-exclamation-sign',
    'rioglyph-exclamation',
    'rioglyph-eye-close',
    'rioglyph-eye-option',
    'rioglyph-face-frown',
    'rioglyph-face-neutral',
    'rioglyph-face-smile',
    'rioglyph-facetime-video',
    'rioglyph-factory',
    'rioglyph-file-signature',
    'rioglyph-files',
    'rioglyph-fill',
    'rioglyph-filled-chart',
    'rioglyph-filling-e-station',
    'rioglyph-filling-station',
    'rioglyph-filter-active',
    'rioglyph-filter',
    'rioglyph-fingerprint',
    'rioglyph-finish',
    'rioglyph-fire',
    'rioglyph-flag',
    'rioglyph-flash',
    'rioglyph-folder-closed',
    'rioglyph-folder-open',
    'rioglyph-fuel-diesel',
    'rioglyph-fuel-electric',
    'rioglyph-fuel-gas',
    'rioglyph-fuel-hydrogen',
    'rioglyph-fullscreen',
    'rioglyph-geofence',
    'rioglyph-globe',
    'rioglyph-group',
    'rioglyph-hand-down',
    'rioglyph-hand-left',
    'rioglyph-hand-right',
    'rioglyph-hand-up',
    'rioglyph-handshake',
    'rioglyph-hash',
    'rioglyph-heart',
    'rioglyph-heating',
    'rioglyph-home-sign',
    'rioglyph-home',
    'rioglyph-hour-glass',
    'rioglyph-id',
    'rioglyph-inbox-in',
    'rioglyph-inbox-out',
    'rioglyph-inbox-stack',
    'rioglyph-inbox',
    'rioglyph-info-sign',
    'rioglyph-info',
    'rioglyph-key',
    'rioglyph-kickdown',
    'rioglyph-language',
    'rioglyph-layer-pois',
    'rioglyph-layer',
    'rioglyph-line-chart',
    'rioglyph-link',
    'rioglyph-load-unload',
    'rioglyph-load',
    'rioglyph-location-arrow',
    'rioglyph-lock-open',
    'rioglyph-lock',
    'rioglyph-log-in',
    'rioglyph-logout',
    'rioglyph-looking-glass-man',
    'rioglyph-looking-glass',
    'rioglyph-maintenance-components',
    'rioglyph-map-location',
    'rioglyph-map-marker',
    'rioglyph-map',
    'rioglyph-megaphone',
    'rioglyph-menu-hamburger',
    'rioglyph-merge',
    'rioglyph-milage',
    'rioglyph-minus-light',
    'rioglyph-minus-sign',
    'rioglyph-minus',
    'rioglyph-missing',
    'rioglyph-mode-dark-light',
    'rioglyph-more',
    'rioglyph-new-window',
    'rioglyph-newspaper',
    'rioglyph-notification',
    'rioglyph-number-1',
    'rioglyph-number-2',
    'rioglyph-number-3',
    'rioglyph-off',
    'rioglyph-oil-can',
    'rioglyph-ok-circle',
    'rioglyph-ok-sign',
    'rioglyph-ok',
    'rioglyph-onboarding',
    'rioglyph-ongoing',
    'rioglyph-option-horizontal',
    'rioglyph-option-vertical',
    'rioglyph-order',
    'rioglyph-palette-broken',
    'rioglyph-palette',
    'rioglyph-paper-clip',
    'rioglyph-parcel-broken',
    'rioglyph-parcel',
    'rioglyph-parking',
    'rioglyph-pause-circle',
    'rioglyph-pause',
    'rioglyph-pdf-file',
    'rioglyph-pencil-square',
    'rioglyph-pencil',
    'rioglyph-phone',
    'rioglyph-pictures',
    'rioglyph-pin-range',
    'rioglyph-pin',
    'rioglyph-play-circle',
    'rioglyph-play',
    'rioglyph-plugged-off',
    'rioglyph-plugged-on',
    'rioglyph-plus-light',
    'rioglyph-plus-sign',
    'rioglyph-plus',
    'rioglyph-pm',
    'rioglyph-poi',
    'rioglyph-polygon',
    'rioglyph-position',
    'rioglyph-print',
    'rioglyph-progress-ongoing',
    'rioglyph-progress-remaining',
    'rioglyph-pto-off',
    'rioglyph-pto-on',
    'rioglyph-pushpin',
    'rioglyph-puzzle',
    'rioglyph-pylon',
    'rioglyph-qr-code',
    'rioglyph-question-sign',
    'rioglyph-question',
    'rioglyph-record',
    'rioglyph-refresh',
    'rioglyph-remove-circle',
    'rioglyph-remove-sign',
    'rioglyph-remove',
    'rioglyph-repeat',
    'rioglyph-resize-full',
    'rioglyph-resize-horizontal',
    'rioglyph-resize-small',
    'rioglyph-resize-vertical',
    'rioglyph-retrofit',
    'rioglyph-retweet',
    'rioglyph-revert',
    'rioglyph-rio-marker',
    'rioglyph-road-restrictions',
    'rioglyph-road',
    'rioglyph-robot',
    'rioglyph-rocket',
    'rioglyph-role-management',
    'rioglyph-route-option',
    'rioglyph-route-view',
    'rioglyph-route',
    'rioglyph-ruler',
    'rioglyph-save',
    'rioglyph-scale',
    'rioglyph-scan',
    'rioglyph-scissors',
    'rioglyph-search-list',
    'rioglyph-search-truck',
    'rioglyph-search',
    'rioglyph-send',
    'rioglyph-series',
    'rioglyph-server-stack',
    'rioglyph-settings',
    'rioglyph-share-alt',
    'rioglyph-share-nodes',
    'rioglyph-share',
    'rioglyph-ship',
    'rioglyph-shopping-bag',
    'rioglyph-shopping-cart',
    'rioglyph-signature',
    'rioglyph-slope-down-max',
    'rioglyph-slope-down-min',
    'rioglyph-slope-up-max',
    'rioglyph-slope-up-min',
    'rioglyph-sort-by-attributes-alt',
    'rioglyph-sort-by-attributes',
    'rioglyph-sort',
    'rioglyph-sparkles',
    'rioglyph-speed',
    'rioglyph-sphere',
    'rioglyph-spinner',
    'rioglyph-split-view',
    'rioglyph-stack',
    'rioglyph-star-empty',
    'rioglyph-star',
    'rioglyph-stars',
    'rioglyph-start',
    'rioglyph-stats',
    'rioglyph-status-available',
    'rioglyph-status-driving',
    'rioglyph-status-resting',
    'rioglyph-status-working',
    'rioglyph-steering-wheel',
    'rioglyph-stopover',
    'rioglyph-support',
    'rioglyph-table-view',
    'rioglyph-tachograph-download',
    'rioglyph-tachograph',
    'rioglyph-tag',
    'rioglyph-tasks',
    'rioglyph-temperature',
    'rioglyph-th-list',
    'rioglyph-thumbs-down',
    'rioglyph-thumbs-up',
    'rioglyph-tickets',
    'rioglyph-time-alt',
    'rioglyph-time-cancle',
    'rioglyph-time',
    'rioglyph-tire',
    'rioglyph-tms',
    'rioglyph-to-bottom',
    'rioglyph-to-left',
    'rioglyph-to-right',
    'rioglyph-to-top',
    'rioglyph-traffic-lights',
    'rioglyph-trailer-baseline',
    'rioglyph-trailer',
    'rioglyph-trailerposition',
    'rioglyph-transfer',
    'rioglyph-trash',
    'rioglyph-triangle-bottom',
    'rioglyph-triangle-left',
    'rioglyph-triangle-right',
    'rioglyph-triangle-top',
    'rioglyph-trophy',
    'rioglyph-truck-baseline',
    'rioglyph-truck-breakdown',
    'rioglyph-truck-unit',
    'rioglyph-truck',
    'rioglyph-unlink',
    'rioglyph-upload',
    'rioglyph-user-add',
    'rioglyph-user-group',
    'rioglyph-user-ok',
    'rioglyph-user-remove',
    'rioglyph-user-sign-off',
    'rioglyph-user-sign',
    'rioglyph-user',
    'rioglyph-van-baseline',
    'rioglyph-van',
    'rioglyph-volume',
    'rioglyph-wallet',
    'rioglyph-warning-sign',
    'rioglyph-weather-cloudy',
    'rioglyph-weather-icy',
    'rioglyph-weather-overcast',
    'rioglyph-weather-raining',
    'rioglyph-weather-snowing',
    'rioglyph-weather-stormy',
    'rioglyph-weather-sunny',
    'rioglyph-weather-thundering',
    'rioglyph-weather-windy',
    'rioglyph-weight',
    'rioglyph-width',
    'rioglyph-wifi-off',
    'rioglyph-wifi',
    'rioglyph-window',
    'rioglyph-workshop',
    'rioglyph-wrench',
    'rioglyph-xmas-raindeer',
    'rioglyph-xmas-santa',
    'rioglyph-rio',
];
