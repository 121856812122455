import { NavLink } from 'react-router-dom';

import { Playground } from '../../../components/playground/Playground';
import { Description } from '../../../components/Description';

import Divider from '../../../../../src/Divider';

export const Key = () => (
    <div>
        <Description headline='Key' anchor='key' />
        <Playground example={keyExample} />
    </div>
);

// https://apple.stackexchange.com/questions/55727/where-can-i-find-the-unicode-symbols-for-mac-functional-keys-command-shift-e

const keyExample = (
    <div>
        <label>Keys example</label>
        <p title='press m to comment'>
            <b>Pro tip:</b> press <kbd>m</kbd> to comment
        </p>
        <p title='press ctrl + a to select all'>
            Press <kbd>ctrl</kbd> + <kbd>a</kbd> to select all
        </p>
        <Divider />
        <label>Use lower-case names</label>
        <p>
            <kbd>shift</kbd>
            <kbd>enter</kbd>
            <kbd>ctrl</kbd>
            <kbd>alt</kbd>
        </p>
        <label>Unicode symbols</label>
        <p>
            <kbd>⇧</kbd>
            <kbd>⇪</kbd>
            <kbd>↵</kbd>
            <kbd>⌫</kbd>
            <kbd>→</kbd>
            <kbd>←</kbd>
            <kbd>↑</kbd>
            <kbd>↓</kbd>
            <kbd>⇥</kbd>
            <kbd>⇤</kbd>
            <kbd>␣</kbd>
        </p>
        <Divider />
        <label>
            Mac specific keys - see <NavLink to='/components/deviceUtils'>DeviceUtils</NavLink>
        </label>
        <p>
            <kbd>⌘</kbd>
            <kbd>⌥</kbd>
            <kbd>⌃</kbd>
            <kbd></kbd>
        </p>
    </div>
);
