import { Playground } from '../../../components/playground/Playground';
import { PlaygroundClasses } from '../../../components/playground/PlaygroundClasses';
import { Description } from '../../../components/Description';

export const Blur = () => (
    <div>
        <Description headline='Blur' anchor='blur' />
        <Playground example={blurExample} classes={blurClasses} />
        <Description headline='Backdrop blur' anchor='backdropBlur' />
        <Playground example={backdropBlurExample} classes={backdropBlurClasses} />
    </div>
);

const eachBlur = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '20', '30', '40', '50'];

const eachBackdropBlur = ['1', '2', '3', '4', '5'];

const blurExample = (
    <div className='display-flex flex-wrap gap-20'>
        {eachBlur.map(value => (
            <div className={`blur-${value} width-30 height-30 rounded-circle bg-primary`} key={crypto.randomUUID()} />
        ))}
    </div>
);

const backdropBlurExample = (
    <div className='position-relative'>
        <div className='max-width-600'>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. In pharetra dui eget massa efficitur aliquam
            condimentum in felis. Integer eget metus vel nunc efficitur iaculis. Vivamus elementum ante et est sagittis
            semper. Integer eget metus vel nunc efficitur iaculis. Vivamus elementum ante et est sagittis semper.
            Integer eget metus vel nunc efficitur iaculis. Vivamus elementum ante et est sagittis semper. Integer eget
            metus vel nunc efficitur iaculis. Vivamus elementum ante et est sagittis semper. Integer eget metus vel nunc
            efficitur iaculis. Vivamus elementum ante et est sagittis semper. Integer eget metus vel nunc efficitur
            iaculis. Vivamus elementum ante et est sagittis semper. Integer eget metus vel nunc efficitur iaculis
            elementum ante et est sagittis integer eget metus vel nunc semper.
        </div>
        <div className='position-absolute top-15 left-15 space-y-10 pointer-events-none'>
            <div className='display-flex flex-wrap gap-20'>
                {eachBackdropBlur.map(value => (
                    <div
                        className={`backdrop-blur-${value} width-60 height-60 border rounded`}
                        key={crypto.randomUUID()}
                    />
                ))}
            </div>
            <div className='display-flex flex-wrap gap-20'>
                {eachBackdropBlur.map((value, index) => (
                    <div className='position-relative' key={crypto.randomUUID()}>
                        <div
                            className={`position-absolute inset-0 bg-primary opacity-${eachBackdropBlur[index]}0 rounded`}
                        />
                        <div className={`backdrop-blur-${value} width-60 height-60 border rounded position-relative`} />
                    </div>
                ))}
            </div>
        </div>
    </div>
);

const blurClasses = <PlaygroundClasses prefix='blur-' classes={eachBlur} />;

const backdropBlurClasses = <PlaygroundClasses prefix='backdrop-blur-' classes={eachBackdropBlur} />;
