import { Playground } from '../../../components/playground/Playground';
import { PlaygroundColors } from '../../../components/playground/PlaygroundColors';
import { PlaygroundClasses } from '../../../components/playground/PlaygroundClasses';
import { Description } from '../../../components/Description';

import { colors, getColorNames } from '../../../utils/colors';

export const Labels = () => (
    <div>
        <Description headline='Labels' anchor='labels' />
        <Playground
            example={labels}
            classes={labelsClasses}
            availableColors={
                <PlaygroundColors bgDefault bgPrimary bgSecondary bgInfo bgSuccess bgWarning bgDanger bgMuted />
            }
        />
    </div>
);

const eachColor = ['default', ...getColorNames(colors.brand, colors.status), 'muted'];

const labels = (
    <div className='row'>
        <div className='col-lg-6 margin-bottom-25'>
            <h5>Standard labels</h5>
            <div className='display-flex flex-wrap gap-10'>
                {eachColor.map((color, index) => (
                    <div className={`label label-${color}`} key={index}>
                        {color}
                    </div>
                ))}
            </div>
        </div>

        <div className='col-lg-6 margin-bottom-25'>
            <h5>Filled labels</h5>
            <div className='display-flex flex-wrap gap-10'>
                {eachColor.map((color, index) => (
                    <div className={`label label-${color} label-filled`} key={index}>
                        {color}
                    </div>
                ))}
            </div>
        </div>

        <div className='col-lg-6 margin-bottom-25'>
            <h5>Condensed labels</h5>
            <div className='display-flex flex-wrap gap-10'>
                {eachColor.map((color, index) => (
                    <div className={`label label-${color} label-condensed`} key={index}>
                        {color}
                    </div>
                ))}
            </div>
        </div>

        <div className='col-lg-6 margin-bottom-25'>
            <h5>Condensed and filled labels</h5>
            <div className='display-flex flex-wrap gap-10'>
                {eachColor.map((color, index) => (
                    <div className={`label label-${color} label-filled label-condensed`} key={index}>
                        {color}
                    </div>
                ))}
            </div>
        </div>
    </div>
);

const labelsClasses = (
    <>
        <PlaygroundClasses label='Colors' prefix='label-' classes={eachColor} />
        <PlaygroundClasses label='Variants' prefix='label-' classes={['filled', 'condensed']} />
    </>
);
