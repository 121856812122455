import { Playground, PLAYGROUNG_BG_LIGHTER } from '../../../components/playground/Playground';
import { PlaygroundClasses } from '../../../components/playground/PlaygroundClasses';
import { Description } from '../../../components/Description';
import { replaceUikitImport } from '../../../utils/replaceUikitImport';

import TableGroupedExample from './TableGroupedExample';
import TableGroupedExampleRaw from './TableGroupedExample.tsx?raw';

export const TableGrouped = () => (
    <div>
        <Description headline='Grouped table' anchor='table-grouped' />
        <Playground
            example={<TableGroupedExample />}
            codeReact={replaceUikitImport(TableGroupedExampleRaw)}
            classes={tableGroupedClasses}
            background={PLAYGROUNG_BG_LIGHTER}
            className='overflow-hidden'
        />
    </div>
);

const tableGroupedClasses = (
    <>
        <PlaygroundClasses label='<table>' classes='table' />
        <PlaygroundClasses label='<tr>' classes={['compactRow', 'extendedRow']} />
    </>
);
