// @ts-ignore-next-line importsNotUsedAsValues
import React, { type MutableRefObject, type PropsWithChildren } from 'react';

import Tooltip, { type TooltipProps } from './Tooltip';
import OverlayTrigger from '../overlay/OverlayTrigger';
import { PLACEMENT } from '../../values/Placement';
import { TRIGGER, type TriggerType } from '../../values/Trigger';

export type delayProp = {
    show: number;
    hide: number;
};

export type SimpleTooltipProps = Omit<TooltipProps, 'content'> & {
    /**
     * The tooltip content.
     */
    content: string | React.ReactNode;

    /**
     * Specify which action or actions trigger Overlay visibility.
     * Possible values are: `hover`, `click`, `focus`
     * @default 'hover'
     */
    trigger?: TriggerType;

    /**
     * The react ref object assigned to the target of the tooltip.
     */
    targetRef?: MutableRefObject<HTMLDivElement>;

    /**
     * A millisecond delay amount to show and hide the Overlay once triggered.
     * If passed an Object, you can control the show and hide timeouts individually.
     */
    delay?: number | delayProp;

    /**
     * A Popper.js config object passed to the the underlying popper instance.
     */
    popperConfig?: object;
};

const SimpleTooltip = (props: PropsWithChildren<SimpleTooltipProps>) => {
    const {
        children,
        placement = PLACEMENT.AUTO,
        trigger,
        content,
        delay,
        popperConfig,
        targetRef,
        ...remainingProps
    } = props;

    return (
        <OverlayTrigger
            placement={placement}
            trigger={trigger}
            overlay={
                <Tooltip {...remainingProps} ref={targetRef}>
                    {content}
                </Tooltip>
            }
            delay={delay}
            popperConfig={popperConfig}
        >
            {children}
        </OverlayTrigger>
    );
};

SimpleTooltip.TRIGGER_CLICK = TRIGGER.CLICK;
SimpleTooltip.TRIGGER_HOVER = TRIGGER.HOVER;
SimpleTooltip.TRIGGER_FOCUS = TRIGGER.FOCUS;

export default SimpleTooltip;
