import React, { type JSX, type ReactNode, useEffect, useState } from 'react';
import classNames from 'classnames';
import noop from 'lodash/noop';

import OverlayTrigger from '../overlay/OverlayTrigger';
import Tooltip from '../tooltip/Tooltip';

// TODO: add responsive behaviour and trigger a change from table to list for small screen -> see DriverAdministration

const wrapWithTooltip = (tooltipContent: ReactNode, element: JSX.Element) => (
    <OverlayTrigger
        placement='bottom'
        overlay={
            <Tooltip id='tooltip' allowOnTouch>
                {tooltipContent}
            </Tooltip>
        }
    >
        {element}
    </OverlayTrigger>
);

const typeIcons: Record<TableViewTogglesViewType, string> = {
    TABLE: 'rioglyph-table-view',
    SINGLE_CARD: 'rioglyph-th-list',
    MULTI_CARDS: 'rioglyph-split-view',
};

type TypeButtonProps = {
    viewType: TableViewTogglesViewType;
    currentViewType: TableViewTogglesViewType;
    disabledViewTypes: TableViewTogglesViewType[];
    tooltipContent: ReactNode;
    onClick: (vt: TableViewTogglesViewType) => void;
    disabled: boolean;
};

const TypeButton = (props: TypeButtonProps) => {
    const { viewType, currentViewType, disabledViewTypes, tooltipContent, onClick, disabled } = props;

    if (disabledViewTypes.includes(viewType)) {
        return null;
    }

    const classes = classNames(
        'btn btn-default btn-icon-only',
        disabled && 'disabled pointer-events-none',
        viewType === currentViewType && 'active'
    );

    const button = (
        <button className={classes} type='button' onClick={() => onClick(viewType)}>
            <span className={`rioglyph ${typeIcons[viewType]}`} />
        </button>
    );

    return tooltipContent ? wrapWithTooltip(tooltipContent, button) : button;
};

export type TableViewTogglesViewType = 'SINGLE_CARD' | 'MULTI_CARDS' | 'TABLE';

export type TableViewTogglesProps = {
    /**
     * The current viewType for controlling the TablesViewToggles component.
     */
    viewType?: TableViewTogglesViewType;

    /**
     * Defines the initial viewType (when viewType is not controlled from the outside).
     *
     * @default TableViewToggles.VIEW_TYPE_TABLE
     */
    initialViewType?: TableViewTogglesViewType;

    /**
     * Defines the view types which shall not be supported and are omitted.
     *
     * @default []
     */
    disabledViewTypes?: TableViewTogglesViewType[];

    /**
     * Callback function for when the user wants to change the viewType.
     *
     * @param viewType The new viewType selected by the user.
     */
    onViewTypeChange: (viewType: TableViewTogglesViewType) => void;

    /**
     * Optional tooltip content for table view button.
     */
    tableViewTooltipContent?: string | ReactNode;

    /**
     * Optional tooltip content for single card view button.
     */
    singleCardViewTooltipContent?: string | ReactNode;

    /**
     * Optional tooltip content for multi card view button.
     */
    multiCardsViewTooltipContent?: string | ReactNode;

    /**
     * Disables all buttons.
     *
     * This means the buttons cannot be clicked. Used when disabling toolbar buttons during loading state or when the
     * corresponding table is empty.
     *
     * @default false
     */
    disabled?: boolean;

    /**
     * Optional class names for the wrapper element.
     */
    className?: string;
};

const TableViewToggles = (props: TableViewTogglesProps) => {
    const {
        viewType,
        initialViewType = 'TABLE',
        disabledViewTypes = [],
        onViewTypeChange = noop,
        tableViewTooltipContent,
        singleCardViewTooltipContent,
        multiCardsViewTooltipContent,
        disabled = false,
        className,
        ...remainingProps
    } = props;

    const [internalViewType, setInternalViewType] = useState(initialViewType);

    // update internal state when external state changes - controlled component case
    useEffect(() => {
        if (viewType) {
            setInternalViewType(viewType);
        }
    }, [viewType]);

    const setViewType = (vt: TableViewTogglesViewType) => {
        setInternalViewType(vt);
        onViewTypeChange(vt);
    };

    const wrapperClassNames = classNames(
        'TableViewToggles',
        'btn-group',
        'display-flex',
        'flex-row',
        className && className
    );

    return (
        <div {...remainingProps} className={wrapperClassNames}>
            <TypeButton
                viewType='TABLE'
                currentViewType={internalViewType}
                disabledViewTypes={disabledViewTypes}
                tooltipContent={tableViewTooltipContent}
                onClick={setViewType}
                disabled={disabled}
            />

            <TypeButton
                viewType='SINGLE_CARD'
                currentViewType={internalViewType}
                disabledViewTypes={disabledViewTypes}
                tooltipContent={singleCardViewTooltipContent}
                onClick={setViewType}
                disabled={disabled}
            />

            <TypeButton
                viewType='MULTI_CARDS'
                currentViewType={internalViewType}
                disabledViewTypes={disabledViewTypes}
                tooltipContent={multiCardsViewTooltipContent}
                onClick={setViewType}
                disabled={disabled}
            />
        </div>
    );
};

// Don't export values as string but as a distinct union type
TableViewToggles.VIEW_TYPE_TABLE = 'TABLE' as TableViewTogglesViewType;
TableViewToggles.VIEW_TYPE_SINGLE_CARD = 'SINGLE_CARD' as TableViewTogglesViewType;
TableViewToggles.VIEW_TYPE_MULTI_CARDS = 'MULTI_CARDS' as TableViewTogglesViewType;

export default TableViewToggles;
