import React from 'react';

export type StateIconProps = {
    name?: string;
    className?: string;
    color?: string;
};

const StateIcon = ({ name, color = 'text-color-light', className = 'text-size-300pct' }: StateIconProps) => (
    <span className={`rioglyph ${name} ${color} ${className}`} />
);

export default StateIcon;
