import { useState } from 'react';

import Button from '../../../../../src/Button';
import { Note } from '../../../components/Note';

type FormGroupProps = {
    size?: 'sm' | 'lg';
    value: string;
    editMode: boolean;
    onChange: VoidFunction;
};

const FormGroup = ({ size, value, editMode, onChange }: FormGroupProps) => {
    return (
        <div className={`form-group ${size ? `form-group-${size}` : ''} ${!editMode ? 'form-group-not-editable' : ''}`}>
            <label htmlFor='input1'>{`form-group${size ? `-${size}` : ''} ${
                !editMode ? '+ form-group-not-editable' : ''
            }`}</label>
            {editMode ? (
                <div className='input-group'>
                    <input id='input1' className='form-control' type='text' defaultValue={value} onChange={onChange} />
                    <span className='input-group-btn'>
                        <Button
                            iconOnly
                            iconName='rioglyph-trash'
                            bsSize={size}
                            className={!editMode ? 'form-group-not-editable' : ''}
                        />
                    </span>
                </div>
            ) : (
                <div>{value}</div>
            )}
        </div>
    );
};

type InputGroupProps = {
    size?: 'sm' | 'lg';
    value: string;
    editMode: boolean;
    onChange: VoidFunction;
};

const InputGroup = ({ size, value, editMode, onChange }: InputGroupProps) => {
    return (
        <>
            <label htmlFor='input2'>{`input-group${size ? `-${size}` : ''} ${
                !editMode ? 'input-group-not-editable' : ''
            }`}</label>
            <div
                className={`input-group ${size ? `input-group-${size}` : ''} ${
                    !editMode ? '+ input-group-not-editable' : ''
                }`}
            >
                {editMode ? (
                    <>
                        <input
                            id='input2'
                            className='form-control'
                            type='text'
                            defaultValue={value}
                            onChange={onChange}
                        />
                        <span className='input-group-btn'>
                            <Button
                                iconOnly
                                iconName='rioglyph-trash'
                                bsSize={size}
                                className={!editMode ? 'form-group-not-editable' : ''}
                            />
                        </span>
                    </>
                ) : (
                    <div>{value}</div>
                )}
            </div>
        </>
    );
};

export default () => {
    const [editModes, setEditModes] = useState<Record<string, boolean>>({
        formGroupEditMode: false,
        inputGroupEditMode: false,
    });

    const [hasFormGroupExampleChanged, setHasFormGroupExampleChanged] = useState(false);
    const [hasInputGroupExampleChanged, setHasInputGroupExampleChanged] = useState(false);

    const handleFormInputChange = () => setHasFormGroupExampleChanged(true);
    const handleInputChange = () => setHasInputGroupExampleChanged(true);

    const handleChange = (key: string) => {
        const newState = !editModes[key];

        setEditModes({
            ...editModes,
            [key]: newState,
        });

        setHasFormGroupExampleChanged(false);
        setHasInputGroupExampleChanged(false);
    };

    const { formGroupEditMode, inputGroupEditMode } = editModes;

    return (
        <>
            <div className='btn-group' />
            <div className='row equal-height'>
                <div className='col-12 col-md-6 margin-bottom-15'>
                    <div
                        className={
                            'panel panel-default panel-body height-100pct shadow-default ' +
                            'display-flex flex-column justify-content-between'
                        }
                    >
                        <div>
                            <div className='h5'>Size by form-group</div>
                            <Note>Different form-group sizes come with different bottom spacing</Note>
                            <hr className='margin-top-20 margin-bottom-15' />

                            <div className='h5'>Wrapped by sized form-groups</div>
                            <FormGroup
                                onChange={handleFormInputChange}
                                editMode={formGroupEditMode}
                                size='sm'
                                value='Lorem Ipsum Dolor'
                            />
                            <FormGroup
                                onChange={handleFormInputChange}
                                editMode={formGroupEditMode}
                                value='Lorem Ipsum Dolor'
                            />
                            <FormGroup
                                onChange={handleFormInputChange}
                                editMode={formGroupEditMode}
                                size='lg'
                                value='Lorem Ipsum Dolor'
                            />
                        </div>

                        <div className={`margin-top-25 display-flex ${formGroupEditMode ? 'justify-content-end' : ''}`}>
                            {!formGroupEditMode && (
                                <Button iconName='rioglyph-pencil' onClick={() => handleChange('formGroupEditMode')}>
                                    Edit
                                </Button>
                            )}
                            {formGroupEditMode && (
                                <div className='btn-toolbar'>
                                    <Button onClick={() => handleChange('formGroupEditMode')}>Cancel</Button>
                                    <Button
                                        bsStyle='primary'
                                        disabled={!hasFormGroupExampleChanged}
                                        onClick={() => handleChange('formGroupEditMode')}
                                    >
                                        Save changes
                                    </Button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                <div className='col-12 col-md-6 margin-bottom-15'>
                    <div
                        className={
                            'panel panel-default panel-body height-100pct shadow-default ' +
                            'display-flex flex-column justify-content-between'
                        }
                    >
                        <div>
                            <div className='h5'>Size by input-group</div>
                            <Note>No form-group size results in the same bottom spacing</Note>
                            <hr className='margin-top-20 margin-bottom-15' />
                            <div className='h5'>Wrapped only by form-group</div>
                            <div className='form-group'>
                                <InputGroup
                                    onChange={handleInputChange}
                                    editMode={inputGroupEditMode}
                                    size='sm'
                                    value='Lorem Ipsum Dolor'
                                />
                            </div>
                            <div className='form-group'>
                                <InputGroup
                                    onChange={handleInputChange}
                                    editMode={inputGroupEditMode}
                                    value='Lorem Ipsum Dolor'
                                />
                            </div>
                            <div className='form-group'>
                                <InputGroup
                                    onChange={handleInputChange}
                                    editMode={inputGroupEditMode}
                                    size='lg'
                                    value='Lorem Ipsum Dolor'
                                />
                            </div>
                        </div>

                        <div
                            className={`margin-top-25 display-flex ${inputGroupEditMode ? 'justify-content-end' : ''}`}
                        >
                            {!inputGroupEditMode && (
                                <Button iconName='rioglyph-pencil' onClick={() => handleChange('inputGroupEditMode')}>
                                    Edit
                                </Button>
                            )}
                            {inputGroupEditMode && (
                                <div className='btn-toolbar'>
                                    <Button onClick={() => handleChange('inputGroupEditMode')}>Cancel</Button>
                                    <Button
                                        bsStyle='primary'
                                        disabled={!hasInputGroupExampleChanged}
                                        onClick={() => handleChange('inputGroupEditMode')}
                                    >
                                        Save changes
                                    </Button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
