import { dummyText, dummyTextExtraShort } from '../../../utils/data';
import { isDesktop } from '../../../../../src/DeviceUtils';

const text = isDesktop() ? dummyText : dummyTextExtraShort;

export default () => (
    <div>
        <div className='display-grid grid-cols-1 grid-cols-2-ls gap-20'>
            <div className='counter color-gray'>
                {[1, 2].map(index => (
                    <div key={index}>{text}</div>
                ))}
            </div>
            <div className='counter color-gray filled'>
                {[1, 2].map(index => (
                    <div key={index}>{text}</div>
                ))}
            </div>
        </div>
        <hr />
        <div className='display-grid grid-cols-1 grid-cols-2-ls gap-20'>
            <div className='counter color-primary'>
                {[1, 2].map(index => (
                    <div key={index}>{text}</div>
                ))}
            </div>
            <div className='counter color-primary filled'>
                {[1, 2].map(index => (
                    <div key={index}>{text}</div>
                ))}
            </div>
        </div>
        <hr />
        <div className='display-grid grid-cols-1 grid-cols-2-ls gap-20'>
            <div className='counter color-secondary'>
                {[1, 2].map(index => (
                    <div key={index}>{text}</div>
                ))}
            </div>
            <div className='counter color-secondary filled'>
                {[1, 2].map(index => (
                    <div key={index}>{text}</div>
                ))}
            </div>
        </div>
        <hr />
        <div className='display-grid grid-cols-1 grid-cols-3-ls gap-20'>
            <div className='counter size-small'>
                <div>{text}</div>
            </div>
            <div className='counter'>
                <div>{text}</div>
            </div>
            <div className='counter size-large'>
                <div>{text}</div>
            </div>
        </div>
        <hr />
        <div className='display-grid grid-cols-1 grid-cols-3-ls gap-20'>
            <div className='counter size-small'>
                <div data-count='6'>{text}</div>
            </div>
            <div className='counter'>
                <div data-count='3'>{text}</div>
            </div>
            <div className='counter size-large'>
                <div data-count='8'>{text}</div>
            </div>
        </div>
    </div>
);
