import { getClasses } from './mapUtils';

export const getSizeClasses = getClasses;

// These values must always be kept in sync with those of the less folder
// src/themes/RIO/css/mapping/sizes-map.less

export const sizes = {
    headlines: [
        {
            class: 'text-size-h1',
            value: '40',
        },
        {
            class: 'text-size-h2',
            value: '30',
        },
        {
            class: 'text-size-h3',
            value: '26',
        },
        {
            class: 'text-size-h4',
            value: '22',
        },
        {
            class: 'text-size-h5',
            value: '18',
        },
        {
            class: 'text-size-h6',
            value: '14',
        },
    ],
    text: [
        {
            class: 'text-size-10',
            value: '10',
        },
        {
            class: 'text-size-11',
            value: '11',
        },
        {
            class: 'text-size-12',
            value: '12',
        },
        {
            class: 'text-size-14',
            value: '14',
        },
        {
            class: 'text-size-16',
            value: '16',
        },
        {
            class: 'text-size-18',
            value: '18',
        },
        {
            class: 'text-size-20',
            value: '20',
        },
    ],
    lineHeightHeadlines: [
        {
            class: 'line-height-h1',
            value: 'h1',
        },
        {
            class: 'line-height-h2',
            value: 'h2',
        },
        {
            class: 'line-height-h3',
            value: 'h3',
        },
        {
            class: 'line-height-h4',
            value: 'h4',
        },
        {
            class: 'line-height-h5',
            value: 'h5',
        },
        {
            class: 'line-height-h6',
            value: 'h6',
        },
    ],
    lineHeightText: [
        {
            class: 'line-height-10',
            value: '10',
        },
        {
            class: 'line-height-11',
            value: '11',
        },
        {
            class: 'line-height-12',
            value: '12',
        },
        {
            class: 'line-height-14',
            value: '14',
        },
        {
            class: 'line-height-16',
            value: '16',
        },
        {
            class: 'line-height-18',
            value: '18',
        },
        {
            class: 'line-height-20',
            value: '20',
        },
    ],
    lineHeightRelative: [
        {
            class: 'line-height-125rel',
        },
        {
            class: 'line-height-130rel',
        },
        {
            class: 'line-height-135rel',
        },
        {
            class: 'line-height-150rel',
        },
    ],
    px: [
        '0',
        '1',
        '2',
        '3',
        '4',
        '5',
        '10',
        '15',
        '20',
        '25',
        '30',
        '35',
        '40',
        '45',
        '50',
        '60',
        '70',
        '80',
        '90',
        '100',
        '150',
        '200',
        '250',
        '300',
        '350',
        '400',
        '450',
        '500',
        '600',
        '700',
        '800',
        '900',
        '1000',
    ],
    px_0_to_100: [
        '0',
        '1',
        '2',
        '3',
        '4',
        '5',
        '10',
        '15',
        '20',
        '25',
        '30',
        '35',
        '40',
        '45',
        '50',
        '60',
        '70',
        '80',
        '90',
        '100',
    ],
    px_50_to_500: ['50', '60', '70', '80', '90', '100', '150', '200', '250', '300', '350', '400', '450', '500'],
    px_50_to_1000: [
        '50',
        '60',
        '70',
        '80',
        '90',
        '100',
        '150',
        '200',
        '250',
        '300',
        '350',
        '400',
        '450',
        '500',
        '600',
        '700',
        '800',
        '900',
        '1000',
    ],
    pct: ['10', '20', '25', '30', '33', '40', '50', '60', '66', '70', '75', '80', '90', '100'],
    vh: ['25', '50', '75', '100'],
    vw: ['25', '50', '75', '100'],
};
