import type { ReactNode } from 'react';

type CheckboxIconProps = {
    icon: string;
    iconSize: number;
    iconLabelPosition: 'vertical' | 'horizontal';
    text?: string | ReactNode;
};

export const CheckboxIcon = (props: CheckboxIconProps) => {
    const { icon, iconSize, iconLabelPosition, text } = props;

    const iconStyles = {
        width: `${iconSize}px`,
        height: `${iconSize}px`,
        fontSize: `${iconSize}px`,
        lineHeight: `${iconSize}px`,
    };

    return (
        <span className={`checkbox-icon label-${iconLabelPosition}`}>
            <span className={`rioglyph ${icon}`} style={iconStyles} />
            <span className='checkbox-label'>{text}</span>
        </span>
    );
};
