import React, { useContext } from 'react';

import ActionBarItem from '../../../src/ActionBarItem';
import { UikitContext } from '../UikitContext';

export const MenuToggle = () => {
    const { onMenuIconClicked } = useContext(UikitContext);

    return (
        <ActionBarItem id='menuToggle'>
            <ActionBarItem.Icon onClick={onMenuIconClicked}>
                <span className='UikitMenuToggle icon rioglyph rioglyph-menu-hamburger' />
            </ActionBarItem.Icon>
        </ActionBarItem>
    );
};
