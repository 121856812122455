/* eslint-disable new-cap */
import React from 'react';
import classNames from 'classnames';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import isEmpty from 'lodash/fp/isEmpty';
import noop from 'lodash/noop';

import Checkbox from '../checkbox/Checkbox';
import Collapse from '../collapse/Collapse';
import { TableSettingsColumnDetails } from './TableSettingsColumnDetails';
import { TableSettingsColumnButtons } from './TableSettingsColumnButtons';
import type { TableSettingsItemProps } from './TableSettingsDialog.types';

const MAX_COLUMN_WIDTH = 1000;

export const filterColumns = (searchValue: string, column: string, columnLabelStrings: Record<string, string>) => {
    if (!searchValue) {
        return false;
    }
    const label = columnLabelStrings[column] || '';
    return !label.toLowerCase().includes(searchValue.toLowerCase());
};

export type TableSettingsListItemProps = TableSettingsItemProps & {
    column: string;
    orderIndex: number;
    isActive: boolean;
    className?: string;
};

const TableSettingsListItem = (props: TableSettingsListItemProps) => {
    const {
        column,
        orderIndex,
        columnLabels,
        autoLabel,
        disabledColumns = [],
        columnOrder,
        hiddenColumns = [],
        columnSearchValue,
        columnsDetails,
        columnLabelStrings = {},
        openColumnsDetails = {},
        updateColumnLabelStrings,
        onColumnWidthChange,
        onResetColumnWidth,
        onMoveColumn,
        onOpenDetails,
        onToggleHideColumn = noop,
        isActive = false,
        className,
    } = props;

    const isSortingDisabled = !isEmpty(columnSearchValue);

    const { attributes, listeners, setNodeRef, setActivatorNodeRef, transform, transition } = useSortable({
        id: column,
        disabled: isSortingDisabled,
    });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    // Filter out items which don't match the search value.
    // Note that we need to render all items at least once at the beginning in order to get their DOM labels
    // otherwise the search won't work when initial search value is provided via props.
    const isFiltered = filterColumns(columnSearchValue, column, columnLabelStrings) && !updateColumnLabelStrings;

    const itemClassNames = classNames(
        'table-settings-item',
        updateColumnLabelStrings && 'opacity-0',
        isFiltered && 'position-offscreen',
        className
    );

    const columnDetails = columnsDetails[column];

    return (
        <div className={itemClassNames} key={`table-settings-item-${column}`} ref={setNodeRef} style={style}>
            <div className='table-settings-item-header user-select-none' ref={setActivatorNodeRef}>
                <div className='CheckboxWrapper display-flex align-items-center padding-left-2'>
                    <Checkbox
                        checked={!hiddenColumns.includes(column)}
                        onClick={event => {
                            onToggleHideColumn(column);
                            event.stopPropagation();
                        }}
                        disabled={disabledColumns.includes(column)}
                    />
                </div>
                <div
                    className={`table-settings-item-label ${isSortingDisabled ? 'no-drag' : ''}`}
                    data-key={column}
                    {...attributes}
                    {...listeners}
                >
                    {columnLabels[column]}
                </div>
                {columnDetails && (
                    <div className='column-width-label'>
                        {columnDetails.width ? `${columnDetails.width}px` : autoLabel}
                    </div>
                )}
                <TableSettingsColumnButtons
                    column={column}
                    index={orderIndex}
                    columnDetails={columnDetails}
                    columnOrder={columnOrder}
                    openColumnsDetails={openColumnsDetails}
                    disabled={isSortingDisabled}
                    onMoveColumn={onMoveColumn}
                    onOpenDetails={onOpenDetails}
                />
            </div>
            {columnDetails && !isActive && (
                <Collapse open={!!openColumnsDetails[column]}>
                    <div>
                        <TableSettingsColumnDetails
                            {...columnDetails}
                            column={column}
                            maxColumnWidth={MAX_COLUMN_WIDTH}
                            onColumnWidthChange={onColumnWidthChange}
                            onResetColumnWidth={onResetColumnWidth}
                        />
                    </div>
                </Collapse>
            )}
        </div>
    );
};

export default TableSettingsListItem;
