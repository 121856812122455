// @ts-ignore-next-line importsNotUsedAsValues
import React, { type ComponentProps, type PropsWithChildren } from 'react';
import classNames from 'classnames';

export type DialogBodyProps = ComponentProps<'div'> & {
    /**
     * Additional classes for the modal-body element.
     */
    className?: string;
};

const DialogBody = ({ className = '', children, ...remainingProps }: PropsWithChildren<DialogBodyProps>) => {
    const classes = classNames('modal-body', className);
    return (
        <div {...remainingProps} className={classes} aria-label='dialog body'>
            {children}
        </div>
    );
};

export default DialogBody;
