import React, { type ReactNode, useEffect, useState } from 'react';
import { Nav } from 'react-bootstrap';
import classNames from 'classnames';

export type SteppedProgressBarLabel = {
    /**
     * The element shown in the step element.
     *
     * This can be a single step number or an icon element.
     */
    icon?: string | ReactNode;

    /**
     * The label shown below the step element.
     */
    label?: string | ReactNode;

    /**
     * Additional classes to be set on the label element.
     */
    labelClassName?: string;

    /**
     * Additional classes to be set on the label wrapping element.
     */
    labelWrapperClassName?: string;
};

export type SteppedProgressBarProps = {
    /**
     * Objects to defining the icon and the label of the navigation items.
     *
     * The icon can be a string or an icon.
     */
    labels: SteppedProgressBarLabel[];

    /**
     * Index of the selected navigation item.
     */
    selectedStepNumber: number;

    /**
     * Called with the new selected index after the user clicked a nav item.
     */
    onSelectedChanged: (selectedKey: number) => void;

    /**
     * The SteppedProgressBar Variant.
     *
     * One of "circle", "rectangle" or "roadsign".
     *
     * @default 'circle'
     */
    variant?: 'circle' | 'rectangle' | 'roadsign';

    /**
     * All navigation items with a higher index than this will be disabled.
     */
    disableFollowingPages?: number;

    /**
     * Deactivating the wrapping of bubbles on mobile.
     *
     * @default true
     */
    mobileBreakpoint?: boolean;

    /**
     * Additional classes to be set on the wrapper element.
     */
    className?: string;
};

const SteppedProgressBar = (props: SteppedProgressBarProps) => {
    const {
        labels,
        selectedStepNumber,
        onSelectedChanged,
        variant = 'circle',
        disableFollowingPages: disableFollowingPageIndex,
        mobileBreakpoint = true,
        className = '',
    } = props;

    const [disableFollowingPages, setDisableFollowingPages] = useState(
        disableFollowingPageIndex ? disableFollowingPageIndex : selectedStepNumber
    );

    useEffect(() => {
        if (disableFollowingPageIndex !== undefined) {
            if (disableFollowingPageIndex < selectedStepNumber) {
                console.warn(
                    'Invalid component state detected in SteppedProgressBar: can not disable following ' +
                        `pages ${disableFollowingPageIndex} when selectedStep is ${selectedStepNumber}!`
                );
                return;
            }

            setDisableFollowingPages(disableFollowingPageIndex);
        } else if (selectedStepNumber > disableFollowingPages) {
            setDisableFollowingPages(selectedStepNumber);
        }
    }, [disableFollowingPageIndex, selectedStepNumber]);

    const wrapperClasses = classNames(
        'stepped-progress-bar',
        variant && `variant-${variant}`,
        mobileBreakpoint && 'mobile-breakpoint',
        className && className
    );

    const handleSelect = (selectedKey: string | null) => onSelectedChanged(Number(selectedKey));

    return (
        <Nav className={wrapperClasses} activeKey={selectedStepNumber} onSelect={handleSelect}>
            {labels.map((labelObject, index) => (
                <Nav.Link
                    key={index}
                    eventKey={index}
                    disabled={index > disableFollowingPages}
                    as='a'
                    className={classNames('step-tab', labelObject.labelWrapperClassName)}
                >
                    <div className='step-tab-content'>
                        <div className='item-icon'>{labelObject.icon}</div>
                        <div className={classNames('item-label', labelObject.labelClassName)}>{labelObject.label}</div>
                    </div>
                </Nav.Link>
            ))}
        </Nav>
    );
};

SteppedProgressBar.VARIANT_CIRCLE = 'circle' as const;
SteppedProgressBar.VARIANT_RECTANGLE = 'rectangle' as const;
SteppedProgressBar.VARIANT_ROADSIGN = 'roadsign' as const;

export default SteppedProgressBar;
