import { Playground } from '../../../components/playground/Playground';
import {
    PlaygroundClasses,
    PlaygroundResponsiveClasses,
    PlaygroundPrintClasses,
    PlaygroundClassesSpacer,
} from '../../../components/playground/PlaygroundClasses';
import { Description } from '../../../components/Description';

import { sizes } from '../../../utils/sizes';

export const Width = () => (
    <div>
        <Description headline='Width' anchor='width' />
        <Playground example={widthExample} classes={widthClasses} />
    </div>
);

const widthExample = (
    <>
        <div className='display-flex flex-wrap gap-20 margin-bottom-25'>
            {sizes.px_0_to_100.map((size, index) => (
                <div className={`bg-info rounded height-20 text-center width-${size}`} key={index}>
                    {Number(size) >= 30 ? `${size}px` : ''}
                </div>
            ))}
        </div>
        <div className='display-grid grid-cols-1 gap-5'>
            {sizes.pct.map((size, index) => (
                <div className={`bg-info rounded padding-5 width-${size}pct`} key={index}>
                    {size}pct
                </div>
            ))}
        </div>
    </>
);

const widthClasses = (
    <>
        <PlaygroundClasses label='Resetting' classes={['width-auto', 'min-width-0', 'min-width-auto']} />
        <PlaygroundClassesSpacer label='Pixel values' />
        <PlaygroundClasses label='Width' prefix='width-' classes={sizes.px} />
        <PlaygroundClasses label='Min width' prefix='min-width-' classes={sizes.px_50_to_500} />
        <PlaygroundClasses label='Max width' prefix='max-width-' classes={['none', '0', ...sizes.px_50_to_500]} />
        <PlaygroundClassesSpacer label='Percentage values' />
        <PlaygroundClasses label='Width' prefix='width-' classes={sizes.pct} suffix='pct' />
        <PlaygroundClasses label='Min width' prefix='min-width-' classes={sizes.pct} suffix='pct' />
        <PlaygroundClasses label='Max width' prefix='max-width-' classes={sizes.pct} suffix='pct' />
        <PlaygroundClassesSpacer label='Viewport values' />
        <PlaygroundClasses label='Width' prefix='width-' classes={sizes.vw} suffix='vw' />
        <PlaygroundClasses label='Min width' prefix='min-width-' classes={sizes.vw} suffix='vw' />
        <PlaygroundClasses label='Max width' prefix='max-width-' classes={sizes.vw} suffix='vw' />
        <PlaygroundResponsiveClasses />
        <PlaygroundPrintClasses />
    </>
);
