import { useCallback, useState } from 'react';

import { THEME_DARK, THEME_DATA_ATTRIBUTE } from '../utils/colorScheme';
import useEffectOnce from './useEffectOnce';
import useMutationObserver from './useMutationObserver';

/**
 * Custom hook to detect if dark mode is enabled.
 *
 * @returns A boolean indicating whether dark mode is enabled.
 */
const useDarkMode = (): boolean => {
    const [isDarkMode, setIsDarkMode] = useState(false);

    // Check if dark mode is enabled on initial render
    useEffectOnce(() => {
        const html = document.documentElement;

        // Check if the HTML element has the dark mode theme attribute
        if (html.getAttribute(THEME_DATA_ATTRIBUTE) === THEME_DARK) {
            setIsDarkMode(true);
        }
    });

    const handleMutation = useCallback((mutationList: MutationRecord[]) => {
        mutationList.map((mutation: MutationRecord) => {
            // Check if the mutation is related to the theme attribute
            if (mutation.type === 'attributes' && mutation.attributeName === THEME_DATA_ATTRIBUTE) {
                const scheme = (mutation.target as HTMLElement).getAttribute(THEME_DATA_ATTRIBUTE);

                // Update dark mode status based on the theme attribute value
                setIsDarkMode(scheme === THEME_DARK);
            }
        });
    }, []);

    // Observe mutations in the theme attribute using MutationObserver
    useMutationObserver(document.documentElement, handleMutation, {
        attributes: true,
        childList: false,
        subtree: false,
    });

    return isDarkMode;
};

export default useDarkMode;
