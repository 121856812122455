import { useEffect, useRef } from 'react';

// See https://overreacted.io/making-setinterval-declarative-with-react-hooks/

/**
 * Custom hook to execute a callback function at specified intervals.
 *
 * @param callback - The callback function to be executed.
 * @param delay - The interval duration in milliseconds. Set to null to stop the interval.
 *
 * @returns A mutable ref object that holds the interval ID.
 */
const useInterval = (callback: () => void, delay: number | null): React.MutableRefObject<number | null> => {
    const intervalRef = useRef<number | null>(null);
    const savedCallback = useRef<() => void>(callback);

    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {
        const tick = () => savedCallback.current();

        if (typeof delay === 'number') {
            intervalRef.current = window.setInterval(tick, delay);
            return () => window.clearInterval(intervalRef.current ?? undefined);
        }
    }, [delay]);

    return intervalRef;
};

export default useInterval;
