import React, { useState } from 'react';

const columns = [...Array(4)];

const rowData = Array.from({ length: 8 }, () => ({
    id: crypto.randomUUID(),
}));

const extendedRowData = Array.from({ length: 3 }, () => ({
    id: crypto.randomUUID(),
}));

const TableGroupedExample = () => {
    const [expandedRows, setExpandedRows] = useState<string[]>([rowData[5].id]);

    const handleToggleRow = (id: string) => {
        if (expandedRows.includes(id)) {
            setExpandedRows(expandedRows.filter(expanded => expanded !== id));
        } else {
            setExpandedRows([...expandedRows, id]);
        }
    };

    return (
        <div className='table-responsive'>
            <table className='table table-head-filled'>
                <thead>
                    <tr>
                        {columns.map(() => (
                            <th key={crypto.randomUUID()}>Head column</th>
                        ))}
                        <th className='table-action' />
                    </tr>
                </thead>
                <tbody>
                    {rowData.map(row => {
                        const isExpanded = expandedRows.includes(row.id);
                        return (
                            <React.Fragment key={row.id}>
                                <tr className='compactRow'>
                                    {columns.map(() => (
                                        <td key={crypto.randomUUID()}>
                                            <span>CompactRow column</span>
                                        </td>
                                    ))}
                                    <td className='table-action' onClick={() => handleToggleRow(row.id)}>
                                        <span>
                                            <span className='btn btn-muted btn-icon-only'>
                                                <span
                                                    className={`rioglyph ${
                                                        isExpanded ? 'rioglyph-chevron-up' : 'rioglyph-chevron-down'
                                                    }`}
                                                />
                                            </span>
                                        </span>
                                    </td>
                                </tr>

                                {isExpanded &&
                                    extendedRowData.map(() => (
                                        <tr className='extendedRow' key={crypto.randomUUID()}>
                                            {columns.map(() => (
                                                <td key={crypto.randomUUID()}>
                                                    <span>ExtendedRow column</span>
                                                </td>
                                            ))}
                                            <td className='table-action' />
                                        </tr>
                                    ))}
                            </React.Fragment>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};

export default TableGroupedExample;
