import type { ReactNode } from 'react';
import isArray from 'lodash/fp/isArray';
import isObject from 'lodash/fp/isObject';
import isString from 'lodash/fp/isString';
import cond from 'lodash/fp/cond';
import { Link } from 'react-router-dom';

import { findByName } from '../../utils/mapUtils';
import { breakpoints, getBreakpointNames } from '../../utils/breakpoints';

const eachBreakpoint = getBreakpointNames(breakpoints) as string[];

type PlaygroundClassesSpacerProps = {
    label: string;
    guidelinesLink?: string;
};

export const PlaygroundClassesSpacer = ({ label, guidelinesLink }: PlaygroundClassesSpacerProps) => (
    <div className='border border-bottom-only margin-bottom-20'>
        <label className='text-size-16'>{label}</label>
        {guidelinesLink && (
            <>
                <span className='margin-x-5'>|</span>
                <Link className='text-size-16' to={guidelinesLink}>
                    Guidelines
                </Link>
            </>
        )}
    </div>
);

type PlaygroundClassesProps = {
    label?: ReactNode;
    prefix?: string;
    suffix?: ReactNode | string[];
    classes: string | string[] | ReactNode;
};

export const PlaygroundClasses = (props: PlaygroundClassesProps) => {
    const { label, classes, prefix, suffix } = props;

    const renderString = (value: string) => <code>{`${value}`}</code>;
    const renderNode = (value: ReactNode) => value;
    const renderList = (value: string[]) =>
        value.map((color, index) => (
            <code key={index}>
                {prefix && prefix}
                {color}
                {suffix && suffix}
            </code>
        ));

    const content: ReactNode = cond<string | string[] | ReactNode, ReactNode>([
        [isString, renderString as any],
        [isArray, renderList as any],
        [isObject, renderNode],
    ])(classes);

    return (
        <div className='playground-classes'>
            {label && <div className='playground-classes-label'>{label}</div>}
            <div className='playground-classes-list-wrapper'>
                <div className='playground-classes-list'>{content}</div>
            </div>
        </div>
    );
};

type PlaygroundResponsiveClassesProps = {
    affixInside?: boolean;
    showHeader?: boolean;
    valueMap?: string[];
};

export const PlaygroundResponsiveClasses = (props: PlaygroundResponsiveClassesProps) => {
    const { affixInside = false, valueMap = eachBreakpoint, showHeader = true } = props;

    return (
        <>
            {showHeader && (
                <PlaygroundClassesSpacer label='Breakpoint related classes' guidelinesLink='/start/responsiveness' />
            )}

            <div className='playground-classes'>
                <div className='playground-classes-label'>Responsive</div>
                <div className='playground-classes-list-wrapper'>
                    <div className='playground-classes-list'>
                        {valueMap.map((value, index) => (
                            <code key={index} className='width-90 text-center'>
                                {affixInside ? `...-${value}-...` : `...-${value}`}
                            </code>
                        ))}
                    </div>
                </div>
            </div>

            <div className='playground-classes'>
                <div className='playground-classes-label'>Range</div>
                <div className='playground-classes-list-wrapper'>
                    <div className='playground-classes-list'>
                        {eachBreakpoint.map((breakpointName, index) => (
                            <code
                                key={index}
                                className='display-flex align-items-center justify-content-center width-90'
                            >
                                <span>{findByName(breakpointName, breakpoints).minWidth}</span>
                                <span className='margin-x-5'>to</span>
                                {affixInside ? (
                                    <span className='text-size-18 line-height-18 text-light'>∞</span>
                                ) : (
                                    <span>
                                        {breakpointName === 'xs' ? (
                                            findByName(breakpointName, breakpoints).nextBreakpointSize
                                        ) : (
                                            <span className='text-size-18 line-height-18 text-light'>∞</span>
                                        )}
                                    </span>
                                )}
                            </code>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
};

export const PlaygroundNegativeClasses = () => (
    <>
        <PlaygroundClassesSpacer label='Negative values' />
        <div className='playground-classes'>
            <div className='playground-classes-label'>Negative</div>
            <div className='playground-classes-list-wrapper'>
                <div className='playground-classes-list'>
                    <span>These classes are also available with negative values by adding a second</span>
                    <code className='node-inline'>-</code>in front of the value.
                </div>
            </div>
        </div>
    </>
);

export const PlaygroundPrintClasses = () => (
    <>
        <PlaygroundClassesSpacer label='Print view' guidelinesLink='/start/guidelines/print-css' />
        <div className='playground-classes'>
            <div className='playground-classes-label'>Print</div>
            <div className='playground-classes-list-wrapper'>
                <div className='playground-classes-list'>
                    <code>...-print</code>
                </div>
            </div>
        </div>
    </>
);

type PlaygroundNavClassesProps = {
    showNavVariants?: boolean;
};

export const PlaygroundNavClasses = ({ showNavVariants = false }: PlaygroundNavClassesProps) => (
    <>
        <PlaygroundClassesSpacer label='Nav Basics' />
        <PlaygroundClasses label='Wrapper' classes='nav' />
        {showNavVariants && (
            <PlaygroundClasses label='Variants' classes={['nav-stacked', 'nav-justified', 'nav-justified-word-wrap']} />
        )}
    </>
);
