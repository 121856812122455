// @ts-ignore-next-line importsNotUsedAsValues
import React, { type ChangeEvent } from 'react';
import classNames from 'classnames';
import noop from 'lodash/noop';

import Slider from '../slider/Slider';
import type { TableColumnDetails, TableColumnDetailsMap } from './TableSettingsDialog.types';

export type TableSettingsColumnDetailsProps = TableColumnDetails & {
    column: string;
    maxColumnWidth: number;
    onColumnWidthChange?: (column: keyof TableColumnDetailsMap, value: number) => void;
    onResetColumnWidth?: (column: keyof TableColumnDetailsMap) => void;
};

export const TableSettingsColumnDetails = (props: TableSettingsColumnDetailsProps) => {
    const {
        width = 0,
        defaultWidth = 0,
        maxWidth,
        column,
        maxColumnWidth,
        onColumnWidthChange = noop,
        onResetColumnWidth = noop,
    } = props;

    const handleWidthInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        const parsedValue = event.target.value ? Number.parseInt(event.target.value, 10) : 0;
        const max = maxWidth || maxColumnWidth;
        const value = Math.min(parsedValue, max);
        onColumnWidthChange(column, value);
    };

    const handleColumnWidthChange = (value: number) => onColumnWidthChange(column, value);

    const handleResetColumnWidth = () => onResetColumnWidth(column);

    const resetWidthButtonClassNames = classNames(
        'btn',
        'btn-muted',
        'btn-icon-only',
        'btn-sm',
        'margin-left-10',
        width === defaultWidth && 'disabled'
    );

    return (
        <div className='table-settings-item-body'>
            <Slider
                className='margin-bottom-0'
                value={width}
                minValue={0}
                maxValue={maxWidth || maxColumnWidth}
                step={1}
                onChange={handleColumnWidthChange}
            />
            <div className={resetWidthButtonClassNames} role='button' onClick={handleResetColumnWidth}>
                <span className='rioglyph rioglyph-revert' />
            </div>
            <div className='column-width-input'>
                <div className='input-group'>
                    <input
                        className='form-control text-right padding-right-5 no-controls'
                        type='number'
                        value={width || ''}
                        min={0}
                        max={maxWidth || maxColumnWidth}
                        onChange={handleWidthInputChange}
                    />
                    <div className='input-group-addon'>px</div>
                </div>
            </div>
        </div>
    );
};
