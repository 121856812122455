import React from 'react';
import classNames from 'classnames';

const FOUR_BY_THREE = '4by3';
const SIXTEEN_BY_NINE = '16by9';

export type AspectRatio = typeof FOUR_BY_THREE | typeof SIXTEEN_BY_NINE;

export type ResponsiveVideoProps = {
    /**
     * The link to the source of the video file.
     */
    src: string;

    /**
     * Defined the aspect ratio of the video.
     * @default `16by9`
     *
     * Possible values are:
     * `ResponsiveVideo.ASPECT_RATIO_4_BY_3`, `ResponsiveVideo.ASPECT_RATIO_16_BY_9` or
     * `4by3`, `16by9`.
     */
    aspectRatio: '4by3' | '16by9';

    /**
     * Allows the fullscreen feature of the underlying embedded iframe.
     * @default true
     */
    allowFullScreen?: boolean;

    /**
     * Additional classes added to the wrapping element.
     */
    className?: string;
};

const ResponsiveVideo = (props: ResponsiveVideoProps) => {
    const { src, className = '', allowFullScreen = true, aspectRatio = '16by9' } = props;

    const wrapperClassName = classNames('video-responsive', `video-responsive-${aspectRatio}`, className && className);

    return (
        <div className={wrapperClassName}>
            <iframe src={src} allowFullScreen={allowFullScreen} />
        </div>
    );
};

ResponsiveVideo.ASPECT_RATIO_4_BY_3 = FOUR_BY_THREE as typeof FOUR_BY_THREE;
ResponsiveVideo.ASPECT_RATIO_16_BY_9 = SIXTEEN_BY_NINE as typeof SIXTEEN_BY_NINE;

export default ResponsiveVideo;
