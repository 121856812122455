import React, { type ForwardedRef, type HTMLAttributes, forwardRef, useEffect, useState } from 'react';

import useInterval from '../../hooks/useInterval';

const TIMER = 10;

export type AnimatedNumberProps = HTMLAttributes<HTMLSpanElement> & {
    /**
     * The start value.
     */
    start: number;

    /**
     * The end value.
     */
    end: number;

    /**
     * A prefix to be added to the final string.
     */
    prefix?: string;

    /**
     * A unit suffix to be added to the final string.
     */
    unit?: string;

    /**
     * The speed in milliseconds to count up or down.
     *
     * @default 10
     */
    speed?: number;

    /**
     * Enables to count backwards.
     *
     * @default false
     */
    decreasing?: boolean;

    /**
     * Callback function to be invoked when the end value is reached.
     */
    onEnd?: VoidFunction;

    /**
     * Additional classes to be set on the wrapping element.
     */
    className?: string;
};

export const AnimatedNumber = forwardRef((props: AnimatedNumberProps, ref: ForwardedRef<HTMLSpanElement>) => {
    const {
        start,
        end,
        prefix = '',
        unit = '',
        speed = TIMER,
        decreasing = false,
        onEnd = () => {},
        className = '',
        ...remainingProps
    } = props;

    const [value, setValue] = useState(start);

    const shallContinueRunning = decreasing ? value > end : value < end;

    useInterval(
        () => {
            setValue(currentValue => (decreasing ? currentValue - 1 : currentValue + 1));
        },
        shallContinueRunning ? speed : null
    );

    useEffect(() => {
        if (!shallContinueRunning) {
            onEnd();
        }
    });

    return <span ref={ref} {...remainingProps} className={className}>{`${prefix}${value}${unit}`}</span>;
});

export default AnimatedNumber;
