export const UP = 'up';
export const DOWN = 'down';

// Scroll to selected dropdown menu-item in case the item is not visible due to overflow
export const scrollItemIntoView = (
    direction: string,
    dropDownDOMNode: HTMLElement | null,
    focusedItemDOMNode: HTMLElement | null | undefined
) => {
    if (!dropDownDOMNode || !focusedItemDOMNode) {
        return;
    }

    const menuHeight = dropDownDOMNode.clientHeight;
    const itemHeight = focusedItemDOMNode.clientHeight;
    const itemOffsetTop = focusedItemDOMNode.offsetTop;

    switch (direction) {
        case UP:
            if (itemOffsetTop < dropDownDOMNode.scrollTop) {
                dropDownDOMNode.scrollTop = dropDownDOMNode.scrollTop - itemHeight;
            }
            break;
        case DOWN:
            if (itemOffsetTop > menuHeight - itemHeight) {
                dropDownDOMNode.scrollTop = dropDownDOMNode.scrollTop + itemHeight;
            }
            break;
        default:
            break;
    }
};
