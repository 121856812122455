export const handleDetachWindow = (
    contentRef: React.RefObject<HTMLDivElement>,
    playgroundRef: React.RefObject<HTMLDivElement>
) => {
    if (!contentRef.current || !playgroundRef.current) return;

    // Open a new tab by omitting the size and position options
    const newWindow = window.open('', '_blank');

    if (newWindow) {
        const headContent = document.head.innerHTML;

        // Construct the Slack URL
        const slackChannel = 'C8T2WSZJ9';
        const slackUser = 'T0FJQS8J1';
        const slackURL = `https://app.slack.com/client/${slackUser}/${slackChannel}/new-message`;

        newWindow.document.write(`
            <!DOCTYPE html>
            <html lang="en" class="bg-white">
            <head>
                ${headContent}
            </head>
            <body class="height-100vh bg-white">
                <div id="detached-content">
                    <div class="padding-x-10 bg-lightest border border-bottom-only display-flex align-items-center justify-content-between height-60">
                        <button id="open-figma" class="btn btn-primary">
                            <span class="rioglyph rioglyph-question"></span>
                            How to use the UIKIT in Figma
                        </button>
                        <a class="btn btn-muted" href="${slackURL}" target="_blank">
                            <span class="rioglyph rioglyph-damages"></span>
                            Report broken import of the current view
                        </a>
                    </div>
                    <div class="padding-15">${contentRef.current.innerHTML}</div>
                </div>
                <script>
                    document.getElementById('open-figma').addEventListener('click', function() {
                        const figmaTemplateFileID = "wo3QaivIbsFk1UYbHt54QM";  // Replace with your actual template file ID
                        const figmaURL = \`https://www.figma.com/file/\${figmaTemplateFileID}\`;
                        window.open(figmaURL, '_blank');
                    });
                </script>
            </body>
            </html>
        `);

        newWindow.document.close();
    }
};
