import ExpanderPanel from '../../../../../src/ExpanderPanel';

const TablePanelExample = () => {
    return (
        <ExpanderPanel
            open
            title={
                <table className='table table-layout-fixed width-100pct'>
                    <thead>
                        <tr>
                            <td colSpan={2}>
                                <b>Panel header table</b>
                            </td>
                            <td>
                                <div>
                                    <span className='rioglyph rioglyph-volume margin-right-3' />
                                    <span>Lorem ipsum dolor</span>
                                </div>
                            </td>
                            <td>Dolor sit</td>
                            <td>Amet</td>
                        </tr>
                    </thead>
                </table>
            }
            headerClassName='padding-0 position-relative'
            iconClassName='position-absolute top-0 right-15 height-100pct display-flex align-items-center'
            titleClassName='width-100pct margin-0 overflow-hidden'
            bodyClassName='padding-0'
            className='shadow-default overflow-hidden'
        >
            <table className='table table-head-filled table-layout-fixed'>
                <thead>
                    <tr>
                        {[...Array(5)].map(() => (
                            <th key={crypto.randomUUID()} className='ellipsis-1'>
                                Head column
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td colSpan={2}>Lorem ipsum</td>
                        <td>
                            <div>
                                <span className='rioglyph rioglyph-volume margin-right-3' />
                                <span>2 Lorems</span>
                            </div>
                        </td>
                        <td />
                        <td />
                    </tr>
                    <tr>
                        <td colSpan={2}>Dolor sit amet</td>
                        <td>
                            <div>
                                <span className='rioglyph rioglyph-volume margin-right-3' />
                                <span>1 Lorem</span>
                            </div>
                        </td>
                        <td />
                        <td />
                    </tr>
                </tbody>
            </table>
        </ExpanderPanel>
    );
};

export default TablePanelExample;
