import { useCallback, useEffect, useRef } from 'react';

type KeyboardEventType = 'keyup' | 'keydown' | 'keypress';

/**
 * Custom hook to handle keyboard events.
 *
 * @param keyOrCallback - Either a key string (e.g., 'Enter') or a callback function.
 * @param callbackOrEventTypes - If the first argument is a key, this should be the callback function.
 *                               If the first argument is a callback, this is an optional array of event types.
 * @param [eventTypesOrNode=['keydown']] - If the first argument is a key, this should be an array of event types.
 *                                         If the first argument is a callback, this is the node (defaults to document).
 * @param [node=document] - Optional DOM node to attach the event listener to (defaults to document).
 */
const useKey = (
    keyOrCallback: string | ((event: KeyboardEvent) => void),
    callbackOrEventTypes?: (event: KeyboardEvent) => void | KeyboardEventType[],
    eventTypesOrNode: KeyboardEventType[] | Document = ['keydown'],
    node: Document | HTMLElement = document
) => {
    const callbacksRef = useRef<((event: KeyboardEvent) => void)[]>([]);

    useEffect(() => {
        const isKeyMode = typeof keyOrCallback === 'string';

        const callback = isKeyMode
            ? (callbackOrEventTypes as (event: KeyboardEvent) => void)
            : (keyOrCallback as (event: KeyboardEvent) => void);

        const keyHandler = (event: KeyboardEvent) => {
            if (isKeyMode) {
                if (event.key === keyOrCallback) {
                    callback(event);
                }
            } else {
                callback(event);
            }
        };

        // Register the keyHandler
        callbacksRef.current.push(keyHandler);

        const genericHandler = (event: KeyboardEvent) => {
            for (const callBecky of callbacksRef.current) {
                callBecky(event);
            }
        };

        const eventTypes = Array.isArray(eventTypesOrNode) ? (eventTypesOrNode as KeyboardEventType[]) : ['keydown'];

        for (const eventType of eventTypes) {
            node.addEventListener(eventType, genericHandler as EventListener);
        }

        return () => {
            for (const eventType of eventTypes) {
                node.removeEventListener(eventType, genericHandler as EventListener);
            }
            // Clean up callback reference
            callbacksRef.current = callbacksRef.current.filter(singleCallback => singleCallback !== keyHandler);
        };
    }, [keyOrCallback, callbackOrEventTypes, eventTypesOrNode, node]);
};

export default useKey;
