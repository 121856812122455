// @ts-ignore-next-line importsNotUsedAsValues
import React, { type MouseEventHandler, type PropsWithChildren } from 'react';
import classNames from 'classnames';

import StatsWidgetHeader, { type StatsWidgetHeaderProps } from './StatsWidgetHeader';
import StatsWidgetBody, { type StatsWidgetBodyProps } from './StatsWidgetBody';
import StatsWidgetFooter, { type StatsWidgetFooterProps } from './StatsWidgetFooter';

export type StatsWidgetProps = {
    /**
     * Callback to reset the filter.
     */
    onFilterReset?: MouseEventHandler;

    /**
     * Defines whether a filter is active for this widget.
     */
    hasFilter?: boolean;

    /**
     * Additional class names to be added to the element.
     */
    className?: string;
};

// re-export these because they're not exposed on root directly
export type { StatsWidgetHeaderProps, StatsWidgetBodyProps, StatsWidgetFooterProps };

const StatsWidget = (props: PropsWithChildren<StatsWidgetProps>) => {
    const { children, hasFilter = false, onFilterReset = () => {}, className, ...remainingProps } = props;
    const wrapperClasses = classNames('StatsWidget', className);

    return (
        <div {...remainingProps} className={wrapperClasses}>
            {hasFilter && (
                <span
                    className={
                        'bg-light hover-bg-highlight display-flex justify-content-center align-items-center padding-2' +
                        ' rounded-circle position-absolute top-15 right-15 display-block height-20 width-20' +
                        ' cursor-pointer'
                    }
                    onClick={onFilterReset}
                >
                    <span className='rioglyph rioglyph-revert text-color-white text-size-12' />
                </span>
            )}
            {children}
        </div>
    );
};

StatsWidget.Header = StatsWidgetHeader;
StatsWidget.Body = StatsWidgetBody;
StatsWidget.Footer = StatsWidgetFooter;

export default StatsWidget;
