import React, { type PropsWithChildren, useEffect, useRef, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import classNames from 'classnames';
import noop from 'lodash/fp/noop';
import toNumber from 'lodash/fp/toNumber';

import SidebarFooter from './SidebarFooter';
import SidebarBackdrop from './SidebarBackdrop';
import SidebarFullscreenToggle from './SidebarFullscreenToggle';
import SidebarCloseButton from './SidebarCloseButton';
import Resizer from '../resizer/Resizer';
import useWindowResize from '../../hooks/useWindowResize';
import useEsc from '../../hooks/useEsc';
import getWidthInBoundaries from '../../utils/getWidthInBoundaries';
import type { ObjectValues } from '../../utils/ObjectValues';
import SmoothScrollbars from '../smoothScrollbars/SmoothScrollbars';

const DEFAULT_WIDTH = 350;
const DEFAULT_MIN_WIDTH = 100;
const DEFAULT_MAX_WIDTH = 800;
const DEFAULT_SWITCH_MODE_BREAKPOINT = 0;

// Values define the min and max width for the module-content
// where the fluid sidebar automatically changes to "fly" and back.
const MIN_MODULE_CONTENT_WIDTH = 400;
const MAX_MODULE_CONTENT_WIDTH = 800;

const ANIMATION_DURATION = 0.14;
const RESIZE_THROTTLE = 500;

const SidebarPosition = {
    LEFT: 'left',
    RIGHT: 'right',
} as const;

const SidebarMode = {
    MODE_FLY: 'fly',
    MODE_FLUID: 'fluid',
};

type AnimationVariantProps = {
    fromRight: boolean;
    closed?: boolean;
};

const animationVariants = {
    sidebarEnter: ({ fromRight }: AnimationVariantProps) => {
        return {
            x: fromRight ? '100%' : '-100%',
            opacity: 0,
        };
    },
    sidebarVisible: ({ fromRight, closed }: AnimationVariantProps) => {
        return {
            x: !closed ? 0 : fromRight ? '100%' : '-100%',
            opacity: closed ? 0 : 1,
            display: closed ? 'none' : 'block',
        };
    },
    sidebarExit: ({ fromRight }: AnimationVariantProps) => {
        return {
            x: fromRight ? '100%' : '-100%',
            opacity: 0,
        };
    },
};

export type SidebarProps = {
    /**
     * Defines if the component will overlap the body content.
     * @default false
     */
    fly?: boolean;

    /**
     * Defines whether the component is hidden or not.
     * @default false
     */
    closed?: boolean;

    /**
     * Hides the close button. This may be used when the sidebar is always visible and may not be closed.
     * @default false
     */
    disableClose?: boolean;

    /**
     * Callback function triggered when clicking the close icon.
     */
    onClose?: VoidFunction;

    /**
     * The Footer content. For example a save button.
     */
    footer?: string | React.ReactNode;

    /**
     * Additional classes added to the Sidebar footer.
     */
    footerClassName?: string;

    /**
     * Additional buttons to be rendered in the header.
     */
    headerButtons?: React.ReactNode;

    /**
     * Additional classes added to the Sidebar header.
     */
    headerClassName?: string;

    /**
     * Shows a sidebar header border
     */
    showHeaderBorder?: boolean;

    /**
     * Defines the width of the component. The value is set as pixel value as inline style.
     * Note: In the past, the type allowed to pass in a sting value like "400px" but that is not
     * possible anymore due to internal width calculations. To be backwards compatible for non
     * Typescript projects, the width is converted to a number.
     * @default 350
     */
    width?: number;

    /**
     * Defines the minimum width in px of the component that will take effect when resizing.
     * @default 100
     */
    minWidth?: number;

    /**
     * Defines the maximum width in px of the component that will take effect when resizing.
     * @default 800
     */
    maxWidth?: number;

    /**
     * Opens Sidebar in fullscreen, means 100vw.
     * @default false
     */
    openInFullscreen?: boolean;

    /**
     * Callback for when the fullscreen is toggled.
     */
    onFullScreenChange?: VoidFunction;

    /**
     * Enables the fullscreen functionality and shows the fullscreen toggle.
     * @default false
     */
    enableFullscreenToggle?: boolean;

    /**
     * Translated tooltip text for the fullscreen toggle button.
     */
    fullscreenToggleTooltip?: string;

    /**
     * @deprecated Please use `headerButtons` instead. This allows to also add
     * translated tooltips to the next and previous button
     */
    enableNavigationButtons?: boolean;

    /**
     * @deprecated Please use `enableFullscreenToggle` instead.
     */
    disableFullscreen?: boolean;

    /**
     * @deprecated Please use `openInFullscreen` instead.
     */
    fullscreen?: boolean;

    /**
     * Tells the sidebar where it is positioned. This will affect the resizing behavior
     * respectively. Note: the position need to be set properly whe using the resize
     * functionality to know on which side of the sidebar to attach the resizer handle.
     *
     * It is also relevant when the sidebar mode is set to 'fly' to properly animate
     * the sidebar into the view.
     *
     * Possible values are:
     * `left`, `right`, `Sidebar.LEFT` or `Sidebar.RIGHT`.
     *
     * @default 'left'
     */
    position?: ObjectValues<typeof SidebarPosition>;

    /**
     * Defines whether or not the sidebar is resizable.
     * @default false
     */
    resizable?: boolean;

    /**
     * Callback for when the resize is done.
     */
    onResizeEnd?: VoidFunction;

    /**
     * Defines the breakpoint in pixel when the sidebar mode shall be changed. If the window width is lower
     * than the given breakpoint the mode is set to `fly`. If the window width is higher the mode is set
     * to `fluid`. This functionality is disabled by default and will be enabled when defining a breakpoint
     * higher than 0.
     */
    switchModeBreakpoint?: number;

    /**
     * By default the fullscreen mode can be left with "esc". If this is unwanted it can be disabled.
     * @default false
     */
    disableEsc?: boolean;

    /**
     * Defined whether or not a backdrop will be rendered behind the Sidebar to avoid clicks outside.
     * @default false
     */
    hasBackdrop?: boolean;

    /**
     * Defined whether or not the UIKIT SmoothScrollbar is active
     * @default false
     */
    hasSmoothScrollbar?: boolean;

    /**
     * Defined whether or not the backdrop is visible similar to the backdrop for modal dialogs.
     * @default false
     */
    makeBackdropVisible?: boolean;

    /**
     * Callback for when the backdrop is clicked. This comes in handy when handling transient data from
     * the sidebar which need to be saved first and handle clicks outside.
     */
    onBackdropClick?: VoidFunction;

    /**
     * Additional ref added to the Sidebar body.
     */
    bodyRef?: React.MutableRefObject<HTMLDivElement | null>;

    /**
     * Content that will be displayed in the components header.
     */
    title?: string | React.ReactNode;

    /**
     * Additional classes added to the Sidebar title.
     */
    titleClassName?: string;

    /**
     * Additional classes added to the Sidebar backdrop.
     */
    backdropClassName?: string;

    /**
     * Additional classes added to the Sidebar body.
     */
    bodyClassName?: string;

    /**
     * Additional classes added on the wrapper element.
     */
    className?: string;
};

const Sidebar = (props: PropsWithChildren<SidebarProps>) => {
    const {
        fly = false,
        closed = false,
        title = '',
        footer,
        resizable = false,
        position,
        width = DEFAULT_WIDTH,
        minWidth = DEFAULT_MIN_WIDTH,
        maxWidth = DEFAULT_MAX_WIDTH,
        hasBackdrop = false,
        hasSmoothScrollbar = false,
        makeBackdropVisible = false,
        enableFullscreenToggle = false,
        fullscreenToggleTooltip,
        openInFullscreen = false,
        showHeaderBorder = false,
        disableClose = false,
        bodyRef,
        headerButtons,
        switchModeBreakpoint = DEFAULT_SWITCH_MODE_BREAKPOINT,
        onClose = noop,
        onResizeEnd = noop,
        onFullScreenChange = noop,
        onBackdropClick = noop,
        disableEsc = false,
        titleClassName = '',
        bodyClassName = '',
        headerClassName = '',
        footerClassName = '',
        backdropClassName = '',
        className = '',
        children,
        ...remainingProps
    } = props;

    const [internalWidth, setInternalWidth] = useState(toNumber(width));
    const [sidebarMode, setSidebarMode] = useState(fly ? Sidebar.MODE_FLY : Sidebar.MODE_FLUID);
    const [isFullscreen, setIsFullscreen] = useState(openInFullscreen);
    const [isResize, setIsResize] = useState(false);
    const [isSplit, setIsSplit] = useState(false);
    const [isRight, setIsRight] = useState(position === SidebarPosition.RIGHT);

    const sidebarRef = useRef<HTMLDivElement>(null);

    // Used to keep the external reference to the module-content to avoid querying the DOM
    // periodically on window resize
    const moduleContentRef: React.MutableRefObject<HTMLDivElement | null> = useRef(null);

    // Position fallback in case the position property is not defined but the sidebar mode is fly
    // and we want to animate it, we nee to know the direction from where th sidebar is coming from.
    // That's why we check the ApplicationLayoutSidebar class if the sidebar inside is right aligned
    // or not and us this value instead.
    // Note: the position need to be set properly whe using the resize functionality to know on which
    // side of the sidebar to attach the resizer handle.
    useEffect(() => {
        if (sidebarRef.current && sidebarMode === Sidebar.MODE_FLY) {
            const sidebarLayout = sidebarRef.current.parentNode as HTMLDivElement;
            if (sidebarLayout?.className.includes('right')) {
                setIsRight(true);
            }
        }
    }, [sidebarRef.current, sidebarMode]);

    // Keep the previous closed state in order to prevent animation when switching sidebar mode
    const [previousClosed, setPreviousClosed] = useState(closed);
    if (previousClosed !== closed) {
        setPreviousClosed(closed);
    }

    // Update internal width from outside
    const [previousWidth, setPreviousWidth] = useState(width);
    if (previousWidth !== width) {
        console.log('update internal width');
        setInternalWidth(toNumber(width));
        setPreviousWidth(width);
    }

    // Switch between mode "fly" and "fluid"
    useWindowResize(() => adaptSidebarMode(), RESIZE_THROTTLE);

    // Initially check breakpoint after mounting the component
    useEffect(() => adaptSidebarMode(), []);

    // When Sidebar opens, check for available module-content width and
    // switch sidebar mode to fly in case
    useEffect(() => {
        if (!closed) {
            setTimeout(() => adaptSidebarMode());
        }
    }, [closed]);

    useEffect(() => {
        const moduleContent = document.querySelector('.module-content');
        if (moduleContent) {
            moduleContentRef.current = moduleContent as HTMLDivElement;
        }
    }, []);

    // Close the fullscreen with "esc"
    useEsc(() => {
        if (!disableEsc && enableFullscreenToggle && isFullscreen) {
            setIsFullscreen(false);
            onFullScreenChange(false);
        }
    });

    const adaptSidebarMode = () => {
        const moduleContentWidth = moduleContentRef.current?.clientWidth;

        if (fly || switchModeBreakpoint === DEFAULT_SWITCH_MODE_BREAKPOINT) {
            return;
        }

        const isModuleContentTooSmall = moduleContentWidth && moduleContentWidth <= MIN_MODULE_CONTENT_WIDTH;
        const isModuleContentBigEnough = moduleContentWidth && moduleContentWidth > MAX_MODULE_CONTENT_WIDTH;

        const isWindowSmallerThanBreakpoint = window.innerWidth <= switchModeBreakpoint;

        // switch to fly when module-content is less than x pixel and back if it is bigger
        if (isModuleContentTooSmall || isWindowSmallerThanBreakpoint) {
            setSidebarMode(Sidebar.MODE_FLY);
            return;
        }

        // if module-content is bigger and is initially fluid set back to initial fluid state
        if (isModuleContentBigEnough && !isWindowSmallerThanBreakpoint) {
            setSidebarMode(Sidebar.MODE_FLUID);
            return;
        }
    };

    const handleResize = (diff: number) => {
        const wasSplit = isSplit;
        const halfWindowWidth = window.innerWidth * 0.5;
        const usedMaxWidth = maxWidth || halfWindowWidth;

        // Check for sidebar width if it is half the window size. If it was before but the sidebar was resized so it is
        // no longer half window size, set the sidebar with to half window size to avoid jumping sidebar to old width
        setInternalWidth(oldWidth => {
            const updatedWidth = position === Sidebar.RIGHT ? oldWidth + diff : oldWidth - diff;
            const newWidth = getWidthInBoundaries(minWidth, usedMaxWidth, updatedWidth);

            const newIsSplit = newWidth === halfWindowWidth;
            setIsSplit(newIsSplit);

            return wasSplit && !isSplit ? halfWindowWidth : newWidth;
        });

        adaptSidebarMode();
    };

    const handleResizeStart = () => {
        const body = document.body;
        body?.classList.add('pointer-events-none');
        setIsResize(true);
    };

    const handleResizeEnd = () => {
        const body = document.body;
        body?.classList.remove('pointer-events-none');
        setIsResize(false);
        onResizeEnd();
    };

    const handleFullscreenChange = () => {
        const newFullscreenState = !isFullscreen;
        setIsFullscreen(newFullscreenState);
        onFullScreenChange(newFullscreenState);
    };

    const wrapperClasses = classNames(
        'Sidebar',
        className,
        closed && 'closed',
        isFullscreen && 'width-100vw sidebar-fullscreen',
        isSplit && !isFullscreen && 'max-width-50vw width-50vw',
        sidebarMode === SidebarMode.MODE_FLY ? 'fly' : isFullscreen ? 'fly' : 'fluid'
    );

    const headerClassNames = classNames('SidebarHeader', headerClassName, showHeaderBorder && 'show-border');
    const titleClassNames = classNames('SidebarTitle', titleClassName);
    const bodyClassNames = classNames('SidebarBody', bodyClassName);

    const resizeLimitClasses = classNames('SidebarResizeLimit', isResize && 'display-block');

    const resizeIndicatorPosition = maxWidth || window.innerWidth * 0.5;
    const resizeLimitStyle = isRight ? { right: resizeIndicatorPosition } : { left: resizeIndicatorPosition };

    const sidebarContent = (
        <>
            <div className={resizeLimitClasses} style={resizeLimitStyle} />
            <div className='SidebarContent'>
                <div className={headerClassNames}>
                    <div className={titleClassNames}>{title}</div>
                    <div className='SidebarButtons non-printable close'>
                        {headerButtons}
                        {enableFullscreenToggle && (
                            <SidebarFullscreenToggle
                                isFullscreen={isFullscreen}
                                onClick={handleFullscreenChange}
                                tooltip={fullscreenToggleTooltip}
                            />
                        )}
                        {!disableClose && (headerButtons || enableFullscreenToggle) && (
                            <div className='SidebarButtons-spacer' />
                        )}
                        {!disableClose && <SidebarCloseButton onClick={onClose} />}
                    </div>
                </div>
                {hasSmoothScrollbar ? (
                    <SmoothScrollbars slideIn className={bodyClassNames} ref={bodyRef}>
                        {children}
                    </SmoothScrollbars>
                ) : (
                    <div className={bodyClassNames} ref={bodyRef}>
                        {children}
                    </div>
                )}

                <SidebarFooter footer={footer} className={footerClassName} />
            </div>
            {resizable && (
                <Resizer
                    onResizeStart={handleResizeStart}
                    onResize={handleResize}
                    onResizeEnd={handleResizeEnd}
                    direction={Resizer.HORIZONTAL}
                    position={isRight ? Resizer.LEFT : Resizer.RIGHT}
                />
            )}
        </>
    );

    return (
        <>
            {/* @ts-ignore */}
            <AnimatePresence initial exitBeforeEnter>
                <motion.div
                    {...remainingProps}
                    initial={previousClosed && 'sidebarEnter'}
                    animate='sidebarVisible'
                    // Cannot exit animation as sidebar is moved to offscreen by CSS class, means is still mounted
                    exit='sidebarExit'
                    variants={animationVariants}
                    custom={{ fromRight: isRight, closed }}
                    transition={{ duration: ANIMATION_DURATION }}
                    className={wrapperClasses}
                    style={{ width: internalWidth }}
                    ref={sidebarRef}
                >
                    {sidebarContent}
                </motion.div>
            </AnimatePresence>
            {hasBackdrop && !closed && (
                <SidebarBackdrop
                    className={backdropClassName}
                    makeBackdropVisible={makeBackdropVisible}
                    onClick={onBackdropClick}
                />
            )}
        </>
    );
};

Sidebar.LEFT = SidebarPosition.LEFT;
Sidebar.RIGHT = SidebarPosition.RIGHT;

Sidebar.MODE_FLY = SidebarMode.MODE_FLY;
Sidebar.MODE_FLUID = SidebarMode.MODE_FLUID;

export default Sidebar;
