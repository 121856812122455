// @ts-ignore-next-line importsNotUsedAsValues
import React from 'react';

export type DropdownHeaderProps = {
    icon?: React.ReactNode;
    center?: boolean;
    label: string | React.ReactNode;
    className?: string;
};

const DropdownHeader = (props: DropdownHeaderProps) => {
    const { icon, label, center = true, className = '' } = props;
    return (
        <li className={`dropdown-header ${center ? 'center' : ''} ${className}`}>
            <span className='dropdown-header-text'>
                {icon && <span className='margin-right-5'>{icon}</span>}
                {label}
            </span>
        </li>
    );
};

export default DropdownHeader;
