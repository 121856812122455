import { getNames } from './mapUtils';

export const getContainerNames = getNames;

// These values must always be kept in sync with those of the less folder
// src/themes/RIO/css/variables.less

export const containers = {
    ls_to_xl: [
        {
            name: 'ls',
            maxWidth: '440px', // 480px - 40px
        },
        {
            name: 'sm',
            maxWidth: '728px', // 768px - 40px
        },
        {
            name: 'md',
            maxWidth: '952px', // 992px - 40px
        },
        {
            name: 'lg',
            maxWidth: '1160px', // 1200px - 40px
        },
        {
            name: 'xl',
            maxWidth: '1660px', // 1700px - 40px
        },
    ],
};

export const fluidContainers = {
    sm_to_xxl: [
        {
            name: 'small',
            maxWidth: '980px',
        },
        {
            name: 'default',
            maxWidth: '1280px',
        },
        {
            name: 'large',
            maxWidth: '1440px',
        },
        {
            name: 'extra-large',
            maxWidth: '1680px',
        },
        {
            name: 'ultra-large',
            maxWidth: '1920px',
        },
    ],
};
