import { Playground } from '../../../components/playground/Playground';
import { Description } from '../../../components/Description';

export const Code = () => (
    <div>
        <Description headline='Code' anchor='code' />
        <Playground example={code} />
    </div>
);

const code = (
    <>
        <div className='display-grid grid-cols-1 grid-cols-2-ls gap-20 margin-bottom-20'>
            <div className='margin-bottom-10'>
                <code>var test = 'Hello World!';</code>
            </div>

            <div className='margin-bottom-10'>
                This is an inline <code className='code-inline'>Codeblock</code> for use in text.
            </div>
        </div>

        <pre>
            <code>
                {`// This is a JS example
var test = "Hello World";
console.log(test);`}
            </code>
        </pre>
    </>
);
