import { colors, getColorNames } from '../../../utils/colors';
import { getRandomIcon } from '../../../utils/icons';

const eachColor: string[] = getColorNames(colors.brand, colors.status);
const eachSize = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'];

export default () => (
    <div className='btn-toolbar align-items-start'>
        {eachColor.map((color, index) => (
            <button type='button' className={`btn btn-${color} btn-action text-size-${eachSize[index]}`} key={index}>
                <span className={`rioglyph ${getRandomIcon()}`} aria-hidden='true' />
                <span className=''>Click me</span>
            </button>
        ))}
    </div>
);
