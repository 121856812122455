import React, { type ComponentProps } from 'react';
import noop from 'lodash/fp/noop';
import classNames from 'classnames';

import { useDialogContext } from './dialogContext';

export type DialogHeaderProps = Omit<ComponentProps<'div'>, 'title'> & {
    /**
     * The dialog title (can also be a FormattedMessage component).
     */
    title: string | React.ReactNode;

    /**
     * The dialog header subtitle content.
     */
    subtitle?: string | React.ReactNode;

    /**
     * Allows to add additional buttons to the dialog header.
     */
    headerButtons?: React.ReactNode;

    onCloseButtonClick?: () => void;
};

const DialogHeader = (props: DialogHeaderProps) => {
    const { title, subtitle, headerButtons, onCloseButtonClick = noop, ...remainingProps } = props;

    const { onClose, isSmallestDialog, showXsDialogBorders, showCloseButton } = useDialogContext();

    const doShowXsDialogBorders = isSmallestDialog ? showXsDialogBorders : true;

    const doShowCloseButton = isSmallestDialog ? false : showCloseButton;

    const headerClasses = classNames('modal-header', !doShowXsDialogBorders && 'border-bottom-none');
    const headerTextClasses = classNames(
        'modal-header-text',
        !doShowXsDialogBorders && 'padding-bottom-0 width-100pct'
    );

    const handleClose = (event: React.MouseEvent<HTMLButtonElement>) => {
        onCloseButtonClick(event);
        onClose(event);
    };

    return (
        <div {...remainingProps} className={headerClasses} aria-label='dialog header'>
            <div className={headerTextClasses}>
                {subtitle && <div className='modal-header-subtitle'>{subtitle}</div>}
                <div className='modal-header-title'>{title}</div>
            </div>
            <div className='modal-header-buttons'>
                {headerButtons && !isSmallestDialog && (
                    <React.Fragment>
                        {headerButtons}
                        <div className='modal-header-buttons-spacer' />
                    </React.Fragment>
                )}
                {doShowCloseButton && (
                    <button
                        type='button'
                        className='btn btn-icon-only btn-muted modal-header-close close'
                        onClick={handleClose}
                        data-testid='close'
                        aria-label='dialog close button'
                    >
                        <span className='rioglyph rioglyph-remove' aria-hidden='true' />
                    </button>
                )}
            </div>
        </div>
    );
};

export default DialogHeader;
