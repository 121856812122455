import { Playground, PLAYGROUNG_BG_LIGHTER } from '../../../components/playground/Playground';
import {
    PlaygroundClasses,
    PlaygroundResponsiveClasses,
    PlaygroundPrintClasses,
} from '../../../components/playground/PlaygroundClasses';
import { Description } from '../../../components/Description';
import { dummyTextLong } from '../../../utils/data';

export const Overflow = () => (
    <div>
        <Description headline='Overflow' anchor='overflow' />
        <Playground example={overflowExample} classes={overflowClasses} background={PLAYGROUNG_BG_LIGHTER} />
    </div>
);

const overflowExample = (
    <div className='row margin-top-25 margin-bottom-25'>
        <div className='col-12 col-ls-4 margin-bottom-25-xs'>
            <div className='bg-white padding-10 height-100 overflow-hidden'>{dummyTextLong}</div>
        </div>
        <div className='col-12 col-ls-4 margin-bottom-25-xs'>
            <div className='bg-white padding-10 height-100 overflow-x-hidden overflow-y-scroll'>{dummyTextLong}</div>
        </div>
        <div className='col-12 col-ls-4'>
            <div className='bg-white padding-10 height-100 overflow-x-scroll overflow-y-hidden'>
                <div className='width-500'>{dummyTextLong}</div>
            </div>
        </div>
    </div>
);

const overflowClasses = (
    <>
        <PlaygroundClasses label='Auto' prefix='overflow-' classes={['auto', 'x-auto', 'y-auto']} />
        <PlaygroundClasses label='Hidden' prefix='overflow-' classes={['hidden', 'x-hidden', 'y-hidden']} />
        <PlaygroundClasses label='Visible' prefix='overflow-' classes={['visible', 'x-visible', 'y-visible']} />
        <PlaygroundClasses label='Scroll' prefix='overflow-' classes={['scroll', 'x-scroll', 'y-scroll']} />
        <PlaygroundResponsiveClasses />
        <PlaygroundPrintClasses />
    </>
);
