// @ts-ignore-next-line importsNotUsedAsValues
import React from 'react';
import classNames from 'classnames';

export type SidebarFooterProps = {
    footer?: React.ReactNode;
    className?: string;
};

const SidebarFooter = ({ footer, className = '' }: SidebarFooterProps) => {
    if (!footer) {
        return null;
    }

    const footerClassNames = classNames('SidebarFooter', className);

    return <div className={footerClassNames}>{footer}</div>;
};

export default SidebarFooter;
