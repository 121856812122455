import { Playground } from '../../../components/playground/Playground';
import { PlaygroundClasses } from '../../../components/playground/PlaygroundClasses';
import { Description } from '../../../components/Description';

import { dummyTextShort } from '../../../utils/data';

export const UserSelection = () => (
    <div>
        <Description headline='User select' anchor='user-select' />
        <Playground example={userSelectionExample} classes={userSelectionClasses} />
    </div>
);

const userSelectionExample = (
    <div className='display-grid grid-cols-2 gap-20'>
        <div className='user-select-all'>
            <code className='code-inline-first'>user-select-all</code>
            {dummyTextShort}
        </div>
        <div className='user-select-none'>
            <code className='code-inline-first'>user-select-none</code>
            {dummyTextShort}
        </div>
    </div>
);

const userSelectionClasses = <PlaygroundClasses prefix='user-select-' classes={['all', 'none']} />;
