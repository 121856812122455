import React, { forwardRef, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import noop from 'lodash/noop';

import useAfterMount from '../../hooks/useAfterMount';
import useElementSize from '../../hooks/useElementSize';
import usePrevious from '../../hooks/usePrevious';
import {
    hasAlignItemsClass,
    hasAlignSelfClass,
    hasBgClass,
    hasBorderClass,
    hasCursorClass,
    hasDisplayClass,
    hasFlexClass,
    hasHoverScaleClass,
    hasHoverTextColorClass,
    hasOverflowClass,
    hasPaddingClass,
    hasRoundedClass,
    hasSpaceClass,
    hasTextColorClass,
    hasTextSizeClass,
    hasUserSelectClass,
} from '../../utils/hasUtilityClass';

const DEFAULT_MIN_WITH = 200;
const DEFAULT_MIN_DAYS = 1;
const DEFAULT_MAX_DAYS = 7;

const ANIMATION_DURATION = 0.2;
const ANIMATION_NEXT = 'page';
const ANIMATION_BACK = 'pageBack';

const ITEM_NAME = 'ColumnItem';

const variants = {
    pageEnter: (pageDirection: typeof ANIMATION_NEXT | typeof ANIMATION_BACK) => ({
        x: pageDirection === ANIMATION_NEXT ? '60%' : '-60%',
        opacity: 0,
    }),
    pageCenter: () => ({ x: 0, opacity: 1 }),
};

const getDateString = (date: Date) => date.toISOString().split('T').at(0) as string;

const getFirstColumnItem = (node: Element): Element => {
    if ([...node.classList].includes(ITEM_NAME)) {
        return node;
    }
    return getFirstColumnItem(node.children[0]);
};

export type CalendarStripeProps = {
    /**
     * The minimum width in pixel of a single day column
     *
     * This value determines how many days are shown per page depending on the parent width.
     *
     * @default 200
     */
    minDayWith?: number;

    /**
     * The minimum amount of days that should be shown per page.
     *
     * @default 1
     */
    minDays?: number;

    /**
     * The maximum amount of days that should be shown per page.
     *
     * @default 7
     */
    maxDays?: number;

    /**
     * @deprecated Please use `renderDay` instead.
     */
    render?: React.ReactNode;

    /**
     * A function that renders each individual day to be displayed.
     *
     * @param date The day to render
     */
    renderDay?: (date: Date) => React.JSX.Element;

    /**
     * Defines whether the days for the weekends are included.
     *
     * @default false
     */
    skipWeekends?: boolean;

    /**
     * The date of the first day that are rendered.
     *
     * @default the current date
     */
    startDate?: Date;

    /**
     * Callback function for when the next button is clicked.
     *
     * @param newStartDate Date value that is now the start of the stripe.
     */
    onNextClick?: (newStartDate: Date) => void;

    /**
     * Callback function for when the previous button is clicked.
     *
     * @param newStartDate Date value that is now the start of the stripe.
     */
    onPreviousClick?: (newStartDate: Date) => void;

    /**
     * Additional classes set to the navigation buttons.
     */
    buttonClassName?: string;

    /**
     * Additional classes set to the calendar element.
     */
    className?: string;

    /**
     * Additional classes set to the days wrapper element.
     */
    daysWrapperClassName?: string;

    /**
     * Additional classes set to the days wrapper element.
     */
    dayWrapperClassName?: string;
};

const CalendarStripe = forwardRef<HTMLDivElement, CalendarStripeProps>((props: CalendarStripeProps, ref) => {
    const {
        minDayWith = DEFAULT_MIN_WITH,
        minDays = DEFAULT_MIN_DAYS,
        maxDays = DEFAULT_MAX_DAYS,
        render,
        renderDay,
        skipWeekends = false,
        startDate = new Date(),
        onNextClick = noop,
        onPreviousClick = noop,
        buttonClassName = '',
        className = '',
        daysWrapperClassName = '',
        dayWrapperClassName = '',
    } = props;

    if (render) {
        console.warn(
            'CalendarStripe has set the "render" prop deprecated. This will be removed in the upcoming releases,' +
                ' Please use the "renderDay" prop instead.'
        );
    }

    if (!render && !renderDay) {
        console.error('CalendarStripe component is missing the required "renderDay" prop');
    }

    const [firstDate, setFirstDate] = useState(startDate);
    const [amountOfDaysToShow, setAmountOfDaysToShow] = useState(1);
    const [enableInitialAnimation, setEnableInitialAnimation] = useState(false);

    // The base for reacting on changing width of the wrapping element.
    // It uses a ResizeObserver under the hood.
    const columnWrapperRef = useRef<HTMLDivElement>(null);
    const [columnWrapperWidth] = useElementSize(columnWrapperRef);

    const previous = usePrevious(getDateString(firstDate));
    const animationDirection = getDateString(firstDate) > (previous as string) ? ANIMATION_NEXT : ANIMATION_BACK;

    // Update startDate from outside
    const [previousStartDate, setPreviousStartDate] = useState(startDate);
    if (getDateString(startDate) !== getDateString(previousStartDate)) {
        setFirstDate(startDate);
        setPreviousStartDate(startDate);
    }

    useAfterMount(() => {
        setEnableInitialAnimation(true);
    }, []);

    useEffect(() => {
        if (!columnWrapperRef.current) {
            return;
        }

        const firstItem = getFirstColumnItem(columnWrapperRef.current.children[0]);
        if (!firstItem) {
            return;
        }

        // Get the width of the first column to calculate how many columns fit on one page
        // according to the given minWidth per column
        const columnWidth = firstItem.getBoundingClientRect().width;

        // Limit columns per page for given min and max values
        const allowForLessColumns = amountOfDaysToShow - 1 >= minDays;
        const allowForMoreColumns = amountOfDaysToShow + 1 <= maxDays;

        const goSmaller = allowForLessColumns && columnWidth <= minDayWith;
        const goBigger =
            allowForMoreColumns && (amountOfDaysToShow + 1) * minDayWith <= (columnWrapperWidth as unknown as number);

        if (goBigger) {
            const newValue = amountOfDaysToShow + 1;
            setAmountOfDaysToShow(newValue);
            return;
        }

        if (goSmaller) {
            const newValue = amountOfDaysToShow - 1;
            setAmountOfDaysToShow(newValue);
            return;
        }
    }, [columnWrapperWidth, columnWrapperRef.current, amountOfDaysToShow, firstDate]);

    const handleNext = () => {
        const newFirstDate = new Date(firstDate);
        newFirstDate.setDate(newFirstDate.getDate() + amountOfDaysToShow);
        setFirstDate(newFirstDate);

        onNextClick(newFirstDate);
    };

    const handlePrev = () => {
        const newFirstDate = new Date(firstDate);
        newFirstDate.setDate(newFirstDate.getDate() - amountOfDaysToShow);
        setFirstDate(newFirstDate);

        onPreviousClick(newFirstDate);
    };

    // Re-calculate the dates to show depending on the amount of days to show
    const dates = [];

    let addDays = true;
    let index = 1;

    while (addDays) {
        const currentDate = new Date(firstDate);
        currentDate.setDate(firstDate.getDate() + index);

        const isSaturday = currentDate.getDay() === 6;
        const isSunday = currentDate.getDay() === 0;
        const isWeekend = isSaturday || isSunday;

        if (isWeekend && !skipWeekends) {
            dates.push(currentDate);
        } else if (!isWeekend) {
            dates.push(currentDate);
        }

        index++;

        if (dates.length === amountOfDaysToShow) {
            addDays = false;
        }
    }

    const wrapperClassNames = classNames(
        'CalenderStripe',
        !hasDisplayClass(className) && 'display-flex',
        !hasAlignItemsClass(className) && 'align-items-center',
        !hasOverflowClass(className) && 'overflow-hidden',
        !hasBgClass(className) && 'bg-white',
        !hasBorderClass(className) && 'border',
        !hasRoundedClass(className) && 'rounded',
        className
    );

    const baseButtonClassNames = classNames(
        !hasAlignItemsClass(buttonClassName) && 'align-items-center',
        !hasDisplayClass(buttonClassName) && 'display-flex',
        !hasHoverScaleClass(buttonClassName) && 'hover-scale-105',
        !hasHoverTextColorClass(buttonClassName) && 'hover-text-color-darkest',
        !hasPaddingClass(buttonClassName) && 'padding-10',
        !hasCursorClass(buttonClassName) && 'cursor-pointer',
        !hasTextColorClass(buttonClassName) && 'text-color-darker',
        !hasTextSizeClass(buttonClassName) && 'text-size-12',
        !hasAlignSelfClass(buttonClassName) && 'align-self-stretch',
        buttonClassName
    );

    const daysWrapperClassNames = classNames(
        !hasDisplayClass(daysWrapperClassName) && 'display-flex',
        !hasSpaceClass(daysWrapperClassName) && 'space-x--1',
        !hasUserSelectClass(daysWrapperClassName) && 'user-select-none',
        daysWrapperClassName
    );

    const dayWrapperClassNames = classNames(
        ITEM_NAME,
        !hasFlexClass(dayWrapperClassName) && 'flex-1-1-0',
        !hasSpaceClass(dayWrapperClassName) && 'space-x--1',
        !hasUserSelectClass(dayWrapperClassName) && 'user-select-none',
        dayWrapperClassName
    );

    return (
        <div ref={ref} className={wrapperClassNames}>
            <div className={baseButtonClassNames} onClick={handlePrev}>
                <span className='rioglyph rioglyph-chevron-left' />
            </div>
            <div className='flex-1-1 overflow-hidden' ref={columnWrapperRef}>
                {/* @ts-ignore-next-line */}
                <AnimatePresence exitBeforeEnter custom={animationDirection}>
                    <motion.div
                        key={getDateString(firstDate)}
                        variants={variants}
                        initial={enableInitialAnimation ? 'pageEnter' : false}
                        animate='pageCenter'
                        custom={animationDirection}
                        transition={{ duration: ANIMATION_DURATION }}
                    >
                        <div className={daysWrapperClassNames}>
                            {dates.map(date => (
                                <div className={dayWrapperClassNames} key={`${getDateString(date)}`}>
                                    {renderDay ? renderDay(date) : null}
                                    {render && React.cloneElement(<>{render}</>, { date })}
                                </div>
                            ))}
                        </div>
                    </motion.div>
                </AnimatePresence>
            </div>
            <div className={baseButtonClassNames} onClick={handleNext}>
                <span className='rioglyph rioglyph-chevron-right' />
            </div>
        </div>
    );
});

export default CalendarStripe;
