const hasClass = (utilityClass: string) => {
    return (classes: string): boolean => {
        if (classes.includes(utilityClass)) {
            return true;
        } else {
            return false;
        }
    };
};

const hasAlignContentClass = hasClass('align-content-');
const hasAlignItemsClass = hasClass('align-items-');
const hasAlignSelfClass = hasClass('align-self');
const hasBgClass = hasClass('bg-');
const hasBorderClass = hasClass('border-');
const hasBorderStyleClass = hasClass('border-style-');
const hasBorderWidthClass = hasClass('border-width');
const hasCursorClass = hasClass('cursor-');
const hasDisplayClass = hasClass('display-');
const hasFlexClass = hasClass('flex-');
const hasGapClass = hasClass('gap-');
const hasHeightClass = hasClass('height-');
const hasHoverBgClass = hasClass('hover-bg-');
const hasHoverOpacityClass = hasClass('hover-opacity-');
const hasHoverScaleClass = hasClass('hover-scale-');
const hasHoverTextColorClass = hasClass('hover-text-color-');
const hasMarginClass = hasClass('margin-');
const hasMaxHeightClass = hasClass('max-height-');
const hasMinHeightClass = hasClass('min-height-');
const hasOpacityClass = hasClass('opacity-');
const hasOverflowClass = hasClass('overflow-');
const hasPaddingClass = hasClass('padding-');
const hasRioglyphClass = hasClass('rioglyph-');
const hasRoundedClass = hasClass('rounded-');
const hasScaleClass = hasClass('scale-');
const hasSpaceClass = hasClass('space-');
const hasTextColorClass = hasClass('text-color-');
const hasTextSizeClass = hasClass('text-size');
const hasUserSelectClass = hasClass('user-select-');

export {
    hasAlignContentClass,
    hasAlignItemsClass,
    hasAlignSelfClass,
    hasBgClass,
    hasBorderClass,
    hasBorderStyleClass,
    hasBorderWidthClass,
    hasCursorClass,
    hasDisplayClass,
    hasFlexClass,
    hasGapClass,
    hasHeightClass,
    hasHoverBgClass,
    hasHoverOpacityClass,
    hasHoverScaleClass,
    hasHoverTextColorClass,
    hasMarginClass,
    hasMaxHeightClass,
    hasMinHeightClass,
    hasOpacityClass,
    hasOverflowClass,
    hasPaddingClass,
    hasRioglyphClass,
    hasRoundedClass,
    hasScaleClass,
    hasSpaceClass,
    hasTextColorClass,
    hasTextSizeClass,
    hasUserSelectClass,
};
