// @ts-ignore-next-line importsNotUsedAsValues
import React, { PropsWithChildren } from 'react';
import classNames from 'classnames';
import { useAutoAnimate } from '@formkit/auto-animate/react';

export type TreeRootProps = {
    disableAnimation: boolean;
    maxHeight?: number;
};

const TreeRoot = ({ maxHeight, disableAnimation, children }: PropsWithChildren<TreeRootProps>) => {
    const treeRootClasses = classNames('TreeRoot', 'user-select-none', maxHeight ? 'overflow-auto' : 'overflow-hidden');

    const [parent, enableAnimations] = useAutoAnimate();
    enableAnimations(!disableAnimation);

    return (
        <div ref={parent} className={treeRootClasses} style={{ maxHeight }}>
            {children}
        </div>
    );
};

export default TreeRoot;
