import { Playground } from '../../../components/playground/Playground';
import { PlaygroundClasses } from '../../../components/playground/PlaygroundClasses';
import { Description } from '../../../components/Description';
import { Note } from '../../../components/Note';

import { findByName } from '../../../utils/mapUtils';
import { containers, fluidContainers, getContainerNames } from '../../../utils/container';

const eachContainer = getContainerNames(containers.ls_to_xl) as string[];
const eachFluidContainer = getContainerNames(fluidContainers.sm_to_xxl) as string[];

export const Container = () => (
    <div>
        <Description headline='Container fluid' />
        <Playground example={containerFluidExample} classes={containerFluidClasses} />
        <div className='table-responsive'>
            <table className='table table-bordered table-head-filled margin-bottom-25'>
                <thead>
                    <tr>
                        <th />
                        {eachFluidContainer.map((name, index) => (
                            <th key={index}>{name}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>max-width</td>
                        {eachFluidContainer.map((containerName, index) => (
                            <td key={index}>{findByName(containerName, fluidContainers.sm_to_xxl).maxWidth}</td>
                        ))}
                    </tr>
                </tbody>
            </table>
        </div>
        <Description headline='Container' headlineType='h3' />
        <Note>
            If a container is used without the fluid classes, it has a maximum width per breakpoint and will jump when
            the screen is resized.
        </Note>
        <Playground example={containerExample} classes={containerClasses} />
        <div className='table-responsive'>
            <table className='table table-bordered table-head-filled'>
                <thead>
                    <tr>
                        <th />
                        {eachContainer.map((name, index) => (
                            <th key={index}>{name}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>max-width</td>
                        {eachContainer.map((containerName, index) => (
                            <td key={index}>{findByName(containerName, containers.ls_to_xl).maxWidth}</td>
                        ))}
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
);

const containerFluidExample = (
    <>
        {eachFluidContainer.map((containerName, index) => (
            <div
                className={`
                    container fluid-${containerName} bg-info rounded padding-15
                    margin-bottom-20 last-child-margin-bottom-0
                `}
                key={index}
            >
                container fluid-{containerName}
            </div>
        ))}
    </>
);

const containerFluidClasses = (
    <>
        <PlaygroundClasses label='Wrapper' classes='container' />
        <PlaygroundClasses label='Sizes' prefix='fluid-' classes={eachFluidContainer} />
    </>
);

const containerExample = (
    <>
        <div className='container bg-info rounded padding-15'>container</div>
    </>
);

const containerClasses = (
    <>
        <PlaygroundClasses label='Wrapper' classes='container' />
    </>
);
