import { useState, useRef, useLayoutEffect, useEffect, type ReactNode } from 'react';
import classNames from 'classnames';

import Resizer from '../../../../src/Resizer';

import { hasPaddingClass } from '../../../../src/utils/hasUtilityClass';

import { ErrorBoundary } from './ErrorBoundary';
import { CodeSnippets } from '../CodeSnippets';
import { PlaygroundHeader } from './PlaygroundHeader';
import { findByName } from '../../utils/mapUtils';
import { breakpoints, getBreakpointNames, type Breakpoint } from '../../utils/breakpoints';
import { handleDetachWindow } from '../../utils/detachWindow';

export const PLAYGROUNG_BG_WHITE = 'bg-white';
export const PLAYGROUNG_BG_LIGHTEST = 'bg-lightest';
export const PLAYGROUNG_BG_LIGHTER = 'bg-lighter';
export const PLAYGROUNG_BG_LIGHT = 'bg-light';
export const PLAYGROUNG_BG_CHECKERBOARD = 'bg-checkerboard';

const INITIAL_RESIZER_WIDTH = 100;
const MIN_PLAYGROUND_WIDTH = 400;
const MOBILE_BREAKPOINT = 480;

const MOBILE_CONTAINER_CLASS = 'container-query-mobile';

const eachBreakpoint = getBreakpointNames(breakpoints);
const eachBreakpointWithoutZero = eachBreakpoint.slice(1);

const pxToNumber = (value = '0') => Number(value.replace('px', ''));

type PlaygroundSize = 'phone' | 'desktop' | 'width';

type PlaygroundProps = {
    size?: PlaygroundSize;
    background?: string;
    className?: string;
    innerClassName?: string;
    example?: ReactNode;
    codeHtml?: string;
    codeHtmlTitle?: string;
    codeReact?: string;
    codeReactTitle?: string;
    codeJS?: string;
    codeJSTitle?: string;
    props?: ReactNode;
    openBlock?: 'codeReact' | 'codeHtml' | 'codeJS' | 'props' | 'classes';
    showHtml?: boolean;
    showSizeButtons?: boolean;
    showBackgroundButtons?: boolean;
    classes?: ReactNode;
    availableColors?: ReactNode;
    children?: ReactNode;
};

export const Playground = (props: PlaygroundProps) => {
    const {
        size,
        background = 'bg-white',
        className = '',
        innerClassName = '',
        showHtml = true,
        openBlock,
        example,
        props: componentProps,
        codeHtml,
        codeHtmlTitle = 'HTML',
        codeReact,
        codeReactTitle = 'React',
        codeJS,
        codeJSTitle = 'JavaScript',
        classes,
        availableColors,
        children,
        showSizeButtons = true,
        showBackgroundButtons = true,
    } = props;

    const [backgroundClass, setBackgroundClass] = useState(background);
    const [playgroundSize, setPlaygroundSize] = useState(size);
    const [isDOMReady, setIsDOMReady] = useState(false);
    const [showResizer, setShowResizer] = useState(false);
    const [playgroundWidth, setPlaygroundWidth] = useState<number | string>('100%');

    const contentRef = useRef<HTMLDivElement>(null);
    const playgroundRef = useRef<HTMLDivElement>(null);

    useLayoutEffect(() => {
        setIsDOMReady(true);
    }, []);

    useEffect(() => {
        if (size) {
            handlePlaygroundSize(size);
        }
    }, [size]);

    const toggleMobileContainerClass = (playgroundTargetWidth: number) => {
        const playgroundClasses = contentRef.current?.className;

        if (playgroundTargetWidth > MOBILE_BREAKPOINT && playgroundClasses?.includes(MOBILE_CONTAINER_CLASS)) {
            contentRef.current?.classList.remove(MOBILE_CONTAINER_CLASS);
        } else if (playgroundTargetWidth <= MOBILE_BREAKPOINT && !playgroundClasses?.includes(MOBILE_CONTAINER_CLASS)) {
            contentRef.current?.classList.add(MOBILE_CONTAINER_CLASS);
        }
    };

    const handleBackgroundChange = (newBg: string) => setBackgroundClass(newBg);

    const handlePlaygroundSize = (newSize: PlaygroundSize) => {
        switch (newSize) {
            case 'width': {
                const scrollWidth = playgroundRef.current?.scrollWidth || 0;
                const targetWidth = scrollWidth - INITIAL_RESIZER_WIDTH;
                toggleResizer(true);
                setPlaygroundWidth(targetWidth);
                toggleMobileContainerClass(targetWidth);
                break;
            }
            case 'phone':
                toggleResizer(true);
                setPlaygroundWidth(MOBILE_BREAKPOINT);
                toggleMobileContainerClass(MOBILE_BREAKPOINT);
                break;
            default:
                toggleResizer(false);
                setPlaygroundWidth('100%');
                break;
        }
        setPlaygroundSize(newSize);
    };

    const toggleResizer = (shouldShow: boolean) => {
        if (shouldShow) {
            setPlaygroundWidth(playgroundRef.current?.scrollWidth || 0);
        } else {
            setPlaygroundWidth('100%');
        }
        setShowResizer(shouldShow);
    };

    const handleResize = (diff: number) => {
        setPlaygroundWidth(oldWidth => {
            const currentPlaygroundWidth = playgroundRef.current?.scrollWidth || 0;

            const updatedWidth = playgroundWidth === '100%' ? currentPlaygroundWidth : (oldWidth as number) - diff;
            const clampedWidth = updatedWidth <= MIN_PLAYGROUND_WIDTH ? MIN_PLAYGROUND_WIDTH : updatedWidth;

            toggleMobileContainerClass(clampedWidth);

            return clampedWidth;
        });
    };

    const content = example || children;

    const wrapperClasses = classNames(
        'playground shadow-default rounded border margin-bottom-20',
        className,
        playgroundSize && `size-${playgroundSize}`
    );

    const playgroundContentClasses = classNames(
        'playground-content ',
        innerClassName,
        backgroundClass,
        !hasPaddingClass(innerClassName) ? 'padding-20 padding-bottom-25' : '',
        !availableColors && !showSizeButtons && !showBackgroundButtons && 'rounded-top'
    );

    return (
        <div className={wrapperClasses} ref={playgroundRef}>
            {!content && (
                <CodeSnippets
                    codeReact={codeReact}
                    codeJS={codeJS}
                    openBlock={openBlock}
                    componentProps={props.props}
                />
            )}
            {content && (
                <>
                    <PlaygroundHeader
                        availableColors={availableColors}
                        onBackgroundChange={handleBackgroundChange}
                        showSizeButtons={showSizeButtons}
                        showBackgroundButtons={showBackgroundButtons}
                        onPlaygroundSizeChange={handlePlaygroundSize}
                        // Use the imported function here
                        onDetachWindow={() => handleDetachWindow(contentRef, playgroundRef)}
                    />
                    <ErrorBoundary>
                        <div className='display-flex'>
                            <div
                                className={playgroundContentClasses}
                                style={{ width: playgroundWidth }}
                                ref={contentRef}
                            >
                                {content}
                                {showResizer && (
                                    <Resizer
                                        className={
                                            'right-0 display-grid place-items-center width-auto height-100pct ' +
                                            'padding-2 bg-white border border-top-none border-bottom-none'
                                        }
                                        position={Resizer.RIGHT}
                                        onResize={handleResize}
                                    >
                                        <div className='height-20 width-2 bg-gray' />
                                    </Resizer>
                                )}
                            </div>
                            <div className='playground-sizes'>
                                {eachBreakpointWithoutZero.map((breakpoint: Breakpoint) => {
                                    const minWidth = findByName(breakpoint, breakpoints).minWidth;
                                    const prevBreakpointSize = findByName(breakpoint, breakpoints).prevBreakpointSize;
                                    return (
                                        <div
                                            key={`index-${prevBreakpointSize}`}
                                            style={{
                                                minWidth: pxToNumber(minWidth) - pxToNumber(prevBreakpointSize),
                                            }}
                                            className={
                                                'padding-10 border border-style-dashed border-color-light ' +
                                                'border-right-only text-color-gray'
                                            }
                                        >
                                            <div>
                                                Width: <b>{prevBreakpointSize}</b>
                                            </div>
                                            {prevBreakpointSize === '480px' && (
                                                <>
                                                    <p>
                                                        This demo emulates the mobile breakpoint to demonstrate how to
                                                        handle mobile screen size.
                                                    </p>
                                                    <hr />
                                                    <div className='text-size-12 opacity-70 line-height-125rel'>
                                                        <p>
                                                            To handle various screen sizes, please use the
                                                            breakpoint-specific responsive CSS classes. Many components
                                                            have them built-in already. In some cases, you need to set
                                                            them individually.
                                                        </p>
                                                        <p>
                                                            These classes are documented in the CLASS expander of the
                                                            respective aspect in the Design section.
                                                        </p>
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </ErrorBoundary>
                    {isDOMReady && (
                        <CodeSnippets
                            exampleRef={contentRef}
                            showHtml={showHtml}
                            codeHtml={codeHtml}
                            codeHtmlTitle={codeHtmlTitle}
                            codeReact={codeReact}
                            codeReactTitle={codeReactTitle}
                            codeJS={codeJS}
                            codeJSTitle={codeJSTitle}
                            componentProps={componentProps}
                            classes={classes}
                            openBlock={openBlock}
                        />
                    )}
                </>
            )}
        </div>
    );
};
