import React from 'react';

type TreeNothingFoundProps = {};

const TreeNothingFound = React.memo((props: TreeNothingFoundProps) => {
    return (
        <div
            className={`TreeTreeNothingFound display-flex justify-content-center text-size-20
                padding-top-25 margin-top-25 margin-bottom-25`}
        >
            <span className='rioglyph rioglyph-looking-glass-man text-size-400pct text-color-light' />
        </div>
    );
});

export default TreeNothingFound;
