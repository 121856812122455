import React from 'react';
import classNames from 'classnames';
import noop from 'lodash/fp/noop';

export type SidebarBackdropProps = {
    makeBackdropVisible?: boolean;
    onClick?: VoidFunction;
    className?: string;
};

const SidebarBackdrop = ({ onClick = noop, makeBackdropVisible = false, className = '' }: SidebarBackdropProps) => {
    const backdropClassNames = classNames('SidebarBackdrop', makeBackdropVisible && 'sidebar-backdrop-bg', className);

    return <div className={backdropClassNames} onClick={onClick} data-testid='sidebarBackdrop' />;
};

export default SidebarBackdrop;
