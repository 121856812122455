import classNames from 'classnames';

import { Playground } from '../../../components/playground/Playground';
import { PlaygroundClasses } from '../../../components/playground/PlaygroundClasses';

import { Description } from '../../../components/Description';
import { Note } from '../../../components/Note';

import useDarkMode from '../../../../../src/hooks/useDarkMode';

import { colors, getColorNames, hasWarmup, hasColdplay, showWarmup, showColdplay } from '../../../utils/colors';

export const TextColors = () => (
    <>
        <Description anchor='colors-text' headline='Text' />
        {useDarkMode() && (
            <div className='alert alert-info margin-bottom-20'>
                <Note defaultContent='darkmode' />
            </div>
        )}
        <Playground example={textExample} classes={textClasses} />
        <Description headline='Hover text' headlineType='h3' />
        <Playground example={hoverTextExample} classes={hoverTextClasses} />
    </>
);

const eachText = getColorNames(colors.text) as string[];
const eachBrand = getColorNames(colors.brand) as string[];
const eachStatus = getColorNames(colors.status) as string[];
const eachDrivingStatus = getColorNames(colors.drivingStatus) as string[];
const eachMapMarker = getColorNames(colors.mapMarker) as string[];
const eachRating = getColorNames(colors.rating) as string[];
const eachGray = getColorNames(colors.gray) as string[];
const eachHighlight = getColorNames(colors.highlight) as string[];
const eachColdplay = getColorNames(colors.coldplay) as string[];
const eachWarmup = getColorNames(colors.warmup) as string[];

type TextsProps = {
    label: string;
    eachColor: string[];
    prefix: string;
    showBorder: boolean;
};

const Texts = (props: TextsProps) => {
    const { label, eachColor, prefix, showBorder } = props;
    const classes = classNames('rounded padding-5 text-center text-size-12 display-grid place-items-center');

    return (
        <div
            className={
                'display-flex align-items-center-sm margin-bottom-15pct margin-bottom-20-sm ' +
                'last-child-margin-bottom-0 line-height-125rel'
            }
        >
            <div className='text-medium text-size-14 width-100 margin-right-20'>{label}</div>
            <div className='width-100pct display-grid grid-cols-1 grid-cols-2-ls grid-cols-4-sm grid-cols-8-md gap-10'>
                {eachColor.map((color, index) => (
                    <div className={`${prefix}${color} ${classes} ${showBorder ? 'border' : ''}`} key={index}>
                        {prefix}
                        {color}
                    </div>
                ))}
            </div>
        </div>
    );
};

const textExample = (
    <>
        <Texts label='Basic' prefix='text-color-' eachColor={eachText} showBorder />
        <Texts label='Brand' prefix='text-color-' eachColor={eachBrand} showBorder />
        <Texts label='Status' prefix='text-color-' eachColor={eachStatus} showBorder />
        <Texts label='Driving status' prefix='text-color-' eachColor={eachDrivingStatus} showBorder />
        <Texts label='Map marker' prefix='text-color-' eachColor={eachMapMarker} showBorder />
        <Texts label='Rating' prefix='text-color-' eachColor={eachRating} showBorder />
        <Texts label='Gray' prefix='text-color-' eachColor={eachGray} showBorder />
        <Texts label='Highlight' prefix='text-color-' eachColor={eachHighlight} showBorder />
        {showColdplay && <Texts label='Coldplay' prefix='text-color-' eachColor={eachColdplay} showBorder />}
        {showWarmup && <Texts label='Warmup' prefix='text-color-' eachColor={eachWarmup} showBorder />}
    </>
);

const hoverTextExample = (
    <>
        <Texts label='Basic' prefix='hover-text-color-' eachColor={eachText} showBorder />
        <Texts label='Brand' prefix='hover-text-color-' eachColor={eachBrand} showBorder />
        <Texts label='Status' prefix='hover-text-color-' eachColor={eachStatus} showBorder />
        <Texts label='Driving status' prefix='hover-text-color-' eachColor={eachDrivingStatus} showBorder />
        <Texts label='Map marker' prefix='hover-text-color-' eachColor={eachMapMarker} showBorder />
        <Texts label='Rating' prefix='hover-text-color-' eachColor={eachRating} showBorder />
        <Texts label='Gray' prefix='hover-text-color-' eachColor={eachGray} showBorder />
        <Texts label='Highlight' prefix='hover-text-color-' eachColor={eachHighlight} showBorder />
        {showColdplay && <Texts label='Coldplay' prefix='hover-text-color-' eachColor={eachColdplay} showBorder />}
        {showWarmup && <Texts label='Warmup' prefix='hover-text-color-' eachColor={eachWarmup} showBorder />}
    </>
);

const textClasses = (
    <>
        <PlaygroundClasses label='Basic' prefix='text-color-' classes={eachText} />
        <PlaygroundClasses label='Brand' prefix='text-color-' classes={eachBrand} />
        <PlaygroundClasses label='Status' prefix='text-color-' classes={eachStatus} />
        <PlaygroundClasses label='Driving status' prefix='text-color-' classes={eachDrivingStatus} />
        <PlaygroundClasses label='Map marker' prefix='text-color-' classes={eachMapMarker} />
        <PlaygroundClasses label='Rating' prefix='text-color-' classes={eachRating} />
        <PlaygroundClasses label='Gray' prefix='text-color-' classes={eachGray} />
        <PlaygroundClasses label='Highlight' prefix='text-color-' classes={eachHighlight} />
        {hasColdplay && <PlaygroundClasses label='Coldplay' prefix='text-color-' classes={eachColdplay} />}
        {hasWarmup && <PlaygroundClasses label='Warmup' prefix='text-color-' classes={eachWarmup} />}
    </>
);

const hoverTextClasses = (
    <>
        <PlaygroundClasses label='Basic' prefix='hover-text-color-' classes={eachText} />
        <PlaygroundClasses label='Brand' prefix='hover-text-color-' classes={eachBrand} />
        <PlaygroundClasses label='Status' prefix='hover-text-color-' classes={eachStatus} />
        <PlaygroundClasses label='Driving status' prefix='hover-text-color-' classes={eachDrivingStatus} />
        <PlaygroundClasses label='Map marker' prefix='hover-text-color-' classes={eachMapMarker} />
        <PlaygroundClasses label='Rating' prefix='hover-text-color-' classes={eachRating} />
        <PlaygroundClasses label='Gray' prefix='hover-text-color-' classes={eachGray} />
        <PlaygroundClasses label='Highlight' prefix='hover-text-color-' classes={eachHighlight} />
        {hasColdplay && <PlaygroundClasses label='Coldplay' prefix='hover-text-color-' classes={eachColdplay} />}
        {hasWarmup && <PlaygroundClasses label='Warmup' prefix='hover-text-color-' classes={eachWarmup} />}
    </>
);
