import { Playground, PLAYGROUNG_BG_CHECKERBOARD } from '../../../components/playground/Playground';
import { PlaygroundClasses } from '../../../components/playground/PlaygroundClasses';
import { Description } from '../../../components/Description';

import { numbers } from '../../../utils/numbers';

export const Opacity = () => (
    <div>
        <Description headline='Opacity' anchor='opacity' />
        <Playground example={opacityExample} classes={opacityClasses} background={PLAYGROUNG_BG_CHECKERBOARD} />
        <Description headlineType='h3' headline='Hover opacity' />
        <Playground
            example={opacityHoverExample}
            classes={opacityHoverClasses}
            background={PLAYGROUNG_BG_CHECKERBOARD}
        />
    </div>
);

const opacityExample = (
    <div className='display-flex flex-wrap gap-10'>
        {numbers.from_0_to_100.map((value, index) => (
            <div
                className={`width-50 aspect-ratio-1 display-grid place-items-center bg-info rounded opacity-${value}`}
                key={index}
            >
                {value}
            </div>
        ))}
    </div>
);

const opacityClasses = <PlaygroundClasses prefix='opacity-' classes={numbers.from_0_to_100} />;

const opacityHoverExample = (
    <div className='display-flex flex-wrap gap-10'>
        {numbers.from_0_to_100.map((value, index) => (
            <div
                className={`
                    width-50 aspect-ratio-1 display-grid place-items-center bg-info rounded
                    opacity-${value === '0' ? 100 : 110 - value}
                    hover-opacity-${value}
                `}
                key={index}
            >
                {value}
            </div>
        ))}
    </div>
);

const opacityHoverClasses = <PlaygroundClasses prefix='hover-opacity-' classes={numbers.from_0_to_100} />;
