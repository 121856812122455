import classNames from 'classnames';
import { PropsWithChildren } from 'react';

type NoteProps = {
    label?: string;
    type?: 'default' | 'info' | 'success' | 'warning' | 'danger';
    defaultContent?: 'darkmode';
    className?: string;
};

export const Note = (props: PropsWithChildren<NoteProps>) => {
    const { label = 'Note', type = 'info', defaultContent, className, children } = props;

    const wrapperClasses = classNames(
        'display-flex',
        'gap-10',
        'max-width-1000',
        'margin-bottom-10',
        'last-child-margin-bottom-0',
        className
    );

    return (
        <div className={wrapperClasses}>
            <div>
                <span className={`label label-${type} label-condensed label-filled`}>{label}</span>
            </div>
            <div className='width-100pct children-first-margin-top-0 children-last-margin-bottom-0 padding-top-1'>
                {defaultContent === 'darkmode' && (
                    <>
                        Our <b>dark mode</b> color classes <b>cannot be set individually,</b> but are calculated
                        automatically using the normal color classes. Even if our guidelines do not always agree that
                        the colors in dark mode are as <b>good</b> or <b>bad</b> as those in light mode, we have decided
                        to take this approach because it simplifies the work of the developer tremendously.
                    </>
                )}
                {children && children}
            </div>
        </div>
    );
};
