import React, { type PropsWithChildren, useEffect } from 'react';
import Prism from 'prismjs';
import beautify from 'js-beautify';

// Require all needed languages
import 'prismjs/components/prism-typescript.min.js';
import 'prismjs/components/prism-jsx.min.js';
import 'prismjs/components/prism-tsx.min.js';
import 'prismjs/components/prism-css.min.js';
import 'prismjs/components/prism-css-extras.min.js';
import 'prismjs/components/prism-less.js';

// Require all needed plugins
// import 'prismjs/plugins/line-numbers/prism-line-numbers.js';
// import 'prismjs/plugins/line-numbers/prism-line-numbers.css';

export const withoutReactComment = (text: string) => text.replace(/<!--[\s\S]*?-->/gm, '');

type CodeBlockProps = {
    language?: string;
    wrapperClassName?: string;
    exampleRef?: React.RefObject<HTMLDivElement>;
};

export const CodeBlock = React.memo((props: PropsWithChildren<CodeBlockProps>) => {
    const { language, wrapperClassName = '', children, exampleRef } = props;

    useEffect(() => {
        Prism.highlightAll();
    }, []);

    const extractFormattedMarkup = (ref: HTMLDivElement) => {
        const withoutReactComments = withoutReactComment(ref.innerHTML);
        const lineBreaks = withoutReactComments.split('><').join('>\n<');
        const formattedMarkup = beautify.html(lineBreaks, { ['indent_size']: 4 });
        return formattedMarkup;
    };

    return (
        <pre className={`padding-10 margin-bottom-0 ${wrapperClassName}`}>
            <code className={language}>
                {exampleRef?.current ? extractFormattedMarkup(exampleRef.current) : children}
            </code>
        </pre>
    );
});
