import { Playground } from '../../../components/playground/Playground';
import { PlaygroundClasses, PlaygroundClassesSpacer } from '../../../components/playground/PlaygroundClasses';
import { Description } from '../../../components/Description';
import { Title } from '../../../components/Title';

import { sizes, getSizeClasses } from '../../../utils/sizes';

const eachTextSize = getSizeClasses(sizes.text) as string[];
const eachHeadlineSize = getSizeClasses(sizes.headlines) as string[];
const eachTextWeight = ['text-thin', 'text-light', 'text-normal', 'text-medium', 'text-bold'];
const eachTextDecoration = [
    'text-decoration-none',
    'hover-text-decoration-none',
    'text-decoration-line-through',
    'text-decoration-underline',
];
const eachTextDecorationUnderlineOffset = [
    'underline-offset-2',
    'underline-offset-3',
    'underline-offset-4',
    'underline-offset-5',
];
const eachTextWrapping = ['white-space-nowrap', 'white-space-normal'];
const eachTextTransform = [
    'text-capitalize',
    'text-lowercase',
    'text-uppercase',
    'text-capitalize-first-word',
    'text-transform-none',
];
const eachHeadingClass = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'];

export const TextStyles = () => (
    <div>
        <Description headline='Text styles' anchor='text-styles' />
        <Playground example={TextStylesExample} classes={TextStylesClasses} />
        <Playground example={HeadingsExample} classes={HeadingsClasses} />
    </div>
);

const TextStylesExample = (
    <div className='display-grid grid-cols-1 grid-cols-2-ls grid-cols-3-sm grid-cols-6-md gap-20'>
        <div>
            <Title>Headline size</Title>
            {eachHeadlineSize.map((value, index) => (
                <p className={`${value} padding-5`} key={index}>
                    {value}
                </p>
            ))}
        </div>
        <div>
            <Title>Text size</Title>
            {eachTextSize.map((value, index) => (
                <p className={`${value} padding-5`} key={index}>
                    {value}
                </p>
            ))}
        </div>
        <div>
            <Title>Text weight</Title>
            {eachTextWeight.map((value, index) => (
                <p className={`${value} padding-5`} key={index}>
                    {value}
                </p>
            ))}

            <Title className='padding-top-20'>Text style</Title>

            <p className='text-italic padding-5'>italic</p>
        </div>
        <div>
            <Title>Text decoration</Title>
            {eachTextDecoration.map((value, index) => (
                <p className={`${value} padding-5`} key={index}>
                    {value}
                </p>
            ))}
            {eachTextDecorationUnderlineOffset.map((value, index) => (
                <p className={`text-decoration-underline ${value} padding-5`} key={index}>
                    {value}
                </p>
            ))}
        </div>
        <div>
            <Title>Text wrapping</Title>
            {eachTextWrapping.map((value, index) => (
                <p className={`${value} padding-5`} key={index}>
                    {value}
                </p>
            ))}
        </div>
        <div>
            <Title>Text transform</Title>
            <p className='text-capitalize padding-5'>text-capitalize</p>
            <p className='text-lowercase padding-5'>TEXT-LOWERCASE</p>
            <p className='text-uppercase padding-5'>text-uppercase</p>
            <p className='text-capitalize-first-word padding-5'>text capitalize first word</p>
            <p className='text-transform-none padding-5'>text-transform-none</p>
        </div>
    </div>
);

const HeadingsExample = (
    <div>
        <Title>Headings</Title>
        <div className='margin-bottom-25'>
            <p>
                The reason why there are heading tags like <b>{'<h2>'}</b> and heading classes like <b>.h2</b> is due to
                different use cases. A <b>{'<h2>'}</b> tag for instance is an essential SEO tag often used by a CMS like
                WordPress or Typo3. For these Tools, we can not change the code but we need some styling for the HTML
                element itself.
            </p>
            <p>
                Another example is the <b>{'<strong>'}</b> or <b>{'<b>'}</b> tags and the <b>.text-bold</b> class. The
                heading classes like the <b>.h2</b> class can be used in a service application, as those are not SEO
                relevant. As the UIKIT uses an atomic CSS approach, the same style can be achieved when combining{' '}
                <b>.text-size-h2</b> <b>.text-bold margin-top-10 margin-bottom-20</b> classes.
            </p>
        </div>
        <div className='display-grid grid-cols-1 grid-cols-2-ls grid-cols-3-sm gap-20'>
            <div>
                <Title>Headline tags</Title>
                <h1>Heading 1</h1>
                <h2>Heading 2</h2>
                <h3>Heading 3</h3>
                <h4>Heading 4</h4>
                <h5>Heading 5</h5>
                <h6>Heading 6</h6>
            </div>
            <div>
                <Title>Headline classes</Title>
                {eachHeadingClass.map((value, index) => (
                    <div className={`${value}`} key={index}>
                        {value}
                    </div>
                ))}
            </div>
            <div>
                <Title>Misc tags</Title>
                <p>
                    <small>This line of text is meant to be treated as fine print.</small>
                </p>
                <p>
                    The following snippet of text is <strong>rendered as bold text</strong>.
                </p>
                <p>
                    The following snippet of text is <i>rendered as italicized text</i>.
                </p>
                <p>
                    An abbreviation of the word attribute is <abbr title='attribute'>attr</abbr>.
                </p>
            </div>
        </div>
    </div>
);

const TextStylesClasses = (
    <>
        <PlaygroundClassesSpacer label='Sizes' />
        <PlaygroundClasses label='Headlines' classes={eachHeadlineSize} />
        <PlaygroundClasses label='Text' classes={eachTextSize} />
        <PlaygroundClassesSpacer label='Percentage sizes' />
        <PlaygroundClasses label='Parent' classes='text-size-...' />
        <PlaygroundClasses
            label='Child'
            prefix='text-size-'
            classes={['100', '200', '300', '400', '500']}
            suffix='pct'
        />
        <PlaygroundClassesSpacer label='Styles' />
        <PlaygroundClasses label='Weight' classes={eachTextWeight} />
        <PlaygroundClasses label='Decoration' classes={eachTextDecoration} />
        <PlaygroundClasses label='Underline offset' classes={eachTextDecorationUnderlineOffset} />
        <PlaygroundClasses label='Wrapping' classes={eachTextWrapping} />
        <PlaygroundClasses label='Transform' classes={eachTextTransform} />
    </>
);

const HeadingsClasses = <PlaygroundClasses label='Headings' classes={eachHeadingClass} />;
