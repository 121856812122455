import { push, replace } from 'connected-react-router';
import qs from 'qs';
import getOr from 'lodash/fp/getOr';
import isEmpty from 'lodash/fp/isEmpty';
import isArray from 'lodash/fp/isArray';
import compact from 'lodash/fp/compact';
import { parseColumnsSettingsStrings, mapColumnsSettingsToStrings, getSortDirShort, parseSorting } from '../../index';

import { tableConfig, BASE_PATH } from './content';

const OPTIONS = {
    comma: true, // required to parse comma separated string into array
    arrayFormat: 'comma', // required to stringify arrays into comma separated strings
    indices: false, // don't use array indices
    encode: false, // don't encode the entire string as it will be done individually for certain params
    decode: false,
    skipNulls: true, // required to remove empty params
};

export const parseRoute = router => {
    const location = getOr({}, 'location')(router);
    const searchParams = location.search ? location.search.replace('?', '') : location.search;
    const { sort, q, chunks, viewType, columns, showTableSettings, activityFilter, stateFilter } = qs.parse(
        searchParams,
        OPTIONS
    );

    const sorting = sort && parseSorting(sort);

    return {
        search: q && decodeURIComponent(q),
        sortBy: sorting && sorting.sortBy,
        sortDir: sorting && sorting.sortDir,
        chunks: chunks && parseInt(chunks, 10),
        viewType,
        columns: columns && parseColumnsSettingsStrings(columns, tableConfig.defaultColumnsDetails),
        showTableSettings: Boolean(showTableSettings),
        activityFilter: !isEmpty(activityFilter) ? (isArray(activityFilter) ? activityFilter : [activityFilter]) : [],
        stateFilter: !isEmpty(stateFilter) ? (isArray(stateFilter) ? stateFilter : [stateFilter]) : [],
    };
};

export const makeRoute = ({
    sortBy,
    sortDir,
    search,
    chunks,
    viewType,
    showTableSettings,
    columns,
    activityFilter,
    stateFilter,
}) => {
    // Set props to undefined or null in order to remove it from the URL when not defined
    const params = {
        q: search ? encodeURIComponent(search) : undefined,
        sort: sortBy && `${getSortDirShort(sortDir)}${sortBy}`,
        chunks,
        viewType,
        columns: columns && mapColumnsSettingsToStrings(columns),
        showTableSettings: showTableSettings || null,
        activityFilter: isEmpty(compact(activityFilter)) ? null : activityFilter,
        stateFilter: isEmpty(compact(stateFilter)) ? null : stateFilter,
    };
    const queryParams = qs.stringify(params, OPTIONS);
    const searchFragment = queryParams && `?${queryParams}`;

    return `${BASE_PATH}/table${searchFragment}`;
};

const updateSelectedAssetIds = (assetIds, router) => {
    const url = makeRoute({
        ...parseRoute(router),
        eventTimestamp: undefined,
        assetIds,
    });
    return dispatch => dispatch(push(url));
};

const updateSortProps = (sortBy, sortDir, router) => {
    const route = parseRoute(router);
    const url = makeRoute({
        ...route,
        sortBy: sortBy || route.sortBy,
        sortDir: sortDir || route.sortDir,
    });
    return dispatch => dispatch(push(url));
};

const updateRouteProps = ({ props, router, useReplace = false }) => {
    const route = parseRoute(router);
    const url = makeRoute({ ...route, ...props });
    return dispatch => dispatch(useReplace ? replace(url) : push(url));
};

export const routeIntents = {
    updateSelectedAssetIds,
    updateSortProps,
    updateRouteProps,
};
