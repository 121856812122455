import OverlayTrigger from '../overlay/OverlayTrigger';

// alignment / position
export const LEFT = OverlayTrigger.LEFT;
export const TOP = OverlayTrigger.TOP;
export const RIGHT = OverlayTrigger.RIGHT;
export const BOTTOM = OverlayTrigger.BOTTOM;

// icon size
export const SMALL = 'small';
export const LARGE = 'large';

// font weight
export const LIGHT = 'light';
export const BOLD = 'bold';
