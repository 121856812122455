import { Playground } from '../../../components/playground/Playground';
import {
    PlaygroundClasses,
    PlaygroundResponsiveClasses,
    PlaygroundClassesSpacer,
    PlaygroundPrintClasses,
} from '../../../components/playground/PlaygroundClasses';
import { Description } from '../../../components/Description';
import { Title } from '../../../components/Title';

import { spacings } from '../../../utils/spacings';
const spacingsWithoutZero = spacings.slice(1);

export const Padding = () => (
    <div>
        <Description headline='Padding' anchor='padding' />
        <Playground example={paddingExample} classes={paddingClasses} />
    </div>
);

const paddingExample = (
    <>
        <div className='display-grid grid-cols-1 grid-cols-4-sm grid-cols-7-lg gap-20'>
            <div>
                <Title>Surrouding</Title>
                {spacings.map((spacing, index) => (
                    <div className='display-flex justify-content-center' key={index}>
                        <div className={`padding-${spacing} margin-bottom-5 text-center bg-info rounded`}>
                            padding-{spacing}
                        </div>
                    </div>
                ))}
            </div>
            <div>
                <Title>Horizontal</Title>
                {spacings.map((spacing, index) => (
                    <div className='display-flex justify-content-center' key={index}>
                        <div className={`padding-x-${spacing} margin-bottom-5 text-center bg-info rounded`}>
                            padding-x-{spacing}
                        </div>
                    </div>
                ))}
            </div>
            <div>
                <Title>Vertical</Title>
                {spacings.map((spacing, index) => (
                    <div className='display-flex justify-content-center' key={index}>
                        <div className={`padding-y-${spacing} margin-bottom-5 text-center bg-info rounded`}>
                            padding-y-{spacing}
                        </div>
                    </div>
                ))}
            </div>
            <div>
                <Title>Left</Title>
                {spacings.map((spacing, index) => (
                    <div className='display-flex justify-content-center' key={index}>
                        <div className={`padding-left-${spacing} margin-bottom-5 bg-info rounded`}>
                            padding-left-{spacing}
                        </div>
                    </div>
                ))}
            </div>
            <div>
                <Title>Right</Title>
                {spacings.map((spacing, index) => (
                    <div className='display-flex justify-content-center' key={index}>
                        <div className={`padding-right-${spacing} margin-bottom-5 text-right bg-info rounded`}>
                            padding-right-{spacing}
                        </div>
                    </div>
                ))}
            </div>
            <div>
                <Title>Top</Title>
                {spacings.map((spacing, index) => (
                    <div className='display-flex justify-content-center' key={index}>
                        <div className={`padding-top-${spacing} margin-bottom-5 text-center bg-info rounded`}>
                            padding-top-{spacing}
                        </div>
                    </div>
                ))}
            </div>
            <div>
                <Title>Bottom</Title>
                {spacings.map((spacing, index) => (
                    <div className='display-flex justify-content-center' key={index}>
                        <div className={`padding-bottom-${spacing} margin-bottom-5 text-center bg-info rounded`}>
                            padding-bottom-{spacing}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    </>
);

const paddingClasses = (
    <>
        <PlaygroundClassesSpacer label='Pixel values' />
        <PlaygroundClasses label='Surrounding' prefix='padding-' classes={spacings} />
        <PlaygroundClasses label='Horizontal' prefix='padding-x-' classes={spacings} />
        <PlaygroundClasses label='Vertical' prefix='padding-y-' classes={spacings} />
        <PlaygroundClasses label='Left' prefix='padding-left-' classes={spacings} />
        <PlaygroundClasses label='Right' prefix='padding-right-' classes={spacings} />
        <PlaygroundClasses label='Top' prefix='padding-top-' classes={spacings} />
        <PlaygroundClasses label='Bottom' prefix='padding-bottom-' classes={spacings} />
        <PlaygroundClassesSpacer label='Percentage values' />
        <PlaygroundClasses label='Surrounding' prefix='padding-' classes={spacingsWithoutZero} suffix='pct' />
        <PlaygroundClasses label='Horizontal' prefix='padding-x-' classes={spacingsWithoutZero} suffix='pct' />
        <PlaygroundClasses label='Vertical' prefix='padding-y-' classes={spacingsWithoutZero} suffix='pct' />
        <PlaygroundClasses label='Left' prefix='padding-left-' classes={spacingsWithoutZero} suffix='pct' />
        <PlaygroundClasses label='Right' prefix='padding-right-' classes={spacingsWithoutZero} suffix='pct' />
        <PlaygroundClasses label='Top' prefix='padding-top-' classes={spacingsWithoutZero} suffix='pct' />
        <PlaygroundClasses label='Bottom' prefix='padding-bottom-' classes={spacingsWithoutZero} suffix='pct' />
        <PlaygroundResponsiveClasses />
        <PlaygroundPrintClasses />
    </>
);
