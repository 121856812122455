import { Playground, PLAYGROUNG_BG_LIGHTER } from '../../../components/playground/Playground';
import { PlaygroundClasses, PlaygroundResponsiveClasses } from '../../../components/playground/PlaygroundClasses';
import { Description } from '../../../components/Description';
import { dummyTextUltraShort, dummyTextShort } from '../../../utils/data';
import { Note } from '../../../components/Note';

import { findByName } from '../../../utils/mapUtils';
import { breakpoints, getBreakpointNames } from '../../../utils/breakpoints';

const eachBreakpoint = getBreakpointNames(breakpoints);
const eachBreakpointWithoutXS = eachBreakpoint.slice(1) as string[];

export const Cols = () => {
    return (
        <div>
            <Description headline='Cols' anchor='cols'>
                Column classes indicate the number of columns you like to use out of the possible 12 per row. They must
                be immediate children of rows.
            </Description>
            <Playground example={colsExample} classes={colsClasses} />
            <Note>
                <div>
                    Col classes without a breakpoint addition are always weaker than with an addition. To reset a
                    breakpoint class, you always have to set another larger breakpoint class, otherwise the set
                    breakpoint is always active.
                </div>
                <div className='space-y-5 margin-top-5'>
                    <div>
                        <span className='text-color-success'>Right example</span>{' '}
                        <code className='code-inline'>col-xs-6</code>
                        <code className='code-inline'>col-sm-12</code> col-xs-6 will only be active on small screens
                    </div>
                    <div>
                        <span className='text-color-danger'>Wrong example</span>{' '}
                        <code className='code-inline'>col-xs-6</code>
                        <code className='code-inline'>col-12</code> col-xs-6 will always be active
                    </div>
                </div>
            </Note>
            <Description headline='Responsive cols' headlineType='h3' className='hidden-xs' />
            <Playground example={responsiveColsExample} showSizeButtons={false} />
            <div className='table-responsive'>
                <table className='table table-bordered table-head-filled'>
                    <thead>
                        <tr>
                            <th />
                            {eachBreakpointWithoutXS.map(breakpoint => (
                                <th key={crypto.randomUUID()}>{breakpoint}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>min-width</td>
                            {eachBreakpointWithoutXS.map(breakpointName => (
                                <td key={crypto.randomUUID()}>{findByName(breakpointName, breakpoints).minWidth}</td>
                            ))}
                        </tr>
                        <tr>
                            <td>class</td>
                            {eachBreakpointWithoutXS.map(breakpointName => (
                                <td key={crypto.randomUUID()}>
                                    <code>col-{breakpointName}-X</code>
                                </td>
                            ))}
                        </tr>
                    </tbody>
                </table>
            </div>
            <Description headline='Equal Height Cols' headlineType='h3' />
            <Playground example={equalHeightExample} classes={equalHeightClasses} background={PLAYGROUNG_BG_LIGHTER} />
        </div>
    );
};

const colsExample = (
    <>
        <div className='row'>
            {[1].map(() => (
                <div className='col-12 margin-bottom-20' key={crypto.randomUUID()}>
                    <div className='bg-info padding-10 rounded'>col-12</div>
                </div>
            ))}
        </div>
        <div className='row'>
            {[...Array(2)].map(() => (
                <div className='col-6 margin-bottom-20' key={crypto.randomUUID()}>
                    <div className='bg-info padding-10 rounded'>col-6</div>
                </div>
            ))}
        </div>
        <div className='row'>
            {[...Array(3)].map(() => (
                <div className='col-4 margin-bottom-20' key={crypto.randomUUID()}>
                    <div className='bg-info padding-10 rounded'>col-4</div>
                </div>
            ))}
        </div>
        <div className='row'>
            {[...Array(4)].map(() => (
                <div className='col-3 margin-bottom-20' key={crypto.randomUUID()}>
                    <div className='bg-info padding-10 rounded'>col-3</div>
                </div>
            ))}
        </div>
        <div className='row'>
            {[...Array(6)].map(() => (
                <div className='col-2 margin-bottom-20' key={crypto.randomUUID()}>
                    <div className='bg-info padding-10 rounded'>col-2</div>
                </div>
            ))}
        </div>
        <div className='row hidden-xs'>
            {[...Array(12)].map(() => (
                <div className='col-1' key={crypto.randomUUID()}>
                    <div className='bg-info padding-10 rounded'>col-1</div>
                </div>
            ))}
        </div>
    </>
);

const colsClasses = (
    <>
        <PlaygroundClasses label='Wrapper' classes='row' />
        <PlaygroundClasses label='Columns' prefix='col-' classes={[...Array(12)]} />
        <PlaygroundResponsiveClasses affixInside />
    </>
);

const responsiveColsExample = (
    <>
        <div className='row'>
            {[...Array(4)].map(() => (
                <div className='col-xs-6 col-sm-3 margin-bottom-20' key={crypto.randomUUID()}>
                    <div className='bg-info padding-10 rounded'>col-xs-6 col-sm-3</div>
                </div>
            ))}
        </div>
    </>
);

const equalHeightExample = (
    <div className='row equal-height'>
        <div className='col-6'>
            <div className='bg-info padding-10 rounded height-100pct'>{dummyTextUltraShort}</div>
        </div>
        <div className='col-6'>
            <div className='bg-info padding-10 rounded height-100pct'>{dummyTextShort}</div>
        </div>
    </div>
);

const equalHeightClasses = (
    <>
        <PlaygroundClasses label='Wrapper' classes='row equal-height' />
        <PlaygroundClasses label='Columns' prefix='col-' classes={[...Array(12)]} />
        <PlaygroundClasses label='Child' classes='height-100pct' />
        <PlaygroundResponsiveClasses affixInside />
    </>
);
