// @ts-ignore-next-line importsNotUsedAsValues
import React, { type PropsWithChildren } from 'react';

export type TreeSummaryRowProps = {
    /**
     * Defined the number of grid columns for a single row. Note, when the
     * amount of children exceed the columns it will wrap around to a new line.
     *
     * @default 4
     */
    gridCols?: number;
};

export const TreeSummaryRow = ({ gridCols = 4, children }: PropsWithChildren<TreeSummaryRowProps>) => (
    <div className={`TreeSummaryRow display-grid grid-cols-${gridCols} gap-10`}>{children}</div>
);

export default TreeSummaryRow;
