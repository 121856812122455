// @ts-ignore-next-line importsNotUsedAsValues
import React, { type PropsWithChildren } from 'react';
import classNames from 'classnames';

export type TreeNodeContainerProps = {
    isOpen: boolean;
    groupId?: string;
    disableAnimation: boolean;
};

const TreeNodeContainer = (props: PropsWithChildren<TreeNodeContainerProps>) => {
    const { isOpen, groupId, disableAnimation, children } = props;

    const treeNodeContainerClasses = classNames(
        'TreeNodeContainer',
        'user-select-none overflow-hidden',
        isOpen && 'open'
    );

    return (
        <div className={treeNodeContainerClasses} data-id={groupId}>
            {children}
        </div>
    );
};

export default TreeNodeContainer;
