import flatten from 'lodash/fp/flatten';
import map from 'lodash/fp/map';
import flow from 'lodash/fp/flow';
import find from 'lodash/fp/find';

const mapItems = (items, mapper) => flow(flatten, mapper)(items);

export const getNames = (...args) =>
    mapItems(
        args,
        map(item => item.name)
    );

export const getClasses = (...args) =>
    mapItems(
        args,
        map(color => color.class)
    );

export const getBackgrounds = (...args) =>
    mapItems(
        args,
        map(color => color.background)
    );

// [{
//      class: 'line-height-10',
//      value: '10',
// }]
export const findByClass = (value, array) => find(item => item.class === value, array);

// [{
//     name: 'ls',
//     minWidth: '480px',
//     maxWidth: '767.99px',
// }]
export const findByName = (value, array) => find(item => item.name === value, array);
