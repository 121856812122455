import { lazy, Suspense, useEffect, useState } from 'react';
import { NavLink, Redirect, Route, Switch } from 'react-router-dom';
import type { RouteComponentProps } from 'react-router';
import qs from 'qs';

import packageJson from '../../package.json';
import { getThemeFromDocument, STYLE_RIO, STYLE_RIO_LIGHT, Themes } from './header/Themes';
import { Loading } from './components/Loading';
import { ApplicationLabel } from './header/ApplicationLabel';
import { VersionSwitcher } from './header/VersionSwitcher';
import { navItems } from './header/Navigation';
import { MenuToggle } from './header/MenuToggle';
import { UikitContext } from './UikitContext';
import ApplicationLayout from '../../src/ApplicationLayout';
import ApplicationHeader from '../../src/ApplicationHeader';
import VolkswagenApplicationHeader from '../../src/VolkswagenApplicationHeader';
import NotificationsContainer from '../../src/NotificationsContainer';
import { isMobile, isMobileScreen, toggleZoomOnMobile } from '../../src/DeviceUtils';
import { getStartMenuItems } from './sites/start/StartMenu';
import { getComponentsMenuItems } from './sites/components/ComponentsMenu';
import { getDesignMenuItems } from './sites/design/DesignMenu';
import { getMapMenuItems } from './sites/map/MapComponentsMenu';
import { getChartsMenuItems } from './sites/charts/ChartsMenu';

import './uikit.less';

const Start = lazy(() => import('./sites/start/Start'));
const Design = lazy(() => import('./sites/design/Design'));
const Components = lazy(() => import('./sites/components/Components'));
const MapComponents = lazy(() => import('./sites/map/MapComponents'));
const ChartComponents = lazy(() => import('./sites/charts/ChartComponents'));
const DemoService = lazy(() => import('./sites/demoService/index'));
const Internal = lazy(() => import('./sites/internal/Internal'));
const WidgetDemo = lazy(() => import('./sites/widgets/WidgetDemo'));
const WidgetDemoDialog = lazy(() => import('./sites/widgets/WidgetDemoDialog'));
const PostMessageExample = lazy(() => import('./sites/widgets/PostMessageExample'));

const CDN_URL = 'https://uikit.developers.rio.cloud';

const toggleTheme = (style = '', href?: string) => {
    const html = document.documentElement;

    // Remove any theme css before applying a new theme
    const headerLinks = [...document.head.querySelectorAll('link')];
    headerLinks.forEach(headerLink => {
        if (
            headerLink.href.includes('vw-') ||
            headerLink.href.includes('man-') ||
            headerLink.href.includes('scania-') ||
            headerLink.href.includes('traton-')
        ) {
            document.head.removeChild(headerLink);
        }
    });

    // Remove the theme class name
    html.classList.remove('style-vw');
    html.classList.remove('style-man');
    html.classList.remove('style-scania');
    html.classList.remove('style-traton');

    if (style === 'RIO') {
        return;
    }

    // Add the theme CSS import
    const styleName = style.toLowerCase();

    const isDev = import.meta.env.DEV;

    const source = isDev ? 'dist' : CDN_URL;
    const linkRef = href || `${source}/${packageJson.version}/${styleName}-uikit.css`;

    const link = document.createElement('link');
    link.href = linkRef;
    link.type = 'text/css';
    link.rel = 'stylesheet';
    document.head.appendChild(link);

    // Add the theme class name
    html.classList.add(`style-${style.toLowerCase()}`);
};

const isMenuToggleClicked = (event: MouseEvent | TouchEvent) => {
    const target = event.target as HTMLElement;

    const className = target?.className;
    if (typeof className === 'object') {
        return true;
    }

    if (className.includes('UikitMenuToggle')) {
        return true;
    }

    if (className.includes('ActionBarItemIcon')) {
        return (target.firstChild as HTMLElement).className.includes('UikitMenuToggle');
    }
};

const excludeHeaderUrls = [
    'demoService/start',
    'demoService/table',
    'iframe-widget-example',
    'widgetDemo',
    'widgetDemoDialog',
    'postMessageExample',
];

export const UIKIT = ({ match, location }: RouteComponentProps) => {
    const { url } = match;
    const { search, pathname } = location;

    const getInitialTheme = () => {
        const documentTheme = getThemeFromDocument();
        return documentTheme ? `RIO_${documentTheme.toUpperCase()}` : STYLE_RIO_LIGHT;
    };

    const isMobileOrSmallScreen = isMobile() || isMobileScreen();

    const [theme, setTheme] = useState(getInitialTheme());
    const [showHomeIcon, setShowHomeIcon] = useState(false);
    const [showMenu, setShowMenu] = useState(!isMobileOrSmallScreen);
    const [showVolkswagenHeader, setShowVolkswagenHeader] = useState(false);

    useEffect(() => {
        toggleZoomOnMobile();
    }, []);

    const handleMenuIconClicked = () => {
        if (showMenu) {
            setShowMenu(false);
        } else {
            setShowMenu(true);
        }
    };

    const handleMenuOutsideClicked = (event: MouseEvent | TouchEvent) => {
        const isMenuIcon = isMenuToggleClicked(event);
        if (!isMenuIcon && showMenu) {
            setShowMenu(false);
        }
    };

    // Workaround:
    // Replace the encoded # character for deep-links like to the writing guide.
    // This might happen when opening the link from Slack or other applications.
    if (pathname.includes('%23')) {
        window.location.href = window.location.href.replace('%23', '#');
    }

    const searchParams: { style?: string; css?: string } = qs.parse(search?.replace('?', ''));

    if (searchParams.style) {
        toggleTheme(searchParams.style, searchParams.css);
        setShowHomeIcon(searchParams.style === 'man');
        setShowVolkswagenHeader(searchParams.style === 'vw');
    }

    const handleThemeChange = (themeWithMode: string) => {
        const newTheme = themeWithMode.startsWith(STYLE_RIO) ? themeWithMode.split('-')[0] : themeWithMode;
        toggleTheme(newTheme);
        setShowHomeIcon(newTheme === 'MAN');
        setShowVolkswagenHeader(newTheme === 'VW');

        // Store brand style + light/dark theme in context to be used to switch
        // intro image composition
        setTheme(themeWithMode);
    };

    // Don't show the UIKIT ApplicationHeader for certain deep links
    const hasHeader = !excludeHeaderUrls.includes(pathname.replace('/', ''));

    const actionBarItems = [];
    actionBarItems.push(<VersionSwitcher key='versionSwitcher' />);
    // actionBarItems.push(<About key='about' />);
    actionBarItems.push(<Themes key='themes' onThemeChange={handleThemeChange} />);
    actionBarItems.push(<MenuToggle key='menuToggle' />);

    return (
        <UikitContext.Provider
            value={{
                theme,
                showMenu,
                onMenuIconClicked: handleMenuIconClicked,
                onMenuOutsideClicked: handleMenuOutsideClicked,
                startMenu: getStartMenuItems(),
                designMenu: getDesignMenuItems(),
                componentsMenu: getComponentsMenuItems(),
                mapMenu: getMapMenuItems(),
                chartsMenu: getChartsMenuItems(),
            }}
        >
            <ApplicationLayout className='uikit-demo'>
                <NotificationsContainer />
                {hasHeader && (
                    <ApplicationLayout.Header>
                        {showVolkswagenHeader && (
                            <VolkswagenApplicationHeader
                                homeRoute={<NavLink to='/' />}
                                navItems={navItems}
                                actionBarItems={actionBarItems}
                            />
                        )}
                        {!showVolkswagenHeader && (
                            <ApplicationHeader
                                label={<ApplicationLabel />}
                                homeRoute={<NavLink to='/' />}
                                showHomeIcon={showHomeIcon}
                                navItems={navItems}
                                actionBarItems={actionBarItems}
                            />
                        )}
                    </ApplicationLayout.Header>
                )}
                <Suspense fallback={<Loading />}>
                    <Switch>
                        {/* Fallback for external links to icons */}
                        <Redirect from='/components/icons' to='/design/rioglyph' />

                        <Route path={`${url}start`} component={Start} />
                        <Route path={`${url}design`} component={Design} />
                        <Route path={`${url}components`} component={Components} />
                        <Route path={`${url}map`} component={MapComponents} />
                        <Route path={`${url}charts`} component={ChartComponents} />
                        <Route path={`${url}demoService`} component={DemoService} />
                        <Route path={`${url}internal`} component={Internal} />
                        <Route path={`${url}widgetDemo`} component={WidgetDemo} />
                        <Route path={`${url}widgetDemoDialog`} component={WidgetDemoDialog} />
                        <Route path={`${url}postMessageExample`} component={PostMessageExample} />
                        <Redirect to='/start' />
                    </Switch>
                </Suspense>
            </ApplicationLayout>
        </UikitContext.Provider>
    );
};
