import type { PropsWithChildren } from 'react';
import { NavLink } from 'react-router-dom';
import noop from 'lodash/fp/noop';

import { New, Beta } from '../../components/NavLinkItem';

import { UikitMenuSidebar } from '../../components/UikitMenuSidebar';
import { UikitMenu } from '../../components/UikitMenu';
import { routes } from '../../routes';

const base = routes.START;

export const getStartMenuItems = () => [
    {
        navItems: [
            {
                key: 'Intro',
                meta: 'intro',
                item: (
                    <NavLink activeClassName='active' to={`${base}/intro`}>
                        Intro
                    </NavLink>
                ),
            },
            {
                key: 'How to',
                meta: 'howto',
                item: (
                    <NavLink activeClassName='active' to={`${base}/howto`}>
                        How to
                    </NavLink>
                ),
            },
            {
                key: 'Changelog',
                meta: 'changelog',
                item: (
                    <NavLink activeClassName='active' to={`${base}/changelog`}>
                        Changelog
                    </NavLink>
                ),
            },
        ],
    },
    {
        group: 'Guidelines',
        navItems: [
            {
                key: 'Responsiveness',
                meta: 'responsiveness platforms breakpoints col grid flex',
                item: (
                    <NavLink activeClassName='active' to={`${base}/responsiveness`}>
                        Responsiveness
                    </NavLink>
                ),
            },
            {
                key: 'Good to know',
                meta: 'goodtoknow',
                item: (
                    <NavLink activeClassName='active' to={`${base}/goodtoknow`}>
                        Good to know
                    </NavLink>
                ),
            },
            {
                key: 'Color combinations',
                meta: 'color-combinations',
                item: (
                    <NavLink activeClassName='active' to={`${base}/guidelines/color-combinations`}>
                        Color combinations
                    </NavLink>
                ),
            },
            {
                key: 'Custom CSS',
                meta: 'custom-css',
                item: (
                    <NavLink activeClassName='active' to={`${base}/guidelines/custom-css`}>
                        Custom CSS
                    </NavLink>
                ),
            },
            {
                key: 'Cross-Browser',
                meta: 'cross-browser',
                item: (
                    <NavLink activeClassName='active' to={`${base}/guidelines/cross-browser`}>
                        Cross-Browser
                    </NavLink>
                ),
            },
            {
                key: 'iframe',
                meta: 'iframe',
                item: (
                    <NavLink activeClassName='active' to={`${base}/guidelines/iframe`}>
                        Iframes
                    </NavLink>
                ),
            },
            {
                key: 'Loading',
                meta: 'spinner',
                item: (
                    <NavLink activeClassName='active' to={`${base}/guidelines/spinner`}>
                        Loading
                    </NavLink>
                ),
            },
            {
                key: 'Email',
                meta: 'email',
                item: (
                    <NavLink activeClassName='active' to={`${base}/guidelines/email`}>
                        HTML Emails
                    </NavLink>
                ),
            },
            {
                key: 'Print CSS',
                meta: 'print-css',
                item: (
                    <NavLink activeClassName='active' to={`${base}/guidelines/print-css`}>
                        Print CSS
                    </NavLink>
                ),
            },
            {
                key: 'Supported Browsers',
                meta: 'supported-browsers',
                item: (
                    <NavLink activeClassName='active' to={`${base}/guidelines/supported-browsers`}>
                        Supported Browsers
                    </NavLink>
                ),
            },
            {
                key: 'Formatting guide',
                meta: 'formatting date number dateformat datetime time format',
                item: (
                    <NavLink activeClassName='active' to={`${base}/guidelines/formatting`}>
                        Formatting guide
                    </NavLink>
                ),
            },
            {
                key: 'Writing style guide',
                meta: 'writing',
                item: (
                    <NavLink activeClassName='active' to={`${base}/guidelines/writing`}>
                        Writing style guide
                    </NavLink>
                ),
            },
        ],
    },
    {
        group: 'Demo service',
        navItems: [
            {
                key: 'Start',
                meta: 'start',
                item: (
                    <NavLink activeClassName='active' to='/demoService/start'>
                        Start
                    </NavLink>
                ),
            },
            {
                key: 'Table',
                meta: 'table',
                item: (
                    <NavLink activeClassName='active' to='/demoService/table'>
                        Table
                    </NavLink>
                ),
            },
        ],
    },
];

export const StartMenu = () => (
    <UikitMenuSidebar title='Getting started'>
        <UikitMenu />
    </UikitMenuSidebar>
);
