// @ts-ignore-next-line importsNotUsedAsValues
import React from 'react';
import classNames from 'classnames';
import noop from 'lodash/fp/noop';

import DropdownSubmenu from '../dropdown/DropdownSubmenu';
import DropdownHeader from '../selects/DropdownHeader';

export type MenuItemProps = {
    index?: number;
    value?: string | React.ReactNode;
    active?: boolean;

    /**
     * Setting "disabled" to true will disable the respective item.
     *
     * @default false
     */
    disabled?: boolean;

    /**
     * Will add a divider line between the items inside the dropdown.
     *
     * @default false
     */
    divider?: boolean;
    header?: boolean;

    /**
     * Callback function triggered when an item is selected.
     * @param value
     * @param event
     * @returns
     */
    onSelect?: (value: number | undefined, event: React.MouseEvent<HTMLLIElement>) => void;
    closeMenu?: VoidFunction;
    onMouseEnter?: (event: React.MouseEvent<HTMLLIElement>) => void;
};

const MenuItem = (props: MenuItemProps) => {
    const {
        index,
        value,
        divider = false,
        disabled = false,
        active = false,
        header = false,
        onSelect = noop,
        closeMenu = noop,
        onMouseEnter = noop,
    } = props;

    if (React.isValidElement(value) && value.type === DropdownSubmenu) {
        return value;
    }

    const role = divider ? 'separator' : 'presentation';

    const classes = classNames(divider && 'divider pointer-events-none', disabled && 'disabled', active && 'active');

    const handleSelectItem = (event: React.MouseEvent<HTMLLIElement>) => {
        if (!disabled) {
            onSelect(index, event);

            if (closeMenu) {
                closeMenu();
            }
        }
    };

    const handleItemMouseEnter = (event: React.MouseEvent<HTMLLIElement>) => {
        if (!disabled) {
            onMouseEnter(event);
        }
    };

    if (header) {
        return <DropdownHeader label={value} center={false} />;
    }

    return (
        <li
            role={role}
            className={classes}
            onClick={handleSelectItem}
            onMouseEnter={handleItemMouseEnter}
            data-item-index={index}
        >
            <a role='menuitem'>{value}</a>
        </li>
    );
};

export default MenuItem;
