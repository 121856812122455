import { type PropsWithChildren, type ReactNode, useContext } from 'react';

import ApplicationLayout from '../../../src/ApplicationLayout';
import Sidebar from '../../../src/Sidebar';
import useClickOutside from '../../../src/useClickOutside';
import { isMobile, isMobileScreen, SCREEN_SM } from '../../../src/DeviceUtils';
import { UikitContext } from '../UikitContext';

const SIDEBAR_WIDTH = 270;

type UikitMenuSidebarProps = {
    title: string | ReactNode;
};

export const UikitMenuSidebar = (props: PropsWithChildren<UikitMenuSidebarProps>) => {
    const { title, children } = props;
    const { onMenuOutsideClicked, showMenu } = useContext(UikitContext);
    const isMobileOrSmallScreen = isMobile() || isMobileScreen();
    const ref = useClickOutside(event => isMobileOrSmallScreen && onMenuOutsideClicked(event));

    return (
        <ApplicationLayout.Sidebar className='uikit-frame-menu'>
            <Sidebar
                title={title}
                titleClassName='padding-left-10'
                width={SIDEBAR_WIDTH}
                disableClose
                closed={!showMenu}
                hasSmoothScrollbar
                switchModeBreakpoint={SCREEN_SM}
                position={Sidebar.LEFT}
            >
                <div ref={ref}>{children}</div>
            </Sidebar>
        </ApplicationLayout.Sidebar>
    );
};
