import React, {
    useState,
    useRef,
    useEffect,
    forwardRef,
    useImperativeHandle,
    type PropsWithChildren,
    type ForwardedRef,
} from 'react';
import debounce from 'lodash/fp/debounce';
import classNames from 'classnames';

import type Scrollbars from 'react-custom-scrollbars-2';

import SmoothScrollbars from '../smoothScrollbars/SmoothScrollbars';

const RESIZE_THROTTLING = 200;
const DEFAULT_BOTTOM_BAR_HEIGHT = 54;

export type ApplicationLayoutBodyProps = {
    /**
     * Show scroll to top button after scrolling the content.
     *
     * @default true
     */
    enableScrollToTop?: boolean;

    /**
     * Always show vertical scrollbar to prevent the content from jumping.
     *
     * @default true
     */
    forceScrollbar?: boolean;

    /**
     * Prop to trigger scroll reset from outside. This comes in handy when changing the body content
     * and to reset the scrolling like when the pathname changes from an body internal menu.
     */
    scrollResetTrigger?: any;

    /**
     * The ApplicationLayoutBodyBanner component.
     */
    banner?: React.ReactNode;

    /**
     * The `ApplicationLayoutBodyNavigation` component.
     */
    navigation?: React.ReactNode;

    /**
     * Optional bottom bar component or a simple string.
     */
    bottomBar?: React.ReactNode;

    /**
     * Height of the bottomBar in pixel.
     *
     * @default 54
     */
    bottomBarHeight?: number;

    /**
     * Additional class names that are added to the inner module-content component.
     */
    innerClassName?: string;

    /**
     * Additional class names that are added to the respective component.
     */
    className?: string;
};

type Ref = ForwardedRef<HTMLDivElement>;
type Props = PropsWithChildren<ApplicationLayoutBodyProps>;

const ApplicationLayoutBody = forwardRef((props: Props, ref: Ref) => {
    const {
        className,
        innerClassName,
        forceScrollbar = true,
        enableScrollToTop = true,
        banner,
        navigation,
        bottomBar,
        bottomBarHeight = DEFAULT_BOTTOM_BAR_HEIGHT,
        scrollResetTrigger,
        children,
        ...remainingProps
    } = props;

    const [offset, setOffset] = useState(0);
    const moduleContentRef = useRef<Scrollbars>(null);
    const layoutBodyRef = useRef<HTMLDivElement | null>(null);

    useImperativeHandle<HTMLDivElement, any>(ref, () => layoutBodyRef, []);

    // Reset scroll position when children are different. This fixes the issue of
    // changing body content (like in another route) and still having the same scroll position
    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        if (moduleContentRef.current) {
            moduleContentRef.current.scrollTop(0);
            setOffset(0);
        }
    }, [scrollResetTrigger]);

    useEffect(() => {
        if (layoutBodyRef.current?.parentNode instanceof HTMLElement) {
            layoutBodyRef.current.parentNode.style.setProperty(
                '--ApplicationLayoutBodyBottomBarHeight',
                `${bottomBarHeight}px`
            );
        }
    }, [bottomBarHeight]);

    const handleScroll = debounce(RESIZE_THROTTLING)(() => {
        if (moduleContentRef.current) {
            setOffset(moduleContentRef.current.getScrollTop());
        }
    });

    const handleToTop = () => {
        if (!moduleContentRef.current) {
            return;
        }

        const scrollWrapper = moduleContentRef.current.container;
        const scrollingElement = scrollWrapper.firstElementChild;
        const currentScroll = scrollingElement?.scrollTop ?? 0;

        if (currentScroll > 0) {
            window.requestAnimationFrame(handleToTop);
            scrollingElement?.scrollTo(0, currentScroll - currentScroll / 5);
            scrollWrapper.classList.add('is-scrolling-to-top');
            setOffset(currentScroll);
        } else {
            scrollWrapper.classList.remove('is-scrolling-to-top');
        }
    };

    const classes = classNames('ApplicationLayoutBody', bottomBar && 'has-footer', className);

    const innerClasses = classNames(
        'module-content',

        innerClassName && innerClassName
    );

    const offsetThreshold = window.innerHeight * 0.1;

    const scrollToTopClasses = classNames('scroll-to-top', offset > offsetThreshold && 'in');

    return (
        <React.Fragment>
            <div {...remainingProps} ref={layoutBodyRef} className={classes}>
                <div className='module-content-wrapper'>
                    {navigation && navigation}
                    {banner && banner}

                    <SmoothScrollbars
                        ref={moduleContentRef}
                        slideIn={!forceScrollbar}
                        largeTrack
                        trackOffset
                        className={innerClasses}
                        onScroll={handleScroll}
                    >
                        <div className='scrollbar-content'>{children}</div>
                    </SmoothScrollbars>
                </div>

                {enableScrollToTop && (
                    <span className={scrollToTopClasses}>
                        <button type='button' className='btn btn-primary btn-icon-only' onClick={handleToTop}>
                            <span className='rioglyph rioglyph-arrow-up' />
                        </button>
                    </span>
                )}
            </div>

            {bottomBar && bottomBar}
        </React.Fragment>
    );
});

export default ApplicationLayoutBody;
