import process from 'process';

import { inIframe } from '../deviceUtils';

// Note: using import.meta.env.DEV does not work here due to backward compatibility with webpack and jest setups
export const isProd = process.env.NODE_ENV === 'production' || location.protocol.startsWith('https');
export const isTest = process.env.NODE_ENV === 'test';

export const isInIframe = inIframe();

export const debug = (message: string) => !isTest && console.debug(message);
