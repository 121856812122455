import React, { type PropsWithChildren } from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import { AnimatePresence, motion, type AnimatePresenceProps } from 'framer-motion';

import { getOrCreatePortalRoot } from '../../utils/portalRoot';

const OFFSET_POSITION = -1000;

export type MobileHeaderModalProps = {
    show?: boolean;
    showClose?: boolean;
    modalClassName?: string;
    onClose?: () => void;
    className?: string;
};

const MobileHeaderModal = (props: PropsWithChildren<MobileHeaderModalProps>) => {
    const { show = false, showClose = true, modalClassName, className, onClose = () => {}, children } = props;

    const wrapperClasses = classNames(
        'MobileHeaderModal',
        'position-fixed left-0 right-0 bottom-0',
        'z-index-max',
        className
    );

    const modalClasses = classNames(
        'display-flex flex-column justify-content-center',
        'margin-15',
        'text-center',
        'rounded',
        'bg-white',
        'shadow-hard',
        'overflow-auto',
        modalClassName
    );

    const modalRoot = getOrCreatePortalRoot();

    const pageHeight = window.innerHeight - 30;
    const offset = OFFSET_POSITION - window.innerHeight;

    return (
        <>
            {ReactDOM.createPortal(
                <React.Fragment>
                    {/* @ts-expect-error */}
                    <AnimatePresence>
                        {show && (
                            <motion.div
                                className={wrapperClasses}
                                initial={{ opacity: 0, top: offset }}
                                animate={{ opacity: 1, y: 0, top: 0 }}
                                exit={{ opacity: 0, transition: { duration: 0.3 }, top: offset }}
                            >
                                <div className={modalClasses} style={{ height: `${pageHeight}px` }}>
                                    {children}
                                </div>
                                {showClose && (
                                    <div
                                        className={
                                            'position-absolute top-15 right-15 margin-top-15 margin-right-15 ' +
                                            'text-size-20 text-color-dark'
                                        }
                                        onClick={onClose}
                                    >
                                        <span className='rioglyph rioglyph-remove' />
                                    </div>
                                )}
                            </motion.div>
                        )}
                    </AnimatePresence>
                    {/* @ts-expect-error */}
                    <AnimatePresence>
                        {show && (
                            <motion.div
                                className='bottom-sheet-backdrop'
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 0.5 }}
                                exit={{ opacity: 0 }}
                            />
                        )}
                    </AnimatePresence>
                </React.Fragment>,
                modalRoot
            )}
        </>
    );
};

export default MobileHeaderModal;
