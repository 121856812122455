import { type PropsWithChildren } from 'react';

import { Playground } from '../../../components/playground/Playground';
import { PlaygroundClasses } from '../../../components/playground/PlaygroundClasses';
import { Description } from '../../../components/Description';

export const Rounded = () => (
    <div>
        <Description headline='Rounded' anchor='rounded' />
        <Playground example={roundedExample} classes={roundedClasses} />
        <Playground example={roundedSizeExample} classes={roundedSizesClasses} />
    </div>
);

type BoxProps = {
    size: string;
    ratio: string;
    className: string;
};

const Box = ({ size, ratio, className, children }: PropsWithChildren<BoxProps>) => (
    <div
        className={`
            width-${size} aspect-ratio-${ratio} bg-highlight padding-5
            display-flex align-items-center justify-content-center ${className}`}
    >
        {children}
    </div>
);

const roundedExample = (
    <div>
        <div className='display-flex flex-wrap gap-20 margin-bottom-20'>
            <Box size='100' ratio='1' className='rounded'>
                rounded
            </Box>
            <Box size='100' ratio='1' className='rounded-circle'>
                ...-circle
            </Box>
        </div>
        <div className='display-flex flex-wrap gap-20 margin-bottom-20'>
            <Box size='150' ratio='2-1' className='rounded-top'>
                ...-top
            </Box>
            <Box size='150' ratio='2-1' className='rounded-bottom'>
                ...-bottom
            </Box>
            <Box size='150' ratio='2-1' className='rounded-left'>
                ...-left
            </Box>
            <Box size='150' ratio='2-1' className='rounded-right'>
                ...-right
            </Box>
        </div>
        <div className='display-flex flex-wrap gap-20'>
            <Box size='150' ratio='2-1' className='rounded-top-left'>
                ...-top-left
            </Box>
            <Box size='150' ratio='2-1' className='rounded-top-right'>
                ...-top-right
            </Box>
            <Box size='150' ratio='2-1' className='rounded-bottom-right'>
                ...-bottom-right
            </Box>
            <Box size='150' ratio='2-1' className='rounded-bottom-left'>
                ...-bottom-left
            </Box>
        </div>
    </div>
);

const roundedClasses = (
    <>
        <PlaygroundClasses label='None' classes='rounded-none' />
        <PlaygroundClasses label='Inherit' classes='rounded-inherit' />
        <PlaygroundClasses label='Surrounding' classes={['rounded', 'rounded-circle']} />
        <PlaygroundClasses label='Top & Bottom' prefix='rounded-' classes={['top', 'bottom']} />
        <PlaygroundClasses label='Left & Right' prefix='rounded-' classes={['left', 'right']} />
        <PlaygroundClasses
            label='Single corner'
            prefix='rounded-'
            classes={['top-left', 'top-right', 'bottom-left', 'bottom-right']}
        />
    </>
);

const roundedSizeExample = (
    <div className='display-flex flex-wrap gap-20'>
        <Box size='150' ratio='2-1' className='rounded-small'>
            ...-small
        </Box>
        <Box size='150' ratio='2-1' className='rounded'>
            ...
        </Box>
        <Box size='150' ratio='2-1' className='rounded-large'>
            ...-large
        </Box>
        <Box size='150' ratio='2-1' className='rounded-extra-large'>
            ...-extra-large
        </Box>
    </div>
);

const roundedSizesClasses = (
    <>
        <PlaygroundClasses
            label='Small'
            classes={['rounded-small', 'rounded-top-small', 'rounded-bottom-right-small', '...']}
        />
        <PlaygroundClasses label='Default' classes={['rounded', 'rounded-top', 'rounded-bottom-right', '...']} />
        <PlaygroundClasses
            label='Large'
            classes={['rounded-large', 'rounded-top-large', 'rounded-bottom-right-large', '...']}
        />
        <PlaygroundClasses
            label='Extra large'
            classes={['rounded-extra-large', 'rounded-top-extra-large', 'rounded-bottom-right-extra-large', '...']}
        />
    </>
);
