import { useState } from 'react';

import { Playground } from '../../../components/playground/Playground';
import { Description } from '../../../components/Description';

import ClearableInput from '../../../../../src/ClearableInput';
import { rioglypIcons } from './RioglyphPreview';
import { iconsMetaData } from './iconsMetaData';

export const RioglyphCheatsheet = () => (
    <div className='uikit-icons'>
        <Description headline='Icon cheatsheet' anchor='cheatsheet' />
        <Playground example={<RioglyphCheatsheetExample />} />
    </div>
);

const RioglyphCheatsheetExample = () => {
    const [icons, setIcons] = useState<string[]>(rioglypIcons ?? []);
    const [filterValue, setFilterValue] = useState('');

    const handleFilterIcons = (value: string) => {
        const filterByNameResult = rioglypIcons.filter(icon => {
            return icon.includes(value) || iconsMetaData[icon]?.toLowerCase().includes(value.toLowerCase());
        });
        setIcons(filterByNameResult);
        setFilterValue(value);
    };

    return (
        <>
            <div className='form-group max-width-500'>
                <div className='input-group width-100pct'>
                    <span className='input-group-addon'>
                        <span className='rioglyph rioglyph-search' aria-hidden='true' />
                    </span>
                    <ClearableInput
                        value={filterValue}
                        placeholder='Filter by icon name'
                        onChange={handleFilterIcons}
                    />
                </div>
            </div>
            <div className='display-flex flex-wrap'>
                {icons?.map(icon => (
                    <div
                        key={icon}
                        className='webfont-preview display-flex align-items-center margin-top-10 margin-bottom-10'
                    >
                        <span className={`rioglyph ${icon} text-size-20 margin-right-10`} />
                        <span className='text-light user-select-all'>{`rioglyph ${icon}`}</span>
                    </div>
                ))}
            </div>
        </>
    );
};
