import { Playground } from '../../../components/playground/Playground';
import {
    PlaygroundClasses,
    PlaygroundResponsiveClasses,
    PlaygroundPrintClasses,
    PlaygroundClassesSpacer,
} from '../../../components/playground/PlaygroundClasses';
import { Description } from '../../../components/Description';

import { sizes } from '../../../utils/sizes';

export const Height = () => (
    <div>
        <Description headline='Height' anchor='height' />
        <Playground example={heightExample} classes={heightClasses} />
    </div>
);

const heightExample = (
    <div className='display-grid grid-cols-1 grid-cols-5-sm gap-20'>
        <div className='bg-info rounded height-20' />
        <div className='bg-info rounded height-40' />
        <div className='bg-info rounded height-60' />
        <div className='bg-info rounded height-80' />
        <div className='bg-info rounded height-100' />
    </div>
);

const heightClasses = (
    <>
        <PlaygroundClasses label='Resetting' classes={['height-auto', 'min-height-0', 'min-height-auto']} />
        <PlaygroundClassesSpacer label='Pixel values' />
        <PlaygroundClasses label='Height' prefix='height-' classes={sizes.px} />
        <PlaygroundClasses label='Min height' prefix='min-height-' classes={sizes.px_50_to_500} />
        <PlaygroundClasses label='Max height' prefix='max-height-' classes={['none', '0', ...sizes.px_50_to_500]} />
        <PlaygroundClassesSpacer label='Percentage values' />
        <PlaygroundClasses label='Height' prefix='height-' classes={sizes.pct} suffix='pct' />
        <PlaygroundClasses label='Min height' prefix='min-height-' classes={sizes.pct} suffix='pct' />
        <PlaygroundClasses label='Max height' prefix='max-height-' classes={sizes.pct} suffix='pct' />
        <PlaygroundClassesSpacer label='Viewport values' />
        <PlaygroundClasses label='Height' prefix='height-' classes={sizes.vh} suffix='vh' />
        <PlaygroundClasses label='Min height' prefix='min-height-' classes={sizes.vh} suffix='vh' />
        <PlaygroundClasses label='Max height' prefix='max-height-' classes={sizes.vh} suffix='vh' />
        <PlaygroundResponsiveClasses />
        <PlaygroundPrintClasses />
    </>
);
