// @ts-ignore-next-line importsNotUsedAsValues
import React from 'react';
import noop from 'lodash/fp/noop';

import Checkbox from '../checkbox/Checkbox';

export type TreeSelectAllProps = {
    value?: string;
    onSelect?: Function;
    isChecked?: boolean;
    isEnabled?: boolean;
    isDisabled?: boolean;
    isIndeterminate?: boolean;
    hasSearchResults?: boolean;
    className?: string;
};

const TreeSelectAll = (props: TreeSelectAllProps) => {
    const {
        isDisabled,
        isChecked = false,
        isEnabled = true,
        isIndeterminate = false,
        hasSearchResults = false, // needed?
        onSelect = noop,
    } = props;

    const handleSelectAll = (event: React.MouseEvent<HTMLInputElement>) =>
        onSelect((event.target as HTMLInputElement).checked, isIndeterminate);

    return isEnabled ? (
        <div className='TreeSelectAll display-flex align-items-center'>
            <Checkbox
                checked={isChecked}
                onClick={handleSelectAll}
                disabled={isDisabled}
                indeterminate={isIndeterminate}
                className='margin-top--1'
            />
        </div>
    ) : null;
};

export default TreeSelectAll;
