// @ts-ignore-next-line importsNotUsedAsValues
import React, { type HTMLAttributes, type PropsWithChildren } from 'react';
import classNames from 'classnames';
import omit from 'lodash/fp/omit';

export type ActionBarItemPopoverProps = Omit<HTMLAttributes<HTMLDivElement>, 'title' | 'useOffscreen'> & {
    /**
     * The popover title.
     */
    title?: string | React.ReactNode;

    /**
     * Define whether the popover content shall be rendered behind the scene.
     *
     * This allows to preload content or to avoid unmounting the content component.
     */
    useOffscreen?: boolean;

    /**
     * Additional class names that are added to the popover content.
     */
    className?: string;
};

const ActionBarItemPopoverContent = ({
    className,
    children,
    ...remainingProps
}: PropsWithChildren<ActionBarItemPopoverProps>) => {
    const classes = classNames('ActionBarItemPopoverContent', className);
    const filterProps = omit(['title', 'useOffscreen'])(remainingProps) as HTMLAttributes<HTMLDivElement>;

    return (
        <div {...filterProps} className={classes}>
            {children}
        </div>
    );
};

// The displayName is used in ActionBarItem for checking the component "isActionBarItemPopover"
ActionBarItemPopoverContent.displayName = 'ActionBarItemPopoverContent';

export default ActionBarItemPopoverContent;
