import type { ReactNode, RefObject } from 'react';
import isEmpty from 'lodash/fp/isEmpty';

import { ExpanderList, type ExpanderListItem } from '../index';
import { CodeBlock } from './CodeBlock';

type CodeSnippetsProps = {
    showHtml?: boolean;
    codeHtml?: string;
    codeHtmlTitle?: string;
    codeReact?: string;
    codeReactTitle?: string;
    codeJS?: string;
    codeJSTitle?: string;
    exampleRef?: RefObject<HTMLDivElement>;
    componentProps?: ReactNode;
    classes?: ReactNode;
    openBlock?: 'codeReact' | 'codeHtml' | 'codeJS' | 'props' | 'classes';
};

export const CodeSnippets = (props: CodeSnippetsProps) => {
    const ref = isEmpty(props.codeHtml) ? props.exampleRef : undefined;

    const listItems: ExpanderListItem[] = [];

    if (props.codeReact) {
        listItems.push(
            makeListItem({
                title: props.codeReactTitle,
                body: <CodeBlock language='language-jsx'>{props.codeReact}</CodeBlock>,
                open: props.openBlock === 'codeReact',
            })
        );
    }

    if (props.showHtml) {
        listItems.push(
            makeListItem({
                title: props.codeHtmlTitle,
                body: (
                    <CodeBlock language='language-markup' exampleRef={ref}>
                        {props.codeHtml}
                    </CodeBlock>
                ),
                open: props.openBlock === 'codeHtml',
            })
        );
    }

    if (props.codeJS) {
        listItems.push(
            makeListItem({
                title: props.codeJSTitle,
                body: <CodeBlock language='language-javascript'>{props.codeJS}</CodeBlock>,
                open: props.openBlock === 'codeJS',
            })
        );
    }

    if (props.componentProps) {
        listItems.push(
            makeListItem({
                title: 'Props',
                body: (
                    <div>
                        {props.componentProps}
                        <div className='margin-top-15'>
                            <i>* Required props are displayed in bold</i>
                        </div>
                    </div>
                ),
                open: props.openBlock === 'props',
            })
        );
    }

    if (props.classes) {
        listItems.push(
            makeListItem({
                title: 'Classes',
                body: <div>{props.classes}</div>,
                open: props.openBlock === 'classes',
            })
        );
    }

    return (
        <div className='playground-expander'>
            <ExpanderList
                className='border border-top-only rounded-bottom'
                bordered={false}
                rounded={false}
                items={listItems}
            />
        </div>
    );
};

const makeListItem = (opts: { title: string | undefined; body: ReactNode; open: boolean }): ExpanderListItem => ({
    className: 'overflow-hidden',
    headerClassName: 'expander-list-header-bg',
    header: <span className='code-sample-header'>{opts.title ?? ''}</span>,
    body: opts.body,
    open: opts.open,
});
