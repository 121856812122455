export default () => (
    <div className='row equal-height'>
        <div className='col-12 col-md-6 margin-bottom-15'>
            <div className='panel panel-default panel-body height-100pct shadow-default'>
                <div className='h5'>Size by form-Group class</div>
                <div className='form-group form-group-sm'>
                    <label htmlFor='input1'>form-group-sm</label>
                    <input id='input1' className='form-control' type='text' placeholder='Lorem Ipsum Dolor' />
                </div>

                <div className='form-group form-group-sm'>
                    <label htmlFor='input2'>form-group-sm</label>
                    <div className='input-group'>
                        <span className='input-group-addon'>
                            <span className='rioglyph rioglyph-heart' />
                        </span>
                        <input id='input2' className='form-control' type='text' placeholder='Lorem Ipsum Dolor' />
                        <span className='input-group-addon'>XX</span>
                    </div>
                </div>

                <hr className='margin-top-25 margin-bottom-15' />

                <div className='form-group form-group-sm has-error has-feedback'>
                    <label htmlFor='input3'>form-group-sm</label>
                    <input id='input3' type='text' className='form-control' />
                    <span className='form-control-feedback rioglyph rioglyph-error-sign' />
                </div>

                <div className='form-group form-group'>
                    <label htmlFor='input4'>form-group</label>
                    <input id='input4' className='form-control' type='text' placeholder='Lorem Ipsum Dolor' />
                </div>

                <div className='form-group form-group'>
                    <label htmlFor='input5'>form-group</label>
                    <div className='input-group'>
                        <span className='input-group-addon'>
                            <span className='rioglyph rioglyph-heart' />
                        </span>
                        <input id='input5' className='form-control' type='text' placeholder='Lorem Ipsum Dolor' />
                        <span className='input-group-addon'>XX</span>
                    </div>
                </div>

                <div className='form-group form-group has-error has-feedback'>
                    <label htmlFor='input6'>form-group</label>
                    <input id='input6' type='text' className='form-control' />
                    <span className='form-control-feedback rioglyph rioglyph-error-sign' />
                </div>

                <hr className='margin-top-25 margin-bottom-15' />

                <div className='form-group form-group-lg'>
                    <label htmlFor='input7'>form-group-lg</label>
                    <div className='input-group'>
                        <span className='input-group-addon'>
                            <span className='rioglyph rioglyph-heart' />
                        </span>
                        <input id='input7' className='form-control' type='text' placeholder='Lorem Ipsum Dolor' />
                        <span className='input-group-addon'>XX</span>
                    </div>
                </div>

                <div className='form-group form-group-lg'>
                    <label htmlFor='input8'>form-group-lg</label>
                    <input id='input8' className='form-control' type='text' placeholder='Lorem Ipsum Dolor' />
                </div>

                <div className='form-group form-group-lg has-error has-feedback'>
                    <label htmlFor='input9'>form-group-lg</label>
                    <input id='input9' type='text' className='form-control' />
                    <span className='form-control-feedback rioglyph rioglyph-error-sign' />
                </div>
            </div>
        </div>

        <div className='col-12 col-md-6 margin-bottom-15'>
            <div className='panel panel-default panel-body height-100pct shadow-default'>
                <div className='h5'>Size by Input Class</div>
                <div className='form-group'>
                    <label htmlFor='input10'>input-sm</label>
                    <input id='input10' className='form-control input-sm' type='text' placeholder='Lorem Ipsum Dolor' />
                </div>

                <div className='form-group'>
                    <label htmlFor='input11'>input-group-sm</label>
                    <div className='input-group input-group-sm'>
                        <span className='input-group-addon'>
                            <span className='rioglyph rioglyph-heart' />
                        </span>
                        <input id='input11' className='form-control' type='text' placeholder='Lorem Ipsum Dolor' />
                        <span className='input-group-addon'>XX</span>
                    </div>
                </div>

                <div className='form-group has-error has-feedback'>
                    <label htmlFor='input12'>input-sm</label>
                    <input id='input12' type='text' className='form-control input-sm' />
                    <span className='form-control-feedback rioglyph rioglyph-error-sign' />
                </div>

                <hr className='margin-top-25 margin-bottom-15' />

                <div className='form-group'>
                    <label htmlFor='input13'>input</label>
                    <input id='input13' className='form-control input' type='text' placeholder='Lorem Ipsum Dolor' />
                </div>

                <div className='form-group'>
                    <label htmlFor='input14'>input-group</label>
                    <div className='input-group input-group'>
                        <span className='input-group-addon'>
                            <span className='rioglyph rioglyph-heart' />
                        </span>
                        <input id='input14' className='form-control' type='text' placeholder='Lorem Ipsum Dolor' />
                        <span className='input-group-addon'>XX</span>
                    </div>
                </div>

                <div className='form-group has-error has-feedback'>
                    <label htmlFor='input15'>input</label>
                    <input id='input15' type='text' className='form-control input' />
                    <span className='form-control-feedback rioglyph rioglyph-error-sign' />
                </div>

                <hr className='margin-top-25 margin-bottom-15' />

                <div className='form-group'>
                    <label htmlFor='input16'>input-lg</label>
                    <input id='input16' className='form-control input-lg' type='text' placeholder='Lorem Ipsum Dolor' />
                </div>

                <div className='form-group'>
                    <label htmlFor='input17'>input-group-lg</label>
                    <div className='input-group input-group-lg'>
                        <span className='input-group-addon'>
                            <span className='rioglyph rioglyph-heart' />
                        </span>
                        <input id='input17' className='form-control' type='text' placeholder='Lorem Ipsum Dolor' />
                        <span className='input-group-addon'>XX</span>
                    </div>
                </div>

                <div className='form-group has-error has-feedback'>
                    <label htmlFor='input18'>input-lg</label>
                    <input id='input18' type='text' className='form-control input-lg' />
                    <span className='form-control-feedback rioglyph rioglyph-error-sign' />
                </div>
            </div>
        </div>
    </div>
);
