import { useRef } from 'react';
import isEqual from 'lodash/fp/isEqual';

// Based on the react beta docs, we avoid using an effect here but
// use another state to store the previous value to compare to.
// See: https://react.dev/reference/react/useState#storing-information-from-previous-renders

/**
 * The usePrevious hook allows to easily access state from previous component render.
 * Instead of using a *React.ref* or a *useEffect*, this hook uses a *useState* internally to keep track of the
 * previous state value.
 *
 * Quoting from the React documentation:
 *
 * *In most cases, you don’t need this:*
 *
 * - *If the value you need can be computed entirely from the current props or other state, remove*
 * *that redundant state altogether. If you’re worried about recomputing too often, the useMemo Hook can help.*
 * - *If you want to reset the entire component tree’s state, pass a different key to your component.*
 * - *If you can, update all the relevant state in the event handlers.*
 *
 * @link https://react.dev/reference/react/useState#storing-information-from-previous-renders
 *
 * @param state The value of the current state to keep track of.
 * @returns
 */
const usePrevious = <T>(
    state: T,
    isEqualFunc?: ((value: T | null | undefined, current: T | null | undefined) => any) | undefined
): T => {
    const ref = useRef<{ target: T; previous: T }>({ target: state, previous: state });

    if (isEqualFunc ? !isEqualFunc(state, ref.current.target) : !isEqual(state, ref.current.target)) {
        ref.current.previous = ref.current.target;
        ref.current.target = state;
    }

    return ref.current.previous;
};

export default usePrevious;
